import { useState, useEffect } from 'react';
import { updateCentralReportingData } from '../../_services';
import { getTokenFromLocal } from '../../_helpers/handleResponseMiddleware';
import { centralHospitalActions, centralReportingDataActions, configActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';

const usePromiseAllSettled = () => {
  const [responses, setResponses] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  let reportingData = useSelector(state => state.centralReportingData)


    const fetchData = async (params) => {
      setIsLoading(true);
      dispatch(configActions.showSpinningLoader());
      let allIds = {}
      if(reportingData && reportingData?.reportingData?.data?.length){
        allIds =  {
          activePatientId: reportingData.activePatientId,
          lead_id:reportingData?.reportingData?.data[0]?.lead_id
        }
      }
      let user = getTokenFromLocal();
      let token = user ? user.access_token : null;
      let userName = user ? user.username || user?.name : null;
      let currentDate = new Date();
      let currentISODate = currentDate.toISOString();
       let appId = allIds && allIds.activePatientId
       let leadId= allIds && allIds.lead_id
       
      try {
        const promises = Object.keys(params).map((key, index) => {
          const updateParams = {"appId":appId,"updateObj":{ [key]:{"value":params[key],"actionTakenBy":token,"actionTakenByUser":userName,"dateTimeIso": currentISODate, "source":"hosChatApp"},"leadId":leadId}}

          return updateCentralReportingData(updateParams)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`Error fetching data from ${key}`);
              }
              return response.json();
            })
            .then((data) => ({ status: 'fulfilled', index, data }))
            .catch((error) => ({ status: 'rejected', index, error }));
        });

        const results = await Promise.allSettled(promises);

        const resolvedResponses = results
          .filter((result) => result.status === 'fulfilled')
          .map((result) => result.value);

        const rejectedErrors = results
          .filter((result) => result.status === 'rejected')
          .map((result) => result.reason);

        setResponses(resolvedResponses);
        setErrors(rejectedErrors);
      } catch (error) {
        console.error('Error fetching data:', error);
        setErrors([error]);
      } finally {
        setIsLoading(false);
        dispatch(configActions.hideSpinningLoader());
        dispatch(centralReportingDataActions.getCentralReportingData({"search_query":appId}))
        dispatch(centralHospitalActions.getCentralHospitalList());
      }
    };
    
  return [responses, errors, isLoading, fetchData];
};

export default usePromiseAllSettled;


// USAGE

// const MyComponent = () => {
//   const [data, setData] = useState([]);
//   const [errors, setErrors] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);

//   const apiUrls = ['https://api.example.com/data1', 'https://api.example.com/data2'];
//   const params = { param1: 'value1', param2: 'value2' };

//   const { responses, errors: fetchErrors, isLoading: loading } = usePromiseAllSettled(apiUrls, params);

//   useEffect(() => {
//     if (!loading) {
//       setData(responses.filter((res) => res.status === 'fulfilled').map((res) => res.data));
//       setErrors(fetchErrors.filter((err) => err.status === 'rejected').map((err) => err.error));
//       setIsLoading(false);
//     }
//   }, [responses, fetchErrors, loading]);

//   if (isLoading) {
//     return <div>Loading...</div>;
//   }

//   if (errors.length > 0) {
//     return (
//       <div>
//         {errors.map((error, index) => (
//           <div key={index}>Error: {error.message}</div>
//         ))}
//       </div>
//     );
//   }

//   // Render your data
//   return (
//     <div>
//       {data.map((response, index) => (
//         <div key={index}>
//           {/* Render your data here */}
//           {/* For example: <p>{response}</p> */}
//         </div>
//       ))}
//     </div>
//   );
// };

