import { useCallback, useRef, useState } from "react"

const useLongPress = (onLongPress, onClick, { shouldPreventDefault = true, delay = 200, shouldTriggerClick = false } = {}) => {
    const [longPressTriggered, setIsLongPressTriggered] = useState(false);
    const timer = useRef(null);
    const target = useRef(null);

    const onStart = useCallback((event) => {
        if (shouldPreventDefault && event.target) {
            event.target.addEventListener("ontouchend", preventDefault, {
                passive: true
            });
            target.current = event.target;
        }
        timer.current = setTimeout(() => {
            onLongPress(event);
            setIsLongPressTriggered(true);
        }, delay)
    }, [onLongPress, delay, shouldPreventDefault]);


    const onClear = useCallback((event) => {
        timer.current && clearTimeout(timer.current);
        setIsLongPressTriggered(false);
        if (shouldPreventDefault && target.current) {
            target.current.removeEventListener('touchend', preventDefault)
        }
       onClick();
    }, [shouldPreventDefault, onClick, longPressTriggered])

    return {
        onMouseDown: e => onStart(e),
        onTouchStart: e => onStart(e),
        onMouseUp: e => onClear(e),
        onMouseLeave: e => onClear(e),
        onTouchEnd: e => onClear(e)
    }
}

const isTouchEvent = (e) => {
    return "touches" in e;
}

const preventDefault = (e) => {
    if (!isTouchEvent(e)) return;
    if (e.touches.length < 2 && e.preventDefault) {
        e.preventDefault()
    }
}

export default useLongPress;