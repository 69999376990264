import { cacheStorageConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";
export function cacheStorage(
  state = getInitialState("cacheStorage"),
  action
) {
  const { cacheOf = "", key = "", value } = action;

  switch (action.type) {
    case cacheStorageConstants.GET_CACHE_ITEM:
      return {
        ...state,
      };

    case cacheStorageConstants.SET_CACHE_ITEM:
      return {
        ...state,
        [cacheOf]: { ...state[cacheOf], [key]: value },
      };

    case cacheStorageConstants.UPDATE_FULL_CACHE_STATE:
      const { newState } = action;

      return {
        ...newState,
      };

    case cacheStorageConstants.SYNC_CACHED_MESSAGES: {
        const {
          params: localId,
          patientId,
          cacheOf: cacheBox,
        } = action.payload;
        const currentMessages = state.chatBoxMessagesCache[patientId];

        const newCachedList = currentMessages.map((item) => {
          if (localId == item.Attributes.uploadLocalId) {
            item.isSync = true;
            return item;
          } else return item;
        });
        return {
          ...state,
          [cacheBox]: { ...state[cacheOf], [patientId]: newCachedList },
        };
      }

    case cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST: {
        const { reqPayload } = action
        var count=state.sendAgainList.length+1
        return {
          ...state,
          sendAgainList: [...state.sendAgainList, reqPayload],
          sendAgainListCount:count
        };
      }
    case cacheStorageConstants.EMPTY_SEND_AGAIN_LIST: {
      return {
        ...state,
        sendAgainList: []
      };
      }
    case cacheStorageConstants.ADD_TO_RETRY_LIST: {
        const { reqPayload } = action
        return {
          ...state,
          retryList: [...state.retryList, reqPayload]
        };
      }
    case cacheStorageConstants.SET_NETWORK_STATUS: {
        const { online } = action
        return {
          ...state,
          networkStatus: online
        };
      }
    case cacheStorageConstants.UPDATE_SEND_AGAIN_LIST_COUNT:{
      let count=state.sendAgainListCount>0?state.sendAgainListCount-1:null
      console.log('count is getting this',count)
      return{
        ...state,
        sendAgainListCount:count
      }
    }
    case cacheStorageConstants.ADD_TTL_TO_STATE:{
      const {params}=action
      return{
        ...state,
        statesWithTTL:[...state.statesWithTTL,params]
      }
    }
    case cacheStorageConstants.UPDATE_TTL_OF_STATE:{
      const {newStatesWithTTL}=action
       
      return{
        ...state,
        statesWithTTL:newStatesWithTTL
      }
    }
    default:
      return state;
  }
}
