import { Box, IconButton, InputBase } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import searchImg from '../../../assets/search-icon.png';
import { checklistActions } from "../../../_actions/checklist.actions";
import { Debounce } from "../../../_helpers";

const SearchField = () => {
  const dispatch = useDispatch()
  const { screenName, patientFilter, hospitalFilter , hospitalViewFilter} = useSelector(({ checklistSummary }) => checklistSummary)
  const [inputValue, setInputValue] = useState("")
  const [valueOnSubmit, setValueOnSubmit] = useState("")
  const searchInputDebounce = new Debounce();

  function handleSubmit(e=null) {
    console.log('handle submit is called',e)
    if(e)
    e.preventDefault()
    setValueOnSubmit(inputValue)
  }

  useEffect(() => {
    if (screenName === 'hospitalScreen') {
      setValueOnSubmit(hospitalFilter?.search)
      setInputValue(hospitalFilter?.search)
    }
    if (screenName === 'hospitalViewScreen') {
      setValueOnSubmit(hospitalViewFilter?.search)
      setInputValue(hospitalViewFilter?.search)
    }
    if (screenName === 'patientScreen') {
      setValueOnSubmit("")
      setInputValue("")
    }
  }, [screenName])


  useEffect(()=>{

    searchInputDebounce.debounce(handleSubmit, 300);
  },[inputValue])
  useEffect(() => {
    if (screenName === 'patientScreen') {
      const filterObj = { ...patientFilter, search: inputValue }
      dispatch(checklistActions.setChecklistFilters({ screenName: 'patientScreen', filterObj }))
    }
    if (screenName === 'hospitalScreen') {
      const filterObj = { ...hospitalFilter, search: inputValue }
      dispatch(checklistActions.setChecklistFilters({ screenName: 'hospitalScreen', filterObj }))
    }
    if (screenName === 'hospitalViewScreen') {
      const filterObj = { ...hospitalViewFilter, search: inputValue }
      dispatch(checklistActions.setChecklistFilters({ screenName: 'hospitalViewScreen', filterObj }))
    }
  }, [valueOnSubmit])
  return (
    <Box component={`form`}  onSubmit={handleSubmit}  sx={{ p: '4px 4px', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '5px', backgroundColor: '#fff' }}>
      <IconButton type="submit" sx={{ p: '6px' }} aria-label="search">
        <img src={searchImg} alt="search" width="20px" />
      </IconButton>
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
      />
    </Box>
  );
}

export default SearchField;