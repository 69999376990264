import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { pcScoreActions } from "../../_actions";

const OwnerInfo = () => {
  const role = useSelector(state => state.authentication.user.roles.role)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(pcScoreActions.getHospitalDocList({ role: role }));
  },[])
  return (
    <>
      <Outlet />
    </>
  );
}

export default OwnerInfo;