export const patientConstants = {
  PATIENT_LIST_REQUESTING: "PATIENT_LIST_REQUESTING",
  PATIENT_LIST_FETCHED: "PATIENT_LIST_FETCHED",
  PATIENT_LIST_FAILED: "PATIENT_LIST_FAILED",
  SET_PATIENT_INFO: "SET_PATIENT_INFO",
  GET_PATIENT_INFO_REQUESTING: "GET_PATIENT_INFO_REQUESTING",
  GET_PATIENT_INFO_FETCHED: "GET_PATIENT_INFO_FETCHED",
  GET_PATIENT_INFO_FAILED: "GET_PATIENT_INFO_FAILED",
  SURGERY_ACCEPT_REJECT_REQUESTING: "SURGERY_ACCEPT_REJECT_REQUESTING",
  SURGERY_ACCEPT_REJECT_SUCCESS: "SURGERY_ACCEPT_REJECT_SUCCESS",
  SURGERY_ACCEPT_REJECT_FAILED: "SURGERY_ACCEPT_REJECT_FAILED",

  HANDLE_SOCKET_SURGERY_REJECT: "HANDLE_SOCKET_SURGERY_REJECT",
  HANDLE_SOCKET_SURGERY_ACCEPT: "HANDLE_SOCKET_SURGERY_ACCEPT",

  UPDATE_FULL_PATIENT_STATE: "UPDATE_FULL_PATIENT_STATE",
  EDIT_END_OF_CHAT_OBJECT:"EDIT_END_OF_CHAT_OBJECT",
  UPDATE_SELECTED_MOP:"UPDATE_SELECTED_MOP",
  GET_AVAILABLE_TIME_SLOTS:"GET_AVAILABLE_TIME_SLOTS",
  SAVE_SELECTED_TIME_SLOTS:"SAVE_SELECTED_TIME_SLOTS",
  UPDATE_PATIENTS_READ_COUNT:"UPDATE_PATIENTS_READ_COUNT",
  UPDATE_PATIENTS_LIST:"UPDATE_PATIENTS_LIST"
};
