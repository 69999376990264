import { Box, Grid, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import uploadIcon from "./../../../assets/uploadIconGrey.svg";
import './CarouselList.css';
import DocumentCardThumbnail from "./DocumentCardThumbnail";
import UploadDocumentThumbnail from "./UploadDocumentThumbnail";

function CarouselList({ uploadedDocuments, previewUrls, uploadDocumentStatus, changeActiveItem, removeDoc, activeId, fileUploadClick,showClose,showUploadAnother=true }) {
    const settings = {
        className: "slider",
        dots: false,
        speed: 500,
        centerMode: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        variableWidth: true,
        centerPadding: "60px",
        rows: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    rows: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    rows: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    rows: 1,

                }
            }
        ]
    };

    return (
        <Box className='uploadDocumentsSlider' sx={{ display: ['flex', 'flex', 'none'],overflowX:"scroll", pt:"10px",pb:'10px',marginTop:['10px'],gap:"10px",height:"fit-content" }} >

                {showUploadAnother&&
                // <Box className="" sx={{ background: "#F5F5F5",height:"88px",width:"110px" }} onClick={fileUploadClick}>
                //     <div style={{
                //         display: "flex",
                //         background: "white",
                //         margin: "5px 5px 0px 5px",
                //         borderRadius: "5px",
                //         fontSize: "13px",
                //         color: "#3D7CC9",
                //         height:"67px",
                //         alignItems:"center"
                //     }} >
                //         <div style={{ flex: 1, display: "flex", alignItems: "center",background:'#F5F5F5',marginLeft:"5px",padding:"2px",borderRadius:"5px",height:"50%" }}>
                //             <img src={uploadIcon}></img>
                //         </div>
                //         <div style={{ whiteSpace: "normal",fontSize:"12px",padding:"9px" }}>
                //             Upload Another Document
                //         </div>
                //     </div>
                //     <div style={{ fontSize: "8px", padding: "0px 0px 0px 6px" }}>
                //         Select Document
                //     </div>

                // </Box>
                <DocumentCardThumbnail  fileName={'Select Document'}   handleClick={fileUploadClick}>
                    {/* {getMediaDiv({ docType: docType })} */}
                    <Grid container sx={{flexWrap:'nowrap'}}>
                    <Grid sx={{p:"0px 5px 0px 5px",display:"flex",alignItems:"center"}} item> <img style={{height:"30px"}}src={uploadIcon} alt=""></img></Grid>
                    <Grid item><Typography sx={{color:"#3D7CC9",fontSize:'11px'}}>Upload Another Document</Typography></Grid>
                    </Grid>
                </DocumentCardThumbnail>
                }
            
                {uploadedDocuments.map((item, id) => {
                    return (
                        // border:id === activeId?'1px solid #3D7CC9':''
                        // <Box sx={{background:"#F5F5F5",width:"110px",height:"76px",}}>
                            <UploadDocumentThumbnail
                                previewUrl={previewUrls[id]}
                                uploadedDocument={item}
                                docType={uploadDocumentStatus["docType"]}
                                changeActiveItem={changeActiveItem}
                                id={id}
                                activeId={activeId}
                                selected={id === activeId ? true : false}
                                removeDoc={removeDoc}
                                showClose={showClose}
                            />

                        // <Box sx={{fontSize:"8px", whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",paddingLeft:"10px",background:"#F5F5F5"}}>
                        //     {item.name}
                        // </Box>
                        // </Box>
                    );
                })
                }


        </Box>
    )
}

export default CarouselList