import { Close } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { groupInfoPopupActions, pcScoreActions } from "../../../_actions";
import { DailogHWA } from "../../../_components";
import { ProfileButton } from "../OwnerCommonComponents";
import noListImage from "./../../../assets/noList.png";


const StaffMemberList = ({blockEdit}) => {
  const [staffMembers, setStaffMembers] = useState(null)
  const { sid } = useSelector(({ hospitals: { currentHospitalData } }) => currentHospitalData)
  const { hospital_id } = useSelector((state) => state.hospitals.currentHospitalData);
  const showLoader = useSelector(state => state.config.showSpinningLoader)


  const { hospitalMembers } = useSelector(({ pcScore }) => pcScore)
  const [removeMemberModal, setRemoveMemberModal] = useState(false)
  const [selectedMember, setSelectedMember] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (hospitalMembers) {
      let members = hospitalMembers.map(hospitalMember => {
        const { attributes, memberSid } = hospitalMember
        return {
          memberSid: memberSid,
          name: attributes?.username,
          mobile: attributes?.Mobile,
          role: attributes?.role,
          userId: attributes.userId
        }
      })

      setStaffMembers(members)
    }
  }, [hospitalMembers])

  useEffect(() => {
    dispatch(pcScoreActions.getHospitalMembersListAction({
      channelSid: sid,
      listType: true
    }))
  }, [])
  const removeMember = () => {
    const { memberSid, userId } = selectedMember
    if (!userId) {
      toast.error("Can't remove this user ,Please contact tech team")
      return
    }
    dispatch(groupInfoPopupActions.removeGroupMember({ hospitalID: hospital_id, userType: "External", membersid: memberSid, userId: userId }, null, { sid: sid }))
    setRemoveMemberModal(false)
  }

  useEffect(()=>{
    document.getElementById('dashboardWrapper').scrollTop = 0
  }, [])
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingTop: "10px", px: ['0', '0', '30px', '60px'], pb: "100px" }} data-component="StaffMemberList">
      {showLoader===false&&<Grid container columnSpacing={3}>
        {staffMembers?.length > 0 && <Grid item xs={12} md={6}>
          <Box sx={{ borderRadius: '5px', background: '#fff', py: ['10px', '15px'], boxShadow: '2px 2px 6px #0000000D' }}>
            {staffMembers?.map((member, i) => {
              return (i % 2 === 0) ? (<MemberCared blockEdit={blockEdit} key={i} {...member} onCrossClick={() => { setRemoveMemberModal(true); setSelectedMember(member) }} />) : null
            })}
          </Box>
        </Grid>}
        {staffMembers?.length > 1 && <Grid item xs={12} md={6}>
          <Box sx={{ borderRadius: '5px', background: '#fff', py: ['10px', '15px'], boxShadow: '2px 2px 6px #0000000D' }}>
            {staffMembers?.map((member, i) => {
              return (i % 2 !== 0) ? (<MemberCared blockEdit={blockEdit} key={i} {...member} onCrossClick={() => { setRemoveMemberModal(true); setSelectedMember(member) }} />) : null
            })}
          </Box>
        </Grid>}
        {
          staffMembers!=null&&staffMembers?.length===0&&<div className="noDataFound" style={{justifyContent:'center',width:"100%",position: "absolute",
          top:"40%",
          left:"0px"}} >
          <div style={{fontSize:"2rem"}}>Oops! No List Found</div>
          <div style={{height:"265px"}}> <img style={{height:"100%",width:"100%",objectFit:"contain"}} src={noListImage} alt="no list img" /></div>
        </div>
        }
      </Grid>}

      <DailogHWA title={`Remove Member`} open={removeMemberModal} onClose={() => setRemoveMemberModal(false)} >
        <Box sx={{ maxWidth: '413px', pt: '30px', pb: '15px', px: ['0', '0', '20px'] }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{paddingTop:"5px !important"}}>
              <Typography color={`primary`}>Are you sure you want to remove {selectedMember?.name} from the staff list?</Typography>
            </Grid>
            <Grid item xs={6}>
              <ProfileButton onClick={() => setRemoveMemberModal(false)} sx={{width:'100%',paddingTop:'5px !important',paddingBottom:'5px !important'}} >No</ProfileButton>
            </Grid>
            <Grid item xs={6}>
              <ProfileButton onClick={() => removeMember()} sx={{width:"100%",paddingTop:'5px !important',paddingBottom:'5px !important'}} variant="contained" >Yes</ProfileButton>
            </Grid>
          </Grid>
        </Box>
      </DailogHWA>

    </Box>
  )
}
export default StaffMemberList

const MemberCared = ({ memberSid, userId, name, mobile, role, onCrossClick,blockEdit }) => {
  return (
    <Box key={memberSid} sx={{ px: ['10px', '20px', '30px'], py: ['10px', '10px', '15px'] }}>
      <Box sx={{ position: "relative", border: '2px solid #F5F5F5', borderBottom: 'none', borderRadius: '5px' }}>
        <Box sx={{
          background: '#3D7CC9',
          width: {xs: '20px', md: '30px'},
          height: {xs: '20px', md: '30px'},
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: ['1px', '1px', '2px'],
          position: 'absolute',
          zIndex: 1,
          top: '0px',
          transform: 'translateY(-50%)',
          right: "10px",
          cursor: "pointer"
        }}
          onClick={(e) => { !blockEdit&&onCrossClick && onCrossClick(e) }}
        >
          <Close sx={{ width: '100%', color: '#fff' }} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ paddingBlock: ["10px", "10px", "25px"], paddingInline: ["10px 0", "10px 0", "30px 0"], fontSize: ['12px', '14px', '16px', '18px', '20px'], flexBasis: ['40%', '40%', '30%'], borderBottom: "2px solid #F5F5F5", borderRight: "2px solid #F5F5F5" }}>Name</Box>
          <Box sx={{ paddingBlock: ["10px", "10px", "25px"], paddingInline: ["10px 0", "10px 0", "30px 0"], fontSize: ['12px', '14px', '16px', '18px', '20px'], flexBasis: ['60%', '60%', '70%'], borderBottom: "2px solid #F5F5F5", color: "#3D7CC9" }}>{name || 'Unknown'}</Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ paddingBlock: ["10px", "10px", "25px"], paddingInline: ["10px 0", "10px 0", "30px 0"], fontSize: ['12px', '14px', '16px', '18px', '20px'], flexBasis: ['40%', '40%', '30%'], borderBottom: "2px solid #F5F5F5", borderRight: "2px solid #F5F5F5" }}>Official Number</Box>
          <Box sx={{ paddingBlock: ["10px", "10px", "25px"], paddingInline: ["10px 0", "10px 0", "30px 0"], fontSize: ['12px', '14px', '16px', '18px', '20px'], flexBasis: ['60%', '60%', '70%'], borderBottom: "2px solid #F5F5F5", color: "#3D7CC9" }}>{mobile?.join(', ') || 'XXXXXXXXXX'}</Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ paddingBlock: ["10px", "10px", "25px"], paddingInline: ["10px 0", "10px 0", "30px 0"], fontSize: ['12px', '14px', '16px', '18px', '20px'], flexBasis: ['40%', '40%', '30%'], borderBottom: "2px solid #F5F5F5", borderRight: "2px solid #F5F5F5" }}>Role</Box>
          <Box sx={{ paddingBlock: ["10px", "10px", "25px"], paddingInline: ["10px 0", "10px 0", "30px 0"], fontSize: ['12px', '14px', '16px', '18px', '20px'], flexBasis: ['60%', '60%', '70%'], borderBottom: "2px solid #F5F5F5", color: "#3D7CC9" }}>{role || 'NA'}</Box>
        </Box>
      </Box>
    </Box>
  )
}