import { pcScoreConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function pcScore(
  state = getInitialState("pcScore"),
  action
) {
  let { payload } = action
  switch (action.type) {
    //  to get chat messages
    case pcScoreConstants.GET_ALL_HOSPITAL_DETAILS: {
      return { ...state, hospitalDetail: payload,     
          fetchingHospitalDetails:false
      }
    }

    case pcScoreConstants.GET_HOSPITAL_SCORE_DETAILS: {
      return { ...state, hospitalScore: payload }
    }

    case pcScoreConstants.GET_HOSPITAL_REVIEW: {
      return { ...state, hospitalReview: payload }
    }

    case pcScoreConstants.GET_HOSPITAL_MEMBER_LIST: {
      return { ...state, hospitalMembers: payload }
    }

    case pcScoreConstants.ADD_STAFF_MEMBER: {
      return { ...state }
    }

    case pcScoreConstants.SAVE_HOSPITAL_BILLING_ADDRESS: {
      return { ...state }
    }

    case pcScoreConstants.SAVE_HOSPITAL_BANK_DETAILS: {
      let newState = {...state}
      newState.hospitalDetail.bankDetails.push(payload)
      return { ...newState }
    }

    case pcScoreConstants.UPLOAD_HOSPITAL_LOGO: {
      let { payload: { files } } = action
      let url = URL.createObjectURL(files)
      return {
        ...state,
        hospitalLogo: url
      }
    }
    case pcScoreConstants.SET_HOSPITAL_LOGO: {
      let { payload: { url } } = action
      return {
        ...state,
        hospitalLogo: url
      }
    }
    case pcScoreConstants.GET_HOSPITAL_DOCLIST: {
      let { payload } = action
      return {
        ...state,
        hospDocList: payload
      }
    }
   case pcScoreConstants.FETCHING_ALL_HOSPITAL_DETAILS:{
     const {payload}=action
     return {
       ...state,
       fetchingHospitalDetails:payload
     }
   }

    default: return state
  }
}
