import { getTokenFromLocal, handleResponse } from "../_helpers/handleResponseMiddleware";
let apiUrl = process.env.REACT_APP_BASE_URL;

export function getAllHospitalDetailsService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/getHospitalDetails`, config).then(handleResponse)
}

export function getHospitalScoreDetailsService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/getHospitalScoreDetails`, config).then(handleResponse)
}

export function getHospitalReviewsService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/getHospitalReviews`, config).then(handleResponse)
}

export function getHospitalMembersListService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/v2/getMembersList`, config).then(handleResponse)
}

export function uploadHospitalLogoService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (key === "files") {
      let item = params[key];
      formData.append(key, item, item.name);
    } else {
      formData.append(key, params[key]);
    }
  });
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
    body: formData,
  };

  return fetch(`${apiUrl}/api/uploadHospitalLogo`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function uploadHospitalDocumentsService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (key === "files") {
      
      for (let i = 0; i < params[key].length; i++) {  
        let item = params[key][i];
        if(item)
        formData.append(key, item, item.name);
      }
    } else {
      formData.append(key, params[key]);
    }
  });
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
    body: formData,
  };

  return fetch(`${apiUrl}/api/uploadHospitalDocuments`, config).then(handleResponse)
}

export function addHospitalMemberService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };
  
  return fetch(`${apiUrl}/api/addHospitalMember`, config).then(handleResponse)
  // return fetch(`${apiUrl}/api/requestHospitalMember`, config).then(handleResponse)
}

export function saveHospitalBankDetailsService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/saveHospitalBankDetails`, config).then(handleResponse)
}

export function saveHospitalBillingAddressService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/saveHospitalBillingAddress`, config).then(handleResponse)
}

export function deleteHospitalDocumentService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/deleteHospitalDocuments`, config).then(handleResponse)
}
export function getHospitalDocListService(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/hospitalDocumentsList`, config).then(handleResponse)
}