import React, { useState } from 'react';
import './centralHospitalCard.css'
import { Avatar } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { isYesterday } from 'date-fns';
import { Upcoming } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import forwardIcon from "../../../assets/centralForward.png"

export const CentralHospitalCard = ({
  data,
  handleOnClick
}) => {
  const [fullName, setFullName] = useState(data.hospitalName)
  const hospitalData = formatTableData(data)

  function formatTableData(data) {
    const order = ["total", "today", "yesterday", "past", "upcoming"];
    const orderedData = [];
    const formattedData = [];
    const { hospitalId, hospitalName, counts } = data;
    const activeIpdCount = counts.activeIpdCount;
    const pendingFieldsCount = counts.pendingFieldsCount;
  
    Object.keys(activeIpdCount).forEach((category) => {
      formattedData.push({
        category,
        activeIpdCount: activeIpdCount[category],
        pendingFieldsCount: pendingFieldsCount[category]
      });
    });
  
    order.forEach(category => {
      const item = formattedData.find(data => data.category === category);
      if (item) {
        orderedData.push(item);
      }
    });
  
    return orderedData;
  };

  const calculateDate = (type) => {
    const today = new Date();
    if (type === 'yest') {
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const dd = String(yesterday.getDate()).padStart(2, '0');
      const mm = String(yesterday.getMonth() + 1).padStart(2, '0'); // January is 0!
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      const month = monthNames[yesterday.getMonth()];

      return `${dd} ${month}`;
    }
    else if (type === 'pastGap') {
      const dayPastTwo = new Date(today);
      const dayPastTen = new Date(today);

      dayPastTwo.setDate(today.getDate() - 2);
      dayPastTen.setDate(today.getDate() - 10);

      const ddPastTwo = String(dayPastTwo.getDate()).padStart(2, '0');
      const mmPastTwo = String(dayPastTwo.getMonth() + 1).padStart(2, '0');
      const ddPastTen = String(dayPastTen.getDate()).padStart(2, '0');
      const mmPastTen = String(dayPastTen.getMonth() + 1).padStart(2, '0');
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
      const monthPastTwo = monthNames[dayPastTwo.getMonth()];
      const monthPastTen = monthNames[dayPastTen.getMonth()];


      return `${ddPastTen} ${monthPastTen} to ${ddPastTwo} ${monthPastTwo}`;
    }

  };

  const handleTableCategoryType = (type) => {
    switch (type) {
      case "today":
        return <div className='todayBox'>Today</div>
        break;
      case "yesterday":
        return calculateDate('yest')
        break;
      case "past":
        return <div className='pastBox'>
          Past
          <Tooltip title={calculateDate('pastGap')} arrow touch>
            <InfoIcon fontSize='small' color='info' />
          </Tooltip>
        </div>
        break;
      case "upcoming":
        return "Upcoming"
        break;
      case "total":
        return <div className='rightSideOfCard_hospitalName'>{data.hospitalName}</div>
        break;
      default:
        break;
    }


  }


  return (

    <div className='parentCardContainer' onClick={()=>handleOnClick(data.hospitalId,data.hospitalName)}>
      <div className='leftSideOfCard'>
        {data.hospitalName && <Avatar
          sx={{
            width: 30,
            height: 30,
            fontSize: "12px",
            color: "#fff",
            bgcolor: "#3D7CC9",
          }}
        >
          {data.hospitalName
            ?.split(" ")
            .map((name) => name[0])
            .slice(0, 2)
            .join("")
            .toUpperCase()}
        </Avatar>}
      </div>
      <div className='rightSideOfCard'>
        <table>
          <tbody>
            {hospitalData.map((row, index) => (
              <tr key={index}>
                <td>{handleTableCategoryType(row.category)}</td>
                <td className={`rightSideOfCard_activeIpdCount ${row.category === 'total' ? 'rightSideOfCard_totalIpdCount':''}`}>{row.activeIpdCount}</td>
                <td className='rightSideOfCard_pendingFieldsCount'>{row.pendingFieldsCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <img src={forwardIcon} alt="forwardIcon" className='central_forwardIcon'/>
      </div>

    </div>
  )
}
