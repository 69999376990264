import { centralGetInitialState } from "../../_helpers/centralGetInitialState";
import { reportingDataConstants } from "../../_constants/centralConstant/reportingData.constant";

export function centralReportingData(
    state = centralGetInitialState("reportingData"),
    action
  ) {
    switch (action.type) {
      case reportingDataConstants.FETCH_REPORTING_DATA_REQUESTING:
        return {
          ...state,
        };
      case reportingDataConstants.FETCH_REPORTING_DATA_FETCHED:
          const { reportingData } = action;
        return {
          ...state,
          reportingData
        };
      case reportingDataConstants.FETCH_REPORTING_DATA_FAILED:
        return {
          ...state,
          reportingDataFetchingError: action.error,
        };
        case reportingDataConstants.SAVE_ACTIVE_CENTRAL_PATIENT_DATA:
          return {
            ...state,
            activePatientId: action.payload?.patientId,
            activePatientName: action.payload?.patientName,
          };

      default:
        return state;
    }
  }
  