import { Box, Typography } from "@mui/material";

const FeedbackThankyou = () => {
  return (
    <>
      <Box sx={{ minHeight: '230px', height: '575px', maxHeight: 'calc(100vh - 165px)', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography sx={{ color: "#00263E", fontSize: '15px', mb: ['20px'] }}>Thank You!</Typography>
        <CheckIcon />
        <Typography sx={{ color: '#46454C', fontSize: '15px', mt: ['20px'] }}>We appreciate your response.</Typography>
        <Typography sx={{ color: '#46454C', fontSize: '15px' }}>Your feedback is important to us!</Typography>
      </Box>
    </>
  );
}

export default FeedbackThankyou;

function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66.038" height="65.076" viewBox="0 0 66.038 65.076">
      <g id="Tick" transform="translate(0.506 0.541)">
        <path id="Path_6084" data-name="Path 6084" d="M62,29.251v2.76A30,30,0,1,1,44.207,4.592" transform="translate(0 0)" fill="none" stroke="#ff8300" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" />
        <path id="Path_6085" data-name="Path 6085" d="M48.027,4,18.006,34.051,9,25.045" transform="translate(13.969 4.017)" fill="none" stroke="#ff8300" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" />
      </g>
    </svg>
  )
}