import React from "react";
import "./listitems.css";
const ListItems = ({ concernsData = [] }) => {
  return (
    <>
      {concernsData.map((item, index) => {
        return (
          <div className="wrapper">
            <div className="left" key={index}>
              <span className="itemName">{item.name}</span>
            </div>
            <div className="right">
              <span className="itemValue">{item.value}</span>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ListItems;
