import { Box } from "@mui/material";
import { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";

const DashboardLayout = () => {
  const dashboardWrapper = useRef(null)

  const location = useLocation();
  
  useEffect(() => {
    dashboardWrapper.current.scrollTop = 0
  }, [location]);
  
  return (
    <Box sx={{ backgroundColor: '#f5f5f5', height: '100%', overflow: 'auto' }} data-component="DashboardLayout" ref={dashboardWrapper} id={`dashboardWrapper`}>
      <Outlet />
    </Box>
  );
}

export default DashboardLayout;