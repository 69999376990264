import { ArrowBackIosNewRounded, Close, KeyboardBackspace } from "@mui/icons-material";
import { Box, Button, CircularProgress, Divider, FormControl, Grid, MenuItem, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import csvDownload from "../../../assets/csvDownload.svg";
import docDownload from "../../../assets/docDownload.svg";
import imgDownload from "../../../assets/imgDownload.svg";
import pdfDownload from "../../../assets/pdfDownload.svg";
import UploadImage from "../../../assets/uploadImage.png";
import videoDownload from '../../../assets/videoDownload.svg';
import xlsDownload from "../../../assets/xlsDownload.svg";
import { pcScoreActions, userActions } from "../../../_actions";
import { DailogHWA } from "../../../_components";
import SelectHWA from "../../../_components/SelectHWA/SelectHWA";
import { getTypeOfUrl } from "../../../_helpers";
import { chatBoxService } from "../../../_services";
import DocumentPopover from "../../common/UploadDocument/DocumentPopover";
import PreviewDocument from "../../common/UploadDocument/PreviewDocuments";
import OwnerCard from "../OwnerCard";
import { OwnerHeading, ProfileButton } from "../OwnerCommonComponents";
import { OwnerInput } from "../ownerComponent";
import YourBankDetail from "../OwnerProfile/BankAccountDetail";
import StaffMemberList from "../OwnerProfile/StaffMemberList";



const OwnerProfile = () => {
  const hospitalLogo = useSelector(state => state.pcScore.hospitalLogo)
  const [screen, setScreen] = useState('screen-1')
  const handlePageRender = (pagenum) => { setScreen(pagenum) }
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const BackIcon = isSmallScreen ? ArrowBackIosNewRounded : KeyboardBackspace
  const navigate = useNavigate();
  
  const role = useSelector((state) => state.authentication?.user?.roles?.role);
  const [blockEdit,setBlockEdit]=useState(true)
  console.log('dragon this is screen', screen)
  useEffect(()=>{
    if(role?.toLowerCase()=='owner'){
      setBlockEdit(false)
    }
  },[role])

  return (
    <>
      <Box sx={{ backgroundColor: '#f5f5f5', px: '20px', minHeight: "calc(100vh - 68px)", pb: "100px" }}>
        <Box sx={{ display: 'flex', alignItems: "center", position: "relative", backgroundColor: '#3D7CC9', color: "#fff", borderRadius: '0px 0px 10px 10px', p: "17px 20px", mx: "-20px", mb: { xs: 2, md: 4 } }}>

          <BackIcon size='medium' sx={{ position: ['absolute'], left: ["15px", "15px", "40px"], fontSize: ["34px", "40px"], cursor: "pointer" }} onClick={() => (screen === 'screen-1') ? navigate(`/owner`) : handlePageRender('screen-1')} />

          <OwnerHeading>
            {screen === 'screen-2' ? 'Bank Account Details' : screen === 'screen-3' ? `Staff Members' List` : 'Profile'}
          </OwnerHeading>
        </Box>
        {screen === 'screen-2' ? <YourBankDetail handlePageRender={handlePageRender} /> : screen === 'screen-3' ? <StaffMemberList blockEdit={blockEdit} /> : <ProfileMain blockEdit={blockEdit} handlePageRender={(sName) => handlePageRender(sName)} hospitalLogo={hospitalLogo} />}
      </Box>
    </>
  );
}
export default OwnerProfile;
const SubTitle = ({ text, children, sx, ...restProps }) => <Typography sx={{ fontSize: ['12px', '12px', '20px'], color: ['#000', '#000', '#00263E'], fontWeight: [400, 400, 500], ...sx }} {...restProps} >{children || text}</Typography>

const ListItemPDF = ({ src, embdedProps, handleRemove,setDocType,setIsShowPreviewDocument,setSelectedDocuments,setPreviewUrls,setDocumentTitle,blockEdit, ...restProps }) => {
  console.log('these are embded props', embdedProps)
  const getUrlIcon = () => {
    const docType = getTypeOfUrl({ url: src })
    console.log('this is doc type', docType)
    var icon
    if (!docType) {
      return <>no data type found</>
    }
    switch (docType) {
      case "image": icon = imgDownload; break;
      case "doc": icon = docDownload; break;
      case "excel": icon = xlsDownload; break;
      case "pdf": icon = pdfDownload; break;
      case "csv": icon = csvDownload; break;
      case "video": icon = videoDownload; break;
      default: break;
    }
    return <Tooltip title="Click to Preview" placement="top" arrow>
      <img src={icon} style={{ height: "25px", width: "25px", objectFit: "contain" }} alt='' />
    </Tooltip>
  }
  const handleDocumentClick=({item})=>{
    var urlList=item.multiUrls?item.multiUrls:[src]
    const docType = getTypeOfUrl({ url: urlList[0] })
    setDocumentTitle(item.title)
    setDocType(docType)
    // var 
    chatBoxService.downloadChatMessage({ urls: urlList, isDownload: false }).then((res)=>{
      if(res.result){
        var temp=res.result?.map(item=>{
          if(docType==='doc'||docType==='excel'){
            return 'https://view.officeapps.live.com/op/embed.aspx?src='+encodeURIComponent(item)
          }else
          return item
        })
        setPreviewUrls(temp)
        setSelectedDocuments(urlList)
        setIsShowPreviewDocument(true)
      }
    })
console.log(item)
  }
  return (
    <>
      <Box sx={{ cursor:'pointer',position: 'relative', minWidth: ['110px', '110px', '200px'], width: ['110px', '110px', '200px'], mb: 0.5, borderRadius: '5px', padding: {xs:'5px 5px 5px 5px',xl:'10px 10px 10px 10px'}, backgroundColor: '#F5F5F5', mr: ['10px', '10px', '30px'] }} {...restProps} >
        <Box sx={{
          background: '#3D7CC9',
          width: ['12px', '12px', '20px'],
          height: ['12px', '12px', '20px'],
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: ['1px', '1px', '2px'],
          position: 'absolute',
          zIndex: 1,
          top: ['-6px', '-6px', '-10px'],
          right: 0,
          cursor: "pointer"
        }}
          onClick={() => handleRemove({ url: src })}
        >
          <Close sx={{ width: '100%', color: '#fff' }} />
        </Box>
        {/* <Tooltip title="Click to Preview" placement="top" arrow> */}
        <Box onClick={() => {!blockEdit&&handleDocumentClick({item:{src, ...embdedProps, ...restProps}})}} sx={{ position: 'relative', display: "flex", justifyContent: "center", alignItems: "center", overflow: 'hidden', pt: "10px", pb: "10px", background: "white" }}>
          {/* <Box
              // component={`embed`}
              //   src={src}
              //   type="application/pdf"
              //   sx={{
              //     overflow: 'hidden',
              //     border: 0,
              //     width: ['100px', '100px', '180px'],
              //     height: '100000px',
              //     position: 'absolute',
              //     transform: 'translateX(-50%)',
              //     left: '50%',
              //     top: 0,
              //     pointerEvents: "none",
              //     userSelect: 'none',
              //   }}
              //   {...embdedProps}

              sx={{ background: "black" }}
            /> */}
          {getUrlIcon()}
        </Box>
        {/* </Tooltip> */}
        <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis',color: '#666A70', fontSize: ['8px', '8px', '12px'], mt: {xs:'5px',xl:'10px'}}}>{embdedProps?.title}</Typography>
      </Box>
    </>
  )
}
const BankDetailItem = ({ accountType, accountHolder, accountNo, bankName, IFSCCode, key }) => {
  return (
    <Box sx={{
      border: ["1px solid #F5F5F5", "1px solid #F5F5F5", "2px solid #F5F5F5"],
      borderRadius: '5px', mt: '10px', display: 'flex', py: "5px", px: "10px",
      alignItems: ['flex-start', 'flex-start', 'center'],
      position: "relative"

    }}
      data-name={key}
    >
      <Box sx={{ width: '40%', py: [0, 0, 1] }}>
        <Typography sx={{ color: ['#000', '#000', '#00263E'], fontSize: ['12px', '14px', '16px', '18px', '20px'], fontWeight: [400, 400, 500], pl: ["0px", "0px", "20px"] }}>{accountType}</Typography>
      </Box>
      <Divider orientation="vertical" flexItem sx={{ mx: 1.5, borderColor: '#f5f5f5', borderRightWidth: ['1px', '1px', '2px'], position: "absolute", left: "40%", height: "85%", top: '50%', transform: 'translateY(-50%)' }} />
      <Box sx={{ fontSize: ["12px", "14px", "16px", "18px", '20px'], color: "#3D7CC9", lineHeight: ['18px', '20px', '22px', '24px', '26px'], fontWeight: 500, width: "60%", py: [0, 0, 1], pl: ["25px", "33px", "40px"], overflowWrap: 'break-word' }}>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={accountHolder}>{accountHolder}</Box>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={bankName}>{bankName}</Box>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={accountNo}>{accountNo}</Box>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={IFSCCode}>{IFSCCode}</Box>
      </Box>
    </Box>
  )
}
const ProfileMain = ({ handlePageRender, hospitalLogo,blockEdit }) => {
  const [banksDetail, setBanksDetail] = useState([])
  const childRef = useRef();
  const dispatch = useDispatch()
  const currentHospitalData = useSelector((state) => state.hospitals.currentHospitalData);
  const fetchingHospitalDetails = useSelector(state => state.pcScore.fetchingHospitalDetails)
  const { hospital_id, friendly_name } = currentHospitalData
  const { hospitalDetail } = useSelector(({ pcScore }) => pcScore)
  const [docType,setDocType]=useState('')
  const [isShowPreviewDocument,setIsShowPreviewDocument]=useState(false)
  const [selectedDocuments,setSelectedDocuments]=useState([])
  const [previewUrls,setPreviewUrls]=useState([])
  const [documentTitle,setDocumentTitle]=useState('')

  useEffect(() => {
    // set dummy data
    dispatch(userActions.getRolesList());
  }, [])
  useEffect(() => {
    if (hospitalDetail) {
      setBanksDetail(hospitalDetail?.bankDetails)
    }
  }, [hospitalDetail])
  useEffect(() => {
    dispatch(pcScoreActions.getAllHospitalDetailsAction({ hospitalId: hospital_id }))
  }, [])
  const [removeDocModal, setRemoveDocModal] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState()
  const removeHosDoc = () => {
    dispatch(pcScoreActions.deleteHospitalDocument({ hospitalId: hospital_id, url: selectedDocument }))
    setRemoveDocModal(false)
  }
  return (<>
    <Grid container columnSpacing={{ xs: 2, md: 4 }}>
      <Grid item xs={12} lg={4}>
        <OwnerCard sx={{ mb: { xs: 2, md: 4 } }}>
          <Grid container columnSpacing={2} sx={{ flexDirection: ['row-reverse', 'row-reverse', 'row'], alignItems: ['center', 'center', 'stretch'] }}>
            <Grid item xs={5} md={4} onClick={(e) => {
              !blockEdit&&
              childRef.current.handleClick({ event: e.target.parentElement, visibleIcons: ['capture', 'image'], disableDocumentTagging: true, typeOfUpload: "uploadHospitalLogo" });
            }}>
              {hospitalLogo ? <Box sx={{ height: ["60px", "86px"], cursor: "pointer" }}><Box component={`img`} sx={{ width: "100%", height: "100%", objectFit: "contain", objectPosition: ['right', 'right', 'left'] }} src={hospitalLogo} alt="hospitalLogo" /></Box> : <Box component={`img`} src={UploadImage} alt="uploadImage" sx={{ width: ['100%'], cursor: 'pointer', display: 'block' }} />}
            </Grid>
            <Grid item xs={7} md={8}>
              <Box sx={{ height: '100%', display: 'flex', flexWrap: 'wrap', alignContent: 'space-between', py: [0.5] }}>
                <Typography sx={{ fontSize: ["16px", "16px", "20px"], color: "#3D7CC9", fontWeight: ["500", "500", "600"], width: '100%' }}>{friendly_name}</Typography>
                <Typography sx={{ fontSize: ['12px', '14px', "20px"], color: "#00263E", width: '100%' }}>{hospital_id}</Typography>
              </Box>
            </Grid>
          </Grid>
        </OwnerCard>
        <OwnerCard title={`Your Bank Details`} buttonClick={() => handlePageRender('screen-2')} buttonProps={{disabled:blockEdit}} buttonText="Add New">
          {banksDetail.map((bankDetail, i) => <BankDetailItem key={i} {...bankDetail} />)}
        </OwnerCard>
      </Grid>
      <Grid item xs={12} lg={4}>
        <BillingAddress blockEdit={blockEdit} />
        <OwnerCard title="Documentation" buttonClick={(e) => childRef.current.handleClick({ event: e, typeOfUpload: "uploadHospitalDocuments", visibleIcons: ['image', 'doc', 'excel', 'pdf', 'csv'], })} buttonText={`Upload New`} buttonProps={{disabled:blockEdit}}>
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', overflowX:'auto', pb: '3px', pt: '10px' }}>
              {hospitalDetail?.DocumentsURL?.map(({ url, ...rest }) => <ListItemPDF handleRemove={({ url }) => { setRemoveDocModal(true); setSelectedDocument(url) }} src={url} setDocType={setDocType} setPreviewUrls={setPreviewUrls} setSelectedDocuments={setSelectedDocuments} setIsShowPreviewDocument={setIsShowPreviewDocument} setDocumentTitle={setDocumentTitle} embdedProps={rest} blockEdit={blockEdit} />)}
            </Box>
            {fetchingHospitalDetails === true && <Box sx={{ minHeight: '100px', background: "#f5f5f5a6", height: '100%', width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: "9", borderRadius: "10px", position: 'absolute', top: '0px' }}><CircularProgress /></Box>}
          </Box>
          <DailogHWA title={`Remove File`} open={removeDocModal} onClose={() => setRemoveDocModal(false)} >
            <Box sx={{ maxWidth: '413px', pt: '30px', pb: '15px', px: ['0', '0', '20px'] }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sx={{paddingTop:"5px !important"}}>
                  <Typography color={`primary`}>Are you sure you want to delete this document</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={() => setRemoveDocModal(false)} variant="contained" sx={{ width: '100%', backgroundColor: '#F5F5F5', boxShadow: '0px 3px 6px #00000029', color: '#666A70', fontSize: '20px', fontWeight: 700 ,paddingTop:'5px !important',paddingBottom:'5px !important',lineHeight:'1.4 !important'}}>No</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={() => removeHosDoc()} variant="contained" sx={{ width: '100%', backgroundColor: '#3D7CC9', boxShadow: '0px 3px 6px #00000029', color: '#ffffff', fontSize: '20px', fontWeight: 700 ,paddingTop:'5px !important',paddingBottom:'5px !important',lineHeight:'1.4 !important'}}>Yes</Button>
                </Grid>
              </Grid>
            </Box>
          </DailogHWA>
        </OwnerCard>
      </Grid>
      <Grid item xs={12} lg={4}>
        <StaffDetails blockEdit={blockEdit} onViewListClick={() => { handlePageRender('screen-3') }} />
      </Grid>
    </Grid>
    <DocumentPopover ref={childRef} />
    <DailogHWA title={`Preview Document`} open={isShowPreviewDocument} onClose={() =>setIsShowPreviewDocument(false)}>
    <PreviewDocument allDocs={selectedDocuments} documentTitle={documentTitle} previewUrls={previewUrls} docType={docType} showCustomHeader={false} />
    </DailogHWA>
  </>
  )
}
function StaffDetails({ onViewListClick,blockEdit }) {
  const currentHospitalData = useSelector((state) => state.hospitals.currentHospitalData);
  const [staffDetailsObj, setStaffDetailsObj] = useState({ name: '', mobile: '', role: "selectRole" })
  const userRolesList = useSelector((state) => state.users?.userRolesList?.roles?.hospital)
  const dispatch = useDispatch()
  const submitStaffDetails = () => {
  
    if (staffDetailsObj.role === 'selectRole') {
      toast.error('Enter role of staff member')
      return
    }
    if (!staffDetailsObj.name) {
      toast.error('Enter Staff Member Name')
      return
    }
    if (!staffDetailsObj.mobile) {
      toast.error('Enter Staff Member Mobile')
      return
    }
    if (staffDetailsObj.mobile.length!==10) {
      console.log("ssa")
      toast.error('Please Enter Valid Official Number')
      return
    }
    var payload = { ...staffDetailsObj }
    payload['hospitalId'] = currentHospitalData && currentHospitalData.hospital_id
    dispatch(pcScoreActions.addHospitalMemberAction(payload))
    setStaffDetailsObj({ name: '', mobile: '', role: "selectRole" })
  }
  return (
    <OwnerCard title="Staff Member" buttonClick={(e) => { onViewListClick && onViewListClick(e) }} buttonText='View List'>
      <SubTitle sx={{ mb: [2, 2, 2, 2, 3] }}>Fill following details to add new staff members:</SubTitle>
      <SelectHWA formProps={{ fullWidth:true, sx: { mb: [2, 2, 2, 2, 3] } }}
        selectProps={{
          fullWidth:true,
          value: staffDetailsObj.role, 
          sx: { height: ['30px', '30px', '50px'], fontSize: ['14px', '14px', '16px', '18px', '20px'], backgroundColor: '#F5F5F5' },
          inputProps:{ fontSize: ['12px', '14px', '16px', '18px', '20px']}
        }}
        onChange={(event) =>
          setStaffDetailsObj((curr) => ({
            ...curr,
            "role": event.target.value,
          }))
        }
        disabled={blockEdit}
      >

        <MenuItem value="selectRole" disabled>Select Role</MenuItem>
        {userRolesList && userRolesList.map(item => {
          return <MenuItem value={item} selected={staffDetailsObj.role === item}>{item}</MenuItem>
        })}
      </SelectHWA>
      <OwnerInput onChange={(e) => { setStaffDetailsObj({ ...staffDetailsObj, name: e.target.value.replace(/[^a-zA-Z ]/gi, '') }) }} value={staffDetailsObj.name} label="Name:" placeholder="Enter Name here.." id="name" inputProps={{ disabled:blockEdit }} />
      <OwnerInput inputProps={{ maxLength: 10,disabled:blockEdit }}
        onChange={(e) => { setStaffDetailsObj({ ...staffDetailsObj, mobile: e.target.value.replace(/[^0123456789]/gi, '') })}} value={staffDetailsObj.mobile} label="Official Number:" placeholder="Enter Number here.." id="official-number" />
      <Box sx={{ textAlign: 'center', mt: [0, 0, 4], display: 'flex', flexWrap: 'wrap', justifyContent: ['space-between', 'space-between', 'center'], flexDirection: ['row-reverse', 'row-reverse', 'row'] }}>
        <ProfileButton  type="button" variant="contained" sx={{ minWidth: ['120px', '120px', '320px'], }} size="small" onClick={() => submitStaffDetails()} disabled={blockEdit}>Add</ProfileButton>
      </Box>
    </OwnerCard>
  )
}
function BillingAddress({blockEdit}) {
  const dispatch = useDispatch()
  const hospital_id = useSelector((state) => state?.hospitals?.currentHospitalData?.hospital_id);
  const { hospitalDetail } = useSelector(({ pcScore }) => pcScore)
  const [billingAddress, setBillingAddress] = useState('')
  const [isEditable, setIseditable] = useState(false)
  function updateBillingAddress() {
    if (!billingAddress) {
      toast.error("Please enter Billing Address")
      return
    }
    dispatch(pcScoreActions.saveHospitalBillingAddressAction({ hospitalId: hospital_id, billingAddress: billingAddress }))
    setIseditable(false)
  }
  useEffect(() => {
    if (hospitalDetail) {
      setBillingAddress(hospitalDetail?.BillingAddress)
    }
  }, [hospitalDetail])
  return (
    <OwnerCard title="Other Details" sx={{ mb: { xs: 2, md: 4 } }}>
      <SubTitle>Billing Address</SubTitle>
      <FormControl fullWidth sx={{ mt: isEditable ? [2] : 0 }}>
        <Box component={isEditable ? `textarea` : 'div'} value={billingAddress} placeholder="Enter Address here…" onChange={(e) => { isEditable && setBillingAddress(e.target.value); }} sx={{
          resize: "none",
          fontSize: ['12px', '14px', '16px', '18px', '20px'],
          fontWeight: '500',
          fontFamily: 'inherit',
          lineHeight: 1.4,
          height: 'calc(4em * 1.4)',
          border: '1px solid ',
          borderColor: isEditable ? '#666A70' : 'transparent',
          borderRadius: '6px',
          px: isEditable ? ['10px', '14px', '16px', '18px', '20px'] : ['10px', '14px', '0px'],
          py: ['10px', '14px', '16px', '18px', '20px'],
          overflow: 'auto',
          color: '#3D7CC9',
          "&:focus-visible": {
            outlineColor: '#1976d2'
          }
        }} >{billingAddress}</Box>
      </FormControl>
      <Box sx={{ textAlign: ['right', 'right', 'center'], mt: { xs: '10px', lg: '30px' } }}>
        {isEditable && <ProfileButton variant="contained" sx={{ minWidth: ['120px', '120px', '120px', '320px'], maxWidth: "25%", width: "100%" }} disabled={billingAddress === hospitalDetail?.BillingAddress} onClick={() => { updateBillingAddress(); }}>Save</ProfileButton>}
        {!isEditable && <ProfileButton variant="contained" sx={{ minWidth: ['120px', '120px', '120px', '320px'], maxWidth: "25%", width: "100%" }} onClick={() => { setIseditable(true) }} disabled={blockEdit}>Edit</ProfileButton>}
      </Box>
    </OwnerCard>
  )
}

