import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Divider, IconButton } from "@mui/material";
import "./centralModal.css";

export const CentralModal = (props) => {
  const {
    modalTitle = "Modal Title",
    children = "",
    description = "",
    modalButton = "",
    onSubmit = () => {},
    buttonTitle = "Submit",
    cancelButtonTitle = "Cancel",
    open = false,
    onClose =() =>{},
    onOpen = () =>{},
    sx = {},
    isSubmitDisabled = false,
    isModalFooterShow = false,
    isShowDivider = true
  } = props;

  const [openModal, setOpenModal] = React.useState(false);

  const handleClose = () => {
    onClose();
    setOpenModal(false)
  };

  const handleOpen = () => {
    onOpen();
    setOpenModal(true)
  };

  const handleOnSubmit = () => {
    onSubmit();
    onClose();
  }

  return (
    <div>
      {modalButton || (
        <div className="defaultModalButton" onClick={handleOpen}>
          View
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open || openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open || openModal}>
          <Box sx={style} style={sx}>
            <Box className="headerContainer">
              <Typography
                id="transition-modal-title"
                className="centralModalTitle"
              >
                {modalTitle}
              </Typography>
              <IconButton onClick={handleClose} className="modalCrossIcon">
                <CloseIcon />
              </IconButton>
            </Box>
            {isShowDivider ? <Divider sx={{width:'90%'}}/>:""}
            <Box className="modal-body-container">
              {description ? (
                <span className="modalDescription">{description}</span>
              ) : (
                ""
              )}
                {children}
            </Box>
            {isModalFooterShow ? (
              <>
               {isShowDivider? <Divider />:""}
                <Box className="footer-container">
                  <Button
                    variant="contained"
                    onClick={handleOnSubmit}
                    disabled={isSubmitDisabled}
                    sx={{width:'85px', background:'#3476CD', lineHeight:'18px'}}
                  >
                    {buttonTitle}
                  </Button>
                    <Button variant="outlined" onClick={handleClose} sx={{width:'85px', color:'black', border:'0.5px solid #919BAA',lineHeight:'18px'}}>
                  {cancelButtonTitle}
                  </Button>
                </Box>
              </>
            ) : (
              ""
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "87%",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  padding: "30px 10px 20px 20px",
  maxHeight: 'calc(100vh - 200px)',
  minHeight: "181px",
  maxWidth: "450px",
  overflow:'hidden'
};
