export const configConstants = {
  FETCH_VERSION_CONFIG: "FETCH_VERSION_CONFIG",
  VERSION_CONFIG_FETCHED: "VERSION_CONFIG_FETCHED",
  VERSION_CONFIG_FAILED: "VERSION_CONFIG_FAILED",
  FETCH_COMMON_DATA: "FETCH_COMMON_DATA",
  COMMON_DATA_FETCHED: "COMMON_DATA_FETCHED",
  COMMON_DATA_FAILED: "COMMON_DATA_FAILED",
  SHOW_SPINNING_LOADER: "SHOW_SPINNING_LOADER",
  HIDE_SPINNING_LOADER: "HIDE_SPINNING_LOADER",
  // for setting document list for uploaded documents
  FETCH_DOCUMENT_LIST: "FETCH_DOCUMENT_LIST",
  DOCUMENT_LIST_FETCHED: "DOCUMENT_LIST_FETCHED",
  DOCUMENT_LIST_FAILED: "DOCUMENT_LIST_FAILED",
  UPDATE_FULL_CONFIG_STATE: "UPDATE_FULL_CONFIG_STATE",
  // SENDING ALARM NOTIFICATION
  SEND_ALARM_ACTION: "SEND_ALARM_ACTION",
  SEND_ALARM_ACTION_SUCCESS: "SEND_ALARM_ACTION_SUCCESS",
  SEND_ALARM_ACTION_FAILED: "SEND_ALARM_ACTION_FAILED",
     //get opthal common data
     GET_OPTHAL_COMMON_DATA:"GET_OPTHAL_COMMON_DATA"
};
