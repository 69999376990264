import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import cancelIcon from './../../../assets/cancel.svg'
function DocumentCardThumbnail({ fileName, children, showClose = false, handleClose, id, showSelected = false, handleClick = () => { }, data, desktopUploadThumbnail=false }) {

  const renderCloseIcon = () => {
    return (
      <img style={{ position: "absolute", right:desktopUploadThumbnail?"5px":"-3px", top: "-5px", height: "14px", width: "14px",cursor:"pointer" }} onClick={(e) => {e.stopPropagation();handleClose(id)}} src={cancelIcon}></img>
    )
  }

  const handleCardClick = () => {
    handleClick(data)
  }


  useEffect(() => {

  }, [])

  return (<>
    {!desktopUploadThumbnail && <Box onClick={() => handleCardClick()} sx={{ background: "#f5f5f5", width: ['110px', '110px'],p:{xs:"5px"}, borderRadius: "5px", position: "relative", height: "fit-content", border: showSelected ? '1px solid #3D7CC9' : '' }}>
      {showClose && renderCloseIcon()}
      <Box  sx={{  cursor:'pointer',background: "white", width: ['100px', '100px'], height: ['50px', '50px'], display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
        {children}
      </Box>
      <Box sx={{  cursor:'pointer',fontSize: "10px", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100px", padding: "5px 0px 0px 0px", overflow: "hidden", fontWeight: "600", color: "#7A7A7A" }}>
        {fileName}
      </Box>
    </Box>
    }
    {
      desktopUploadThumbnail && <Box onClick={() => handleCardClick()} sx={{ height:['14vh'],background: "#f5f5f5", width: ['100%'], padding: ["10px 5px 5px 5px", "10px 5px 5px 5px"], borderRadius: "5px", position: "relative", border: showSelected ? '1px solid #3D7CC9' : '' }}>
      {showClose && renderCloseIcon()}
      <Box sx={{ cursor:'pointer',margin:"auto",background: "white", width: ['100%'], height: ['9vh'], display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
        {children}
      </Box>
      <Box sx={{ cursor:'pointer',fontSize: "10px", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%", padding: "5px 5px 5px 5px", overflow: "hidden", fontWeight: "600", color: "#7A7A7A",textAlign:"center" }}>
        {fileName}
      </Box>
    </Box>
    }

  </>
  )
}

export default DocumentCardThumbnail
