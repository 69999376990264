import React, { useState } from "react";
import "./centralSelect.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormControl, FormHelperText, MenuItem, OutlinedInput, Select } from "@mui/material";

export const CentralSelect = (props) => {
  const {
    value="",
    onChange=()=>{},
    children,
    options = [],
    placeholder = 'Select',
    label,
    jsonDetails= '',
    name = '',
    error = false, disabled=false, helperText ='', width = '47%'
  } = props;
  const [dynamicValue, setDynamicValue] = useState('')

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: {width},
        backgroundColor: "#F6F6F6",
        border: " 1px solid #EBEBEB",
        fontSize: "12px",
        maxWidth:'350px',
      },
    },
  };
  
  const handleOnChange = (event) =>{
    setDynamicValue(event.target.value)
    onChange(event)
  }

  const getValueToDisplay = () => {
    if(jsonDetails?.value && jsonDetails?.value){
      const optionsValues = options.map(option => option.value);
      const lowercaseJsonValue = (jsonDetails?.value && jsonDetails?.value).toLowerCase();
  
      const matchedValue = optionsValues.find((element) => element.toLowerCase() === lowercaseJsonValue);
      return matchedValue
    }
   return ''
  };

  return (
    <FormControl
      sx={{ width: { width }, maxWidth: "350px" ,marginTop:'20px'}}
      variant="outlined"
      disabled={disabled || (jsonDetails && jsonDetails?.disable)}
      size="small"
      margin="dense"
      placeholder={placeholder}
      name= {name || (jsonDetails && jsonDetails?.key)}

    >
      <FormHelperText id="select-field-label" className="selectFieldLabel">
        {label || (jsonDetails &&jsonDetails?.label)}
      </FormHelperText>
      <Select
        labelId = 'select-field-label'
        value={ value || dynamicValue || getValueToDisplay()}
        name= {name || (jsonDetails && jsonDetails?.postKey)}
        onChange={handleOnChange}
        placeholder={placeholder}
        error={error}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={SelectArrow}
        sx={{
            '& .MuiSelect-select .notranslate::after': placeholder
                ? {
                    content: `"${placeholder}"`,
                    opacity: 0.42,
                  }
                : {},
                height:'30px',fontSize:'12px', color:'#393939', lineHeight:'18px'
        }}
      >
        {/* <MenuItem value="" disabled className="selectMenuItemsStyle" hidden>Select</MenuItem> */}
            {options.length>0? options?.map(item => {
              return <MenuItem key={item.value} value={item.value} className="selectMenuItemsStyle" sx={{ '&.Mui-selected': { backgroundColor: '#3476CD !important' , color:'#fff' }}} >{item.value}</MenuItem>
            }):''}
        {children}
      </Select>
      {error && helperText ? (
        <FormHelperText error id="" className="selectFieldError">
          {helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </FormControl>
  );
};

const SelectArrow = ({...rest}) => <KeyboardArrowDownIcon  {...rest}  sx={{ color: '#3D7CC9!important',right:'3px!important',top:'unset!important' }}  />

