export const socketConstants = {
  SOCKET_CONNECTION_REQUESTING: "SOCKET_CONNECTION_REQUESTING",
  SOCKET_CONNECTION_SUCCESS: "SOCKET_CONNECTION_SUCCESS",
  SOCKET_CONNECTION_RECONNECTING: "SOCKET_CONNECTION_RECONNECTING",
  SOCKET_CONNECTION_STOP_ATTEMPT: "SOCKET_CONNECTION_STOP_ATTEMPT",
  SOCKET_CONNECTION_FAILED: "SOCKET_CONNECTION_FAILED",
  SOCKET_CONNECTION_CLOSED: "SOCKET_CONNECTION_CLOSED",
  SOCKET_MESSAGE_RECEIVE: "SOCKET_MESSAGE_RECEIVE",
  SOCKET_MESSAGE_REMOVED: "SOCKET_MESSAGE_REMOVED",
  SOCKET_MEMBER_ADD: "SOCKET_MEMBER_ADD",
  SOCKET_MEMBER_REMOVED: "SOCKET_MEMBER_REMOVED",
  SOCKET_SURGERY_ACCEPT: "SOCKET_SURGERY_ACCEPT",
  SOCKET_SURGERY_REJECT: "SOCKET_SURGERY_REJECT",
  UPDATE_FULL_SOCKET_STATE: "UPDATE_FULL_SOCKET_STATE",
  SOCKET_BUILD_UPDATE:"SOCKET_BUILD_UPDATE",
  SOCKET_ALARM_RECEIVED:"SOCKET_ALARM_RECEIVED"
};
