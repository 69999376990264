import React, { useEffect, useRef, useState } from 'react'
import { CentralHospitalCard } from '../../component/CentralHospitalCard/CentralHospitalCard'
import { CentralCommonHeader } from '../../component/CommonHeader/CentralCommonHeader'
import "./index.css"
import { useDispatch, useSelector } from 'react-redux'
import { centralHospitalActions } from '../../../_actions'
import { hospitalDataConstants } from '../../../_constants'
import { Debounce } from '../../../_helpers'
import { useNavigate } from 'react-router-dom'

export const CentralHospitalList = () => {
    const dispatch = useDispatch()
    const isMountedRef= useRef(true)
    const isApp = localStorage.getItem("isApp");
    const centraHospitaData = useSelector(state => state.centralHospitalData.listData)
    const [searchInput, setSearchInput] = useState("");
    const [hospitalData,setHospitalData] = useState([])
    const [noDataFound,setNoDataFound] = useState(false)
    const searchInputDebounce = new Debounce();
    const navigate = useNavigate()

    const fetchDataAndDispatch = () => {
        dispatch(centralHospitalActions.getCentralHospitalList());
    };

    useEffect(() => {
        fetchDataAndDispatch();
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        const fetchDataInterval = setInterval(() => {
            fetchDataAndDispatch();
        }, 20000);
        return () => clearInterval(fetchDataInterval);
    }, []);

    useEffect(()=>{
        if(centraHospitaData && centraHospitaData.length>0 && isMountedRef.current){
            setNoDataFound(false)
            setHospitalData(centraHospitaData)
        }
        else{
            setNoDataFound(true)
            setHospitalData([])
        }
    },[centraHospitaData])

    useEffect(() => {
        if (isMountedRef.current) {
          searchInputDebounce.debounce(filterHospitalList, 100);
        }
      }, [searchInput]);

    const handleHospitalSearch = (val)=>{
        setSearchInput(val)  
    }

    const filterHospitalList = ()=>{
        if(searchInput===''){
            setNoDataFound(false)
            dispatch(centralHospitalActions.getCentralHospitalList())
        }else{
            let filteredList= centraHospitaData.filter(item=>item.hospitalName.toLowerCase().includes(searchInput.toLowerCase())) 
            if(filteredList && filteredList.length>0){
                setNoDataFound(false)
                setHospitalData(filteredList)
            }
            else{
                setHospitalData([])
                setNoDataFound(true)
            }
        }
    }

    const handleCardClick =(id, hospitalName)=>{
        if(id && hospitalName){
            dispatch({
                type:hospitalDataConstants.SAVE_ACTIVE_CENTRAL_HOSPITAL_DATA,
                payload:{id,hospitalName}
            })
        }
        navigate(`/central/${id}`)
    }

    const handleBackFunction = ()=>{
        if(isApp && window?.JBridge){
            window.JBridge.handleBackToApp()
        }
        else{
            navigate('/home')
        }
    }

    const clearFunction = ()=>{
        setSearchInput('')
        setNoDataFound(false)
        dispatch(centralHospitalActions.getCentralHospitalList())
    }
    
  return (
    <>
    <CentralCommonHeader title='Active Cases' searchIcon={true} backIcon={true} backArrowFunction={handleBackFunction} searchFunction={handleHospitalSearch} clearFunction={clearFunction}/>
    <div className='centralHospitalList_header'>
        <p style={{flex:'0.4'}}>Hospitals</p>
        <div className='centralHospitalList_headerFields'>
            <p>Active Cases<br/><span>(Admission Date)</span></p>
            <p>Pending Fields</p>
        </div>
    </div>
    <div className='centralHospitalList_container'>
    {
        hospitalData && hospitalData.length>0 && hospitalData.map((item,index)=>{
            return <CentralHospitalCard data={item} handleOnClick={handleCardClick}/>
        }) 
    }
    {
        noDataFound ? 
        <div className='noResultFound'>
            No Result Found
        </div> :''
    }
    </div>
    </>
  )
}