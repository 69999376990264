import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";


const AccordianCustom = ({ children, title, subtitle, onChange, ...rest }) => {

  return (
    <Accordion
      onChange={(event, status) => {
        if (typeof (onChange) === 'function') onChange(event, status)
      }}
      sx={{ boxShadow: '2px 2px 6px #0000000D', border: '1px solid #3D7CC9', borderRadius: '5px', mt: ['0'], mb: ['20px'] }}
      {...rest}
    >
      <AccordionSummary
        sx={{ p: ['0 15px'],"&expanded":{margin:"10px 0px"} }}
        aria-controls="panel-content"
        expandIcon={<Box sx={{ lineHeight: 1 }}><ExpandMore sx={{ backgroundColor: '#3D7CC9', color: '#fff', borderRadius: '4px', fontSize: '1.25rem', verticalAlign: 'middle' }} /></Box>}
      >
        <Box sx={{ width: '100%' }}>
          {title && <Typography variant="body1" component={`h2`} sx={{ fontWeight: 500, color: "#3D7CC9", lineHeight: 1.25, wordBreak: 'break-all' }}>{title}</Typography>}
          {subtitle && <Typography variant="caption" sx={{ lineHeight: 1.25 }}>{subtitle}</Typography>}
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: "1px solid #3D7CC9", pt: 2 }}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default AccordianCustom;
