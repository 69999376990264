import { mainCacheConstants } from ".";
import { cacheStorageActions, chatBoxActions } from "../_actions";
import { cacheStorageConstants } from "../_constants";

const modifyObject = (payload, name, role, isSync, isMedia) => {
  var newPayload = {};
  for (let key in payload) {
    if (
      !(
        key === "hospital_id" ||
        key === "hasMedia" ||
        key === "mediaUrl" ||
        key === "loadingMedia" ||
        key === "longMediaSize" ||
        key === "key" ||
        key === "destination" ||
        key === "subkey" ||
        key === "title" ||
        key === "files" ||
        key === "" ||
        key === "hasMedia" ||
        key === "loadingMedia" ||
        key === "messageType"
      )
    ) {
      newPayload[key.charAt(0).toUpperCase() + key.substring(1)] = payload[key];

      if (key === "attributes" || key === "Attributes") {
        newPayload["Attributes"] = { ...payload[key], isVisible: true };
      }
    } else newPayload[key] = payload[key];
  }
  newPayload["AuthorName"] = name;
  newPayload["AuthorRole"] = role;
  newPayload["isSync"] = isSync;

  if (isMedia) {
    newPayload["hasMedia"] = true;
  }

  return newPayload;
};
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

const handleChatBoxActions = (
  dispatch,
  getState,
  status,
  payload,
  isMedia = false,
  generalChatActive =false,
  privateChatMessagesActive=false
) => {
  const {
    authentication,
    patients,
    chatBox,
    cacheStorage,
  } = getState();

  const { name } = authentication.user;
  const { role } = authentication.user.roles;

  switch (status) {
    // adding message to history
    
    case "addMessage": {
      const { patientMessages, generalChatMessages, privateChatMessages } = chatBox;
      const newMessage = modifyObject(payload, name, role, false, isMedia);
      const {
        Attributes: { appointment_id,lead_id },
        ChannelSid,
      } = payload;

      
      console.log('add message is getting called')
      let chatboxMessages = generalChatActive
        ? generalChatMessages
        : privateChatMessagesActive
        ? privateChatMessages
        : patientMessages;
      const newPatientMessages = [ ...chatboxMessages,newMessage];
      var newState = {};

      if (generalChatActive) {
        newState = {
          ...chatBox,
          generalChatMessages: newPatientMessages,
        };
      } else if(privateChatMessagesActive){
        newState = {
          ...chatBox,
          privateChatMessages: newPatientMessages
        }
      } else {
        newState = {
          ...chatBox,
          patientMessages: newPatientMessages,
        };
      }
      dispatch(chatBoxActions.updateFullState(newState));
      let cache = generalChatActive
        ? mainCacheConstants.chatBoxGeneralChatMessagesCache
        : privateChatMessagesActive
        ? mainCacheConstants.privateChatMessagesCache
        : mainCacheConstants.chatBoxMessagesCache;
      let key = generalChatActive ? ChannelSid : appointment_id?appointment_id:lead_id;
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: cache,
          key: key,
          value: newPatientMessages,
        })
      );
      setTimeout(() => {
        const el = document.getElementById("customScrollContainer")
        if (el) {
          el.style.overflow = 'auto';
          window.requestAnimationFrame(() => el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' }));
        }
      }, 500);
        const el = document.getElementById("customScrollContainer")
        if (el) {
          el.style.overflow = 'auto';
          window.requestAnimationFrame(() => el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' }));
        }

      break;
    }

    // updating message from response
    case "updateMessage": {
      var newMessage = payload.result[0];
      newMessage = modifyObject(newMessage, name, role, true, isMedia);
      const {
        Attributes: { appointment_id,lead_id},
        ChannelSid,
      } = newMessage;
      var {
        Attributes: { uploadLocalId },
      } = newMessage;
      console.log('RRRRRR',newMessage)
      const { patientMessages, generalChatMessages, privateChatMessages } = chatBox;
      let chatboxMessages = generalChatActive
        ? generalChatMessages
        : privateChatMessagesActive
        ? privateChatMessages
        : patientMessages;
      var prevMessage;
      let newPatientMessages = chatboxMessages.map((item) => {
        if (uploadLocalId === item.Attributes.uploadLocalId) {
          prevMessage = item;
          return newMessage;
        } else {
          return item;
        }
      });


      if (isMedia) {
        newMessage = {
          ...newMessage,
          mediaUrl: prevMessage.mediaUrl,
          Media: newMessage.Media,
          loadingMedia: false,
          Attributes: {
            ...newMessage.Attributes,
            multiUrl: newMessage.Attributes.multiUrl,
          },
        };
      }

      if (prevMessage.mediaUrl) {
        newMessage = { ...newMessage, mediaUrl: prevMessage.mediaUrl };
      }
      if (prevMessage.MediaType) {
        newMessage = {
          ...newMessage,
          mediaUrl: prevMessage.mediaUrl,
          MediaType: prevMessage.MediaType,
        };
      }

      newPatientMessages = chatboxMessages.map((item) => {
        if (uploadLocalId === item.Attributes.uploadLocalId) {
          prevMessage = item;
          return newMessage;
        } else {
          return item;
        }
      });

      let newState = {};

      if (generalChatActive) {
        newState = {
          ...chatBox,
          generalChatMessages: newPatientMessages,
        };
      } else if(privateChatMessagesActive){
        newState = {
          ...chatBox,
          privateChatMessages:newPatientMessages
        }
      } 
      else {
        newState = {
          ...chatBox,
          patientMessages: newPatientMessages,
        };
      }

      dispatch(chatBoxActions.updateFullState(newState));

      let cache = generalChatActive
        ? mainCacheConstants.chatBoxGeneralChatMessagesCache
        : privateChatMessagesActive
        ? mainCacheConstants.privateChatMessagesCache
        : mainCacheConstants.chatBoxMessagesCache;
      let key = generalChatActive ? ChannelSid : appointment_id;
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: cache,
          key: key,
          value: newPatientMessages,
        })
      );
      break;
    }
    // updating send again list
    case "updateSendAgainList": {
      const { sendAgainList, networkStatus } = cacheStorage;


      for (let i = 0; i < sendAgainList.length; i++) {
        let reqPayload = sendAgainList[i];

        if (reqPayload.files) {

          let payload = { ...reqPayload }
          var arr = []
          for (let i = 0; i < payload['files'].length; i++) {
            let item = payload['files'][i]
            if (item.fileUrl)
              arr.push(dataURLtoFile(item.fileUrl, item.fileName))
          }
          if (arr.length === 0) {
            dispatch(cacheStorageActions.updateSendAgainListCount())
            return
          }
          reqPayload['files'] = arr

          delete reqPayload.generalChatActive
          delete reqPayload.documentCheck
          
          dispatch(chatBoxActions.sendFile(reqPayload, null, networkStatus, payload.generalChatActive, payload.documentCheck, true))
        } else if (reqPayload.attributes.message_reply_index) {
          let payload = { ...reqPayload }
          delete reqPayload.generalChatActive
          delete reqPayload.documentCheck
          dispatch(
            chatBoxActions.sendReply(reqPayload, null, "", networkStatus, payload.generalChatActive, true)
          );

        } else {
          let payload = { ...reqPayload }

          delete reqPayload.generalChatActive
          delete reqPayload.documentCheck

          console.log('dragon reply', reqPayload)
          dispatch(
            chatBoxActions.sendMessage(reqPayload, null, "", networkStatus, payload.generalChatActive, true)
          );
        }
      }
      dispatch({ type: cacheStorageConstants.EMPTY_SEND_AGAIN_LIST });

      break;
    }

    // updating retry  list
    case "updateRetryList": {
      const { retryList, networkStatus } = cacheStorage;
      // debugger
      const { uploadLocalId } = payload;
      var localId = uploadLocalId;
      let idx = null;
      for (let i = 0; i < retryList.length; i++) {
        let reqPayload = retryList[i];
        let {
          attributes: { uploadLocalId },
        } = reqPayload;

        if (uploadLocalId === localId) {
          idx = i;
          if (reqPayload.files) {

            let payload = { ...reqPayload }
            let arr = []
            for (let i = 0; i < payload['files'].length; i++) {
              let item = payload['files'][i]
              arr.push(dataURLtoFile(item.fileUrl, item.fileName))
            }
            reqPayload['files'] = arr
            console.log('minion minion', arr)
            delete reqPayload.generalChatActive
            delete reqPayload.documentCheck
            console.log('minion this is payload', payload.generalChatActive, payload.documentCheck)
            dispatch(chatBoxActions.sendFile(reqPayload, null, networkStatus, payload.generalChatActive, payload.documentCheck,payload.privateChatMessagesActive))
          } else if (reqPayload.attributes.message_reply_index) {
            let payload = { ...reqPayload }
            delete reqPayload.generalChatActive
            delete reqPayload.documentCheck
            dispatch(
              chatBoxActions.sendReply(reqPayload, null, "", networkStatus, payload.generalChatActive,null,payload.privateChatMessagesActive)
            );

          } else {
            let payload = { ...reqPayload }

            delete reqPayload.generalChatActive
            delete reqPayload.documentCheck

            console.log('dragon reply', reqPayload)
            dispatch(
              chatBoxActions.sendMessage(reqPayload, null, "", networkStatus, payload.generalChatActive,null,payload.privateChatMessagesActive)
            );
          }
        }
      }
      if (idx != null) retryList.splice(idx, 1);

      const newState = {
        ...cacheStorage,
        retryList: retryList,
      };
      dispatch(cacheStorageActions.updateFullState(newState));

      break;
    }
    // update Message locally
    case "updateMessageLocally": {
    // debugger
      const { uploadLocalId, retryStatus } = payload;
      const ChannelSid  = payload?.reqPayload.channelSid;

      let appointment_id
      if(payload?.reqPayload.attributes){
        appointment_id = payload.reqPayload.attributes.appointment_id
      }
      else if(payload?.reqPayload.Attributes){
        appointment_id = payload.reqPayload.Attributes.appointment_id
      }
      console.log('updateMessageLocally',payload)

      const { patientMessages, generalChatMessages, privateChatMessages } = chatBox;
      
      let chatboxMessages = generalChatActive
      ? generalChatMessages
      : privateChatMessagesActive
      ? privateChatMessages
      : patientMessages;

      let newPatientMessages = chatboxMessages.map((item) => {
        if (uploadLocalId === item.Attributes.uploadLocalId) {
          item.Retry = retryStatus;
          item.isSync = true;
          return item;
        } else {
          return item;
        }
      });

      let newState = {}

      if(generalChatActive){
        newState = {
          ...chatBox,
          generalChatMessages: newPatientMessages,
        }
      }
      else if(privateChatMessagesActive){
        newState = {
          ...chatBox,
          privateChatMessages:newPatientMessages
        }
      }
      else{
        newState = {
          ...chatBox,
          patientMessages: newPatientMessages,
        };
      }
      dispatch(chatBoxActions.updateFullState(newState));

      let cache = generalChatActive
        ? mainCacheConstants.chatBoxGeneralChatMessagesCache
        : privateChatMessagesActive
        ? mainCacheConstants.privateChatMessagesCache
        : mainCacheConstants.chatBoxMessagesCache;

      let key = generalChatActive ? ChannelSid : appointment_id;

      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: cache,
          key: key,
          value: newPatientMessages,
        })
      );

      break;
    }
    // fetch more messages
    case "fetchMoreMessages": {
      const { patientMessages, generalChatMessages } = chatBox;


      let chatboxMessages = generalChatActive
        ? generalChatMessages
        : patientMessages;
      const newPatientMessages = [...chatboxMessages, ...payload];
      let newState = {};

      if (generalChatActive) {
        newState = {
          ...chatBox,
          generalChatMessages: newPatientMessages,
        };
      } else {
        newState = {
          ...chatBox,
          patientMessages: newPatientMessages,
        };
      }

      dispatch(chatBoxActions.updateFullState(newState));

      let cache = generalChatActive
        ? mainCacheConstants.chatBoxGeneralChatMessagesCache
        : mainCacheConstants.chatBoxMessagesCache;


      let key = ""
      if (payload) {
        key = generalChatActive ? payload[0]?.ChannelSid : payload[0]?.AppointmentId;
      }
      if (key) {
        dispatch(
          cacheStorageActions.setCacheStorageItem({
            cacheOf: cache,
            key: key,
            value: newPatientMessages,
          })
        );
      }

      break;
    }

    case "updatePodDocsMessage": {

      const { messageSid, channelSid, appointmentId, podDocs } = payload
      const { generalChatMessages } = chatBox;
      const newPatientMessages = generalChatMessages.map((item) => {
        if (messageSid === item.MessageSid) {
          var newItem = item
          for (let i = 0; i < newItem?.Attributes?.cashlessPodData?.length; i++) {
            var subItem = newItem?.Attributes?.cashlessPodData[i]
            if (subItem.appointmentId === appointmentId) {
              newItem.Attributes.cashlessPodData[i].podDocs = podDocs
            }
          }
          return newItem;
        } else {
          return item;
        }
      });

      console.log("reaching here reaching here")
      const newState = {
        ...chatBox,
        generalChatMessages: newPatientMessages,
      };
      dispatch(chatBoxActions.updateFullState(newState));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: mainCacheConstants.chatBoxGeneralChatMessagesCache,
          key: channelSid,
          value: newPatientMessages,
        })
      );
      break;
    }

    case "updateDynamicBotMessage":{
      const { messageSid,leadId} = payload
      const { patientMessages } = chatBox;
      const newPatientMessages = patientMessages.map((item) => {
        if (messageSid === item.MessageSid) {
          var newItem = item
          newItem["waitingForApiResponse"]=true
          return newItem;
        } else {
          return item;
        }
      });

      const newState = {
        ...chatBox,
        generalChatMessages: newPatientMessages,
      };
      dispatch(chatBoxActions.updateFullState(newState));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: mainCacheConstants.chatBoxMessagesCache,
          key: leadId,
          value: newPatientMessages,
        })
      );
      break;

    }
    case "updateWithPreAuthResponse":{
      const { messageSid, appointmentId,modifiedAttributes} = payload
      const { patientMessages } = chatBox;

      const newPatientMessages = patientMessages.map((item) => {
        if (messageSid === item.MessageSid) {
          var newItem = item
          if(modifiedAttributes){
            newItem["Attributes"]= modifiedAttributes
            delete newItem.waitingForApiResponse
          }
          else{
            delete newItem.waitingForApiResponse
          }
          return newItem;
        } else {
          return item;
        }
      });

      const newState = {
        ...chatBox,
        generalChatMessages: newPatientMessages,
      };
      dispatch(chatBoxActions.updateFullState(newState));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: mainCacheConstants.chatBoxMessagesCache,
          key: appointmentId,
          value: newPatientMessages,
        })
      );
      break;

    }
    default:
      break;
  }
};

export default handleChatBoxActions
