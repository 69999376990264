import React from 'react';
import { useDispatch } from "react-redux";
import { homeStateActions } from '../../../_actions';
function UpgradedToast({ payload }) {
  const dispatch = useDispatch();
  const openChat = (payload) => {
    dispatch(homeStateActions.updateCurrentState(payload))
  }

  return (
    <>
      {payload && <div onClick={() => openChat(payload)}>{payload.notification.body}</div>}
    </>
  )
}

export default UpgradedToast