import React from "react";
import "./barchart.css";
const VerticalBarChart = ({ projectionData = [] }) => {
  const maxValue = Math.max(...projectionData.map((item) => item.value));
  return (
    <div className="vertical-bar-chart">
      {projectionData?.map((item, index) => (
        <div className="bar-container" key={index}>
          <div
            className="bar"
            style={{ height: `${(item.value / maxValue) * 100}px` }}
          >
            <span className="value">{item.value}</span>
          </div>
          <span className="label">{item.monthName}</span>
        </div>
      ))}
    </div>
  );
};

export default VerticalBarChart;
