import React from "react";
import PopUp from "../../HomeScreen/components/PopUp";
import DocumentsBody from "../DocumentsBody";
import GenericPopUp from "../GenericPopUp";
import GroupInfoBody from "../GroupInfoBody";
import LoadingSpinner from "../LoadingSpinner";

const AllPopups = () => {
  return (
    <>
      {/* popups and spinner --------------------------------------------------------------------------------------------------------------------------------------- */}
      <LoadingSpinner />
      <GenericPopUp
        data={{
          Body: GroupInfoBody,
          headerTitle: "Group Info",
          searchPlaceHolderText: "Search Group",
          popUpTitle: "showGroupInfoPopUp",
        }}
      />
      <GenericPopUp
        data={{
          Body: DocumentsBody,
          headerTitle: "Documents",
          searchPlaceHolderText: "Search Documents",
          popUpTitle: "showDocumentsPopup",
        }}
      />
      <PopUp
        data={{
          popUpTitle: "showAcceptPopup",
          type: "accept",
        }}
      />
      <PopUp
        data={{
          popUpTitle: "showRejectPopup",
          type: "reject",
        }}
      />
    </>
  );
};

export default AllPopups;
