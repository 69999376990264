import React, { createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sockette from "sockette";
import { socketActions } from "../_actions";
import { socketConstants } from "../_constants";
import { getTokenFromLocal } from '../_helpers/handleResponseMiddleware';

const WebSocketContext = createContext(null)

const generateLocalId = (length) => {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

let socketEnv = process.env.REACT_APP_SOCKET;
const localDeviceId = generateLocalId(20);
export { WebSocketContext };

const SocketConfig = ({ children }) => {
    const dispatch = useDispatch()
    let user = getTokenFromLocal();
    const userId=useSelector(state=>state.authentication.user.userId)
    
    const socketConnectionStatus = useSelector((state)=>state.socketConnectivity.socketConfig.socketConnect)
    let socketQueue = {}
    let socketFrontIndex = 0
    let socketBackwardIndex = 0
     
    let token = user ? user.access_token : null;
    let wssUrl=`wss://vwqzgx6ejk.execute-api.ap-south-1.amazonaws.com/${socketEnv}?authorization=bearer%20${token}&mobilePlatform=iOS&appName=Hospital%20App%20iOSs&mobilePlatform=desktop&appName=Hostpital_web_app&deviceId=${localDeviceId}`

    if(socketEnv!="production"){
        wssUrl= `wss://wk42v0hu0l.execute-api.ap-south-1.amazonaws.com/${socketEnv}?authorization=bearer%20${token}&mobilePlatform=iOS&appName=Hospital%20App%20iOSs&mobilePlatform=desktop&appName=Hostpital_web_app&deviceId=${localDeviceId}`
    }
    let ws = new Sockette(
        wssUrl,
        {
            timeout: 5e3,
            maxAttempts: 100,
            onopen: (e) => {
                dispatch(success(e));
                if(socketConnectionStatus && Object.keys(socketQueue).length>0){
                    executeSocketMessageQueue()
                }
                return { status: "connected", data: e };
            },
            onmessage: (e) => {
                console.log('socket message received', JSON.parse(e.data))
                dispatch(socketActions.onMessageReceive({payload:JSON.parse(e.data),sendSocketMessage}))
                return { status: "message_receive", data: e };
            },
            onreconnect: (e) => {
                return { status: "socket reconnecting", data: e };
            },
            onmaximum: (e) => {
                return { status: "stop_attempting", data: e };
            },
            onclose: (e) => {
                ws.open();
                if (ws.open) {
                } else {
                    return { status: "closed", data: e };
                }
            },
            onerror: (e) => {
                return { status: "error", data: e };
            },
        }

    );
    
    const executeSocketMessageQueue = () =>{
        const socketMessageQueue = Object.keys(socketQueue)
        socketMessageQueue.map(socket =>{
            const socketMessage = dequeueSocketMessage()
            console.log('socket message is sent',socketMessage)
            ws.send(JSON.stringify(socketMessage))
        })
    }

    const dequeueSocketMessage = () => {
        const socketMessage = socketQueue[socketFrontIndex]
        delete socketQueue[socketFrontIndex]
        socketFrontIndex++
        return socketMessage
    }

    const enqueueSocketMessage = (socketMessage) =>{
        socketQueue[socketBackwardIndex] = socketMessage
        socketBackwardIndex++
    }

    const sendSocketMessage=({action,payload})=>{
        if(socketEnv=="production"){
            return
        }      
        let sendMessagePayload={
            action,
            payload,
            userId
        }
        sendSocket(sendMessagePayload)
    }

    const sendSocket = (socketMessage)=>{
        try{
            console.log('socket message is sent',socketMessage)
            ws.send(JSON.stringify(socketMessage))
        }catch(e){
            enqueueSocketMessage(socketMessage)
        }
    }

    function success(payload) {
        // console.log('socket success method called',payload)
        return { type: socketConstants.SOCKET_CONNECTION_SUCCESS, payload };
    }
    return (<WebSocketContext.Provider value={{ws,sendSocketMessage}}>{children}</WebSocketContext.Provider>
    )
}

export default SocketConfig