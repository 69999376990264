import { toast } from "react-toastify";
import { pcScoreConstants } from "../_constants";
import { chatBoxService, deleteHospitalDocumentService, getHospitalDocListService } from "../_services";
import { addHospitalMemberService, getAllHospitalDetailsService, getHospitalMembersListService, getHospitalReviewsService, getHospitalScoreDetailsService, saveHospitalBankDetailsService, saveHospitalBillingAddressService, uploadHospitalDocumentsService, uploadHospitalLogoService } from '../_services/index';
import { configActions } from "./config.actions";

function getAllHospitalDetailsAction({ hospitalId }) {
  return (dispatch) => {
  dispatch({type:pcScoreConstants.FETCHING_ALL_HOSPITAL_DETAILS,payload:true})
    getAllHospitalDetailsService({ hospitalId }).then(
      (res) => {
        !!res?.description && toast.success(res.description)
        dispatch({
          type: pcScoreConstants.GET_ALL_HOSPITAL_DETAILS,
          payload: res.result
        });
        if(res.result.logoImageUrl){
          setHospitalLogo({payload:{isDownload:false,urls:[res.result.logoImageUrl]},dispatch})
        }
      }
    )
      .catch(error => {
        dispatch({type:pcScoreConstants.FETCHING_ALL_HOSPITAL_DETAILS,payload:false})

        toast.error(error.description)
      })
  };
}

function getHospitalScoreDetailsAction(payload) {
  return (dispatch) => {
    getHospitalScoreDetailsService(payload).then((res) => {
      dispatch({
        type: pcScoreConstants.GET_HOSPITAL_SCORE_DETAILS,
        payload: res.result
      });
    })
      .catch(error => {
        toast.error(error.description)
      })
  };
}

function getHospitalReviewsAction(payload) {
  return (dispatch) => {
    dispatch(configActions.showSpinningLoader())
    getHospitalReviewsService(payload).then(
      (res) => {
        // !!res?.description && toast.success(res.description)
        dispatch(configActions.hideSpinningLoader())
        dispatch({
          type: pcScoreConstants.GET_HOSPITAL_REVIEW,
          payload: res.result
        });
      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  };
}

function getHospitalMembersListAction(payload) {
  return (dispatch) => {
    dispatch(configActions.showSpinningLoader())
    getHospitalMembersListService(payload).then((res) => {
        // !!res?.description && toast.success(res.description)
        dispatch(configActions.hideSpinningLoader())
        dispatch({
          type: pcScoreConstants.GET_HOSPITAL_MEMBER_LIST,
          payload: res.result
        });
      })
      .catch(error => {
        toast.error(error.description)
      })
  };
}

function uploadHospitalLogoAction(payload) {
  return (dispatch) => {
    uploadHospitalLogoService(payload).then(
      (res) => {
        dispatch({type:pcScoreConstants.UPLOAD_HOSPITAL_LOGO,payload})
        toast.success(res.description)
        // dispatch(success(res));
      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  }
}

function uploadHospitalDocumentsAction(payload) {
  return (dispatch) => {
    uploadHospitalDocumentsService(payload).then(
      (res) => {
        var {hospitalId}=payload
        dispatch(pcScoreActions.getAllHospitalDetailsAction({ hospitalId: hospitalId }))

        toast.success(res.description)
        // dispatch(success(res));
      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  };
}

function addHospitalMemberAction(payload) {
  return (dispatch) => {
    addHospitalMemberService(payload).then(
      (res) => {
        if (res?.status === "Failed") {
          toast.error(res.description)
          return
        }
        dispatch({
          type: pcScoreConstants.ADD_HOSPITAL_MEMBER,
          payload: payload
        })
        toast.success(res.description)
      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  };
}

function saveHospitalBankDetailsAction(payload,navigate,handlePageRender) {
  return (dispatch) => {
    dispatch(configActions.showSpinningLoader())
    saveHospitalBankDetailsService(payload).then(
      (res) => {
        toast.success(res.description)
        dispatch({
          type: pcScoreConstants.SAVE_HOSPITAL_BANK_DETAILS,
          payload: payload
        })
        dispatch(configActions.hideSpinningLoader())
      navigate(`/owner/profile`);
      handlePageRender('screen-1')

      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  };
}

function saveHospitalBillingAddressAction(payload) {
  return (dispatch) => {
    saveHospitalBillingAddressService(payload).then(
      (res) => {
        if (res?.status?.toLocaleLowerCase() === 'success') {
          !!res?.description && toast.success(res.description)
          dispatch({
            type: pcScoreConstants.SAVE_HOSPITAL_BILLING_ADDRESS
          });
        }
      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  };
}

function setHospitalLogo({payload,dispatch}){

    chatBoxService.downloadChatMessage(payload).then(res=>{
      dispatch({type:pcScoreConstants.SET_HOSPITAL_LOGO,payload:{url:res.result[0]}})
    })


}
function deleteHospitalDocument(payload){
  return (dispatch)=>{
    deleteHospitalDocumentService(payload).then(res=>{
      const {hospitalId}=payload
      dispatch(pcScoreActions.getAllHospitalDetailsAction({ hospitalId: hospitalId }))
    })
  }
}
function getHospitalDocList(payload){
  return (dispatch)=>{
    getHospitalDocListService(payload).then(res=>{
  dispatch({type:pcScoreConstants.GET_HOSPITAL_DOCLIST,payload:res.result.data})  
      })
  }
}



export const pcScoreActions = {
  getAllHospitalDetailsAction,
  getHospitalScoreDetailsAction,
  getHospitalReviewsAction,
  getHospitalMembersListAction,
  uploadHospitalLogoAction,
  uploadHospitalDocumentsAction,
  addHospitalMemberAction,
  saveHospitalBankDetailsAction,
  saveHospitalBillingAddressAction,
  setHospitalLogo,
  deleteHospitalDocument,
  getHospitalDocList
};
