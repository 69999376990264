import {
  Box,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ratingStar from "../../../assets/rating-star.svg";
import { hospitalActions, pcScoreActions } from "../../../_actions";
import {
  HighChartCustom,
  RatingIcon1,
  RatingIcon2,
  RatingIcon3,
  RatingIcon4,
  RatingIcon5,
} from "../../../_components";
import OwnerCard from "../OwnerCard";
import { ProfileButton } from "../OwnerCommonComponents";
import VerticalBarChart from "../../../_components/BarChart/BarChart";
import ListItems from "../../../_components/ListItems/ListItems";

const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "20px",
  marginBottom: "10px",
  fontWeight: "500",
}));

const CardBlueText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "600",
  color: "#3D7CC9",
  lineHeight: "30px",
  marginBottom: "10px",
}));

const OwnersGraphStatus = ({ handleOpenreview }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const currentHospitalData = useSelector(
    (state) => state.hospitals.currentHospitalData
  );
  const {
    surgeryCount,
    surgeryCount: {
      todayGraphData,
      pastWeekData,
      lastMonthData,
      pastWeekCount,
      todayCount,
      lastMonthCount,
    } = {},
    surgeriesDone,
    revenue,
    sxProjections,
    concernsRaised,
    reportCard,
  } = useSelector((state) => state.pcScore?.hospitalScore) || {};

  const [todayGraphPoints, setTodayGraphPoints] = useState([]);
  const [weekGraphPoints, setWeekGraphPoints] = useState([]);
  const [monthGraphPoints, setMonthGraphPoints] = useState([]);
  const hospitalList = useSelector((state) => state.hospitals.hospitalList);

  const getGraphPoints = () => {
    var points = [];
    points = todayGraphData.map((item) => {
      return item.ipdCount;
    });
    points = points.reverse();
    setTodayGraphPoints(points);

    points = pastWeekData.map((item) => {
      return item.ipdCount;
    });
    points = points.reverse();
    setWeekGraphPoints(points);

    points = lastMonthData.map((item) => {
      return item.ipdCount;
    });
    points = points.reverse();
    setMonthGraphPoints(points);
  };

  useEffect(() => {
    if (surgeryCount) getGraphPoints();
  }, [surgeryCount]);
  const dispatch = useDispatch();
  const { hospitalScore } = useSelector(({ pcScore }) => pcScore);

  useEffect(() => {
    if (currentHospitalData && currentHospitalData.hospital_id) {
      dispatch(
        pcScoreActions.getHospitalScoreDetailsAction({
          hospitalId: currentHospitalData.hospital_id,
        })
      );
    }
  }, [currentHospitalData.hospital_id]);
  useEffect(() => {
    if (!currentHospitalData || !currentHospitalData.hospital_id) {
      if (hospitalList && hospitalList.length > 0) {
        if (hospitalList[0].hospital_id) {
          dispatch(
            hospitalActions.setCurrentHospitalData({
              data: hospitalList[0],
            })
          );
        }
      }
    }
  }, [hospitalList]);

  const handlePdfDownload = () => {
    if (reportCard) {
      window.open(reportCard, "_blank");
    }
  };

  const rating = [
    <RatingIcon1
      sx={{
        width: { xs: "30px", md: "40px" },
        cursor: "pointer",
        borderRadius: "50%",
      }}
    />,
    <RatingIcon2
      sx={{ width: "40px", cursor: "pointer", borderRadius: "50%" }}
    />,
    <RatingIcon3
      sx={{ width: "40px", cursor: "pointer", borderRadius: "50%" }}
    />,
    <RatingIcon4
      sx={{ width: "40px", cursor: "pointer", borderRadius: "50%" }}
    />,
    <RatingIcon5
      sx={{
        width: { xs: "30px", md: "40px" },
        cursor: "pointer",
        borderRadius: "50%",
      }}
    />,
  ];
  const [dateObj, setDateObj] = useState({});
  useEffect(() => {
    var obj = {};

    var yesterday = new Date(moment().subtract(1, "d"));
    var sevenDaysBefore = new Date(moment().subtract(6, "d"));
    var monthBefore = new Date(moment().subtract(30, "d"));

    obj["yesterday"] = Date.UTC(
      yesterday.getUTCFullYear(),
      yesterday.getUTCMonth(),
      yesterday.getUTCDate()
    );
    obj["sevenDaysBefore"] = Date.UTC(
      sevenDaysBefore.getUTCFullYear(),
      sevenDaysBefore.getUTCMonth(),
      sevenDaysBefore.getUTCDate()
    );
    obj["monthBefore"] = Date.UTC(
      monthBefore.getUTCFullYear(),
      monthBefore.getUTCMonth(),
      monthBefore.getUTCDate()
    );
    setDateObj(obj);
  }, []);
  const LineDivider = ({ dividerStyles }) => {
    return (
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          border: "1px solid #f5f5f5",
          marginRight: "10%",
          ...dividerStyles,
        }}
      />
    );
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          px: ["20px", "30px", "40px", "50px", "60px"],
          pb: { xs: 8, md: 3 },
        }}
      >
        <Grid container columnSpacing={3}>
          {/* left section */}
          <Grid item xs={12} md={6}>
            <OwnerCard
              sx={{
                mb: ["20px", "20px", "31px"],
                px: ["10px", "20px", "30px", "40px"],
                py: ["20px", "40px"],
              }}
              title={`Surgery Count Status`}
              data-component="OwnersGraphStatus"
            >
              <Grid container rowSpacing={3}>
                <Grid item container>
                  <Grid item xs={6} md={4} sx={{ alignSelf: "center" }}>
                    <CardSubTitle
                      sx={{
                        fontSize: ["14px", "14px", "16px", "18px", "20px"],
                        marginBottom: ["5px", "10px", "30px"],
                      }}
                    >
                      Today
                    </CardSubTitle>
                    <CardBlueText
                      sx={{
                        fontSize: ["14px", "16px"],
                        pl: "20px",
                      }}
                    >
                      {todayCount}
                    </CardBlueText>
                  </Grid>
                  <Grid item xs={6} md={8} sx={{ alignSelf: "flex-start" }}>
                    <ChartWrapper
                      startText="Yesterday"
                      height={[32, 92, 148]}
                      series={[
                        {
                          name: "Surgeries",
                          data: todayGraphPoints,
                          color: "#F7BE00",
                        },
                      ]}
                      plotOptions={{
                        lineColor: "#00263E",
                        pointStart: dateObj["yesterday"],
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item container columnSpacing={1}>
                  <Grid item xs={6} md={6}>
                    <Box>
                      <CardSubTitle
                        sx={{
                          fontSize: ["14px", "14px", "16px", "18px", "20px"],
                        }}
                      >
                        Past Week
                      </CardSubTitle>
                      <CardBlueText
                        sx={{
                          fontSize: ["14px", "16px"],
                          p: ["0px 0px", "0px 0px", "20px 0"],
                        }}
                      >
                        {pastWeekCount}
                      </CardBlueText>
                      <Box
                        sx={{
                          border: `1px solid #F5F5F5`,
                          borderRadius: "5px",
                        }}
                      >
                        <ChartWrapper
                          startText="Past week"
                          height={[32, 55, 90]}
                          series={[
                            {
                              name: "Surgeries",
                              data: weekGraphPoints,
                              color: "#F7BE00",
                            },
                          ]}
                          plotOptions={{
                            lineColor: "#00263E",
                            pointStart: dateObj["sevenDaysBefore"],
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <CardSubTitle
                      sx={{
                        fontSize: ["14px", "14px", "16px", "18px", "20px"],
                      }}
                    >
                      Last Month
                    </CardSubTitle>
                    <CardBlueText
                      sx={{
                        fontSize: ["14px", "16px"],
                        p: ["0px 0px", "0px 0px", "20px 0"],
                      }}
                    >
                      {lastMonthCount}
                    </CardBlueText>
                    <Box
                      sx={{ border: `1px solid #F5F5F5`, borderRadius: "5px" }}
                    >
                      <ChartWrapper
                        chart={{ type: "areaspline" }}
                        startText="Last Month"
                        height={[32, 55, 90]}
                        series={[
                          { type: "areaspline" },
                          {
                            name: "Surgeries",
                            data: monthGraphPoints,
                            color: "#3D7CC9",
                          },
                        ]}
                        plotOptions={{ pointStart: dateObj["monthBefore"] }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </OwnerCard>
          </Grid>

          {/* right section */}

          <Grid item xs={12} md={6}>
            {/* patient experinece */}
            {isSmallScreen ? (
              <OwnerCard>
                <Grid container>
                  <Grid item xs>
                    <Typography
                      sx={{
                        color: "#3D7CC9",
                        fontSize: ["14px", "18px"],
                        fontWeight: "600",
                      }}
                    >
                      Patient Experience Index
                    </Typography>

                    <CardSubTitle sx={{ fontSize: ["10px", "16px"] }}>
                      Delighted
                    </CardSubTitle>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box>{rating[4]}</Box>

                      <Typography
                        component={`div`}
                        sx={{
                          color: "#3D7CC9",
                          fontSize: ["14px", "18px"],
                          fontWeight: "600",
                        }}
                      >
                        {Math.round(
                          hospitalScore?.patientExperience?.delighted * 100
                        ) / 100}
                        %
                      </Typography>
                    </Box>
                    <CardSubTitle sx={{ fontSize: ["10px", "16px"] }}>
                      Disappointed
                    </CardSubTitle>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Box>{rating[0]}</Box>
                      <Typography
                        component={`div`}
                        sx={{
                          color: "#3D7CC9",
                          fontSize: ["14px", "18px"],
                          fontWeight: "600",
                        }}
                      >
                        {Math.round(
                          hospitalScore?.patientExperience?.disappointed * 100
                        ) / 100}
                        %
                      </Typography>
                    </Box>
                  </Grid>
                  <LineDivider />
                  <Grid item xs>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#3D7CC9",
                          fontSize: ["14px", "18px"],
                          fontWeight: "600",
                        }}
                      >
                        Your PC Score
                      </Typography>
                      <CardSubTitle
                        sx={{ fontSize: ["12px", "16px"], mt: "10px" }}
                      >
                        Your Rating:
                      </CardSubTitle>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component={`img`}
                          src={ratingStar}
                          alt="ratingStar"
                          sx={{ height: { xs: "30px", md: "40px" } }}
                        ></Box>
                        <Typography
                          component={`span`}
                          sx={{
                            color: "#3D7CC9",
                            fontSize: ["14px", "18px"],
                            fontWeight: "600",
                          }}
                        >
                          {Math.round(hospitalScore?.score * 100) / 100}
                        </Typography>
                      </Box>

                      <Box>
                        <ProfileButton
                          variant="contained"
                          sx={{
                            minWidth: { xs: "120px", md: "200px", xl: "320px" },
                            mt: "10px",
                          }}
                          onClick={handleOpenreview}
                        >
                          View Reviews
                        </ProfileButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </OwnerCard>
            ) : (
              <OwnerCard>
                <Grid container>
                  <Grid item xs>
                    <Typography
                      sx={{
                        color: "#3D7CC9",
                        fontSize: ["14px", "18px"],
                        fontWeight: "600",
                      }}
                    >
                      Patient Experience Index
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                        mt: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <CardSubTitle sx={{ fontSize: ["12px", "16px"] }}>
                          Delighted
                        </CardSubTitle>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box>{rating[4]}</Box>
                          <Typography
                            component={`div`}
                            sx={{
                              color: "#3D7CC9",
                              fontSize: ["14px", "18px"],
                              fontWeight: "600",
                            }}
                          >
                            {Math.round(
                              hospitalScore?.patientExperience?.delighted * 100
                            ) / 100}
                            %
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <CardSubTitle sx={{ fontSize: ["12px", "16px"] }}>
                          Disappointed
                        </CardSubTitle>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box>{rating[0]}</Box>
                          <Typography
                            component={`div`}
                            sx={{
                              color: "#3D7CC9",
                              fontSize: ["14px", "18px"],
                              fontWeight: "600",
                            }}
                          >
                            {Math.round(
                              hospitalScore?.patientExperience?.disappointed *
                                100
                            ) / 100}
                            %
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <LineDivider />
                  <Grid item xs>
                    <Box>
                      <Typography
                        sx={{
                          color: "#3D7CC9",
                          fontSize: ["14px", "18px"],
                          fontWeight: "600",
                        }}
                      >
                        Your PC Score
                      </Typography>
                      <CardSubTitle
                        sx={{ fontSize: ["12px", "16px"], mt: "10px" }}
                      >
                        Your Rating:
                      </CardSubTitle>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Box
                          component={`img`}
                          src={ratingStar}
                          alt="ratingStar"
                          sx={{ height: { xs: "30px", md: "40px" } }}
                        ></Box>
                        <Typography
                          component={`span`}
                          sx={{
                            color: "#3D7CC9",
                            fontSize: ["14px", "18px"],
                            fontWeight: "600",
                          }}
                        >
                          {Math.round(hospitalScore?.score * 100) / 100}
                        </Typography>
                        <Box>
                          <ProfileButton
                            variant="contained"
                            onClick={handleOpenreview}
                          >
                            View Reviews
                          </ProfileButton>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </OwnerCard>
            )}

            {/* surgery count */}
            <OwnerCard>
              <Grid container>
                <Grid item xs>
                  <Typography
                    sx={{
                      color: "#3D7CC9",
                      fontSize: ["14px", "18px"],
                      fontWeight: "600",
                    }}
                  >
                    Surgeries Done
                  </Typography>

                  <Typography
                    component={`span`}
                    sx={{
                      color: "#3D7CC9",
                      fontSize: ["14px", "18px"],
                      fontWeight: "600",
                    }}
                  >
                    {surgeriesDone}
                  </Typography>
                </Grid>
                <LineDivider />
                <Grid item xs>
                  <Typography
                    sx={{
                      color: "#3D7CC9",
                      fontSize: ["14px", "18px"],
                      fontWeight: "600",
                    }}
                  >
                    Revenue Generated
                  </Typography>

                  <Typography
                    component={`span`}
                    sx={{
                      color: "#3D7CC9",
                      fontSize: ["14px", "18px"],
                      fontWeight: "600",
                    }}
                  >
                    {revenue}
                  </Typography>
                </Grid>
              </Grid>
            </OwnerCard>

            {/* Growth Projection*/}

            <OwnerCard>
              <Grid container>
                <Grid item xs>
                  <Typography
                    sx={{
                      color: "#3D7CC9",
                      fontSize: ["14px", "18px"],
                      fontWeight: "600",
                    }}
                  >
                    Growth Projecttion
                  </Typography>
                  <Box
                    sx={{
                      padding: { xs: "30px", md: "0" },
                      paddingTop: "40px !important",
                    }}
                  >
                    <VerticalBarChart projectionData={sxProjections} />
                  </Box>
                </Grid>
                {!isSmallScreen && <LineDivider />}
                <Grid item xs>
                  {!isSmallScreen && (
                    <>
                      <Typography
                        sx={{
                          color: "#3D7CC9",
                          fontSize: ["14px", "18px"],
                          fontWeight: "600",
                        }}
                      >
                        Concerns Raised
                      </Typography>
                      <Box
                        sx={{
                          padding: { xs: "10px", md: "0" },
                          paddingTop: "20px !important",
                        }}
                      >
                        <ListItems concernsData={concernsRaised} />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </OwnerCard>
            {isSmallScreen && (
              <OwnerCard>
                <Grid container>
                  <Box>
                    <Typography
                      sx={{
                        color: "#3D7CC9",
                        fontSize: ["14px", "18px"],
                        fontWeight: "600",
                      }}
                    >
                      Concerns Raised
                    </Typography>
                    <Box
                      sx={{
                        padding: { xs: "20px", md: "0" },
                      }}
                    >
                      <ListItems concernsData={concernsRaised} />
                    </Box>
                  </Box>
                </Grid>
              </OwnerCard>
            )}
          </Grid>
        </Grid>
        {/* button section */}
        {!isSmallScreen ? (
          <Grid container>
            <Grid item xs={6} md={6} container justifyContent="flex-end">
              <Link to={`profile`} style={{ textDecoration: "none" }}>
                <ProfileButton
                  variant="contained"
                  sx={{ minWidth: { xs: "150px", md: "280px", xl: "320px" } }}
                >
                  View Full Profile
                </ProfileButton>
              </Link>
            </Grid>
            <Grid item xs={6} md={6} container justifyContent="flex-end">
              <ProfileButton
                variant="contained"
                sx={{ minWidth: { xs: "150px", md: "280px", xl: "320px" } }}
                onClick={handlePdfDownload}
              >
                Download Report
              </ProfileButton>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            columnSpacing={2}
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              background: "#fff",
              padding: "15px",
            }}
          >
            <Grid item xs={6} md={6}>
              <Link to={`profile`} style={{ textDecoration: "none" }}>
                <ProfileButton
                  variant="contained"
                  sx={{
                    width: "100%",
                    background: "#fff",
                    color: "#3D7CC9",
                    border: "1px solid #3D7CC9",
                  }}
                >
                  View Full Profile
                </ProfileButton>
              </Link>
            </Grid>
            <Grid item xs={6} md={6}>
              <ProfileButton
                variant="contained"
                sx={{
                  width: "100%",
                }}
                onClick={handlePdfDownload}
              >
                Download Report
              </ProfileButton>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default OwnersGraphStatus;

const ChartStartEndText = ({ startText = "", endText = "Today" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        fontSize: ["8px", "10px", "12px", "16px"],
      }}
    >
      <Box sx={{ p: "20px 20px 0", color: "#666A70" }}>{startText}</Box>
      <Box sx={{ p: "20px 20px 0", color: "#666A70" }}>{endText}</Box>
    </Box>
  );
};

function ChartWrapper({
  startText,
  endText,
  height,
  chart,
  ...HighChartProps
}) {
  const wrapperRef = useRef(null);
  const [dynamicWidth, setDynamicWidth] = useState(100);

  useEffect(() => {
    async function handleResize() {
      let width = wrapperRef.current.clientWidth;
      setDynamicWidth(width);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{ border: [`1px solid #F5F5F5`], borderRadius: "5px" }}
      ref={wrapperRef}
    >
      <ChartStartEndText startText={startText} endText={endText} />
      {dynamicWidth && (
        <HighChartCustom
          height={height}
          chart={{ width: dynamicWidth, ...chart }}
          {...HighChartProps}
        />
      )}
    </Box>
  );
}
