import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';

const CustomInfiniteScroller = ({ fetchMoreData, inverse = false, children, hasMore }) => {
	const [pageNum, setPageNum] = useState(0);
	const [lastElement, setLastElement] = useState(null);
	const [callScrollToBottom, setCallScrollToBottom] = useState(inverse)
	const [lastScrollHeight, setLastScrollHeight] = useState(0)

	const Loader = () => {
		return <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
			<CircularProgress sx={{ width: "20px" }} />
		</Box>
	}
	const childrenWithProps = React.Children.map(children, (child, idx) => {
		// Checking isValidElement is the safe way and avoids a
		// typescript error too.

		if (React.isValidElement(child)) {
			let endElementIdx = inverse ? 0 : children.length - 1
			if (idx === endElementIdx){
				if(hasMore){
					return <Box ref={setLastElement} sx={{marginTop:"auto"}}>
					{React.cloneElement(child, { name: "endElement" })}
				</Box>;
				}else{
					return <Box sx={{marginTop:"auto"}}>
					{React.cloneElement(child, { name: "endElement" })}
				</Box>;
				}
				}
			else {
				return React.cloneElement(child, {});
			}
		}
		return child;
	});
	const firstRender = useRef(true);

	const observer = useRef(
		new IntersectionObserver((entries) => {
			const first = entries[0];
			if (first.isIntersecting) {
				setPageNum((no) => no + 1);
			}
		})
	);

	;

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		let scrollContainer = document.getElementById("customScrollContainer")
		setLastScrollHeight(scrollContainer.scrollHeight)

		if (hasMore) {
			fetchMoreData();
		}
	}, [pageNum]);

	useEffect(() => {
		if (inverse) {
			let scrollContainer = document.getElementById("customScrollContainer")
			if (scrollContainer) {
				if (scrollContainer.scrollHeight > scrollContainer.clientHeight && callScrollToBottom) {
					setTimeout(() => {
						scrollContainer.scrollTop = scrollContainer.scrollHeight;
					}, 0);
					setCallScrollToBottom(false)
				}
				else if (Math.abs(scrollContainer.scrollHeight - scrollContainer.clientHeight - scrollContainer.scrollTop)<1 ){
					setTimeout(() => {
						scrollContainer.scrollTop = scrollContainer.scrollHeight;
					}, 0);
				}
				else if(lastScrollHeight){
					setTimeout(()=>{
						scrollContainer.scrollTop = scrollContainer.scrollHeight - lastScrollHeight
					})
				}
			}
			setLastScrollHeight(0)
		}
	}, [children])

	useEffect(() => {
		setTimeout(() => {
			
			const currentElement = lastElement;
			const currentObserver = observer.current;
			if (currentElement) {
			currentObserver.observe(currentElement);
		}
		
		return () => {
			if (currentElement) {
				currentObserver.unobserve(currentElement);
			}
		};
	}, 0);
	}, [lastElement]);



	return (

		<div id="customScrollContainer" style={{ height: "100%", overflowY: "auto",display:"flex",flexDirection:"column" }}>
			{inverse && hasMore && <Loader />}
			{childrenWithProps}
			{!inverse && hasMore && <Loader />}
		</div>
	);
};

export default CustomInfiniteScroller;
