import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { groupInfoPopupActions } from '../../../../../_actions'
import { mainCacheConstants, useReduxCacheAndDispatch } from '../../../../../_helpers'
import './MobileMessageInput.css'
function UseMessageInputHook() {


  const [showUserList, setShowUserList] = useState(false)
  const [caretSavedPos, setCaretSavedPos] = useState(null)
  const [recordString, setRecordString] = useState(false)
  const privateChatMessagesActive = useSelector((state)=>state.chatBox.privateChatMessagesActive)
  const saveSelection = () => {
    var sel
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        setCaretSavedPos(sel.getRangeAt(0))
        return sel.getRangeAt(0);
      }
    } else if (document.selection && document.selection.createRange) {
      setCaretSavedPos(document.selection.createRange())
      return document.selection.createRange();
    }
    setCaretSavedPos(null)
    return null;
  }
  const restoreSelection = (range) => {
    var sel
    if (range) {
      if (window.getSelection) {
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (document.selection && range.select) {
        range.select();
      }
    }
  }
  const toggleUserListDisplay = (value) => {
    setShowUserList(value)
  }
  const getCharacterBeforeCaret = () => {
    const range = window.getSelection().getRangeAt(0);
    var text = ''
    if (range.collapsed) {
      text = range.startContainer.textContent.substring(
        0,
        range.startOffset
      );
      text = text.length - 1 >= 0 ? text[text.length - 1] : ''
    }
    return text
  }
  const handleChange = ({ checkUneditableElement = true }) => {
    saveSelection()
    if (checkUneditableElement) {
      checkForUneditableElement()
    }
    if (recordString) {
      const searchString=getSearchText()
      updateUserList(searchString)
    }
    var letter = getCharacterBeforeCaret()
    if (letter === '@') {
      setRecordString(true)
      toggleUserListDisplay(true)
    }

    if(document.getElementById('editableDiv').innerText.length===0){
        toggleUserListDisplay(false)
    }
  }
  const onCaretClick = () => {
    toggleUserListDisplay(false)
    setRecordString(false)
    handleChange({ checkUneditableElement: false })
  }
  const insertTextAtCaret = (item) => {
    console.log({item})
    // console.log("Item >>>>> ", item);
    restoreSelection(caretSavedPos)
    removeLetterBeforeCaret()
    if (window.getSelection) {
      var sel = window.getSelection();

      if (sel.getRangeAt && sel.rangeCount) {
        var range = sel.getRangeAt(0);
        var el = range.startContainer.parentElement
        range.deleteContents();
        var newNode = document.createElement("span");
        newNode.setAttribute('data-object',JSON.stringify(item))
        if(privateChatMessagesActive){
          newNode.classList.add('private__highlightedText')
        }
        else{
          newNode.classList.add('highlightedText')
        }
        newNode.appendChild(document.createTextNode(`@${item.name}`));
        range.insertNode(newNode);
        range.collapse(false);
        range.insertNode(document.createTextNode(' '))
        range.collapse(false);


      }
    } else if (document.selection && document.selection.createRange) {
      document.selection.createRange().text = item.name;
    }
    toggleUserListDisplay(false)

  }
  const removeLetterBeforeCaret = () => {
    var selection = window.getSelection()
    const range = window.getSelection().getRangeAt(0);
    var text = ''
    if (range.collapsed) {
      text = range.startContainer.textContent.substring(0,range.startOffset)
    }
    var startIdx = text.lastIndexOf('@')
    text = text.substring(startIdx, text.length)
    for (let i = 0; i < text.length; i++) {
      selection.modify('extend', 'left', 'character')
    }
    document.execCommand('delete')
  }
  const checkForUneditableElement = () => {
    if (window.getSelection) {
      var s = window.getSelection();
      if (s.getRangeAt && s.rangeCount) {
        var r = s.getRangeAt(0)
        var el = r.startContainer.parentElement
        if (el.classList.contains('highlightedText')) {
          el.remove();

          // if (r.startOffset == r.endOffset && r.endOffset == el.textContent.length) {
          // e.preventDefault();
          // s = window.getSelection();
          // el = r.startContainer.parentElement
          // el.remove();
          // } else if (r.endOffset !== el.textContent.length) {
          // e.preventDefault();
          // el.remove();
          // r.insertNode(document.createTextNode(el.innerText))
          // r.collapse(false);
          // console.log("this is inner text",el.innerText)
          // }
        }

      }

    }

  }
  const getSearchText = () => {

    const range = window.getSelection().getRangeAt(0);
    var text = ''
    if (range.collapsed) {
      text = range.startContainer.textContent.substring(
        0,
        range.startOffset
      );
    }
    var startIdx = text.lastIndexOf('@')
    return text.substring(startIdx + 1, text.length)
  }
  ///////////////////////////////////////////////////
  const [displayUserList, setDisplayUserList] = useState([])
  const groupInfo = useSelector((state) => state.groupInfoPopup.groupInfo);
  const userId = useSelector(state => state.authentication.user.userId)
  const hospitals = useSelector((state) => state.hospitals);
  const memberSid = useSelector((state) => state.hospitals?.currentHospitalData?.sid);
  const cacheAndDispatch = useReduxCacheAndDispatch();

  //this will fetch the group info if it is not yet d
  useEffect(() => {
      let temp = displayUserList.length === 0;
      if(temp){
        fetchAndUpdateGroupMembers(!temp);
      }
  }, []);
  const fetchAndUpdateGroupMembers = (sendCache) => {
      const {
          currentHospitalData: { sid },
      } = hospitals;

      const getNewCache = () => {
          if (sendCache) {
              const newMemberList = groupInfo.filter(
                  (member) => memberSid !== member["memberSid"]
              );
              return newMemberList;
          }

          return null;
      };
      console.log("call 2")

      cacheAndDispatch({
          action: groupInfoPopupActions.getGroupInfoList,
          params: { sid },
          cacheOf: mainCacheConstants.groupInfoListCache,
          key: sid,
          newCache: getNewCache(),
      });
  };
  //   this will update the user tagging list
  useEffect(() => {
      getUserTaggingList()
  }, [groupInfo]);
  //function to user tagging list 
  const getUserTaggingList = () => {
      if (Array.isArray(groupInfo) && groupInfo.length > 0) {

          let updatedUserTaggingList = groupInfo?.filter(item => {
              item['name'] = item.attributes.username
              item['id'] = item.memberSid
              return item.attributes.userId !== userId
          })

          setDisplayUserList(updatedUserTaggingList)
      }
  }
  const updateUserList=(searchString)=>{

    searchString = searchString || ''
    if (Array.isArray(groupInfo) && groupInfo.length > 0) {
        let updatedUserTaggingList = groupInfo?.filter(item => {
            item['name'] = item.attributes.username || ''
            item['id'] = item.memberSid
            return item['name'] !== null && item.attributes.userId !== userId && item['name'].toLowerCase().includes(searchString.toLowerCase())
        })
        // console.log(searchQuery,updatedUserTaggingList)
        if(updatedUserTaggingList.length===0){
            toggleUserListDisplay(false)
        }
        setDisplayUserList(updatedUserTaggingList)
    }
  }





  return { handleChange, showUserList, onCaretClick, insertTextAtCaret,displayUserList }
}



export default UseMessageInputHook