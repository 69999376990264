import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { homeStateActions } from '../../../_actions';
import { checklistActions } from '../../../_actions/checklist.actions';
import { generateLocalId, mainCacheConstants, useReduxCacheAndDispatch } from '../../../_helpers';
import { chatBoxService } from '../../../_services';

function useUploadDocumentData() {

  const documentList = useSelector((state) => state.config.documentList.data);
  const hospDocList = useSelector((state) => state.pcScore.hospDocList)
  const showGeneralChatMessages = useSelector((state) => state.homeState.showGeneralChatMessages);
  const paymentMode = useSelector((state) => state.patients.patientInfo.allInfo.paymentMode);
  const activeTab = useSelector(state => state.homeState.activeTab)
  var updatedCachePatientScreen = useSelector((state) => state.cacheStorage.checklistPatientScreenCache.patientScreen);
  var updatedCacheHospViewScreen = useSelector((state) => state.cacheStorage.checklistHospitalPatientScreenCache.hospitalViewScreen);
  var updatedCache = activeTab ==='checklistSummary/hospitalView' ?  updatedCacheHospViewScreen : updatedCachePatientScreen
  console.log("updatedCache",activeTab,updatedCacheHospViewScreen,updatedCachePatientScreen)
  const checklistSummary = useSelector((state) => state.checklistSummary);
  const { userId, name, roles: { role } } = useSelector((state) => state.authentication.user);
  const hospitalId = useSelector((state) => state.checklistSummary.selectedHospitalId);
  const hospitalViewScreenId = useSelector((state)=> state.hospitals.currentHospitalData.hospital_id)
  const { hospital_id, sid } = useSelector(state => state.hospitals.currentHospitalData)
  const uploadDocumentStatus = useSelector((state) => state.homeState.uploadDocumentStatus);
  const privateChatMessagesActive = useSelector((state)=> state.chatBox.privateChatMessagesActive)

  const dispatch = useDispatch()
  const cacheAndDispatch = useReduxCacheAndDispatch();

  const [previewUrls, setPreviewUrls] = useState([])
  const [activeId, setActiveId] = useState(0)
  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [documentListTags, setDocumentListTags] = useState([]);
  const [selectedDocumentProps, setSelectedDocumentProps] = useState({})

  useEffect(() => {
    let something = []
    if (showGeneralChatMessages) {
      something = documentList?.filter((item) => {
        return item["Key"] === "hospRegistrationCertificate";
      });
    }
    else if (uploadDocumentStatus.typeOfUpload === 'uploadHospitalDocuments') {
      something = hospDocList
    }
    else {
      something = documentList?.filter((item) => {
        return !item.paymentMode || item.paymentMode.includes(paymentMode);
      });
    }
    setDocumentListTags(something);
  }, [])
  const createPreviewUrls = async ({ uploadDocumentStatus }, docs) => {
    var docList = [];
    var length = docs.length;
    if (uploadDocumentStatus['docType'] === 'video' && (docs.length + uploadedDocuments.length > 1)) {
      toast.error(`Please select maximum of 1 video`)
      if (uploadedDocuments.length === 0) {
        handleClose();
      }
      return
    }
    if (uploadDocumentStatus['docType'] === 'video') {
      if (docs && docs[0] && (docs[0].size / (1024 * 1024)).toFixed(2) > 20) {
        toast.error(`Video size should be less than 20 MB`)
        return
      }
    }
    if (docs.length + uploadedDocuments.length > 6) {
      toast.error(`Please select maximum of 6 documents`)
      if (uploadedDocuments.length === 0) {
        handleClose();
      }
      return
    }

    for (let i = 0; i < length; i++) {
      docList.push(document.getElementById(`uploadFileInput`).files[i]);
    }
    // for (let i = 0; i < length; i++) {
    //   docList.push(docs[i]);
    // }
    setUploadedDocuments([...uploadedDocuments, ...docList]);
    var arr = [];
    let data = [];
    if (
      uploadDocumentStatus["docType"] == "doc" ||
      uploadDocumentStatus["docType"] == "excel" ||
      uploadDocumentStatus["docType"] == "csv"
    ) {
      for (let i = 0; i < docList.length; i++) {
        let tempObj = {};
        let file = docList[i];
        tempObj.fileData = await toBase64(file);
        tempObj.fileName = file.name;
        data.push(tempObj);
      }
      await chatBoxService.uploadToS3({ data: data }).then(async (res) => {
        data = [];
        let result = res.result;
        for (let i = 0; i < result.length; i++) {
          let obj = result[i];
          for (let key in obj) data.push(obj[key]);
        }

        await chatBoxService
          .downloadChatMessage({ urls: data, isDownload: false })
          .then((res) => {
            arr = res.result;
          });
        for (let i = 0; i < arr.length; i++) {
          arr[i] =
            "https://view.officeapps.live.com/op/embed.aspx?src=" +
            encodeURIComponent(arr[i]);
        }
      });
    } else if (uploadDocumentStatus["docType"] === "image") {
      for (let i = 0; i < docList.length; i++) {
        let file = docList[i];
        var temp = URL.createObjectURL(file);
        arr.push(temp);
      }
    } else if (uploadDocumentStatus["docType"] === "video") {
      for (let i = 0; i < docList.length; i++) {
        let file = docList[i];
        temp = URL.createObjectURL(file);
        arr.push(temp);
      }
    } else if (uploadDocumentStatus["docType"] === "pdf") {
      for (let i = 0; i < docList.length; i++) {
        let file = docList[i];
        temp = URL.createObjectURL(file);
        arr.push(temp);
      }
    }

    // console.log('these are the previewUrls',[...previewUrls, ...arr])
    setPreviewUrls([...previewUrls, ...arr]);


    return { previewUrls, uploadedDocuments }
  };
  const toBase64 = (file) => new Promise((resolve, reject) => { const reader = new FileReader(); reader.readAsDataURL(file); reader.onload = () => resolve(reader.result); reader.onerror = (error) => reject(error); });
  const removeDoc = (id) => {
    var length = uploadedDocuments.length;

    if (activeId == id) {
      if (id - 1 >= 0) {
        setActiveId(id - 1);
      } else if (length - 1 > 0) {
        setActiveId(0);
      } else {
        handleClose();
      }
    } else {
      if (id < activeId) {
        setActiveId(activeId - 1);
      }
    }
    setPreviewUrls(
      previewUrls.filter((item, idx) => {
        return idx != id;
      })
    );
    setUploadedDocuments(
      uploadedDocuments.filter((item, idx) => {
        return idx != id;
      })
    );
  };
  const handleClose = () => { dispatch(homeStateActions.setUploadDocumentStatus({ status: false })); };
  const changeActiveItem = (id) => {
    console.log('active item is changed', id)
    setActiveId(id)
  };
  const updateCacheWithLoader = ({ uploadDocumentStatus }) => {
    const newCache = updatedCache.map((item) => {
      if (
        item.appointmentId == uploadDocumentStatus.patientDetails.appointmentId
      ) {
        if (uploadDocumentStatus.key.includes("provisional")) {
          item.pdLoading = true;
        } else {
          item.bbLoading = true;
        }
      }
      return item;
    });
    cacheAndDispatch({
      action: checklistActions.getChecklistPatient,
      cacheOf: mainCacheConstants.checklistPatientScreenCache,
      key: "patientScreen",
      newCache: newCache,
    });

    setTimeout(() => {
      const {
        selectedHospitalId,
        filterStatus,
        hospitalFilter,
        patientFilter,
      } = checklistSummary;
      var params = {
        startDate: patientFilter.startDate
          ? patientFilter.startDate
          : hospitalFilter.startDate,
        endDate: patientFilter.endDate
          ? patientFilter.endDate
          : hospitalFilter.endDate,
        hospitalId: selectedHospitalId,
      };
      if (filterStatus && filterStatus.length > 0) {
        params["filter_status"] = filterStatus;
      }
      cacheAndDispatch({
        action: checklistActions.getChecklistPatient,
        params: params,
        cacheOf: mainCacheConstants.checklistPatientScreenCache,
        key: "patientScreen",
        fetchApi: true,
      });
    }, 10000);
  };
  const getSendFilePayload = ({ type, uploadDocumentStatus }) => {
    if ((!selectedDocumentProps || selectedDocumentProps.length === 0) && !showGeneralChatMessages && !uploadDocumentStatus.docType == 'video') {
      toast.error("Add Doc Type for the documents");
      return;
    }
    
    if (type === 'home') {
      const { allInfo: { leadId, _id, FirstName, patientId, AppointmentStartTime, age, category }={} } = uploadDocumentStatus.patientDetails||{}
      const localId = generateLocalId(35);
      if (uploadDocumentStatus && uploadDocumentStatus.typeOfUpload == "uploadPodDocument") {
        const {appointmentId,channelSid,messageSid,attributes,extraMessageAttributes}=uploadDocumentStatus?.uploadDetails||{}
        let reqPayload = { files: uploadedDocuments,appointmentId,channelSid,messageSid,attributes:JSON.stringify(attributes) }
        return { reqPayload }
      }
      else {
        const {extraMessageAttributes}=uploadDocumentStatus?.uploadDetails||{}
        const reqPayload = {
          leadId: leadId,
          appointmentId: _id,
          key: uploadDocumentStatus.docType === 'video' ? "video" : selectedDocumentProps.Key,
          destination: selectedDocumentProps?.destination,
          subkey: selectedDocumentProps?.SubKey,
          userid: userId,
          title: selectedDocumentProps?.title,
          hospId: hospital_id,
          channelSid: sid,
          mobileNumber: localStorage.getItem("userMobile").toString(),
          files: uploadedDocuments,
          messageBody: selectedDocumentProps.title ? `${selectedDocumentProps?.title} of ${FirstName}` : "File Uploaded",

          attributes: {
            message_media_name: uploadedDocuments[0].name,
            has_media: true,
            message_type: "MEDIA",
            message_media_file_type: uploadedDocuments[0].type,
            message_media_size: uploadedDocuments[0].size,
            uploadLocalId: localId,
            hospital_id: hospital_id,
            userId: userId,
            authorName: name,
            authorRole: role,
            docTypeOfPatient: selectedDocumentProps?.title,
            destination: selectedDocumentProps?.destination,
            document_key: selectedDocumentProps?.Key,
            appointment_id: _id,
            lead_id: leadId,
            patient_name: FirstName,
            patientId: patientId,
            age: age,
            appointmentTime: AppointmentStartTime?.toString(),
            category: category,
            document_subkey: selectedDocumentProps?.SubKey,
            chatType: privateChatMessagesActive? "private":"",
            ...extraMessageAttributes
          },
          messageType: "MEDIA",
        };
        const addMessagePayload = {
          isSync: false,
          leadId: leadId,
          appointmentId: _id,
          key: uploadDocumentStatus.docType === 'video' ? "video" : selectedDocumentProps.Key,
          destination: selectedDocumentProps?.destination,
          subkey: selectedDocumentProps?.SubKey,
          userid: userId,
          title: selectedDocumentProps?.title,
          hospId: hospital_id,
          mobileNumber: localStorage.getItem("userMobile").toString(),
          files: uploadedDocuments,
          MessageBody: selectedDocumentProps.title ? `${selectedDocumentProps?.title} of ${FirstName}` : "File Uploaded",
          Author: localStorage.getItem("userMobile"),
          AuthorName: name,
          AuthorRole: role,
          mediaUrl: previewUrls,
          Media: previewUrls[0],
          MediaType: uploadedDocuments[0].type,
          hasMedia: true,
          loadingMedia: false,
          Attributes: {
            isVisible: true,
            message_media_name: uploadedDocuments[0].name,
            has_media: true,
            message_type: "MEDIA",
            message_media_file_type: uploadedDocuments[0].type,
            message_media_size: uploadedDocuments[0].size,
            uploadLocalId: localId,
            hospital_id: hospital_id,
            userId: userId,
            multiUrl: previewUrls,
            authorName: name,
            authorRole: role,
            docTypeOfPatient: selectedDocumentProps?.title,
            destination: selectedDocumentProps?.destination,
            document_key: selectedDocumentProps?.Key,
            appointment_id: _id,
            lead_id: leadId,
            patient_name: FirstName,
            patientId: patientId,
            age: age,
            appointmentTime: AppointmentStartTime?.toString(),
            category: category,
            document_subkey: selectedDocumentProps?.SubKey,
            chatType: privateChatMessagesActive? "private":"",
            ...extraMessageAttributes
          },
          messageType: "MEDIA",
        };
        if (showGeneralChatMessages) {
          // modify addMessage payload for general chat
          let temp = addMessagePayload.Attributes;
          delete temp["appointment_id"];
          delete temp["lead_id"];
          delete temp["patient_name"];
          delete temp["patientId"];
          delete temp["age"];
          delete temp["appointmentTime"];
          delete temp["category"];
          addMessagePayload.Attributes = temp;

          // modify req payload for general chat

          temp = reqPayload.attributes;
          delete temp["appointment_id"];
          delete temp["lead_id"];
          delete temp["patient_name"];
          delete temp["patientId"];
          delete temp["age"];
          delete temp["appointmentTime"];
          delete temp["category"];
          reqPayload.attributes = temp;
        }

        if(!privateChatMessagesActive){
          let temp = addMessagePayload.Attributes;
          delete temp["chatType"]
    
          temp = reqPayload.attributes;
          delete temp["chatType"]
        }

        return { reqPayload: reqPayload, addMessagePayload: addMessagePayload }
      }
    } else if (type === 'checklistSummary') {

      const localId = generateLocalId(30);
      const {
        key,
        patientDetails: { appointmentId, PatientFirstName, leadId },
      } = uploadDocumentStatus;
      const selectedFile = uploadedDocuments[0];
      const reqPayload = {
        leadId: leadId,
        appointmentId: appointmentId,
        key: key,
        userid: userId,
        title:
          key == "billBreakup" ? "Bill Breakup" : "Provisional Discharge Summary",
        hospId: hospitalId,
        mobileNumber: localStorage.getItem("userMobile").toString(),
        files: uploadedDocuments,
        messageBody: (key == "billBreakup" ? "Bill Breakup" : "Provisional Discharge Summary") + ' of ' + PatientFirstName,
        destination: "appointment",
        attributes: {
          lead_id: leadId,
          message_media_name:
            key == "billBreakup"
              ? "Bill Breakup"
              : "Provisional Discharge Summary",
          has_media: true,
          message_type: "MEDIA",
          message_media_file_type: selectedFile.type,
          message_media_size: selectedFile.size,
          uploadLocalId: localId,
          hospital_id: hospitalId,
          userId: userId,
          authorName: name,
          authorRole: role,
          docTypeOfPatient:
            key == "billBreakup"
              ? "Bill Breakup"
              : "Provisional Discharge Summary",
          document_key: key,
          appointment_id: appointmentId,
          // lead_id: leadId,
          patient_name: PatientFirstName,
          // patientId: patientId,
        },
        messageType: "MEDIA",
      };

      return { reqPayload: reqPayload }
    }
    else if (type === 'checklistSummary/hospitalView') {

      const localId = generateLocalId(30);
      const {
        key,
        patientDetails: { appointmentId, PatientFirstName, leadId },
      } = uploadDocumentStatus;

      const selectedFile = uploadedDocuments[0];
      const reqPayload = {
        leadId: leadId,
        appointmentId: appointmentId,
        key: key,
        userid: userId,
        title:
          key == "finalDischargeSummary" ? "Final Discharge Summary" : key == "provBill" ? "Provisional Bill" : "Final Bill",
        hospId: hospitalViewScreenId,
        mobileNumber: localStorage.getItem("userMobile").toString(),
        files: uploadedDocuments,
        messageBody: (key == "finalDischargeSummary" ? "Final Discharge Summary" : key == "provBill" ? "Provisional Bill" : "Final Bill") + ' of ' + PatientFirstName,
        destination: "appointment",
        attributes: {
          lead_id: leadId,
          message_media_name:
          key == "finalDischargeSummary" ? "Final Discharge Summary" : key == "provBill" ? "Provisional Bill" : "Final Bill",
          has_media: true,
          message_type: "MEDIA",
          message_media_file_type: selectedFile.type,
          message_media_size: selectedFile.size,
          uploadLocalId: localId,
          hospital_id: hospitalViewScreenId,
          userId: userId,
          authorName: name,
          authorRole: role,
          docTypeOfPatient:
          key == "finalDischargeSummary" ? "Final Discharge Summary" : key == "provBill" ? "Provisional Bill" : "Final Bill",
          document_key: key,
          appointment_id: appointmentId,
          // lead_id: leadId,
          patient_name: PatientFirstName,
          // patientId: patientId,
        },
        messageType: "MEDIA",
      };

      return { reqPayload: reqPayload }
    }
    else if (uploadDocumentStatus.typeOfUpload === 'uploadHospitalLogo') {
      const reqPayload = {
        files: uploadedDocuments[0],
        hospitalId: hospital_id,
      }
      return { reqPayload: reqPayload }
    }
    else if (uploadDocumentStatus.typeOfUpload === 'uploadHospitalDocuments') {
      const reqPayload = {
        files: uploadedDocuments,
        hospitalId: hospital_id,
        documentKey: selectedDocumentProps.Key
      }
      return { reqPayload: reqPayload }
    }

  }
  const updateSelectedFileProps = (item) => {
    setSelectedDocumentProps(item)
  }
  return { previewUrls, uploadedDocuments, activeId, createPreviewUrls, changeActiveItem, removeDoc, documentListTags, updateCacheWithLoader, getSendFilePayload, selectedDocumentProps, updateSelectedFileProps }
}

export default useUploadDocumentData