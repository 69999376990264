import { configConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function config(state = getInitialState("config"), action) {
  switch (action.type) {
    case configConstants.FETCH_VERSION_CONFIG:
      return {
        ...state,
      };
    case configConstants.VERSION_CONFIG_FETCHED:
      const { versionConfigData } = action;
      return {
        ...state,
        versionConfigData,
      };
    case configConstants.VERSION_CONFIG_FAILED:
      return {
        ...state,
        versionconfigFetchingError: action.error,
      };

    case configConstants.FETCH_COMMON_DATA:
      return {
        ...state,
      };

    case configConstants.COMMON_DATA_FETCHED:
      const { commonData } = action;
      return {
        ...state,
        commonData,
      };
    case configConstants.COMMON_DATA_FAILED:
      return {
        ...state,
        commonoDataFetchingError: action.error,
      };
    case configConstants.SHOW_SPINNING_LOADER:
      return {
        ...state,
        showSpinningLoader: true,
      };
    case configConstants.HIDE_SPINNING_LOADER:
      return {
        ...state,
        showSpinningLoader: false,
      };

    // to fetch the documents
    case configConstants.FETCH_DOCUMENT_LIST:
      return {
        ...state,
      };
    case configConstants.DOCUMENT_LIST_FETCHED:
      {
        const { result = {} } = action.res;
        return {
          ...state,
          documentList: result,
        };
      }
    case configConstants.DOCUMENT_LIST_FAILED:
      return {
        ...state,
        documentListFetchingError: action.error,
      };

    // TO SEND ALARM NOTIFICATION
    case configConstants.SEND_ALARM_ACTION:
      return {
        ...state,
      };
    case configConstants.SEND_ALARM_ACTION_SUCCESS:
      const { result = {} } = action.res;
      return {
        ...state,
        alarmAction: result,
      };
    case configConstants.SEND_ALARM_ACTION_FAILED:
      return {
        ...state,
        alarmActionError: action.error,
      };

    case configConstants.UPDATE_FULL_CONFIG_STATE:
      const { newState } = action;

      return {
        ...newState,
      };
      case configConstants.GET_OPTHAL_COMMON_DATA:{

        const { payload:{result=[]} } = action;
        var tempObj={}
        for(let i=0;i<result.length;i++){
          let item=result[i]
          tempObj[item.name]=item.data
        }
        
        return {
          ...state,
          opthalFormData:tempObj
        };
        
      }

    default:
      return state;
  }
}
