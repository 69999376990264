import { socketConstants } from "../_constants";
import { handleSateWithSocket } from "../_helpers";
import { socketService } from "../_services";
import homeStateActions from "./homeState.actions";

function getSocketConnectivity() {
  // const { userId } = params;
  return (dispatch, getState) => {
    dispatch(request({ status: "socketConnectivity" }));

    let res = socketService.getSocketConnectivity(dispatch, getState);
    if (res.status === "connected") dispatch(success(res));
    if (res.status === "closed") dispatch(closed(res));
    if (res.status === "message_receive") dispatch(msgReceive(res));
    if (res.status === "reconnecting") dispatch(reconnecting(res));
    if (res.status === "stop_attempting") dispatch(onmaximum(res));
    if (res.status === "error") dispatch(failure(res));
    // history.push("/");
  };

  function request(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_REQUESTING, payload };
  }
  function success(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_SUCCESS, payload };
  }
  function msgReceive(payload) {
    let eventObj = JSON.stringify(payload);
    return { type: socketConstants.SOCKET_MESSAGE_RECEIVE, eventObj };
  }
  function reconnecting(payload) {
    return {
      type: socketConstants.SOCKET_CONNECTION_RECONNECTING,
      payload,
    };
  }
  function onmaximum(payload) {
    return {
      type: socketConstants.SOCKET_CONNECTION_STOP_ATTEMPT,
      payload,
    };
  }
  function failure(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_FAILED, payload };
  }
  function closed(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_CLOSED, payload };
  }
}

function onMessageReceive({ payload, sendSocketMessage }) {
  return (dispatch, getState) => {
    let eventType = payload.event;
    console.log('socket message', payload)
    switch (eventType) {
      case "onMemberAdded":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_MEMBER_REMOVED, payload };

      case "onMemberRemoved":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_MEMBER_REMOVED, payload };

      case "readAppointment":
        handleSateWithSocket(dispatch, getState, eventType, payload, sendSocketMessage)
        break;
  
      case "privateChatEvent":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break
      case "onMessageAdded":
        handleSateWithSocket(dispatch, getState, eventType, payload, sendSocketMessage);
        return { type: socketConstants.SOCKET_MESSAGE_RECEIVE, payload };

      case "checkUserSanity":
        handleSateWithSocket(dispatch,getState, eventType, payload);
        // return { type: socketConstants.SOCKET_MESSAGE_RECEIVE, payload };
        
      case "onMessageRemoved":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_MESSAGE_REMOVED, payload };

      case "onSurgeryAccept":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_SURGERY_ACCEPT, payload };

      case "onSurgeryReject":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        // dispatch(patientActions.handleSocketSurgeryReject(payload))
        return { type: socketConstants.SOCKET_SURGERY_REJECT, payload };
      case "onBuildUpdated":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_BUILD_UPDATE };
      case "patientArrivalTimeEvent":
        {
          dispatch(homeStateActions.toggleAlarmNotification(true))
          dispatch(homeStateActions.addAlarmNotification(payload))
          dispatch(homeStateActions.toggleAlarmSound(true))
          setTimeout(() => {
            dispatch(homeStateActions.deleteAlarmNotification())
          }, 60000);
          setTimeout(() => {
            dispatch(homeStateActions.toggleAlarmSound(false))
          }, 10000);
          return { type: socketConstants.SOCKET_ALARM_RECEIVED };
        }

      case "onChatDisable": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_BUILD_UPDATE };
      }

      case "onAppointmentCancelled": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_BUILD_UPDATE };
      }

      case "onPreviousProvBill": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case 'onAppointmentCreated': {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case "onPreviousDischargeSummary": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case "updateDynamicBotMessage": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case "onTentativeListEvent": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case "onTentativeCreateEvent": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      default:
        return { type: socketConstants.SOCKET_MESSAGE_RECEIVE, payload };
    }

  }
}
const updateFullState = (newState) => {
  return {
    type: socketConstants.UPDATE_FULL_SOCKET_STATE,
    newState,
  };
};

export const socketActions = {
  getSocketConnectivity,
  updateFullState,
  onMessageReceive
};
