import { toast } from "react-toastify";
import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let centralBaseUrl = process.env.REACT_APP_CENTRAL_UPLOAD_URL;

export function centralGetSignedUrlService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body:JSON.stringify({ ...params }),
    };
    return fetch(
      `${centralBaseUrl}/EMR/getSignedUrl`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => res)
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }
