import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import feedBackIcon from '../../../../../assets/newFeedbackIcon.png';
import feedBackIcon2 from '../../../../../assets/newfeedBackIcon2.png';
import { patientActions } from "../../../../../_actions";
import FeedbackThankyou from "./FeedbackThankyou";
import RatingScreen from "./RatingScreen";

const Feedback = ({ onSubmitAndClosed }) => {
  const [open, setOpen] = useState(false);
  const [isFeedbackSubmitted, setFeedbackSubmited] = useState(false)
  const patientInfo = useSelector((state) => state?.patients?.patientInfo);
  const dispatch = useDispatch()

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    if (isFeedbackSubmitted) {
      onSubmitAndClosed && onSubmitAndClosed()
    }
  };


  return (
    <>
      <Box sx={{
        position: 'absolute',
        top: '-10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: 1,
      }}
        onClick={handleClickOpen}>
        {!open ? <img src={feedBackIcon} style={{ width: '80px' }} /> : <img src={feedBackIcon2} style={{ width: '80px' }} /> }
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-dialog-title"
        aria-describedby="feedback-dialog-description"
        sx={{
          ".MuiDialog-container": {
            justifyContent: [null, 'end'],
            alignItems: [null, 'start'],
            pt: ['15px', '12.5vmin'],
            pr: ['15px', '12.5vmin'],
            pl: ['15px'],
            pb: ['15px']
          }
        }}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            maxWidth: '100%',
            width: '560px',
            borderBottom: (isFeedbackSubmitted && "40px solid #FF8300")
          }
        }}
      >
        <DialogTitle id="feedback-dialog-title" sx={{ backgroundColor: '#FF8300', color: "#fff", fontWeight: 600, fontSize: '16px', lineHeight: '20px', py: ['10px'], px: ['20px'], height: '40px' }}>
          {!isFeedbackSubmitted && `Share your Feedback`}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10,
              color: '#FF8300',
              backgroundColor: "#fff",
              fontSize: '18px',
              height: '1em',
              width: '1em',
              ":hover": {
                backgroundColor: 'rgb(255 255 255 / 75%)'
              }
            }}
          > <Close sx={{ fontSize: '10px' }} /> </IconButton>
        </DialogTitle>
        <DialogContent sx={{ px: '10px', pb: '30px' }}>
          {isFeedbackSubmitted ? <FeedbackThankyou /> : <RatingScreen onSubmitSuccess={(res) => {
            setFeedbackSubmited(true)
            patientInfo.allInfo.feedbackData = "refresh api to get the key" // didn't recived feedback-key on on submit so i'm putting a string.
            dispatch(patientActions.setPatientInfo(patientInfo))
          }} />}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Feedback;



