import React, { useState } from 'react';
import { FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; 
import "./centralDateTimePicker.css";

function CentralDateTimePicker({
  value='',
  placeholder,
  label='',
  handleOnChange = () =>{},
  error = false,
  disabled = false,
  helperText = '',
  width = '47%',
  jsonDetails = '',
  name = ''
}) {
  const [dateTimeValue, setDateTimeValue] = useState(null)
  const inputStyles = {
    height: '30px', 
    fontSize:'12px'
  };

  const defaultOnChange = (value) =>{
    setDateTimeValue(value)
    handleOnChange(value)
  }

  return (
    <FormControl sx={{ width: { width }, maxWidth: '350px', marginTop:'20px' }} variant="outlined" disabled={disabled || (jsonDetails && jsonDetails?.disable)}>
      <FormHelperText id="input-text-field-label" className='inputFieldLabel'>{label || (jsonDetails &&jsonDetails?.label)}</FormHelperText>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={placeholder}
          renderInput={(props) => <TextField {...props} InputProps={{ style: inputStyles }} name= {name || (jsonDetails && jsonDetails?.postKey)}/>}
          value={value && dayjs(value) || dateTimeValue && dayjs(dateTimeValue) || (jsonDetails?.value && dayjs(jsonDetails?.value)) || null}
          onChange={defaultOnChange}
          disabled={disabled || (jsonDetails && jsonDetails?.disable)}
        />
      </LocalizationProvider>
      {error && helperText ? (
        <FormHelperText error id="" className="inputFieldError">
          {helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </FormControl>
  );
}

export default CentralDateTimePicker;