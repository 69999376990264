import { toast } from "react-toastify";
import swal from "sweetalert";
import { clearLocalStorage, findUrlParameter } from "../_helpers";
import { getTokenFromLocal, handleResponse } from "../_helpers/handleResponseMiddleware";

let apiUrl = process.env.REACT_APP_BASE_URL_COMMON;
let apiUrlJava = process.env.REACT_APP_BASE_URL
let apiUrlJavaOld = process.env.REACT_APP_BASE_URL_OLD
let apiClientToken = process.env.REACT_APP_CLIENT_SECRET;

export async function getOtp(params) {
  let user = getTokenFromLocal() || { access_token: "" };
  let token = user ? user.access_token : null;

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // mobileplatform: "web",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };
  return fetch(apiUrl + "/otp/getotp", requestOptions)
    .then(handleResponse)
    .then(function (response) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(response.data));
      return response;
    })
    .catch(function (error) {
      console.error(error);

      return error;
    })
    .then(function (response) {
      // always executed
      return response;
    });
}
async function logout(useAPI) {
  if (useAPI) {
    let user = getTokenFromLocal() || { access_token: "" };
    let token = user ? user.access_token : null;
    const { access_token: accessToken } = user;
    // const accessToken = apiClientToken;

    const mobile = localStorage.getItem("userMobile");
    const fcmToken = localStorage.getItem("fcmToken")

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // mobileplatform: "web",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ accessToken: accessToken, mobile: mobile, fcmToken: fcmToken }),
    };

    // let postData = `username=cms_${params.mobile}&password=${params.otp}&grant_type=otp`;
    return fetch(`${apiUrl}/api/logout`, requestOptions)
      .then(handleResponse)
      .then(function (
        response = { description: "Something went wrong !", status: "" }
      ) {
        const { description = "", status = "" } = response;

        // remove user from local storage to log user out
        clearLocalStorage(["user", "userMobile"]);

        swal({
          title: status,
          // text: `${description} reloading in 2 seconds.`,
          text: `${description}`,
          icon: status.toLowerCase() === "success" ? "success" : "error",
        }).then(() => {
          // history.push("/login");
        });

        // setTimeout(() => {
        //   history.push("/login");
        //   window.location.reload();
        // }, 2000);

        return response;
      })

      .catch(function (errorRes) {
        const {
          error_description = "Something went wrong !",
          status = "",
          error = "",
        } = errorRes;

        swal({
          title: error,
          text: `${error_description}`,
          icon: status.toLowerCase() === "success" ? "success" : "error",
        });
      })
      .then(function (response) {
        // always executed
        return response;
      });
  } else {
    // remove user from local storage to log user out
    clearLocalStorage(["user", "userMobile"]);
    // history.push("/login");
  }
}

export async function getAuth(params, googleLogin) {
  // let user = getTokenFromLocal();
  // let token = user ? user.access_token : null;
  var requestOptions={}
  if (googleLogin) {
    var body = "credential=" + params.credential
    body += "&grant_type=credential";
    body += '&clientId=' + process.env.REACT_APP_CLIENT_ID
     requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${apiClientToken}`,
        // mobileplatform: "web",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: body,
    };
  }
     else {
     requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Basic ${apiClientToken}`,
        // mobileplatform: "web",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${params.mobile}&password=${params.otp}&grant_type=otp`,
    };
  }
    // let postData = `username=cms_${params.mobile}&password=${params.otp}&grant_type=otp`;
    return (
      fetch(apiUrl + "/oauth/token", requestOptions)
        .then(handleResponse)
        // .then((res) => res.json())
        .then(function (response) {
          localStorage.setItem("user", JSON.stringify(response));
          // window.location.href = "/home";
          // history.push("/home");

          return response;
        })
        .catch(function (errorRes) {
          // history.push("/login");
          toast.error(errorRes['error_description'])
          return Promise.reject(errorRes);
        })
        .then(function (response) {
          // always executed
          return response;
        })
    );
  }

export async function checkAccessTokenAuth() {
  let user = getTokenFromLocal();
  let token = findUrlParameter('accessToken')||user?.access_token;
  console.log('this is something checking',findUrlParameter('accessToken'))
  if(findUrlParameter('accessToken')){
    if(window.location.pathname.includes('central')){
      localStorage.setItem('isApp', true)
    }
    else{
      localStorage.setItem('isApp', false) 
     }
    localStorage.setItem('user',JSON.stringify({access_token:token}))
  }
  else{
    localStorage.setItem('isApp', false)
  }
  if (!!!token) return Promise.reject("No token found in the LocalStorage");

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Basic ${apiClientToken}`,
      // mobileplatform: "web",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // let postData = `username=cms_${params.mobile}&password=${params.otp}&grant_type=otp`;
  return fetch(`${apiUrl}/oauth/check_token?token=${token}`, requestOptions)
    .then(handleResponse)
    .then(function (response) {

      const { status = 0 } = response;
      var number=response['isExternalUser']===true?response["user_name"]:response["otpMobile"]
      localStorage.setItem('userMobile',number)
      if (status !== 200) return Promise.reject("Status is not 200");
      let user = JSON.parse(localStorage.getItem("user"));
      const updatedUser = { ...user, ...response };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return response;
    })
    .catch(function (error) {
      // history.push("/login");
      console.error(error);
      return Promise.reject(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export async function checkRefreshToken(params) {
  let user = getTokenFromLocal();
  let token = user ? user.refresh_token : null;


  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Basic ${apiClientToken}`,
      // mobileplatform: "web",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `refresh_token=${token}&grant_type=refresh_token`,
  };
  // let postData = `username=cms_${params.mobile}&password=${params.otp}&grant_type=otp`;
  return fetch(apiUrl + "/oauth/token", requestOptions)
    .then(handleResponse)
    .then(function (response) {
      localStorage.setItem("user", JSON.stringify(response));
      return response;
    })
    .catch(function (error) {
      console.error(error);
      logout(false);
      return Promise.reject(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}


const getRolesList = async (params) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // mobileplatform: "web" 
    },
    body: JSON.stringify({}),
  };
  return fetch(`${apiUrlJavaOld}/exposed/HospitalChat/Form`, requestOptions)
    .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
};

const hadleUserRegister = async (params) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // mobileplatform: "web"
    },
    body: JSON.stringify({ ...params }),
  };
  return fetch(`${apiUrlJavaOld}/exposed/twilio/register`, requestOptions)
    .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
};

const updateNotificationPermissions = async (params) => {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },

    body: JSON.stringify(params),
  };

  return fetch(`${apiUrlJava}/api/HospitalChat/storeToken`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });

}

export const userService = {
  getOtp,
  getAuth,
  checkRefreshToken,
  checkAccessTokenAuth,
  logout,
  getRolesList,
  hadleUserRegister,
  updateNotificationPermissions
};
