import { Checkbox, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import { patientActions } from '../../../../_actions';
import ButtonHWA from '../../../../_components/ButtonHWA/ButtonHWA';


function OtScheduler({ value, handleChange, handleChangeIndex, handleClose }) {
  const { hospital_id } = useSelector(state => state.hospitals.currentHospitalData)
  const { availableTimeSlots: { [hospital_id]: { timings: { availableSlots, selectedDuration, selectedSlots, selectedSlotsData } = {} } = {}, [hospital_id]: slots } = {} } = useSelector(state => state.patients || {})
  const [timeSlots, setTimeSlots] = useState([])
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([])
  const [sameSlotChecked, setSameSlotChecked] = useState(false)
  const [duration, setDuration] = useState(selectedDuration||'60')
  const [showSelectedSlotScreen, setShowSelectedSlotScreen] = useState(null)
  useEffect(() => {
    dispatch(patientActions.getAvailableTimeSlots({ hospId: hospital_id }))
  }, [])
  useEffect(() => {

    if (slots) {
      const tempArr = availableSlots?.map(item => {
        return { value: `${item[0]} to ${item[1]}`, key: item }
      })
      setTimeSlots(tempArr)
    }
  }, [slots])

  useEffect(() => {
    if (selectedSlots) {
      const tempArr =duration===selectedDuration?selectedSlots?.map(item => {
        return { value: `${item['From']} to ${item['To']}`, key: [item['From'], item['To']] }
      }):[]
      setSelectedTimeSlots(tempArr)
    }
  }, [selectedSlots])
  useEffect(() => {
    if (selectedSlotsData) {
      setShowSelectedSlotScreen(selectedSlotsData)
    } else if (selectedSlotsData === false) {
      setShowSelectedSlotScreen(false)
    }
  }, [selectedSlotsData])
  useEffect(() => {
    if (selectedDuration) {
      setDuration(selectedDuration)
    }
  }, [selectedDuration])
  const dispatch = useDispatch()

  const durationArray = [{ value: '30 Min', key: '30' }, { value: '1 Hr', key: '60' }, { value: '1.5 Hrs', key: '90' }, { value: '2 Hrs', key: '120' }, { value: '2.5 Hrs', key: '150' }, { value: '3 Hrs', key: '180' }]


  const FilterTitle = ({ children, textProps }) => {
    return <Typography sx={{ fontSize: ["16px"], color: "#3D7CC9", fontWeight: "600", lineHeight: "20px", ...textProps }}>{children}</Typography>
  }
  const SlotTile = ({ value, onClick, tileStyling, isSelected }) => {
    var tileStyling = {}
    if (isSelected) {
      tileStyling = {
        border: '1px solid #3D7CC9',
        color: '#3D7CC9',
        background: "#97c6ff52"
      }
    }
    return <Grid onClick={onClick} container sx={{ color: "#00263E", border: "1px solid #00263E", borderRadius: ['8px', '8px', '5px'], padding: ['5px', '5px', '5px 10px 5px 10px'], whiteSpace: 'noWrap', cursor: 'pointer', ...tileStyling }}>
      <Typography sx={{ fontSize: ['12px', '12px', '12px', '10px', '14px'], letterSpacing: '0.2px' }}>{value}</Typography>
    </Grid>
  }
  const SlotDuration = ({ value, onClick, isSelected }) => {
    var tileStyling = {}
    if (isSelected) {
      tileStyling = {
        border: '1px solid #3D7CC9',
        color: '#3D7CC9',
        background: "#97c6ff52"
      }
    }
    return <Grid onClick={onClick} container sx={{ color: "#00263E", border: "1px solid #00263E", borderRadius: ['6px', '6px', '5px'], padding: ['5px', '5px', '5px 10px 5px 10px'], cursor: 'pointer', ...tileStyling }}>
      <Typography sx={{ fontSize: ['12px', '12px', '12px', '10px', '14px'] }}>{value}</Typography>
    </Grid>
  }
  const getAvailableTimeSlots = ({ duration }) => {
    console.log('this is called')
    setSelectedTimeSlots([])
    dispatch(patientActions.getAvailableTimeSlots({ hospId: hospital_id, customizedTime: duration }))
  }
  const saveSelectedTimeSlots = () => {
    if (selectedTimeSlots.length === 0) {
      toast.error('Please select time slots')
      return
    }
    var payload = {
      selectedSlots: selectedTimeSlots.map(item => {

        return { From: item['key'][0], To: item['key'][1] }
      }),
      hospId: hospital_id,
      duration: duration
    }
    if (sameSlotChecked)
      payload['weekFlag'] = sameSlotChecked
    dispatch(patientActions.saveSelectedTimeSlots(payload))
    handleClose()
  }
  const handleSlotClicked = ({ slot }) => {
    var newSelectedSlots = [...selectedTimeSlots]
    if (checkSlotSelection({ slot })) {
      var pos = newSelectedSlots.findIndex(item => {
        return (item["key"][0] === slot["key"][0] && item["key"][1] === slot["key"][1])
      })
      newSelectedSlots.splice(pos, 1);
    } else {
      newSelectedSlots.push(slot)
    }
    setSelectedTimeSlots(newSelectedSlots)
  }
  const checkSlotSelection = ({ slot }) => {
    for (var item in selectedTimeSlots) {
      var temp = selectedTimeSlots[item]['key']
      if (temp && temp[0] === slot['key'][0] && temp[1] === slot['key'][1]) {
        return true
      }
    }
    return false
  }
  return (
    <Grid container sx={{ borderRadius: '8px', maxWidth: ['300px', '300px', '22vw', '22vw', '370px'], background: "#fff", padding: ['20px 10px 20px 10px', '20px 10px 20px 10px', '20px 10px 20px 10px', '10px 10px 10px 10px', '20px 25px 25px 25px'], lineHeight: "1.4", flexDirection: "column" }}>
      {showSelectedSlotScreen === true &&
        <Grid container sx={{ minHeight: '300px', position: "relative" }}>
          <Typography sx={{ fontSize: ['14px', '14px', '16px'], color: "#64696F", fontWeight: '600', marginTop: ['30px', '30px', '30px', '15px', '40px'], width: "100%" }}> You or your team have already provided us input for tomorrow. Do you want to edit it?</Typography>
          <Grid container sx={{
            gap: ['20px', '20px', '13px'], paddingTop: ['20px'], paddingBottom: '5px', paddingLeft: "2px",
            paddingRight: "2px", position: "absolute", bottom: '5px'
          }}>
            <Grid onClick={(e) => handleClose()} item sx={{ flex: 1 }}><ButtonHWA sx={{ fontSize: "" }} >No</ButtonHWA></Grid>
            <Grid onClick={() => {
              setShowSelectedSlotScreen(false);
            }} item sx={{ flex: 1 }}><ButtonHWA variant="contained" >Yes</ButtonHWA></Grid>
          </Grid>
        </Grid>}
      {showSelectedSlotScreen === null &&
        <Grid container sx={{ minHeight: '300px', minWidth: ['280px', '280px', '280px', '260px', '350px'], display: 'flex', alignItems: "center", justifyContent: "center", position: "relative" }}>
          <CircularProgress />
        </Grid>
      }
      {(showSelectedSlotScreen === false) && <SwipeableViews disabled={true} index={value} onChangeIndex={handleChangeIndex} sx={{}}>
        <Grid container value={value} index={0} sx={{ p: '0px 2px 0px 2px' }} >
          <FilterTitle textProps={{ height: "fit-content" }} >Selecting Slots for <span style={{ textDecoration: 'underline' }}>Tomorrow</span></FilterTitle>
          {timeSlots?.length===0&&<Grid container sx={{position:"absolute",alignItems:"center",height:"100%"}}><Typography sx={{ fontSize: ['14px', '14px', '16px'], color: "#64696F", fontWeight: '600', marginTop: ['30px', '30px', '30px', '15px', '40px'], width: "100%",textAlign:'center' }}> No Slots Found</Typography></Grid>}
          {timeSlots?.length>0&&<Box>
            <Typography sx={{ fontSize: ['14px', '14px', '16px'], color: "#64696F", fontWeight: '600', marginTop: ['30px', '30px', '30px', '15px', '40px'], width: "100%" }}>Select Slot</Typography>
          <Grid container sx={{ height: ['300px', '300px', '300px', '200px', '370px'] }}>

            <Grid container sx={{ marginTop: ["5px!important", "5px!important", "5px!important", "10px!important", "5px!important"], height: "fit-content", maxHeight: "100%", overflowY: "auto", }} rowSpacing={["15px", "15px", "15px", "10px", "15px"]} columnSpacing={['10px', '10px', '10px', '10px', '20px']}>
              {timeSlots?.map(item => {
                return (
                  <Grid item xs={6}>
                    <SlotTile onClick={() => handleSlotClicked({ slot: item })} value={item.value} isSelected={checkSlotSelection({ slot: item })} />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: ['40px', '40px', '40px', '20px', '45px'], justifyContent: 'center', gap: '10px' }}>
            <Grid item><Checkbox
              checked={sameSlotChecked}
              onChange={(e) => setSameSlotChecked(e.target.checked)}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: "15px" }, padding: "0px", color: '#3276CF' }}
            /></Grid>
            <Grid item><FilterTitle textProps={{ fontSize: ['14px'], fontWeight: '500' }}>Book Same Slot for all Days</FilterTitle> </Grid>
          </Grid>
          <Grid container sx={{
            gap: ['20px', '20px', '13px'], paddingTop: ['20px'], paddingBottom: '5px', paddingLeft: "2px",
            paddingRight: "2px"
          }}>
            <Grid onClick={(e) => handleChange(e, 1)} item sx={{ flex: 1 }}><ButtonHWA sx={{ fontSize: "" }} >Manage Slots</ButtonHWA></Grid>
            <Grid onClick={() => saveSelectedTimeSlots()} item sx={{ flex: 1 }}><ButtonHWA variant="contained" >Submit</ButtonHWA></Grid>
          </Grid>
          </Box>
          }
        </Grid>
        <Grid container sx={{ background: "#fff", flexDirection: 'column', p: '0px 2px 0px 2px' }} value={value} index={1} >
          <FilterTitle >Select Slot Duration</FilterTitle>
          <Grid container sx={{ paddingTop: ['30px', '30px', '30px', '20px', '45px'], paddingLeft: '2px', paddingRight: '2px', flexDirection: 'column', gap: ['15px', '15px', '15px', '10px', '15px'], justifyContent: 'center' }}>
            {durationArray.map(item => {
              return <Grid item>
                <SlotDuration onClick={(e) => { setSelectedTimeSlots([]);handleChange(e, 0); getAvailableTimeSlots({ duration: item.key }); setDuration(item.key);  }} isSelected={item.key === duration} value={item.value} />
              </Grid>
            })}
          </Grid>
          <Grid container sx={{ position: 'absolute', bottom: '0px', p: '0px 3px 5px 0px' }}>
            <ButtonHWA onClick={(e) => handleChange(e, 0)} variant="contained">Confirm Slot Duration</ButtonHWA>
          </Grid>
        </Grid>
      </SwipeableViews>
      }
    </Grid>
  )
}

export default OtScheduler