import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { patientService } from "../../../../../_services/patient.service";
import callIcon2 from "./../../../../../assets/callIcon2.svg";
import closeIcon from "./../../../../../assets/close.svg";
import "./index.css";
function ClickToCall({ open, handleClose, hospitalId }) {
  const pocListCache = useSelector((state) => state.cacheStorage.pocListCache);
  const [searchText, setSearchText] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [idxClicked,setIdxClicked]=useState([]);

  useEffect(() => {
    var temp = [];

    if (pocListCache[hospitalId]) {
      temp = pocListCache[hospitalId].filter((item) => {
        if (!searchText || searchText.length == 0) return true;
        let checkName =
          item.name &&
            item.name
              .toLowerCase()
              .trim()
              .includes(searchText.toLowerCase().trim())
            ? true
            : false;
        let checkNumber =
          item.mobile &&
            item.mobile
              .toLowerCase()
              .trim()
              .includes(searchText.toLowerCase().trim())
            ? true
            : false;
        return checkName || checkNumber;
      });
      setDisplayData(temp);
    } else {
      setDisplayData([]);
    }
  }, [searchText, hospitalId, open]);
  useEffect(() => {
    setSearchText("");
  }, [open]);

  const clickToCallApi = (item,i) => {
    if(idxClicked.includes(i)){
      return
    }
    var params = {};
    params["CustomerNumber"] = "+91" + item.mobile;
    params["AgentMobile"] = "+91" + localStorage.getItem("userMobile");

    patientService.clickToCallApi(params).then((res) => {setIdxClicked(null) }).catch((err)=>setIdxClicked(null));
    handleClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth="xs" onClose={handleClose} open={open}>
      <div className="clcContainer">
        <div className="clcHeader">
          Select to Call
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              marginTop: "16px",
              marginRight: "16px",
            }}
          >
            <img
              style={{ height: "20px", width: "20px", cursor: 'pointer' }}
              src={closeIcon}
              alt=""
            />
          </div>
        </div>
        <div className="clcBody">
          <div className="clcSearch">
            <TextField
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ cursor: "pointer" }}
                      onClick={(e) =>
                        setSearchText(
                          document.getElementById("input-with-icon-textfield")
                            .value
                        )
                      }
                    />
                  </InputAdornment>
                ),
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  // Do code here
                  ev.preventDefault();
                  setSearchText(
                    document.getElementById("input-with-icon-textfield").value
                  );
                }
              }}
              sx={{ input: { color: "#3d7cc9" } }}
              variant="standard"
              placeholder="Search by name or number"
            />
          </div>
          <div className="clcLine">
          </div>
          <div className="clcList">
            {displayData?.length > 0 ? (
              displayData?.map((item, i) => {
                return (
                  <div className="pocListItem" style={{gap:"10px"}} key={i}>
                    <Box
                      onClick={() => {clickToCallApi(item,i);setIdxClicked(i)}}
                      className="pocIcon"
                      sx={{height:['30px','30px','40px']}}
                    >
                      <img style={{height:"100%",width:"100%",objectFit:"contain"}} src={callIcon2} alt="" />
                    </Box>
                    <div className="pocDetails" style={{flex:"4!important"}}>
                      <Box className="pocName" sx={{fontSize:['16px','16px','20px']}}>{item.name}</Box>
                      <Box className="pocNumber" sx={{fontSize:['14px','14px','18px'],whiteSpace:"nowrap"}}>
                        Number:{" "}
                        {item.mobile.replace(
                          item.mobile.substring(2, 8),
                          "*******"
                        )}
                      </Box>
                    </div>
                    <Box className="pocRole" sx={{fontSize:['15px','15px','20px']}}>{item.role}</Box>
                  </div>
                );
              })
            ) : (
              <div
                style={{ margin: "auto", color: "#3d7cc9", fontSize: "20px" }}
              >
                No Poc List Found
              </div>
            )}
          </div>
          <div className="clcLine"></div>
        </div>
      </div>
    </Dialog>
  );
}

export default ClickToCall;
