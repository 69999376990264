
export const jsonConstants = [
    {
        "status": "Success",
        "description": " CommonData values for deCentralisationScheme",
        "result": {
            "_id": "0otsccnXm1p",
            "_created_at": 1714219571416,
            "data": [
                {
                    "title": "Appointment Details",
                    "key": "app_details",
                    "details": [
                        {
                            "label": "Hospital Name",
                            "key": "hosp_name",
                            "getKey": "hospitalName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "modalDetails": {
                                "header": "",
                                "description": ""
                            },
                            "options": [],
                            "class": "",
                            "disable": false
                        },
                        {
                            "label": "Patient Name",
                            "key": "patient_name",
                            "getKey": "patientName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Disease",
                            "key": "disease",
                            "getKey": "patientDisease.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Doctor Name",
                            "key": "doctor_name",
                            "getKey": "doctorName.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "MOP",
                            "key": "mode_of_payment",
                            "getKey": "payment_mode",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only-style",
                            "disable": false,
                            "class": "green"
                        },
                        {
                            "label": "Surgery Call",
                            "key": "okForSurgery",
                            "getKey": "surgeryStatusIns.surgeryStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "ok-surgery",
                            "disable": false
                        },
                        {
                            "label": "Reimbursement Call Status",
                            "key": "call_status",
                            "getKey": "reimCallStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Date and Time",
                            "key": "appointment_date_time",
                            "getKey": "appointment_start_time",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false,
                            "format": "DD MMM, hh:mm A"
                        },
                        {
                            "label": "BD Name",
                            "key": "bd_name",
                            "getKey": "bd_name",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "IPD Posted At",
                            "key": "ipd_date_time",
                            "getKey": "ipdCreatedAt",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false,
                            "isUTC": true,
                            "format": "DD MMM, hh:mm A"
                        },
                        {
                            "label": "OT Slots(By Hospital)",
                            "key": "otSlots_hospital",
                            "getKey": "otSlots_hospital",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Pre-Auth Status",
                            "key": "preAuth_status",
                            "getKey": "preAuthStatus",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        }
                    ]
                },
                {
                    "title": "Admission",
                    "key": "admission_details",
                    "details": [
                        {
                            "label": "Room Type (Insurance)",
                            "key": "room_type_insurance",
                            "getKey": "insuranceRoomType.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Total Collectible at Admission",
                            "key": "amount_collectible_admission",
                            "getKey": "proposedBreakdown.total",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Actual Arrival Time",
                            "key": "arrival_time",
                            "getKey": "patient_admission_time.value",
                            "postKey": "patient_admission_time",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Room Type",
                            "key": "room_type",
                            "getKey": "hospChatSurgeryObject.room_type_key",
                            "postKey": "roomTypeHos",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Private",
                                    "value": "Private"
                                },
                                {
                                    "label": "Semi-Private",
                                    "value": "Semi-Private"
                                },
                                {
                                    "label": "Triple Sharing",
                                    "value": "Triple Sharing"
                                },
                                {
                                    "label": "General Ward",
                                    "value": "General Ward"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Total Collected at Admission",
                            "key": "amount_collected_admission",
                            "getKey": "totalCollectedOps.value",
                            "postKey": "totalCollectedOps",
                            "value": "",
                            "functionType": "input-numeric",
                        "disable": false,
                        },
                        {
                            "label": "Attendant",
                            "key": "attendant",
                            "getKey": "attendant.value",
                            "postKey": "attendant",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Collection Proof",
                            "key": "extraCashProof",
                            "getKey": "extraCashProof",
                            "postKey": "extraCashProof",
                            "value": "",
                            "functionType": "upload-file",
                            "disable": false
                        }
                    ]
                },
                {
                    "title": "OT Details",
                    "key": "ot_details",
                    "details": [
                        {
                            "label": "Extra Test",
                            "key": "extra_test",
                            "getKey": "extraTest.value",
                            "postKey": "extraTest",
                            "value": "",
                            "functionType": "input-text",
                            "disable": false
                        },
                        {
                            "label": "OT Date & Time(Central OPS)",
                            "key": "ot_central_date_time",
                            "getKey": "otTimeOps.value",
                            "postKey": "otTimeOps",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual OT start time",
                            "key": "actual_ot_start_time",
                            "getKey": "actualOtStartTime.value",
                            "postKey": "actualOtStartTime",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Actual OT end time",
                            "key": "actual_ot_end_time",
                            "getKey": "actualOtEndTime.value",
                            "postKey": "actualOtEndTime",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        }
                    ]
                },
                {
                    "title": "Billing",
                    "key": "billing_details",
                    "details": [
                        {
                            "label": "Collection Gap",
                            "key": "collection_gap",
                            "getKey": "collectionGap",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only-style",
                            "disable": false,
                            "class": "red"
                        },
                        {
                            "label": "Advance Collected at GHV",
                            "key": "amount_collected_at_ghv",
                            "getKey": "advanceCollectedByBD.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Extra Cash Proposed (Insurance)",
                            "key": "extra_cash_insurance",
                            "getKey": "extraCashProposed.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PCCSC Proposed Amount (from Insurance)",
                            "key": "proposed_amount",
                            "getKey": "pccscProposed.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Waived off (insurance)",
                            "key": "waivedOff_insurance",
                            "getKey": "extraCashWavedOff.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Waived off Reasons (Insurance)",
                            "key": "waivedOff_reasons",
                            "getKey": "waiveOffReasons.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "modal-read-only",
                            "modalDetails": {
                                "header": "Waived off Reasons (Insurance)",
                                "description": ""
                            },
                            "disable": false
                        },
                        {
                            "label": "Extra Cash Applicable (Insurance)",
                            "key": "extra_case_applicable_insurance",
                            "getKey": "extraCashApplicable.extraCashApplicable",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PCCSC Applicable Amount",
                            "key": "applicable_amount",
                            "getKey": "pccscApplicable",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Proposed Package Amount (Cash cases)",
                            "key": "package_amount",
                            "getKey": "proposedPackageAmount.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Other Pristyn Payments",
                            "key": "other_payments",
                            "getKey": "CxEMIAmount.value",
                            "postKey": "CxEMIAmount",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Extra Charge Collected",
                            "key": "extra_charge_collected",
                            "getKey": "extraChargeCollected.value",
                            "postKey": "extraChargeCollected",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Reason for difference in Package Amount (Cash cases)",
                            "key": "different_package_amount_reasons",
                            "getKey": "reasonForDifferencePackageAmount.value",
                            "postKey": "reasonForDifferencePackageAmount",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Package Changed by BD",
                                    "value": "Package Changed by BD"
                                },
                                {
                                    "label": "Extra Stay Charges",
                                    "value": "Extra Stay Charges"
                                },
                                {
                                    "label": "Surgery Change",
                                    "value": "Surgery Change"
                                },
                                {
                                    "label": "Conservative Case",
                                    "value": "Conservative Case"
                                },
                                {
                                    "label": "Patient paid extra by mistake",
                                    "value": "Patient paid extra by mistake"
                                },
                                {
                                    "label": "Patient paying Less due to infra issue/ hospital issue",
                                    "value": "Patient paying Less due to infra issue/ hospital issue"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Final Bill amount by Insurance",
                            "key": "final_bill_amount",
                            "getKey": "insFinalBillAmt.value",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "Final Approval Amount",
                            "key": "final_approval_amount",
                            "getKey": "finalBillAmount",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        }
                    ]
                },
                {
                    "title": "Discharge",
                    "key": "discharge_details",
                    "details": [
                        {
                            "label": "Total Collectible at Discharge",
                            "key": "total_collectible_at_discharge",
                            "getKey": "applicableBreakdown.total",
                            "postKey": "",
                            "value": "",
                            "functionType": "text-read-only",
                            "disable": false
                        },
                        {
                            "label": "PDS document",
                            "key": "pds_document",
                            "getKey": "pds_document",
                            "postKey": "",
                            "value": "",
                            "functionType": "summary-modal",
                            "disable": false
                        },
                        {
                            "label": "Total Collected at Discharge",
                            "key": "total_collected_at_discharge",
                            "getKey": "totalCollectedDisOps.value",
                            "postKey": "totalCollectedDisOps",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Extra Charge",
                            "key": "extraCharge.value",
                            "getKey": "extraCharge.value",
                            "postKey": "extraCharge",
                            "value": "",
                            "functionType": "input-numeric",
                            "disable": false
                        },
                        {
                            "label": "Discharge Cab Booking Status",
                            "key": "discharge_cab_booking_status",
                            "getKey": "cabBookingStatus.value",
                            "postKey": "cabBookingStatus",
                            "value": "",
                            "functionType": "input-dropDown",
                            "options": [
                                {
                                    "label": "Yes",
                                    "value": "Yes"
                                },
                                {
                                    "label": "No",
                                    "value": "No"
                                }
                            ],
                            "disable": false
                        },
                        {
                            "label": "Actual Date of discharge (Date and time)",
                            "key": "date_of_discharge",
                            "getKey": "finalDateOfDischarge.value",
                            "postKey": "finalDateOfDischarge",
                            "value": "",
                            "functionType": "input-date-time",
                            "disable": false,
                            "isUTC": true,
                            "format": "YYYY-MM-DDTHH:MM"
                        },
                        {
                            "label": "Collection Proof",
                            "key": "extraCashProof",
                            "getKey": "extraCashProof",
                            "postKey": "extraCashProof",
                            "value": "",
                            "functionType": "upload-file-discharge",
                            "disable": false
                        }
                    ]
                }
            ],
            "name": "deCentralisationScheme"
        }
    }
]