import { toast } from "react-toastify";
import swal from "sweetalert";
import { getTokenFromLocal, handleResponse } from "./../_helpers/handleResponseMiddleware";

let apiUrl = process.env.REACT_APP_BASE_URL;

export function getPatientList(params) {
  const { sid, selectedHospitalId } = params;

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  return fetch(
    `${apiUrl}/api/HospitalChat/v2/allPatientsList?hospitalId=${selectedHospitalId}`,
    requestOptions
  )
    .then(handleResponse)
    .then(function (response) {
      // localStorage.setItem('hospital', JSON.stringify(response.data));
      return { sid, patients: response.result["patientsList"][0] };
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export function fetchPatientInfo(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
      headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },

    body: JSON.stringify(params),
  };

  return fetch(`${apiUrl}/api/HospitalChat/patientInfo`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function handleSurgeryAcceptReject(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
      headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/appointment/surgery`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);

      swal({
        icon: "error",
        title: error?.error || "Error!",
        text: error?.message || "",
      });

      return Promise.reject(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function getChatPocList(params) {
  const { sid, selectedHospitalId } = params;

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...params }),

  };
  return fetch(
    `${apiUrl}/api/hospChatPocList`,
    requestOptions
  )
    .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export function clickToCallApi(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...params }),

  };
  return fetch(
    `${apiUrl}/api/clickToCallApi`,
    requestOptions
  )
    .then(handleResponse)
    .then(function (response) {
      toast.success(response.description)

      return response;
    })
    .catch(function (error) {
      console.error(error);
      toast.error(error)

    })
    .then(function (response) {
      // always executed
      return response;
    });
}


export function getAvailableTimeSlots(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...params }),

  };
  return fetch(
    `${apiUrl}/api/availableTimeSlots`,
    requestOptions
  )
    .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}
export function saveSelectedTimeSlots(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...params }),

  };
  return fetch(
    `${apiUrl}/api/saveSelectedTimeSlots`,
    requestOptions
  )
    .then(handleResponse)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export const patientService = {
  getPatientList,
  fetchPatientInfo,
  handleSurgeryAcceptReject,
  getChatPocList,
  clickToCallApi,
  getAvailableTimeSlots,
  saveSelectedTimeSlots
};
