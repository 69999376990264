import { centralGetInitialState } from "../../_helpers/centralGetInitialState";
import {centralPatientConstants} from "../../_constants/centralConstant/centralPatient.constant"

export function centralPatients(
    state = centralGetInitialState("centralPatients"),
    action
  ) {
    switch (action.type) {
      case centralPatientConstants.CENTRAL_PATIENT_LIST_REQUESTING:
        return {
          ...state,
        };
      case centralPatientConstants.CENTRAL_PATIENT_LIST_FETCHED:
          const { centralPatients } = action;
        return {
          ...state,
          centralPatients,
          isCentralDataFetched:true,
        };
      case centralPatientConstants.CENTRAL_PATIENT_LIST_FAILED:
        return {
          ...state,
          patientFetchingError: action.error,
        };
        case centralPatientConstants.SAVE_ACTIVE_CENTRAL_PATIENT_DATA:
          return {
            ...state,
            activePatientId: action.payload?.patientId,
            activePatientName: action.payload?.patientName,
          };

        case centralPatientConstants.CLEAR_CENTRAL_PATIENT_LIST:
          return {...state, isCentralDataFetched:false};
      default:
        return state;
    }
  }
  