import { toast } from "react-toastify";
import swal from "sweetalert";
import { alertActions, cacheStorageActions, configActions } from ".";
import { patientConstants } from "../_constants";
import { cleanObj, EXTERNAL_HOSPITAL_ROLES, handleSateWithSocket, mainCacheConstants } from "../_helpers";
import { patientService } from "../_services";

function getPatientList(data) {
  const {params = {},callback = null,fetchFromServiceFile = false,cache = undefined}= data;
  return (dispatch,getState) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());
    if (fetchFromServiceFile) {
      patientService.getPatientList(params).then(
        (res) => {
          dispatch(success(res));

          //callback added for the support of the the caching work
          Boolean(callback) && callback(res);

          dispatch(configActions.hideSpinningLoader());

          // history.push("/");
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
      getChatPocList({ params, dispatch,getState });
    } else {
      dispatch(success(cache));
      dispatch(configActions.hideSpinningLoader());
    }
  };

  function request() {
    return {
      type: patientConstants.PATIENT_LIST_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: patientConstants.PATIENT_LIST_FETCHED,
      patients: res["patients"],
    };
  }
  function failure(error) {
    return { type: patientConstants.PATIENT_LIST_FAILED, error };
  }
}

function getPatientInfoAPIData(params) {
  return (dispatch) => {
    dispatch(request());

    params=cleanObj(params)
    patientService.fetchPatientInfo(params).then(
      (res) => {
        dispatch(success(res));
        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: patientConstants.GET_PATIENT_INFO_REQUESTING,
    };
  }
  function success(patientInfoAPIData) {
    return {
      type: patientConstants.GET_PATIENT_INFO_FETCHED,
      patientInfoAPIData,
    };
  }
  function failure(error) {
    return { type: patientConstants.GET_PATIENT_INFO_FAILED, error };
  }
}

function handleSurgeryAcceptReject(params) {
  return (dispatch,getState) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());

    patientService.handleSurgeryAcceptReject(params).then(
      (res) => {
        const {approvalStatus}=params
        dispatch(success(res));
        if(approvalStatus==='Rejected'){
          handleSateWithSocket(dispatch,getState,"onSurgeryReject",params)
        }
        dispatch(configActions.hideSpinningLoader());

        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        dispatch(configActions.hideSpinningLoader());
      }
    );
  };

  function request() {
    return {
      type: patientConstants.SURGERY_ACCEPT_REJECT_REQUESTING,
    };
  }
  function success(response = { status: "", description: "", result: [] }) {
    const { status, description, result } = response;

    swal({
      title: status,
      text: description,
      icon: status.toLowerCase() === "success" ? "success" : "error",
    });
    return { type: patientConstants.SURGERY_ACCEPT_REJECT_SUCCESS, response };
  }
  function failure(error) {
    return { type: patientConstants.SURGERY_ACCEPT_REJECT_FAILED, error };
  }
}

const setPatientInfo = (params) => {
  return {
    type: patientConstants.SET_PATIENT_INFO,
    patientInfo: { ...params },
  };
};

const updateFullState = (newState) => {
  return {
    type: patientConstants.UPDATE_FULL_PATIENT_STATE,
    newState,
  };
};
function endOfChatObject(payload) {
  return { type: patientConstants.EDIT_END_OF_CHAT_OBJECT, payload };
}
function updateSelectedMop(payload) {
  return { type: patientConstants.UPDATE_SELECTED_MOP, payload };
}

const getChatPocList = ({ params, dispatch,getState }) => {
    const { selectedHospitalId } = params;
    const externalRoles = EXTERNAL_HOSPITAL_ROLES;
    const {
      authentication,
      
    } = getState();
    const {user:{roles:{role}}={} } =authentication||{}
  patientService
    .getChatPocList({
      type: externalRoles.includes(role) ? "Pristyn" : "ExternalUser",
      hospitalId: selectedHospitalId,
    })
    .then((res) => {
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: mainCacheConstants.pocListCache,
          key: selectedHospitalId,
          value: res.result,
        })
      );
    });
};
const getAvailableTimeSlots = (params) => {
  return (dispatch) => {
    const {hospId,customizedTime}=params
  patientService
    .getAvailableTimeSlots(params)
    .then((res) => {
      dispatch({type:'GET_AVAILABLE_TIME_SLOTS',payload:{result:res.result,hospitalId:hospId}});
    });
  }
};
const saveSelectedTimeSlots = (params) => {
  return (dispatch) => {
    const {hospId,duration}=params
    // const {hospId}=params
  patientService
  .saveSelectedTimeSlots(params)
  .then((res) => {
    dispatch(patientActions.getAvailableTimeSlots({ hospId: hospId, customizedTime: duration }))
      toast.success('OT Slot updated successfully')
    });
  }
};
const updatePatientsReadCount=({appointment_id,count})=>{
  const payload={appointment_id,count}
  return (dispatch)=>{
    dispatch({type:patientConstants.UPDATE_PATIENTS_READ_COUNT,payload})
  }
}
 const updatePatientsList=(payload)=>{
   return (dispatch)=>{
    dispatch({type:patientConstants.UPDATE_PATIENTS_LIST,payload}) 
   }
 }
export const patientActions = {
  getPatientList,
  setPatientInfo,
  getPatientInfoAPIData,
  handleSurgeryAcceptReject,
  updateFullState,
  endOfChatObject,
  updateSelectedMop,
  getAvailableTimeSlots,
  saveSelectedTimeSlots,
  updatePatientsReadCount,
  updatePatientsList
};
