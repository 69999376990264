export const checklistConstants={

    // fetch hosptial screen data
   FETCH_HOSPITALSCREEN_REQUESTING:"FETCH_HOSPITALSCREEN_REQUESTING",
   FETCH_HOSPITALSCREEN_FETCHED:"FETCH_HOSPITALSCREEN_FETCHED",
   FETCH_HOSPITALSCREEN_FAILED:"FETCH_HOSPITALSCREEN_FAILED",
   //fetch patient screen data
   FETCH_PATIENTSCREEN_REQUESTING:"FETCH_PATIENTSCREEN_REQUESTING",
   FETCH_PATIENTSCREEN_FETCHED:"FETCH_PATIENTSCREEN_FETCHED",
   FETCH_PATIENTSCREEN_FAILED:"FETCH_PATIENTSCREEN_FAILED",
    
   //change screen
   CHANGE_CHECKLIST_SCREEN:"CHANGE_CHECKLIST_SCREEN",

   //change active tab 
   
   CHANGE_ACTIVE_TAB : "CHANGE_ACTIVE_TAB",

   //set checklist filters state 
   SET_CHECKLIST_FILTERS:"SET_CHECKLIST_FILTERS",
   UPDATE_FULL_STATE:"UPDATE_FULL_STATE"

}