import { Box, IconButton, InputAdornment } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UseMessageInputHook from './UseMessageInputHook';
import MicIcon from '@mui/icons-material/Mic';
import DeleteIcon from '@mui/icons-material/Delete';
import './MobileMessageInput.css'
import axios from "axios";
import { toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Draggable from 'react-draggable';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import Divider from '@mui/material/Divider';
import { configActions } from '../../../../../_actions';
import useLongPress from '../../../../../_helpers/LongPress';
import "react-datepicker/dist/react-datepicker.css";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import image1 from "../../../../../assets/image1.png";
import image2 from "../../../../../assets/image2.png";
import image3 from "../../../../../assets/image3.png";

const MobileMessageInput = forwardRef(({ sendMessageFromMessageInput, onDomUpdate, setIsRecording, isRecording }, ref) => {

  const patientInfo = useSelector(state => state.patients.patientInfo)

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const [recordedUrl, setRecordedUrl] = useState('');
  const [cancelRecording, setCancelRecording] = useState(false);
  const [recordedText, setRecordedText] = useState("");
  const [open, setOpen] = useState(false);
  const [recordBeingKept, setRecordBeingKept] = useState({});
  const [tabValue, setTabValue] = useState("1");
  // states used for sending message
  const [sttValue, setSttValue] = useState("");
  const [isPopupMessageSent, setIsPopupMessageSent] = useState(false);
  const [sentDateTime, setSentDateTime] = useState("");
  const [popupSentText, setPopupSentText] = useState("");
  const [otDetailsSubmitted, setOtDetailsSubmitted] = useState(false);
  // states used for form
  const [arrivalTime, setArrivalTime] = useState("");
  const [isArrivalChange, setIsArrivalChange] = useState(false);
  const [roomType, setRoomType] = useState("");
  const [isRoomTypeChange, setIsRoomTypeChange] = useState(false);
  const [roomNumber, setRoomNumber] = useState("");
  const [isRoomNumberChange, setIsRoomNumberChange] = useState(false);
  const [collection, setCollection] = useState("");
  const [isCollectionChange, setIsCollectionChange] = useState(false);
  const [otDateTime, setOtDateTime] = useState("");
  const [isOtDateTimeChange, setIsOtDateTimeChange] = useState(false);
  const [otStartDateTime, setOtStartDateTime] = useState("");
  const [isOtStartDateTimeChange, setIsOtStartDateTimeChange] = useState(false);
  const [otEndDateTime, setOtEndDateTime] = useState("");
  const [isOtEndDateTimeChange, setIsOtEndDateTimeChange] = useState(false);

  const [isStopRecording, setIsStopRecording] = useState(false);
  const deleteRecordingRef = useRef(null);
  const secondsRef = useRef(null);
  const [audioDuration, setAudioDuration] = useState({
    startTime: '',
    duration: ''
  });
  const [isSttAllowed, setIsSttAllowed] = useState(false);
  const [micPermission, setMicPermission] = useState("");

  // Demo backdrop variables
  const imagesArray = [
    {
      url: image1,
      index: 1
    },
    {
      url: image2,
      index: 2
    },
    {
      url: image3,
      index: 3
    }
  ];
  const [showDemo, setShowDemo] = useState(false);
  const [dontShowCheck, setDontShowCheck] = useState(false);
  const [imageUrlObj, setImageUrlObj] = useState(imagesArray[0]);

  // new variables
  const [recordDetails, setRecordDetails] = useState({
    start: false,
    delete: false,
    stop: true
  })

  const [isStopRecAlreadyRun, setIsStopRecAlreadyRun] = useState(false);

  useEffect(() => {
    const updateFunction = (e) => {
      onDomUpdate && onDomUpdate()
    }
    let element = editorRef.current
    element.addEventListener('DOMSubtreeModified', updateFunction)

    return () => {
      element.removeEventListener('DOMSubtreeModified', updateFunction)
    }
  }, [])

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user'));
    if ((user?.roles?.role === 'cityOps' || user?.roles?.otherRoles?.includes('cityOps')) && patientInfo?.appointmentId) {
      setIsSttAllowed(true);
    }
  }, []);

  useEffect(() => {
    checkMicPermission();
  }, []);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('demoNoShow')) && patientInfo?.appointmentId) {
      setShowDemo(true);
    }
  }, []);

  let timer = null;
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsPopupMessageSent(false);
    setPopupSentText("");
    setSentDateTime("");
    setSttValue("");

    setArrivalTime("");
    setIsArrivalChange(false);
    setRoomType("");
    setIsRoomTypeChange(false);
    setRoomNumber("");
    setIsRoomNumberChange(false);
    setCollection("");
    setIsCollectionChange(false);
    setOtDateTime("");
    setIsOtDateTimeChange(false);
    setOtStartDateTime("");
    setIsOtStartDateTimeChange(false);
    setOtEndDateTime("");
    setIsOtEndDateTimeChange(false);
    setOtDetailsSubmitted(false);

    setIsStopRecAlreadyRun(false);

    // Hit API to send tab respective close event
    if (otDetailsSubmitted && isPopupMessageSent) {
      // Do nothing
    } else {
      let token = JSON.parse(localStorage.getItem('user')).access_token;
      let myHeaders = {
        'Authorization': "Bearer " + token
      };
      let reqBody = {
        "appId": patientInfo?.appointmentId,
      };
      if (tabValue === "1") {
        reqBody = {
          ...reqBody,
          "action":"sendMessagePopupClosed"
        };
      } else {
        reqBody = {
          ...reqBody,
          "action":"submitDataPopupClosed"
        };
      }
      let url = `${process.env.REACT_APP_BASE_URL_STT_SAVE}/closedOrSubmitEvent`;
      try {
        axios.post(url, reqBody, {
          headers: myHeaders
        });
      } catch (e) {
        console.log(e);
      }
    }

    setTabValue("1");
  };

  const checkMicPermission = () => {
    navigator.permissions.query({ name: 'microphone' }).then((permissionStatus) => {
      setMicPermission(permissionStatus.state);
    }).catch((err) => {
      console.log("ERR >>> ", err);
    });
  }

  useEffect(() => {
    if (audioDuration?.startTime) {
      timer = setInterval(() => {
        setAudioDuration(prev => ({
          ...prev,
          duration: prev?.duration + 1
        }));
      }, 1000);
    }
    if (audioDuration.duration === 60) {
      setIsStopRecAlreadyRun(true);
      stopRecording();
    }
    return () => {
      clearInterval(timer);
    }
  }, [audioDuration])

  const stopRecording = async () => {
    secondsRef.current = audioDuration.duration;
    if (timer) {
      clearInterval(timer);
    }
    setAudioDuration({});

    const inputBox = document.querySelector('#editableDiv');
    const recordingText = document.querySelector('#recordingSpan');
    if (recordingText) {
      inputBox.removeChild(recordingText);
    }
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  const stopDelete = async () => {
    const inputBox = document.querySelector('#editableDiv');
    if (inputBox.hasChildNodes()) {
      inputBox.innerHTML = "";
    }
    if (timer) {
      clearInterval(timer);
    }
    setAudioDuration({});
  }

  const stopOrDeleteRecording = (isDelete = false) => {
    if (isDelete || recordDetails.delete) {
      setRecordDetails({
        start: false,
        stop: false,
        delete: true
      })
      stopDelete();
    } else {
      if (!isStopRecAlreadyRun) {
        stopRecording();
      }
    }
  }

  const startRecordingHandler = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(
        { audio: true }
      );
      checkMicPermission();
      if (stream.active) {
        const inputBox = document.querySelector('#editableDiv');
        if (inputBox.hasChildNodes()) {
          inputBox.innerHTML = "";
        }
        setRecordDetails({
          start: true,
          stop: false,
          delete: false
        })
        const isRecordingSpan = document.createElement('span');
        isRecordingSpan.id = "recordingSpan";
        isRecordingSpan.innerText = "Slide to cancel...";
        isRecordingSpan.style.color = "rgb(239, 1, 7)";
        inputBox.appendChild(isRecordingSpan);
      }
      mediaStream.current = stream;
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.onstart = () => {
        setAudioDuration({
          startTime: 1,
          duration: 1
        });
      };
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      mediaRecorder.current.onstop = async () => {
        if (timer) {
          clearInterval(timer);
        }
        setRecordDetails({
          start: false,
          stop: true,
          delete: false
        })
        const recordedBlob = new Blob(
          chunks.current, { type: 'audio/mpeg' }
        );
        const url = URL.createObjectURL(recordedBlob);

        try {
          dispatch(configActions.showSpinningLoader());
          let formdata = new FormData();
          formdata.append('file', recordedBlob, 'file.mp3');
          let token = JSON.parse(localStorage.getItem('user')).access_token;
          let myHeaders = {
            'Authorization': "Bearer " + token
          }
          let fetchUrl = `${process.env.REACT_APP_STT_URL}/transcribe`;
          let response = await axios.post(fetchUrl, formdata, {
            headers: myHeaders
          });
          setRecordedText(response.data.text);

          /** API for values retrieved from speech-to-text and Central Ops data */
          let reqBodyStt = {
            "userMessage": response?.data?.text,
            "countSpeechSeconds": secondsRef?.current?.toString(),
            "appId": patientInfo?.appointmentId,
            "sttUrl": response?.data?.sttUrl
          };
          let url2 = `${process.env.REACT_APP_BASE_URL_STT_SAVE}/v1/updateUserTranscript`;
          let sttAndCentralOpsDataRes = await axios.post(url2, reqBodyStt, {
            headers: myHeaders
          });
          let sttAndCentralOpsData = sttAndCentralOpsDataRes?.data?.result;

          // Pre-filling form
          if (sttAndCentralOpsData?.sttData?.arrivalTime || sttAndCentralOpsData?.centralOpsData?.arrivalTime) {
            if (moment(sttAndCentralOpsData?.sttData?.arrivalTime || sttAndCentralOpsData?.centralOpsData?.arrivalTime).isBefore(moment(sttAndCentralOpsData?.patientDetails?.arrivalTime))) {
              setArrivalTime(moment(sttAndCentralOpsData?.patientDetails?.arrivalTime).format('YYYY-MM-DDTHH:mm'));
            }
            else if (moment(sttAndCentralOpsData?.sttData?.arrivalTime || sttAndCentralOpsData?.centralOpsData?.arrivalTime).isAfter(moment())) {
              setArrivalTime(moment().format('YYYY-MM-DDTHH:mm'));
            } else {
              setArrivalTime(moment(sttAndCentralOpsData?.sttData?.arrivalTime || sttAndCentralOpsData?.centralOpsData?.arrivalTime).format('YYYY-MM-DDTHH:mm'));
            }
          }
          setRoomType(sttAndCentralOpsData?.sttData?.roomType || sttAndCentralOpsData?.centralOpsData?.roomType || '');
          setRoomNumber(sttAndCentralOpsData?.sttData?.roomNumber || sttAndCentralOpsData?.centralOpsData?.roomNumber || '');
          setCollection(+sttAndCentralOpsData?.sttData?.amountCollectedAtArrival || +sttAndCentralOpsData?.centralOpsData?.amountCollectedAtArrival || '');
          if (sttAndCentralOpsData?.sttData?.proposedOtTime || sttAndCentralOpsData?.centralOpsData?.proposedOtTime) {
            if (moment(sttAndCentralOpsData?.sttData?.proposedOtTime || sttAndCentralOpsData?.centralOpsData?.proposedOtTime).isBefore(moment(sttAndCentralOpsData?.patientDetails?.appointmentDate))) {
              setOtDateTime(moment(sttAndCentralOpsData?.patientDetails?.appointmentDate).format('YYYY-MM-DDTHH:mm'));            }
            else if (moment(sttAndCentralOpsData?.sttData?.proposedOtTime || sttAndCentralOpsData?.centralOpsData?.proposedOtTime).isAfter(moment())) {
              setOtDateTime(moment().format('YYYY-MM-DDTHH:mm'));
            }
            else {
              setOtDateTime(moment(sttAndCentralOpsData?.sttData?.proposedOtTime || sttAndCentralOpsData?.centralOpsData?.proposedOtTime).format('YYYY-MM-DDTHH:mm'));
            }
          }
          if (sttAndCentralOpsData?.sttData?.otStartTime || sttAndCentralOpsData?.centralOpsData?.otStartTime) {
            if (moment(sttAndCentralOpsData?.sttData?.otStartTime || sttAndCentralOpsData?.centralOpsData?.otStartTime).isBefore(moment(sttAndCentralOpsData?.patientDetails?.appointmentDate))) {
              setOtStartDateTime(moment(sttAndCentralOpsData?.patientDetails?.appointmentDate).format('YYYY-MM-DDTHH:mm'));
            }
            else if (moment(sttAndCentralOpsData?.sttData?.otStartTime || sttAndCentralOpsData?.centralOpsData?.otStartTime).isAfter(moment())) {
              setOtStartDateTime(moment().format('YYYY-MM-DDTHH:mm'));
            } else {
              setOtStartDateTime(moment(sttAndCentralOpsData?.sttData?.otStartTime || sttAndCentralOpsData?.centralOpsData?.otStartTime).format('YYYY-MM-DDTHH:mm'));
            }
          }
          if (sttAndCentralOpsData?.sttData?.otEndTime || sttAndCentralOpsData?.centralOpsData?.otEndTime) {
            if (moment(sttAndCentralOpsData?.sttData?.otEndTime || sttAndCentralOpsData?.centralOpsData?.otEndTime).isBefore(moment(sttAndCentralOpsData?.patientDetails?.appointmentDate))) {
              setOtEndDateTime(moment(sttAndCentralOpsData?.patientDetails?.appointmentDate).format('YYYY-MM-DDTHH:mm'));
            }
            else if (moment(sttAndCentralOpsData?.sttData?.otEndTime || sttAndCentralOpsData?.centralOpsData?.otEndTime).isAfter(moment())) {
              setOtEndDateTime(moment().format('YYYY-MM-DDTHH:mm'));
            }
            else {
              setOtEndDateTime(moment(sttAndCentralOpsData?.sttData?.otEndTime || sttAndCentralOpsData?.centralOpsData?.otEndTime).format('YYYY-MM-DDTHH:mm'));
            }
          }

          setRecordBeingKept(sttAndCentralOpsData);
          setSttValue(response?.data?.text);
          setPopupSentText(response?.data?.text);
          // Open popup
          handleClickOpen();
          setAudioDuration({});

          const inputBox = document.querySelector('#editableDiv');
          const responseSpan = document.createElement('span');
          responseSpan.id = "responseSpan";
          dispatch(configActions.hideSpinningLoader());
          setRecordDetails({
            stop: false,
            start: false,
            delete: false
          });

        } catch (error) {
          setRecordDetails({
            stop: false,
            start: false,
            delete: false
          });
          dispatch(configActions.hideSpinningLoader());
          if (timer) {
            clearInterval(timer);
          }
          setAudioDuration({});
          setIsStopRecAlreadyRun(false);
          toast.error("Something went wrong! Please try again");
        }

        setRecordedUrl(url);
        chunks.current = [];
      };
      mediaRecorder.current.start();
    } catch (error) {
      setRecordDetails({
        stop: false,
        start: false,
        delete: false
      });
      toast.error("Error accessing microphone");
      console.error('Error accessing microphone:', error);
    }
  }

  const longPressEvent = useLongPress(startRecordingHandler, stopOrDeleteRecording, {
    shouldPreventDefault: true,
    delay: 300
  });

  const privateChatMessagesActive = useSelector((state) => state.chatBox.privateChatMessagesActive)
  const extractAndClearEditor = () => {
    //to extract text from editor state

    var contenteditable = document.querySelector('[contenteditable]');
    var value = contenteditable.innerText;

    var mentions = onExtractMentions()
    mentions = mentions.map(item => {
      const { attributes: { username, userId, role, Mobile }, userIds, identity } = item
      let payload = { "name": username, "mobile": identity, "role": role, "userId": userId, "userIds": userIds }
      return payload

    })
    sendMessageFromMessageInput(value, mentions);
    // to empty the editor text
    contenteditable.innerHTML = ''
    document.getElementById("editableDiv").focus()

    setRecordedText("");
  };

  const extractAndClearEditorPopup = () => {
    //to extract text from editor state
    var value = sttValue;
    sendMessageFromMessageInput(value, []);
    setIsPopupMessageSent(true);
    setSentDateTime(moment().format('hh:mm a DD-MMM'));
    // to empty the editor text and show sent message and patient name
    setRecordedText("");
    toast.success("Message sent!");
    setTabValue("2");

    // Hit API for send message event
    let token = JSON.parse(localStorage.getItem('user')).access_token;
    let myHeaders = {
      'Authorization': "Bearer " + token
    };
    let reqBody = {
      "appId": patientInfo?.appointmentId,
      "action": "sendMessageForStt"
    };
    let url = `${process.env.REACT_APP_BASE_URL_STT_SAVE}/closedOrSubmitEvent`;
    try {
      axios.post(url, reqBody, {
        headers: myHeaders
      });
    } catch (e) {
      console.log(e);
    }
  }

  const handlePopupMessageTextChange = (value) => {
    setSttValue(value);
    setPopupSentText(value);
  }

  const changeArrivalTime = (value) => {
    if (!value) {
      setArrivalTime("");
      setIsArrivalChange(false);
      return;
    }
    setArrivalTime(value);
    setIsArrivalChange(true);
  }

  const changeRoomType = (value) => {
    setRoomType(value);
    setIsRoomTypeChange(true);
    document.querySelector('.detailsForm .formInfo .roomDetails .selectRoomType').style.borderColor = 'green';
  }

  const changeRoomNumber = (value) => {
    setRoomNumber(value);
    setIsRoomNumberChange(true);
    document.getElementById('room-number').style.borderColor = 'green';
  }

  const changeCollection = (value) => {
    setCollection(value);
    setIsCollectionChange(true);
    document.getElementById('collection').style.borderColor = 'green';
  }

  const changeOtDateTime = (value) => {
    if (!value) {
      setOtDateTime("");
      setIsOtDateTimeChange(false);
      return;
    }
    setOtDateTime(value);
    setIsOtDateTimeChange(true);
  }

  const changeOtStartDateTime = (value) => {
    if (!value) {
      setOtStartDateTime("");
      setIsOtStartDateTimeChange(false);
      return;
    }
    setOtStartDateTime(value);
    setIsOtStartDateTimeChange(true);
  }

  const changeOtEndDateTime = (value) => {
    if (!value) {
      setOtEndDateTime("");
      setIsOtEndDateTimeChange(false);
      return;
    }
    setOtEndDateTime(value);
    setIsOtEndDateTimeChange(true);
  }

  const submitDetails = async () => {
    let token = JSON.parse(localStorage.getItem('user')).access_token;
    let myHeaders = {
      'Authorization': "Bearer " + token
    };
    dispatch(configActions.showSpinningLoader());
    let url = `${process.env.REACT_APP_BASE_URL_STT_SAVE}/v1/updateProcessedRecords`;
    let reqBody = {
      "appId": patientInfo?.appointmentId
    };
    if (recordBeingKept?.sttData?.arrivalTime || isArrivalChange) {
      reqBody = {
        ...reqBody,
        "arrivalTime": dayjs(arrivalTime).format('YYYY-MM-DDTHH:mm')
      };
    }
    if (recordBeingKept?.sttData?.roomType || isRoomTypeChange) {
      reqBody = {
        ...reqBody,
        "roomType": roomType
      };
    }
    if (recordBeingKept?.sttData?.roomNumber || isRoomNumberChange) {
      reqBody = {
        ...reqBody,
        "roomNumber": roomNumber
      }
    }
    // if (recordBeingKept?.sttData?.amountCollectedAtArrival || isCollectionChange) {
    //   reqBody = {
    //     ...reqBody,
    //     "amountCollectedAtArrival": collection
    //   }
    // }
    if (recordBeingKept?.sttData?.proposedOtTime || isOtDateTimeChange) {
      reqBody = {
        ...reqBody,
        "proposedOtTime": dayjs(otDateTime).format('YYYY-MM-DDTHH:mm')
      }
    }
    if (recordBeingKept?.sttData?.otStartTime || isOtStartDateTimeChange) {
      reqBody = {
        ...reqBody,
        "otStartTime": dayjs(otStartDateTime).format('YYYY-MM-DDTHH:mm')
      }
    }
    if (recordBeingKept?.sttData?.otEndTime || isOtEndDateTimeChange) {
      reqBody = {
        ...reqBody,
        "otEndTime": dayjs(otEndDateTime).format('YYYY-MM-DDTHH:mm')
      }
    }

    try {
      let submitResponse = await axios.post(url, reqBody, {
        headers: myHeaders
      });
      dispatch(configActions.hideSpinningLoader());
      if (submitResponse?.data && submitResponse?.data?.status && submitResponse?.data?.status === "Success") {
        toast.success("Details submitted successfully!");
        setOtDetailsSubmitted(true);
      } else {
        throw new Error("");
      }
    } catch (error) {
      dispatch(configActions.hideSpinningLoader());
      toast.error("Something went wrong! Please try again");
    }

    // API for submit data event
    let reqBodyForSubmit = {
      "appId": patientInfo?.appointmentId,
      "action": "submitDataForCentralOps"
    };
    let urlForSubmit = `${process.env.REACT_APP_BASE_URL_STT_SAVE}/closedOrSubmitEvent`;
    try {
      axios.post(urlForSubmit, reqBodyForSubmit, {
        headers: myHeaders
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (otDetailsSubmitted && isPopupMessageSent) {
      handleClose();
    }
  }, [otDetailsSubmitted, isPopupMessageSent]);

  useImperativeHandle(ref, () => ({
    extractAndClearEditor,
  }));


  const onExtractMentions = () => {
    var mentionNodes = document.querySelectorAll('[data-object]')
    var extractedMentions = []
    for (let i = 0; i < mentionNodes.length; i++) {
      extractedMentions.push(JSON.parse(mentionNodes[i].getAttribute('data-object')))
    }
    return extractedMentions
  }

  const handleDontShowChange = (e) => {
    setDontShowCheck(e.target.checked);
  }

  const nextBackdrop = () => {
    if ((imageUrlObj.index) === imagesArray.length) {
      setShowDemo(false);
      if (dontShowCheck) {
        localStorage.setItem('demoNoShow', true);
      }
    } else {
      setImageUrlObj(imagesArray[imageUrlObj.index]);
    }
  }

  const { showUserList, handleChange, onCaretClick, insertTextAtCaret, displayUserList } = UseMessageInputHook()

  const editorRef = useRef()

  return (<>
    <div className="contentEditableContainer" id="contentEditableContainer" ref={editorRef} placeholder="hey there" >
      {showUserList && <Box className="mentionDropDownMobile">
        {displayUserList.map((item, id) => {
          return <div key={id} onClick={() => { insertTextAtCaret(item) }} className='mentionItem'>{item.name}</div>
        })}
      </Box>}

      <Dialog open={open}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0', zIndex: '1', width: '100%' }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example" centered variant='fullWidth'>
                <Tab label="Send Message" value="1" />
                <Tab label="Fill Details" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '24px 24px 0px 24px' }}>
              {
                !isPopupMessageSent ?
                  <>
                    <TextField
                      id="stt-value"
                      placeholder='Message'
                      multiline
                      rows={18}
                      value={sttValue}
                      onChange={(e) => { handlePopupMessageTextChange(e.target.value) }}
                    />
                    <div className='tabPanelAction'>
                      <Button className='actionBtn' variant="outlined" onClick={handleClose}>Close</Button>
                      <Button className='actionBtn' variant="contained" onClick={extractAndClearEditorPopup}>Send Message</Button>
                    </div>
                  </>
                  :
                  <>
                    <div className='patientDetails'>Patient Name - {recordBeingKept?.patientDetails?.pName}</div>
                    <div className='sentMessage'>
                      {popupSentText}
                      <div className='sentTime'>Sent , {sentDateTime}</div>
                    </div>
                    <div className='tabPanelAction'>
                      <Button className='actionBtnCloseOnly' variant="outlined" onClick={handleClose}>Close</Button>
                    </div>
                  </>
              }

            </TabPanel>
            <TabPanel value="2" sx={{ padding: '15px 0px 0px 0px' }}>
              <section className='detailsForm'>
                <header className='subheader'>Patient Details</header>
                <Divider className='divider' />
                <div className='formInfo'>
                  <div className='row'><span className='pLabel'>Patient Name</span><span className='pValue'>{recordBeingKept?.patientDetails?.pName}</span></div>
                  <div className='row'><span className='pLabel'>Disease</span><span className='pValue'>{recordBeingKept?.patientDetails?.disease}</span></div>
                </div>
              </section>
              <section className='detailsForm'>
                <header className='subheader'>Admission Details</header>
                <Divider className='divider' />
                <div className='formInfo'>
                  <div className='arrivalTimeDiv'>
                    <label className='formInfoLabel' htmlFor="arrival-time">Arrival Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className='arrivalTimeInput'
                        id='arrival-time'
                        renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "DD-MM-YYYY hh:mm", style: { border: isArrivalChange ? '2px solid green' : recordBeingKept?.sttData?.arrivalTime ? '2px solid purple' : '2px solid black' } }} />}
                        value={arrivalTime}
                        onChange={(e) => { changeArrivalTime(e) }}
                        minDateTime={dayjs(recordBeingKept?.patientDetails?.arrivalTime)}
                        maxDate={dayjs()}
                        inputFormat={'DD-MM-YYYY hh:mm a'}
                      // onError={(err) => setArrivalError(err ? "Please select valid date time" : "")}
                      // slotProps={{
                      //   textField: {
                      //     helperText: arrivalError,
                      //   },
                      // }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className='roomDetails'>
                    <div>
                      <label className='formInfoLabel' htmlFor='room-type'>Room Type</label>
                      <Select
                        labelId="demo-simple-select-label"
                        id='room-type'
                        className='selectRoomType'
                        value={roomType}
                        fullWidth
                        size="small"
                        onChange={(e) => { changeRoomType(e.target.value) }}
                        style={recordBeingKept?.sttData?.roomType ? { borderColor: 'purple' } : { borderColor: 'black' }}
                      >
                        <MenuItem value={'Private'}>Private</MenuItem>
                        <MenuItem value={'Semi-Private'}>Semi-Private</MenuItem>
                        <MenuItem value={'Triple Sharing'}>Triple Sharing</MenuItem>
                        <MenuItem value={'General Ward'}>General Ward</MenuItem>
                      </Select>
                    </div>
                    <div>
                      <label className='formInfoLabel' htmlFor='room-number'>Room Number</label>
                      <input className='roomNumber' type='text' id='room-number' value={roomNumber} onChange={(e) => { changeRoomNumber(e.target.value) }} style={recordBeingKept?.sttData?.roomNumber ? { borderColor: 'purple' } : { borderColor: 'black' }} />
                    </div>
                  </div>
                  <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', marginTop: '5px', display: 'none' }}>
                    <label className='formInfoLabel' htmlFor='collection'>Total Collected at Admission</label>
                    <input className='collectionInfo' type='number' id='collection' value={collection} onChange={(e) => { changeCollection(e.target.value) }} style={recordBeingKept?.sttData?.amountCollectedAtArrival ? { borderColor: 'purple' } : { borderColor: 'black' }} />
                    <span className='rupeeLabel'>Rs.</span>
                  </div>
                </div>
              </section>
              <section className='detailsForm'>
                <header className='subheader'>OT Details</header>
                <Divider className='divider' />
                <div className='formInfo'>
                  <div className='otDates'>
                    <label className='formInfoLabel' htmlFor='otDateTime'>OT Date & Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className='otDateTimeInfo'
                        id='otDateTime'
                        renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "DD-MM-YYYY hh:mm", style: { border: isOtDateTimeChange ? '2px solid green' : recordBeingKept?.sttData?.proposedOtTime ? '2px solid purple' : '2px solid black' } }} />}
                        value={otDateTime}
                        onChange={(e) => { changeOtDateTime(e) }}
                        minDateTime={dayjs(recordBeingKept?.patientDetails?.appointmentDate)}
                        maxDate={dayjs()}
                        inputFormat={'DD-MM-YYYY hh:mm a'}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className='otDates'>
                    <label className='formInfoLabel' htmlFor='otStartDateTime'>Actual OT Start Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className='otDateTimeInfo'
                        id='otStartDateTime'
                        renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "DD-MM-YYYY hh:mm", style: { border: isOtStartDateTimeChange ? '2px solid green' : recordBeingKept?.sttData?.otStartTime ? '2px solid purple' : '2px solid black' } }} />}
                        value={otStartDateTime}
                        onChange={(e) => { changeOtStartDateTime(e) }}
                        minDateTime={dayjs(recordBeingKept?.patientDetails?.appointmentDate)}
                        maxDate={dayjs()}
                        inputFormat={'DD-MM-YYYY hh:mm a'}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className='otDates'>
                    <label className='formInfoLabel' htmlFor='otEndDateTime'>Actual OT End Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        className='otDateTimeInfo'
                        id='otEndDateTime'
                        renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "DD-MM-YYYY hh:mm", style: { border: isOtEndDateTimeChange ? '2px solid green' : recordBeingKept?.sttData?.otEndTime ? '2px solid purple' : '2px solid black' } }} />}
                        value={otEndDateTime}
                        onChange={(e) => { changeOtEndDateTime(e) }}
                        minDateTime={dayjs(recordBeingKept?.patientDetails?.appointmentDate)}
                        maxDate={dayjs()}
                        inputFormat={'DD-MM-YYYY hh:mm a'}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </section>
              <div className='tabPanelActionTab2'>
                <Button className='actionBtn' variant="outlined" onClick={handleClose}>Close</Button>
                <Button className='actionBtn' variant="contained" onClick={submitDetails}>Submit Details</Button>
              </div>
            </TabPanel>
          </TabContext>
        </Box>

      </Dialog>
      <span id="duration">{audioDuration?.duration ?
        (Math.floor(audioDuration?.duration / 60)).toString().padStart(2, "0") + ':' + (audioDuration?.duration % 60).toString().padStart(2, "0")
        : null}</span>

      {
        isSttAllowed ?
          <Draggable
            axis="x"
            bounds='parent'
            handle=".recordButton"
            position={{ x: 0, y: 0 }}
            // disabled={isStopRecording}
            onDrag={(e) => {
              var rect = e.target.getBoundingClientRect();
              e.stopPropagation();
              // if (rect.left <= deleteRecordingRef?.current?.getBoundingClientRect().left + 15) {
              //   if (!isStopRecording) {
              //     setIsStopRecording(true);
              //     if (deleteRecordingRef?.current) deleteRecordingRef.current.click();
              //     return false;
              //   } else if (isStopRecording) {
              //     if (timer) {
              //       clearInterval(timer);
              //       setAudioDuration({})
              //     }
              //     return false;
              //   }
              //   return false;
              // } else if (!deleteRecordingRef?.current?.getBoundingClientRect().left) {
              //   deleteRecordingRef.current.click();
              //   return false;
              // }
              if (rect.left <= deleteRecordingRef?.current?.getBoundingClientRect().left + 15) {
                stopOrDeleteRecording(true);
              } else if (!deleteRecordingRef?.current?.getBoundingClientRect().left) {
                deleteRecordingRef.current.click();
                return false;
              } else if (!deleteRecordingRef?.current?.getBoundingClientRect().left) {
                deleteRecordingRef.current.click();
                return false;
              }
            }}
          >
            <div>
              {
                micPermission === 'granted' ?
                  <button className={!recordDetails.start ? 'recordButton' : 'recordButton currentlyRecording'}
                  type='button'
                  {...longPressEvent}
                  onContextMenu={(e) => { e.preventDefault() }}
                    // onTouchStartCapture={() => {
                    //   setIsStopRecording(false);
                    //   startRecording();
                    // }}
                    // onTouchEndCapture={() => {
                    //   if (!isStopRecording) stopRecording();
                    // }}
                  >
                    {!recordDetails.start ? <MicIcon /> : <MicIcon style={{ color: '#EF0107' }} />}</button>
                  :
                  <>
                    <MicOffOutlinedIcon
                      onContextMenu={(e) => { e.preventDefault() }}
                      onTouchStartCapture={() => {
                        setIsStopRecording(false);
                        startRecordingHandler();
                      }}
                      className='micOff'
                      sx={{ color: 'red' }} />
                  </>
              }
            </div>
          </Draggable>
          :
          <></>
      }
      <button
        ref={deleteRecordingRef}
        className={!recordDetails.start ? 'cancelRecordingNoDisp' : 'cancelRecording'}
      // className={!isRecording ? 'cancelRecordingNoDisp' : 'cancelRecording'}
      // onClick={() => { setCancelRecording(true); stopDelete(); }}
      >
        {/* {!isRecording ? <></> : <DeleteIcon sx={{
          height: '1.4em',
          width: '2em'
        }} />} */}

        {!recordDetails.start ? <></> : <DeleteIcon sx={{
          height: '1.4em',
          width: '2em'
        }} />}
      </button>
      <div onInput={handleChange}
        placeholder="Type @ to tag"
        onMouseUp={onCaretClick} id="editableDiv"
        className={`editableDiv ${privateChatMessagesActive ? "privateEditableDiv" : ''}`}
        contentEditable={!recordDetails.start ? true : false}
      // contentEditable={!isRecording ? true : false}
      ></div>
    </div>
    <Backdrop
      sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, .85)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showDemo}
    >
      <div className='backdrop-container'>
        <div className='image-container'>
          <img className='info-image' src={imageUrlObj.url} alt="image" />
        </div>
        <div className='checkbox-container'>
        {
          imageUrlObj.index === 3 ? 
            <>
              <Checkbox checked={dontShowCheck} onChange={(e) => {handleDontShowChange(e)}} id='dontShowCheckbox' sx={{ color: '#fff' }} />
              <label htmlFor='dontShowCheckbox'>Don't show again</label>
            </>
            :
            <></>
        }
        </div>
        <Button size='medium' variant="contained" onClick={() => {nextBackdrop()}}>Next</Button>
      </div>
    </Backdrop>
  </>
  );
}
)

export default MobileMessageInput;
