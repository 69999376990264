export const chatBoxConstants = {
  GET_CHAT_MESSAGES_REQUESTING: "GET_CHAT_MESSAGES_REQUESTING",
  GET_CHAT_MESSAGES_FETCHED: "GET_CHAT_MESSAGES_FETCHED",
  GET_CHAT_MESSAGES_FAILED: "GET_CHAT_MESSAGES_FAILED",

  //general Chat messages
  GENERAL_CHAT_MESSAGES_REQUESTING: "GENERAL_CHAT_MESSAGES_REQUESTING",
  GENERAL_CHAT_MESSAGES_FETCHED: "GENERAL_CHAT_MESSAGES_FETCHED",
  GENERAL_CHAT_MESSAGES_FAILED: "GENERAL_CHAT_MESSAGES_FAILED",

  // setting the selected Message
  SET_SELECTED_MESSAGE: "SET_SELECTED_MESSAGE",

  // for sending chat messages
  SEND_CHAT_MESSAGES_REQUESTING: "SEND_CHAT_MESSAGES_REQUESTING",
  SEND_CHAT_MESSAGES_FETCHED: "SEND_CHAT_MESSAGES_FETCHED",
  SEND_CHAT_MESSAGES_FAILED: "SEND_CHAT_MESSAGES_FAILED",

  // for deleting chat messages
  DELETE_CHAT_MESSAGES_REQUESTING: "DELETE_CHAT_MESSAGES_REQUESTING",
  DELETE_CHAT_MESSAGES_FETCHED: "DELETE_CHAT_MESSAGES_FETCHED",
  DELETE_CHAT_MESSAGES_FAILED: "DELETE_CHAT_MESSAGES_FAILED",

  // for sending chat document
  SEND_CHAT_FILE_REQUESTING: "SEND_CHAT_FILE_REQUESTING",
  SEND_CHAT_FILE_FETCHED: "SEND_CHAT_FILE_FETCHED",
  SEND_CHAT_FILE_FAILED: "SEND_CHAT_FILE_FAILED",

  //reply to chat message
  SEND_CHAT_REPLY_REQUESTING: "SEND_CHAT_REPLY_REQUESTING",
  SEND_CHAT_REPLY_FETCHED: "SEND_CHAT_REPLY_FETCHED",
  SEND_CHAT_REPLY_FAILED: "SEND_CHAT_REPLY_FAILED",
  CLEAR_CHAT_BOX: "CLEAR_CHAT_BOX",

  // add chat message to state
  ADD_CHAT_MESSAGE: "ADD_CHAT_MESSAGE",

  // delete chat message from state
  DELETE_CHAT_MESSAGE: "DELETE_CHAT_MESSAGE",

  // update download chat message
  UPDATE_DOWNLOAD_CHAT_MESSAGE: "UPDATE_DOWNLOAD_CHAT_MESSAGE",

  // update loading  chat message
  LOADING_DOWNLOAD_CHAT_MESSAGE: "LOADING_DOWNLOAD_CHAT_MESSAGE",

  // Sync chat messages with DB
  SYNC_CHAT_MESSAGES_REQUESTING: "SYNC_CHAT_MESSAGES_REQUESTING",
  SYNC_CHAT_MESSAGES_FETCHED: "SYNC_CHAT_MESSAGES_FETCHED",
  SYNC_CHAT_MESSAGES_FAILED: "SYNC_CHAT_MESSAGES_FAILED",

  //sync chat message
  SYNC_CHAT_MESSAGE: "SYNC_CHAT_MESSAGE",

  // get message templates
  GET_CHAT_TEMPLATES_REQUESTING: "GET_CHAT_TEMPLATES_REQUESTING",
  GET_CHAT_TEMPLATES_FETCHED: "GET_CHAT_TEMPLATES_FETCHED",
  GET_CHAT_TEMPLATES_FAILED: "GET_CHAT_TEMPLATES_FAILED",

  // send message templates
  SEND_CHAT_TEMPLATES_REQUESTING: "SEND_CHAT_TEMPLATES_REQUESTING",
  SEND_CHAT_TEMPLATES_FETCHED: "SEND_CHAT_TEMPLATES_FETCHED",
  SEND_CHAT_TEMPLATES_FAILED: "SEND_CHAT_TEMPLATES_FAILED",

  // send to insurance
  SEND_TO_INSURANCE_REQUESTING: "SEND_TO_INSURANCE_REQUESTING",
  SEND_TO_INSURANCE_FETCHED: "SEND_TO_INSURANCE_FETCHED",
  SEND_TO_INSURANCE_FAILED: "SEND_TO_INSURANCE_FAILED",

  //download  chat message
  DOWNLOAD_CHAT_MESSAGE_REQUESTING: "DOWNLOAD_CHAT_MESSAGE_REQUESTING",
  DOWNLOAD_CHAT_MESSAGE_FETCHED: "DOWNLOAD_CHAT_MESSAGE_FETCHED",
  DOWNLOAD_CHAT_MESSAGE_FAILED: "DOWNLOAD_CHAT_MESSAGE_FAILED",

  UPDATE_FULL_CHATBOX_STATE: "UPDATE_FULL_CHATBOX_STATE",
  // number of messages for chatbox
  //for demo make it 8
  MESSAGES_LIMIT:50,
  // Fetch more messages
  FETCH_MORE_MESSAGES_REQUESTING:"FETCH_MORE_MESSAGES_REQUESTING",
  FETCH_MORE_MESSAGES_FETCHED:"FETCH_MORE_MESSAGES_FETCHED",
  FETCH_MORE_MESSAGES_FAILED:"FETCH_MORE_MESSAGES_FAILED",

  // has more messages
   HAS_MORE_MESSAGES:"HAS_MORE_MESSAGES",
   BLOCK_CHATBOX_SCREEN:"BLOCK_CHATBOX_SCREEN",

  // private chat 
  PRIVATE_CHAT_ON:"PRIVATE_CHAT_ON",
  PRIVATE_CHAT_OFF:"PRIVATE_CHAT_OFF",
  PRIVATE_CHAT_MESSAGES:"PRIVATE_CHAT_MESSAGES",
  PRIVATE_CHAT_FAIL:"PRIVATE_CHAT_FAIL"
};
