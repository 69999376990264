export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        // location.reload(true);

        return Promise.reject(data)
      }

      // const error = (data && data.message) || response.statusText;
      // return Promise.reject(error);
      return Promise.reject(data);
    }

    return data;
  });
}

export function getTokenFromLocal() {
  let user = localStorage.getItem("user");
  !user && clearStorage();
  return user ? JSON.parse(user) : null;
}

export function clearStorage() {
  localStorage.setItem("user", null);
}
