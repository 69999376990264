import parse from "html-react-parser";
import { useEffect, useState } from "react";

function MessageText({ message,mentionedColor }) {
  const [messageText, setMessageText] = useState("");
  useEffect(() => { }, []);
  const updateMessagesBody = () => {
    let style = {textTransform:"capitalize",color:mentionedColor}
    let updatedText = message.MessageBody;
    let mention_data = message.Attributes.mention_data;
    if (mention_data && mention_data.length > 0) {
      for (let i = 0; i < mention_data.length; i++) {
        updatedText = updatedText.replace(
          `@${mention_data[i]["name"]}`,
          `<span style="text-transform:capitalize;color:#3d7cc9">
          @${mention_data[i]["name"]}</span>`
        );
      }
    }
    if (updatedText) {
      return parse(updatedText.replace(/\n/g, "<br />"));
    }
  };
  return <div>{updateMessagesBody()}</div>;
}

export default MessageText;
