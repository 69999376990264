import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import csvDownload from "../../../assets/csvDownload.svg";
import videoDownload from '../../../assets/videoDownload.svg';
import { detectBrowser } from "../../../_helpers";
import DocumentCardThumbnail from "./DocumentCardThumbnail";

function UploadDocumentThumbnail({
  bigThumbnail = false,
  docType,
  previewUrl,
  uploadedDocument,
  id,
  selected,
  changeActiveItem,
  removeDoc,
  activeId,
  showClose = false,
  showRemoveDoc = true,
  desktopUploadThumbnail = false
}) {
  const browserName = detectBrowser()
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  const getMediaDiv = ({ docType }) => {

    var src = ''
    if (docType === 'image') {
      return (
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
            borderRadius: '5px'
          }}
          src={previewUrl}
          alt="preview img"
        />
      )
    }
    else if (docType === 'csv') {
      return (
        <img
        src={csvDownload}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain"
          }}
        />
      )
    }
     else if (docType === 'video') {
      return (<Box component={'video'}
        style={{ width: "100%", height: "100%", objectFit: "contain" }}
        src={preloadVideo(previewUrl) ? `${previewUrl}#t=0.001` : previewUrl}
        poster={showDefaultThumbnail(previewUrl)&&videoDownload}
        className="videoThumbnail"
      />)
    }

    else if (docType === "pdf") {
      src = `/web/viewer.html?file=${previewUrl?.replaceAll('&', '%26')}`
    }
    else {
      src = previewUrl
    }
    return (
      <iframe
        style={{
          width: "100%", height: "100%",
          border: "none",
          pointerEvents: "none"
        }}
        src={src}
        key={uploadedDocument.lastModified}
        title={uploadedDocument.lastModified}
        class="thumbnailIframe"
        onLoad={() => modifyThumbnailIframe()}
        data-name={previewUrl?.replaceAll('&', '%26')}

      />
    )
  }


  const modifyThumbnailIframe = () => {
    var allIframes = document.getElementsByClassName("thumbnailIframe")
    for (let i = 0; i < allIframes.length; i++) {

      var iframeDocument = allIframes[i].contentWindow.document
      var toolbars = iframeDocument.getElementsByClassName('toolbar')

      //  to remove all toolbars
      for (let i = 0; i < toolbars.length; i++) {
        toolbars[i].style.display = 'none'
      }

      var mainContainer = iframeDocument.getElementById('mainContainer')
      mainContainer.style.width = "fit-content"
      mainContainer.style['min-width'] = "unset!important"
      mainContainer.style.left = -20
      mainContainer.style['margin-top'] = "-10px"

      var viewContainer = iframeDocument.getElementById('viewerContainer')
      console.log('this is view container', viewContainer)
      if (viewContainer) {
        viewContainer.style.position = "absolute"
        viewContainer.style.left = '-100px'
        viewContainer.style.top = 0
        viewContainer.style.overflow = "hidden"
      }


      iframeDocument.getElementById('outerContainer').style.overflow = "hidden"
    }

  }

  const modifyPreviewIframe = () => {
    console.log('frame is freshed', document.getElementById('previewIframe'))
    var frame = document.getElementById("previewIframe")
    document.getElementById('loadingMessage').style['display']= 'none'

    console.log('dragon this is div', frame)
    var iframeDocument = frame.contentWindow.document
    console.log('dragon this is iframe', iframeDocument)
    var mainContainer = iframeDocument.getElementById('mainContainer')
    console.log('dragon this is maincontainer', mainContainer)
    if (mainContainer) {
      mainContainer.style['min-width'] = "auto"
    }
  }

  const renderFrame = () => {
    const src = docType === 'pdf' ? `/web/viewer.html?file=${previewUrl?.replaceAll('&', '%26')}` : previewUrl
    return (
      <>
      <iframe
        src={src}
        title="preview"
        id="previewIframe"
        data-className="naman"
        className="naman2"
        onLoad={() => modifyPreviewIframe()}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        />
        <div style={{ width: "100%",
    height: "100%",
    zIndex:" 1000",
    background: "#f5f5f5",
    top: "0px",
    left: "0px",
    position: "absolute",
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    }} id="loadingMessage" key={src}>Loading...</div>
        </>
    )
  }

  const preloadVideo = (url) => {
    return browserName === 'safari' && !url.includes('blob')
  }
  const showDefaultThumbnail = (url) => {
    return browserName === 'safari' && url.includes('blob')
  }
  

  return (
    <>

      {bigThumbnail == true && (
        <>
          {docType === "image" && (
            <Box component={'img'}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              src={previewUrl}
              alt="previewUrl img"
            />
          )}
          {docType === "video" && (<>
            <Box component={'video'}
              style={{ backgroundColor: 'black', height: '100%', width: "100%", objectFit: "contain" }}
              src={preloadVideo(previewUrl) ? `${previewUrl}#t=0.001` : previewUrl}
              controls
            />
          </>
          )}
          {(docType === "pdf" || docType === "doc" || docType === "excel") && renderFrame()}

          {!(docType === "pdf" || docType === "doc" || docType === "excel" || docType === 'image' || docType === 'video') && (
            <div>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
                className="overlay"
                data-type={docType}
              >
                <Typography>No Preview Available</Typography>
              </div>
            </div>
          )}
        </>
      )}
      {bigThumbnail == false && (
        <>
          {docType === "image" && (
            <DocumentCardThumbnail desktopUploadThumbnail={desktopUploadThumbnail} fileName={uploadedDocument.name} showClose={showClose} handleClose={removeDoc} id={id} showSelected={id === activeId} data={id} handleClick={changeActiveItem}>
              {getMediaDiv({ docType: docType })}
            </DocumentCardThumbnail>
          )}
          {docType === "video" && (
            <DocumentCardThumbnail desktopUploadThumbnail={desktopUploadThumbnail} fileName={uploadedDocument.name} showClose={showClose} handleClose={removeDoc} id={id} showSelected={id === activeId} data={id} handleClick={changeActiveItem}>
              {getMediaDiv({ docType: docType })}
            </DocumentCardThumbnail>
          )}
          {(docType === "pdf" || docType === "doc" || docType === "excel") && (
            <DocumentCardThumbnail desktopUploadThumbnail={desktopUploadThumbnail} fileName={uploadedDocument.name} showClose={showClose} handleClose={removeDoc} id={id} showSelected={id === activeId} data={id} handleClick={changeActiveItem}>
              {getMediaDiv({ docType: docType })}
            </DocumentCardThumbnail>
          )}
          {docType === "csv" && (
            <DocumentCardThumbnail desktopUploadThumbnail={desktopUploadThumbnail} fileName={uploadedDocument.name} showClose={showClose} handleClose={removeDoc} id={id} showSelected={id === activeId} data={id} handleClick={changeActiveItem}>
              {getMediaDiv({ docType: docType })}
            </DocumentCardThumbnail>

          )}

        </>
      )}
    </>
  );
}

export default UploadDocumentThumbnail;
