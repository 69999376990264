export const useIntersectionObserver = () => {
  const mainCallback = ({
    selectors = [],
    element,
    visibleCallback,
    hiddenCallback,
  }) => {
    let optionsForAnimations = {
      threshold: [...Array(10)].map(
        (_, index, arr) => ((index + 1) * 1) / arr.length
      ),
      rootMargin: "200px",
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          visibleCallback();
        } else {
          hiddenCallback();
        }
      });
    }, optionsForAnimations);

    if (!!selectors) {
      selectors.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          if (!element) return;

          observer.observe(element);
        });
      });
    } else if (!!element) {
      observer.observe(element);
    }
  };

  return mainCallback;
};
