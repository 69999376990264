export function formatDate(dtValue) {
  let date = `dd-mmmm-yyyy`;
  if (dtValue) {
    const MonthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const d = dtValue.getDate()
    const m = MonthsName[dtValue.getMonth()]
    const y = dtValue.getFullYear()
    date = `${d}-${m}-${y}`
  }
  return date
}