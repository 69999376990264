export function SettingIcon() {
  return (
    <>
      <svg width="38" height="38" viewBox="0 0 38 28">
        <defs>
          <filter id="Line_2" x="0" y="0" width="39.5" height="19.5" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.102" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter id="Line_4" x="0" y="15" width="39.5" height="19.5" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-2" />
            <feFlood floodOpacity="0.102" />
            <feComposite operator="in" in2="blur-2" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter id="Line_3" x="0" y="7.5" width="39.5" height="19.5" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-3" />
            <feFlood floodOpacity="0.102" />
            <feComposite operator="in" in2="blur-3" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <g id="Filter_icon" data-name="Filter icon" transform="translate(-294.239 -30.818)">
          <g id="Group_1268" data-name="Group 1268" transform="translate(304.355 32.433)">
            <g transform="matrix(1, 0, 0, 1, -10.12, -1.62)" filter="url(#Line_2)">
              <line id="Line_2-2" data-name="Line 2" x2="20" transform="translate(9.75 6.75)" fill="none" stroke="#3d7cc9" strokeLinecap="round" strokeWidth="1.5" />
            </g>
            <g transform="matrix(1, 0, 0, 1, -10.12, -1.62)" filter="url(#Line_4)">
              <line id="Line_4-2" data-name="Line 4" x2="20" transform="translate(9.75 21.75)" fill="none" stroke="#3d7cc9" strokeLinecap="round" strokeWidth="1.5" />
            </g>
            <g transform="matrix(1, 0, 0, 1, -10.12, -1.62)" filter="url(#Line_3)">
              <line id="Line_3-2" data-name="Line 3" x2="20" transform="translate(9.75 14.25)" fill="none" stroke="#3d7cc9" strokeLinecap="round" strokeWidth="1.5" />
            </g>
            <circle id="Ellipse_23" data-name="Ellipse 23" cx="2.5" cy="2.5" r="2.5" transform="translate(3.635 2.635)" fill="#fff" stroke="#3d7cc9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <circle id="Ellipse_25" data-name="Ellipse 25" cx="2.5" cy="2.5" r="2.5" transform="translate(3.635 17.635)" fill="#fff" stroke="#3d7cc9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            <circle id="Ellipse_24" data-name="Ellipse 24" cx="2.5" cy="2.5" r="2.5" transform="translate(12.635 9.635)" fill="#fff" stroke="#3d7cc9" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </g>
        </g>
      </svg>
    </>
  )
}