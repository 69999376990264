import { Box, Paper, styled, Typography, useMediaQuery } from "@mui/material";
import ButtonHWA from "../../_components/ButtonHWA/ButtonHWA";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: '2px 2px 6px #0000000D',
  borderRadius: '5px',
  padding: "10px",
  marginBottom: "20px"
}));
const OwnerCard = ({ title, children, buttonText, buttonClick, sx,buttonProps, ...rest }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <StyledPaper sx={{ mb: {xs: '20px', md: '30px'}, padding: ['10px', '15px', '20px', '25px', '30px'], ...sx }} {...rest} data-component="OwnerCard">
      {(title || buttonText || buttonClick) && <Box sx={{ mb: "15px", display: 'flex', justifyContent: 'space-between' }}>
        {title && <Typography sx={{ fontSize: ['16px', '20px'], fontWeight: ["500", "500", "600", "600", "700"], color: "#3D7CC9" }}>{title}</Typography>}
        {(buttonText || buttonClick) && isSmallScreen && <ButtonHWA variant="contained" size="small" sx={{
          mt: '-2px', minWidth: '120px', maxWidth: '25%', fontWeight: ["600", "600", "600", "600", "700"], fontSize: ['14px', '14px', '14px', '14px', '20px'], padding: ['5px 0px 5px 0px', '5px 0px 5px 0px', '5px 0px 5px 0px', '5px 0px 5px 0px', '11px 0px 11px 0px'],
          lineHeight: [1.43, 1.43, 1.43, 1.4, 1.4],
        }} onClick={(e) => { typeof (buttonClick) === 'function' && buttonClick(e) }} {...buttonProps}>{buttonText}</ButtonHWA>}
      </Box>}
      {children}

      {!isSmallScreen && (buttonText || buttonClick) &&
        <Box sx={{
          textAlign: 'center',
          mt: {xs: '20px', md: 4}
        }}>
          <ButtonHWA variant="contained" size="small" sx={{
            minWidth: '320px',
            fontSize: ['14px', '14px', '20px', '20px', '20px'],
            padding: ['5px 0px 5px 0px', '5px 0px 5px 0px', '11px 0px 11px 0px', '11px 0px 11px 0px', '11px 0px 11px 0px'],
            lineHeight: [1.43, 1.43, 1.4, 1.4, 1.4],
            fontWeight: ["600", "600", "600", "600", "700"],
            width: 'auto'
          }} onClick={(e) => { typeof (buttonClick) === 'function' && buttonClick(e) }} {...buttonProps}>{buttonText}</ButtonHWA>
        </Box>
      }
    </StyledPaper>
  );
}

export default OwnerCard;