import { Box } from "@mui/material"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { checklistActions } from "../../../_actions/checklist.actions"
import { useFetchHospital, useFetchHostpitalPatient, useFetchPatient } from "../checklistSummaryData"
import SearchAndFilter from "../SearchAndFilter"
import noListImage from "./../../../assets/noList.png"
import HospitalList from "./HospitalList/HospitalList"
import PatientsList from "./patientsList/patientsList"
import HospitalViewList from "./HospitalViewList/hospitalViewList"
import { getTokenFromLocal } from "../../../_helpers/handleResponseMiddleware"


const MobileScreen = () => {
  const [selectedHospital, setSelectedHospital] = useState("")
  const [HospitalClick, setHospitalClick] = useState("")
  const { screenName, patientFilter , hospitalViewFilter} = useSelector(({ checklistSummary }) => checklistSummary);
  const isFetchingData = useSelector(state => state.checklistSummary.isFetchingData)

  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { hospitals, loadMore: hLoadMore, pageNumber: hospitalPageNumber } = useFetchHospital()
  const { patients, loadMoreP, pageNumberP } = useFetchPatient({ hospital: selectedHospital })
  const { hospitalPatients, filterAndfetchHospitalPatients, loadMorePH, pageNumberPH } = useFetchHostpitalPatient();
  const userHospitalId = useSelector((state)=> state.hospitals.currentHospitalData.hospital_id)
  const onHospitalClick = ({ hospitalId, where }) => {
    // filterAndfetchPatient({ hospital: hospitalId })
    setSelectedHospital(hospitalId)
    setHospitalClick(where)
    dispatch(checklistActions.changeChecklistScreen('patientScreen', hospitalId, where !== 'all' ? where : ""))
    const filterObj = {
      ...patientFilter,

      startDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      filterStatus: where !== 'all' ? where : "",
      search: '',
      pds: [],
      bbs: [],
      mop: [],
      hospital: hospitalId
    }
    dispatch(checklistActions.setChecklistFilters({ screenName: 'patientScreen', filterObj }))
    navigate('/checklistSummary/patient')
  }

  const onHospitalView = ({ hospitalId, where }) => {
    // filterAndfetchPatient({ hospital: hospitalId })
    setSelectedHospital(hospitalId)
    setHospitalClick("all")
    dispatch(checklistActions.changeChecklistScreen('hospitalViewScreen', hospitalId, 'all'))
    const filterObj = {
      ...hospitalViewFilter,
      startDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      search: '',
      mop: [],
    }
    dispatch(checklistActions.setChecklistFilters({ screenName: 'hospitalViewScreen', filterObj }))
  }

  const handleScroll = (e) => {
    const scrollElement = e.target
    const scrollBottom = scrollElement.scrollHeight - scrollElement.offsetHeight - scrollElement.scrollTop

    if (screenName === 'hospitalScreen' && hospitalPageNumber > 0 && scrollBottom < 5) {
      hLoadMore()
    }
    if (screenName === 'hospitalViewScreen' && pageNumberPH > 0 && scrollBottom < 5) {
      loadMorePH();
    }
    if (screenName === 'patientScreen' && pageNumberP > 0 && scrollBottom < 5) {
      loadMoreP()
    }
  }

  const renderNoDataScreen = () => {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "60px",
        visibility: isFetchingData === false ? 'unset' : 'hidden'
      }} >
        <div className="noDataFound">
          <div style={{ fontSize: "20px" }}>Oops! No List Found</div>
          <div style={{ width: "300px" }}> <img style={{ height: "100%", width: "100%", objectFit: "contain" }} src={noListImage} alt="no list img" /></div>
        </div>
      </Box>
    )
  }

  useEffect(() => {
    
    if (pathname === '/checklistSummary') {
      dispatch(checklistActions.changeChecklistScreen('hospitalScreen'))
    }
    else if (pathname === '/checklistSummary/hospitalView') {
      console.log("pathname 1",pathname,userHospitalId)
      onHospitalView(userHospitalId,'all')
      dispatch(checklistActions.changeChecklistScreen('hospitalViewScreen', userHospitalId))
    }
    else {
      // dispatch(checklistActions.changeChecklistScreen('patientScreen'))
    }
  }, [pathname])


  return (
    <Box sx={{ p: '10px 15px' }} data-screen-name={screenName} data-hospital-page-number={hospitalPageNumber} data-patient-page-number={pageNumberP}>
      <SearchAndFilter showBackButton={screenName === 'patientScreen'} onBackClick={(e) => {
        dispatch(checklistActions.changeChecklistScreen('hospitalScreen', ""))
        navigate('/checklistSummary')
      }} ClickEventHospital={HospitalClick} onChange={(e) => { }} />
      <Box className="scroll-element" sx={{ mt: '20px', mx: ['-15px'], px: ['15px'], height: 'calc(100vh - 145px)', overflow: 'auto' }} onScroll={handleScroll}>
        {screenName === 'hospitalScreen' && <HospitalList hospitals={hospitals} onHospitalSelect={onHospitalClick} />}
        {screenName === 'patientScreen' && <Box>
          {(patients && patients.length === 0) ? renderNoDataScreen() : <PatientsList patients={patients} />}
          </Box>
        }
        {screenName === 'hospitalViewScreen' && <Box>
          {(hospitals && hospitals.length === 0) ? renderNoDataScreen() : <HospitalViewList hospitals={hospitalPatients} />}
          </Box>
        }
      </Box>
    </Box>
  )
}
export default MobileScreen