import { cacheStorageConstants } from "../_constants";
import { mainCacheConstants } from '../_helpers';
const setCacheStorageItem = (params) => {
  return (dispatch, getState) => {

    const { cacheStorage } = getState();
    const { cacheOf, key } = params
    if (cacheOf == mainCacheConstants.chatBoxMessagesCache || cacheOf == mainCacheConstants.chatBoxGeneralChatMessagesCache || cacheOf == mainCacheConstants.privateChatMessagesCache) {
      if (cacheStorage && !cacheStorage[cacheOf].hasOwnProperty(key)) {
        dispatch(addTtlToState({ parentStateKey: cacheOf, key }))
      }
    }
    dispatch({
      type: cacheStorageConstants.SET_CACHE_ITEM,
      ...params,
    });
  }
};

const updateFullState = (newState) => {
  return {
    type: cacheStorageConstants.UPDATE_FULL_CACHE_STATE,
    newState,
  };
};

const syncCachedMessages = (payload) => {
  return {
    type: cacheStorageConstants.SYNC_CACHED_MESSAGES,
    payload,
  };
};

const addToRetryList = (payload) => {
  return {
    type: cacheStorageConstants.ADD_TO_RETRY_LIST,
    payload,
  };
};
const addToSendAgainList = (payload) => {
  return {
    type: cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST,
    payload,
  };
};

const setNetworkStatus = (payload) => {
  return {
    type: cacheStorageConstants.SET_NETWORK_STATUS,
    payload,
  };
};
const updateSendAgainListCount = () => {
  return {
    type: cacheStorageConstants.UPDATE_SEND_AGAIN_LIST_COUNT,
  };
};
const addTtlToState = ({ key, parentStateKey }) => {
  return (dispatch) => {
    const params = { key, parentStateKey, date: Date.now() }
    dispatch({ type: cacheStorageConstants.ADD_TTL_TO_STATE, params })
  }
}
const timerLimitCheck = ({ statesWithTTL }) => {
  return (dispatch) => {

    if (statesWithTTL && statesWithTTL.length > 0) {
      var statesToBeCleared=[]
      var newStatesWithTTL=[]
      for (let i = 0; i < statesWithTTL.length; i++) {
        const { key, parentStateKey, date } = statesWithTTL[i]
        const t1 = new Date(date)
        const t2 = new Date()
        const timeDiff=Math.round(((t1.getTime() - t2.getTime()) / 1000)/(60*60))
        // for demo use this line and make time difference 5
        // const timeDiff = Math.round(((t2.getTime() - t1.getTime()) / 1000))
        if (timeDiff >= 2) {
          statesToBeCleared.push({ key, parentStateKey })
          newStatesWithTTL.push({key, parentStateKey,date:Date.now()})
        }else{
          newStatesWithTTL.push(statesWithTTL[i])
        }
      }
      dispatch(cacheStorageActions.clearStatesWithTTL({statesToBeCleared}))
      dispatch(updateTTLForState({newStatesWithTTL }))

    }
  }
}
const clearStatesWithTTL = ({statesToBeCleared}) => {
  const removeStaleMessages=({messages})=>{   
    messages = messages?.sort(function (a, b) {
      return b.CreatedAsDate - a.CreatedAsDate;
    })
    //for demo make it 8
    messages = messages?.slice(0, 50)
    return messages
  }
  return (dispatch, getState) => {
    
    const {cacheStorage}=getState()
    var tempCacheStorage=cacheStorage
    for(let i=0;i<statesToBeCleared.length;i++){
      const {key,parentStateKey}=statesToBeCleared[i]
      var oldMessages=tempCacheStorage[parentStateKey][key]
      const newMessages=removeStaleMessages({messages:oldMessages})
      tempCacheStorage[parentStateKey][key]=newMessages
    }
    dispatch(updateFullState(tempCacheStorage))

  }
}
const updateTTLForState = ({ newStatesWithTTL }) => {
  return (dispatch) => {
       dispatch({ type: cacheStorageConstants.UPDATE_TTL_OF_STATE, newStatesWithTTL })
  }
}
export const cacheStorageActions = {
  setCacheStorageItem,
  updateFullState,
  syncCachedMessages,
  addToRetryList,
  addToSendAgainList,
  setNetworkStatus,
  updateSendAgainListCount,
  clearStatesWithTTL,
  updateTTLForState,
  timerLimitCheck

};
