import { documentsPopupConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

const initialState = getInitialState("documentsPopup");

export function documentsPopup(state = initialState, action) {
  switch (action.type) {
    case documentsPopupConstants.DOCUMENTS_LIST_REQUESTING:
      return {
        ...state,
        isFetchingDocumentList: true,
      };
    case documentsPopupConstants.DOCUMENTS_LIST_FETCHED:
      const { documents } = action;

      return {
        ...state,
        isFetchingDocumentList: false,
        documentsListActive: true,
        documents,
      };
    case documentsPopupConstants.DOCUMENTS_LIST_FAILED:
      return {
        ...state,
        documentsFetchingError: action.error,
        isFetchingDocumentList: false,
        documentsListActive: false,
      };

    case documentsPopupConstants.UPDATE_FULL_DOCUMENTS_POPUP_STATE:
      const { newState } = action;

      return {
        ...newState,
      };
    case documentsPopupConstants.SHOW_SANITY_POPUP:
      return {
        ...state,
        userSanityPopup: true,
      }
    case documentsPopupConstants.HIDE_SANITY_POPUP:
      return{
        ...state,
        userSanityPopup:false
      }
    // case documentsPopupConstants.SANITY_POPUP_REQUEST:
    //     return{
    //       ...state,
    //       userSanityPopup:false
    //   }
    // case documentsPopupConstants.SANITY_POPUP_FETCHED:
    //   return{
    //     ...state,
    //     userSanityPopup:action.payload
    //   }
    // case documentsPopupConstants.SANITY_MEMBER_REQUEST:
    // return{
    //     ...state,
    // }
    // case documentsPopupConstants.SANITY_MEMBER_FETCHED:
    //   return{
    //     ...state,
    //     userSanityPopup:false
    //   }
  
    
    case documentsPopupConstants.PRIVATE_POPUP_CHAT_SOCKET:
      return{
        ...state,
        showPrivateChatPopup:action.showPrivateChatPopup
      }
    default:
      return state;
  }
}
