import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import closeOrangeIcon from '../../../assets/crossIconOrange.svg';
import searchIcon from '../../../assets/search-icon.png';
import { configActions } from "../../../_actions";
import { documentsPopupActions } from "../../../_actions/documentsPopup.actions";
import { Debounce, downloadFileInBrowser, handleFileType } from "../../../_helpers";
import { getInitialState } from "../../../_helpers/getInitialState";
import DocumentCardThumbnail from "../UploadDocument/DocumentCardThumbnail";
import "./styles.css";



const searchInputDebounce = new Debounce();
const docsWithCategory = new Map();

const DocumentsBody = ({ data }) => {
 
  const { handlePopUpClose } = data;
  const [searchFilterdDocsCategory, setSearchFilterdDocsCategory] =
    useState(undefined);
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();

  const allDocuments = useSelector((state) => state.documentsPopup.documents);

  const documentsListActive = useSelector(
    (state) => state.documentsPopup.documentsListActive
  );

  const documentListData = useSelector(
    (state) => state.config.documentList.data
  );

  const showGeneralChatMessages = useSelector(
    (state) => state.homeState.showGeneralChatMessages
  );

  const currentPatientInfo = useSelector(
    (state) => state.patients.patientInfo.allInfo
  );

  const currentHospitalData = useSelector(
    (state) => state.hospitals.currentHospitalData
  );

  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchInput(() => value);
  };

  const handleCardClick = async (item) => {
    dispatch(configActions.showSpinningLoader());
    // { urls: urls, isDownload: false },
    if(item.Attributes.multiUrl){
      let arr=item.Attributes.multiUrl
           await downloadFileInBrowser({previewUrl:arr,documentTitle:item.MediaFileName})
    }else{
      await downloadFileInBrowser({previewUrl:[item.Media],documentTitle:item.MediaFileName})
    }

    
    // axios.get(response.result[0], {
    //   responseType: 'blob',
    // })
    // .then((res) => {
    //   fileDownload(res.data, item['MediaFileName'])
    // })
    // const url = response?.result[0];
    // const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    // if (newWindow) newWindow.opener = null;
  };

  const handleDocumentClickMain = (doc) => {
    handleCardClick(doc).finally(() =>
      dispatch(configActions.hideSpinningLoader())
    );
  };

  const updateDocsWithCategory = (type, value) => {
    if (docsWithCategory.has(type)) {
      docsWithCategory.set(type, [...docsWithCategory.get(type), value]);

      return;
    }

    docsWithCategory.set(type, [value]);
  };

  const setNewMapForFilterdGroupList = () => {
    const newFilteredMap = new Map();

    const keys = [...docsWithCategory?.keys()];

    keys.forEach((key) => {
      const allItems = docsWithCategory.get(key);
      const filteredItems = allItems?.filter((item) => {
        const {
          MediaFileName,
          DocTypeOfPatient = "",
          DocumentKey = "",
          Attributes: { docTypeOfPatient = "", document_key = "" },
        } = item;

        return !searchInput.length
          ? true
          : [
            DocTypeOfPatient,
            DocumentKey,
            docTypeOfPatient,
            document_key,
          ].some((item = "") =>
            item
              .trim()
              .toLowerCase()
              .includes(searchInput.trim().toLowerCase())
          );
      });

      newFilteredMap.set(key, filteredItems);

    });

    setSearchFilterdDocsCategory(newFilteredMap);
  };

  const handleSearchInputSubmit = () => {
    setNewMapForFilterdGroupList();
  };

  useEffect(() => {
    searchInputDebounce.debounce(() => {
      if (docsWithCategory) setNewMapForFilterdGroupList();
    }, 200);
  }, [searchInput]);

  useEffect(() => {
    //to create the required order of the key in the map
    ["Patient Doc", "Surgery", "Billing"].forEach((type) => {
      docsWithCategory.set(type, []);
    });

    allDocuments?.forEach((item) => {
      const {
        Attributes: { document_key = "Others", message_media_name },
      } = item;

      const type =
        documentListData?.find(({ Key }) => Key === document_key)?.["type"] ||
        "Others";

      updateDocsWithCategory(type, item);
    });

    setSearchFilterdDocsCategory(docsWithCategory);

  }, [allDocuments, documentListData]);

  const fetchTheDocs = () => {
    if (showGeneralChatMessages) {
      dispatch(
        documentsPopupActions.getDocumentsList(
          {
            channelSid: currentHospitalData["sid"],
            // numberOfMessages: -1,
          },
          true
        )
      );
    } else {
      dispatch(
        documentsPopupActions.getDocumentsList({
          appointmentId: currentPatientInfo["_id"],
          leadId:currentPatientInfo["leadId"]
          // numberOfMessages: -1,
        })
      );
    }
  };

  useEffect(() => {
    fetchTheDocs();

    ///clear the map and state
    return () => {
      docsWithCategory.clear();
      setSearchFilterdDocsCategory(undefined);
      dispatch(
        documentsPopupActions.updateFullState(getInitialState("documentsPopup"))
      );
    };
  }, []);

  const documentActions = ({ inHeader }) => {
    return (
      <Box className="search-input-container"
        sx={{ display: inHeader ? ['none!important', 'none!important', 'flex!important'] : ['flex', 'flex', 'none'] }}>
        <IconButton aria-label="search" onClick={handleSearchInputSubmit}>
          <img style={{ height: "25px" }} src={searchIcon} alt="search" />
        </IconButton>
        <input
          value={searchInput}

          onChange={handleSearchInputChange}
          name="search"
          id="search"
          placeholder={data["searchPlaceHolderText"]}
        />

      </Box>
    )
  }
  return (
    <Grid
      container
      className="documents-body-container"
      sx={{
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderRadius: "8px",
        position: 'relative',
        marginTop:['37px','37px','unset']
      }}
    >
      <Grid item xs={12} sx={{ position: "sticky", top: 0 }}>
        <Box className="modal-header"
          sx={{
            display: ['flex!important', 'flex!important', 'grid'],
            justifyContent: ["center!important", "center!important", "space-between!important"],
            alignItems: "center",
            color: "white",
            zIndex: 9,
            borderRadius: ['8px', '8px', '0']
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: ['18px'], fontWeight: ['600', '700'] }}>
            {data["headerTitle"]}
          </Box>


          {documentActions({ inHeader: true })}
          <div className="close-modal">
            <IconButton sx={{ p: 0 }} onClick={handlePopUpClose}>
              <img src={closeOrangeIcon} alt="close" />
            </IconButton>
          </div>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100% - 60px)",
          padding: "10px 5px",
          // backgroundColor: '#fff'
        }}
      >

        {documentActions({ inHeader: false })}

        {
          <RenderList
            data={{
              searchFilterdDocsCategory,
              handleDocumentClickMain,
              documentsListActive,
              allDocuments,
            }}
          />
        }
      </Grid>
    </Grid>
  );
};

export default DocumentsBody;

const RenderDocs = ({ doc, handleDocumentClickMain }) => {
  const {
    MediaFileName = "file.xyz",
    DocTypeOfPatient,
    MessageBody,
    MediaType,
  } = doc;
  // console.log('this is doc',typeof doc === 'object'&& JSON.parse(doc))
  console.log('this is doc',doc)
  const shortenTheName = (name = "") => name.length < 12 ? name : `${name.slice(0, 12)}...${name.slice(-4)}`;

  // const [extension = "", ...rest] = MediaFileName.split(".").reverse();
  // const name = rest.reverse().join();

  // const file = Boolean(rest.length)
  //   ? `${shortenTheName(name)}.${extension}`
  //   : extension;

  // const file = shortenTheName(DocTypeOfPatient) || shortenTheName(MessageBody);
  const file = DocTypeOfPatient || MessageBody;

  return (
    <DocumentCardThumbnail  fileName={file} handleClick={handleDocumentClickMain} data={doc}>
    {getMediaIcon({ doc })}
    </DocumentCardThumbnail>
    // <Box sx={{ background: "#f5f5f5", width: ['110px','110px'], padding: ["10px 5px 5px 5px", "10px 5px 5px 5px"],borderRadius:"5px" }}>
    //   <Box sx={{ background: "white",width:['100px','100px'],height:['50px','50px'],display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"5px" }}>
    //     {getMediaIcon({ doc })}
    //   </Box>
    //   <Box sx={{fontSize:"10px",textOverflow:"ellipsis",whiteSpace:"nowrap",width:"100px",padding:"5px 5px 0px 5px",overflow:"hidden",fontWeight:"600",color:"#7A7A7A"}}>
    //     {file}
    //   </Box>
    // </Box>
    // <Grid
    //   item
    //   // justifySelf="center"
    //   key={uniqid()}
    //   xs={6}
    //   sm={4}
    //   md={2}
    //   sx={{ height: "100%" }}
    // >
    //   <Card
    //     sx={{
    //       maxWidth: 300,
    //       height: "100%",
    //     }}
    //     elevation={0}
    //     onClick={() => {
    //       handleDocumentClickMain(doc);
    //     }}
    //   >
    //     <CardActionArea
    //       sx={{ height: "100%", background: "#F5F5F5", borderRadius: "15px" }}
    //     >
    //       <CardMedia>{getMediaIcon({ doc })}</CardMedia>
    //       <CardContent sx={{ m: 0, p: "0 !important" }}></CardContent>
    //     </CardActionArea>
    //     <Typography
    //       component="p"
    //       color="textSecondary"
    //       align="left"
    //       sx={{
    //         p: ".5em",
    //         whiteSpace:['pre','pre','normal'],
    //         wordBreak: "break-word",
    //         fontSize: ["10px","10px","15px"],
    //         fontWeight: ['600','600',"medium"],
    //       }}
    //     >
    //       {file}
    //     </Typography>
    //   </Card>
    // </Grid>
  );
};

const NoDocsToShow = () => (
  <Box
    disableGutters
    sx={{
      display: "grid",
      height: "44vh",
      placeContent: "center",
    }}
  >
    <Typography align="center" variant="h4" sx={{ fontSize: ['18px', '1.5rem', '2.125rem'], color: ['#666A70', '#666A70', '#1976d2'], fontWeight: ['600', '600', '700'] }}>
      No Documents to show !
    </Typography>
  </Box>
);

const getMediaIcon = ({ doc }) => {
  const { MediaType = "" } = doc;

  const match = [
    { name: "image", file: "imageIconOnly.svg" },
    { name: "pdf", file: "pdfIconOnly.svg" },
    { name: "csv", file: "csvIconOnly.svg" },
    { name: "xls", file: "xlsIconOnly.svg" },
    { name: "sheet", file: "docIcon-xls.svg" },
    { name: "", file: "imageIconOnly.svg" },
  ].reduce((acc, val) => {
    if (acc) return acc;

    if (MediaType?.includes(val["name"]))
      return (
        <Box
          sx={{
            // textAlign: "center",
            // fontSize: "clamp(3rem, 5vw, 4rem)",
            // m: ".5em auto",
            // display: "",
            // placeContent: "center",
          }}
        >
          <img
            
            src={`./${val["file"]}`}
            alt=""
            style={{
              // objectFit: "contain",
              height:"25px",
              width:"25px"
            }}
          />
        </Box>
      );

    return undefined;
  }, undefined);

  if (match) return match;

  const Icon = handleFileType["image/jpeg"]?.["image"];

  return (
    <>
      <Icon
        sx={{
          textAlign: "center",
          fontSize: "clamp(3rem, 5vw, 4rem)",
          m: ".15em auto",
          display: "block",
        }}
      />
    </>
  );
};

const RenderList = ({ data }) => {
  const {
    searchFilterdDocsCategory,
    handleDocumentClickMain,
    documentsListActive,
    allDocuments = [],
  } = data;

  if (!!!documentsListActive) return <></>;

  if (
    (documentsListActive &&
      !!![...searchFilterdDocsCategory?.values()]?.flat(Infinity)?.length) ||
    (documentsListActive && !!!allDocuments.length)
  )
    return <NoDocsToShow />;

  return (
    <Box disableGutters sx={{ p: "10px", overflow: 'auto', maxHeight: '375px', minHeight: "375px" }} >
      {[...searchFilterdDocsCategory?.keys()]?.map((type) =>
        [...searchFilterdDocsCategory?.get(type)].length ? (
          <>
            {/* <Divider /> */}
            <Typography
              variant="h5"
              sx={{
                color: "#7A7A7A",
                my: "1rem",

                fontWeight: ["600", "600", "medium"],
                fontSize: ["14px", "14px", "clamp(1.2rem, 5vw, 1.8rem)"],

                mt: "20px",
                ":first-of-type": {
                  mt: 0,
                },
              }}
            >
              {type}
            </Typography>
            <Grid
              container
              spacing={2}
              sx={{
                // p: "clamp(1rem, 5vw, 2rem)",
                flexWrap: ["nowrap", 'nowrap', 'nowrap'],
                maxHeight: '330px',
                overflowY: "auto",
                gap:["10px"],
                paddingTop:"10px",
                paddingLeft:"14px",
                paddingBottom:"10px"
              }}
            >
              {[...searchFilterdDocsCategory?.get(type)].map((doc) => (
                <>{RenderDocs({ doc, handleDocumentClickMain })}</>
              ))}
            </Grid>
          </>
        ) : (
          <></>
        )
      )}
    </Box>
  );
};
