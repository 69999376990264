import { Divider, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from 'react-redux';
import { patientActions } from '../../../../_actions';
import ButtonHWA from '../../../../_components/ButtonHWA/ButtonHWA';
import { formatDate } from '../../../ChecklistSummary/SearchAndFilter/FIlterComponent/formatDate';
import './index.css';

function PatientListFilter({ children, handleClose }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"));
  const selectedMop = useSelector((state) => state.patients.selectedMop);
  const { otDateRange,mopArr } = useSelector((state) => state.patients);

  const [mopList, setMopList] = useState(selectedMop||[])
  const dispatch = useDispatch()
  const FilterTitle = ({ title, textProps, onChange, checked }) => {
    return <Typography sx={{ fontSize: ['16px', '16px', '16px', '12px', '16px'], color: "#3D7CC9", fontWeight: "600", ...textProps }}>{title}</Typography>
  }
  const MopListItem = ({ title }) => {
    return <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box><Typography sx={{ fontSize: ["14px", '14px', "14px", '12px', '16px'], color: "#64696F", fontWeight: "600" }}>{title}</Typography></Box>
      <Box><Checkbox
        checked={mopList?.includes(title)}
        onChange={(e) => handleMopFilter(e, title)}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{ '& .MuiSvgIcon-root': { fontSize: "20px" }, padding: "0px", color: '#3276CF' }}

      /></Box>
    </Box>
  }
  const handleMopFilter = (e, title) => {
    var newMopList = [...mopList]
    if (e.target.checked) {
      if (!mopList.includes(title)) {
        newMopList.push(title)
      }
    } else {
      var pos = mopList.indexOf(title)
      newMopList.splice(pos, 1);
    }
    setMopList(newMopList)
  }
  const FilterDivider = ({ dividerStyles }) => {
    return <Divider variant="middle" sx={{ width: "95%", border: "1px solid #f5f5f5", marginLeft: "10px", marginTop: '20px', ...dividerStyles }} />

  }
  const [dateRange, setDateRange] = useState([{
    startDate: otDateRange?.startDate || null,
    endDate: otDateRange?.endDate || new Date(''),
    key: 'selection'
  }])
  const DateData = ({ title,date }) => {
    return <Grid container sx={{ flexDirection: "column", gap: ['10px', '10px','10px', '5px', '15px'] }}>
      <Grid item><FilterTitle title={title} /></Grid>
      <Grid item><TextField sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#3D7CC9', }, '& input': { padding: ['7px 5px 6px 8px', '7px 5px 6px 8px','7px 5px 6px 8px', '7px 5px 6px 12px','10px 5px 10px 6px'] } }, }} value={date!='Invalid Date'?formatDate(date):"dd-mmmm-yyyy"} InputProps={{ readOnly: true, sx: { fontSize: ['12px', '12px', '12px', '10px', '14px'], color: "#3276CF" } }} /></Grid>
    </Grid>
  }
  const handleFilterClick = () => {
    console.log(dateRange)
    dispatch(patientActions.updateSelectedMop({ dateRange: { startDate: dateRange[0].startDate, endDate: dateRange[0].endDate }, selectedMop: mopList }));
    handleClose()
  }
  return (
    <Grid container sx={{ borderRadius: '8px', maxWidth: ['300px', '300px', '22vw', '22vw', '370px'], p: ['15px 13px 20px 13px', '15px 13px 20px 13px', '15px 13px 20px 13px', '10px', '20px 20px 25px 20px'], background: 'white', lineHeight: "1.4" }}>
      <FilterTitle title={isMobile ? 'Mode of Payment' : 'Select Mode of Payment'} />
      {isMobile && <FilterDivider dividerStyles={{ marginTop: '8px' }} />}
      <Grid container sx={{ flexDirection: "column", gap: ['10px', '10px', '10px', '5px','10px'], paddingTop: ['15px', '20px', '20px','5px','15px'],overflowY:'auto',maxHeight:['82px','82px','82px','82px','109px'],flexWrap:'nowrap' }}>
        
        {mopArr?.map(item=>{
          return <Grid item><MopListItem title={item.name} /></Grid>
        })}
      </Grid>
      <FilterDivider dividerStyles={{ marginTop: ['20px','20px','20px','10px','20px'] }} />
      <Grid container sx={{ paddingTop: ['15px','20px','20px','7px','20px'] }}>
        <Grid container sx={{ flexDirection: "row", flexWrap: 'nowrap', gap: ['30px', '30px', '30px', '10px','10px'] }}>
          <Grid item ><DateData title={"OT Start Date"} date={dateRange[0]?.startDate} /></Grid>
          <Grid item ><DateData title={"OT End Date"} date={dateRange[0]?.endDate}/></Grid>
        </Grid>
        <Grid className='dateFilterWrapper' item xs={12} sx={{ paddingTop: ['10px', '10px', '5px','5px','25px'] }}>
          <DateRangePicker
            ranges={dateRange}
            showDateDisplay={false}
            onChange={item => setDateRange([item.selection])}
            dateDisplayFormat={`dd-mmmm-yyy`}
            showMonthArrow={false}
            className="patientFilterDatePicker"
          />
        </Grid>
      </Grid>
      <FilterDivider dividerStyles={{ marginTop: ['20px', '20px','20px','10px','25px'] }} />
      <Grid container sx={{ gap: ['20px', '20px', '13px'], paddingTop: ['20px', '20px','20px', '10px', '30px'] }}>
        <Grid item sx={{ flex: 1 }}><ButtonHWA  onClick={()=>handleFilterClick()}  variant="contained">Apply</ButtonHWA></Grid>
        <Grid  item sx={{ flex: 1 }}><ButtonHWA onClick={() => {
          dispatch(patientActions.updateSelectedMop({ dateRange: { startDate: null, endDate: new Date('') }, selectedMop: [] }));
          ; handleClose()
        }}  >Clear</ButtonHWA></Grid>
      </Grid>
    </Grid>
  )
}

export default PatientListFilter