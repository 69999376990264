import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./style.css";

const LoadingSpinner = () => {
  const showSpinningLoader = useSelector(
    (state) => state.config.showSpinningLoader
  );

  const loadingSpinnerText = useSelector(
    (state) => state.homeState.loadingSpinnerText
  );

  const [showMessage, setShowMessage] = useState(false);

  ///show the message after a while

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowMessage(true);
    }, 5000);

    return () => {
      clearTimeout(timerId);
      setShowMessage(false);
    };
  }, [showSpinningLoader]);

  return (
    <div
      className={`spinning-loader-container ${showSpinningLoader && "show-element"
        }`}
    >
      <div className="spinning-loader"></div>
      <h3
        className={`spinning-loader-message ${showSpinningLoader && showMessage ? "show-element" : ""
          }`}
      >
        {loadingSpinnerText}
      </h3>
    </div>
  );
};

export default LoadingSpinner;
