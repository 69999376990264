import { ArrowBack } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Grid, InputBase } from "@mui/material";
import Box from '@mui/material/Box';
import Popover from "@mui/material/Popover";
import { addDays, subDays } from "date-fns";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import searchIcon from '../../../../../assets/search-icon.png';
import { checklistActions } from "../../../../../_actions/checklist.actions";
import ButtonHWA from "../../../../../_components/ButtonHWA/ButtonHWA";
import { getInitialState } from "../../../../../_helpers/getInitialState";
import { tableData } from "./../../tableData";
import "./index.css";

function Filters({ screenName, getFilteredData, filterStatus }) {
  //states for city filter
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [filterOptions, setFilterOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const activeTab = useSelector((state) => state.checklistSummary.activeTab);
  // const [searchText, setSearchText] = useState("");
  const [filterTemplate, setfilterTemplate] = useState({
    cityFilter: { name: "cityFilter", heading: "City Filter", options: [] },
    mopFilter: { name: "mopFilter", heading: "MOP Filter", options: [] },
    pdsFilter: {
      name: "pdsFilter",
      heading: "Provisional Discharge Summary Filter",
      options: [
        { name: "Done", id: "Done" },
        { name: "Pending", id: "Pending" },
      ],
    },
    bbsFilter: {
      name: "bbsFilter",
      heading: "Bill Breakup Summary Filter",
      options: [
        { name: "Done", id: "Done" },
        { name: "Pending", id: "Pending" },
      ],
    },
    dateRangeHospital: {
      name: "dateRangeHospital",
      heading: "DateRange",
      options: "",
    },
    dateRangeHospitalView: {
      name: "dateRangeHospitalView",
      heading: "DateRange",
      options: "",
    },
    dateRangePatient: {
      name: "dateRangePatient",
      heading: "DateRange",
      options: "",
    },
    searchTextHospital: "",
    searchTextHospitalView:"",
    searchTextPatient: "",
  });
  const hospitalFilter = useSelector(
    (state) => state.checklistSummary.hospitalFilter
  );
  const patientFilter = useSelector(
    (state) => state.checklistSummary.patientFilter
  );

  const hospitalViewFilter = useSelector(
    (state) => state.checklistSummary.hospitalViewFilter
  );

  const [filterReqObj, setFilterRequestObj] = useState({
    hospitalScreen: {
      ...hospitalFilter,
      dateRangeHospital: [
        {
          startDate: subDays(new Date(), 7),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ],
    },
    patientScreen: {
      ...patientFilter,
      dateRangePatient: [
        {
          startDate: subDays(new Date(), 7),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ],
      filterStatus: filterStatus
    },
    hospitalViewScreen :{
      ...hospitalViewFilter,
      dateRangeHospitalView :[
        {
          startDate: activeTab==='pending' ?  addDays(new Date(), 7): subDays(new Date(), 7),
          endDate: addDays(new Date(), 0),
          key: "selection",
        }
      ]
    }
    // cityFilter: [],
    // mopFilter: [],
    // pdsFilter: [],
    // bbsFilter: [],
    // dateRange: [
    //   {
    //     startDate: subDays(new Date(), 7),
    //     endDate: addDays(new Date(), 1),
    //     key: "selection",
    //   },
    // ],
    // searchText: "",
  });

  const [selectedFilter, setSelectedFilter] = useState();

  // getting data for filters
  const cityArr = useSelector((state) => state.checklistSummary.cityArr);
  const mopArr = useSelector((state) => state.checklistSummary.mopArr);

  //  filter actions
  const selectItem = (item) => {
    setSelectedOptions(item);
  };
  const removeItem = (item) => {
    setSelectedOptions(item);
  };

  const handleClick = (event, filterName) => {
    if (filterName === "dateRange") {
      filterName =
        screenName === "hospitalScreen"
          ? "dateRangeHospital"
          : screenName==="hospitalViewScreen" ?
            "dateRangeHospitalView"
          : "dateRangePatient";
    }
    if (filterName === "searchText") {
      filterName =
        screenName === "hospitalScreen"
          ? "searchTextHospital"
          : screenName==="hospitalViewScreen" ?
            "searchTextHospitalView"
          : "searchTextPatient";
    }
    setSelectedFilter(filterTemplate[filterName]);
    setSelectedOptions(filterReqObj[screenName][filterName]);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    updateFilterReqObj(selectedFilter.name);
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const [updatedFilters, setUpdatedFilters] = useState(tableData[screenName]);
  // useEffects

  useEffect(() => {
    setfilterTemplate((current) => ({
      ...current,
      cityFilter: { ...current.cityFilter, options: cityArr },
      mopFilter: { ...current.mopFilter, options: mopArr },
    }));
  }, [cityArr, mopArr]);

  useEffect(() => {
    setUpdatedFilters((curr) => ({
      ...curr,
      filters: tableData[screenName].filters.map((item) => {
        if (filterStatus) {
          if (item.key === "pdsFilter" || item.key === "bbsFilter") {
            item.show = false;
          } else {
            item.show = true;
          }
        } else {
          item.show = true;
        }
        return item;
      }),
    }));


    setFilterRequestObj((current) => ({
      ...current,
      patientScreen: {
        ...patientFilter,
        dateRangePatient: [
          {
            startDate: new Date(patientFilter.startDate),
            endDate: new Date(patientFilter.endDate),
            key: "selection",
          },
        ],
        mopFilter: patientFilter.mop.map(item => { return { name: item, id: item } }),
        pdsFilter: patientFilter.pds.map(item => { return { name: item, id: item } }),
        bbsFilter: patientFilter.bbs.map(item => { return { name: item, id: item } }),
        filterStatus: filterStatus,
        searchTextPatient: patientFilter.search

      },
      hospitalScreen: {
        ...hospitalFilter,
        dateRangeHospital: [
          {
            startDate: new Date(hospitalFilter.startDate),
            endDate: new Date(hospitalFilter.endDate),
            key: "selection",
          },
        ],
        cityFilter: hospitalFilter.city.map(item => { return { name: item, id: item } }),
        searchTextHospital: hospitalFilter.search

      },
      hospitalViewScreen: {
        ...hospitalViewFilter,
        dateRangeHospitalView: [
          {
            startDate: new Date(hospitalViewFilter.startDate),
            endDate: new Date(hospitalViewFilter.endDate),
            key: "selection",
          },
        ],
        mopFilter: hospitalViewFilter.mop.map(item => { return { name: item, id: item } }),
        searchTextHospitalView: hospitalViewFilter.search

      },
    }));
  }, [filterStatus, patientFilter, hospitalFilter, screenName,hospitalViewFilter]);

  //setting filter conditions
  const updateFilterReqObj = (filterName) => {
    setFilterRequestObj((current) => ({
      ...current,
      [screenName]: {
        ...filterReqObj[screenName],
        [filterName]: selectedOptions,
      },
    }));
  };

  const handleSelect = (ranges) => {
    const { selection } = ranges;
    setSelectedOptions([selection]);
  };

  const clearFilter = () => {
    if (screenName === "hospitalScreen") {
      getFilteredData({
        ...filterReqObj,
        [screenName]: {
          ...getInitialState("checklistSummary").hospitalFilter,
          dateRangeHospital: [
            {
              startDate: subDays(new Date(), 7),
              endDate: addDays(new Date(), 0),
              key: "selection",
            },
          ],
        },
      });
    } 
    else if(screenName === "hospitalViewScreen"){
      getFilteredData({
        ...filterReqObj,
        [screenName]: {
          ...getInitialState("checklistSummary").hospitalViewFilter,
          dateRangeHospitalView: [
            {
              startDate: activeTab==='pending' ?  addDays(new Date(), 7): subDays(new Date(), 7),
              endDate: addDays(new Date(), 0),
              key: "selection",
            },
          ],
        },
      });
    }
    else {
      getFilteredData({
        ...filterReqObj,
        [screenName]: {
          ...getInitialState("checklistSummary").patientFilter,
          dateRangePatient: [
            {
              startDate: subDays(new Date(), 7),
              endDate: addDays(new Date(), 0),
              key: "selection",
            },
          ],
          filterStatus: filterStatus
        },
      });
    }
  };

  const navigate = useNavigate()

  const changeScreen = () => {
    dispatch(checklistActions.changeChecklistScreen("hospitalScreen"));
    dispatch(
      checklistActions.setChecklistFilters({
        filterObj: getInitialState('checklistSummary').patientFilter,
        screenName: "patientScreen",
      })
    );

    navigate('/checklistSummary')
  };
  const selectAllOptions = () => {
    setSelectedOptions(selectedFilter.options);
  };
  const clearAllOptions = () => {
    setSelectedOptions([]);
  };
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault()
    }
    getFilteredData(filterReqObj)
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box sx={{ padding: "20px 10px 0px 20px" }} className="filterSearch">
        <div>
          {screenName === "patientScreen" && (
            <Button
              variant="contained"
              className="checklistFilter"
              sx={{ justifyContent: "center!important", textTransform: "none!important", background: "#3D7CC9!important" }}
              onClick={() => changeScreen()}

            // onClick={(e) => getFilteredData(filterReqObj)}
            >
              <ArrowBack sx={{
                position: "absolute",
                left: "10px",
                marginTop: "2px",
              }} />
              Hospital List
            </Button>
          )}
        </div>
        <Box component='form' onSubmit={handleSubmit} className="inputBase">
          <InputBase
            sx={{
              ml: 2,
              flex: 1,
              background: "white",
              padding: "0.5rem",
              minWidth: "10rem",
              marginBottom: "10px!important",
              boxShadow: "5px 5px 15px #0000000D",
              borderRadius: "5px",
              color: "#3D7CC9",
              paddingLeft: '5px',
              height: "50px",
              width: "18.2vw",

            }}
            value={
              screenName === "hospitalScreen"
                ? filterReqObj[screenName]["searchTextHospital"]
                : screenName==="hospitalViewScreen" ?
                  filterReqObj[screenName]["searchTextHospitalView"]
                : filterReqObj[screenName]["searchTextPatient"]
            }
            placeholder={screenName==="hospitalViewScreen"  ? "Search with patient name" : "Search with hospital name"}
            onChange={(e) =>
              setFilterRequestObj((current) => ({
                ...current,
                [screenName]: {
                  ...filterReqObj[screenName],
                  searchTextHospital: e.target.value,
                  searchTextPatient: e.target.value,
                  searchTextHospitalView:e.target.value
                },
              }))
            }
            inputProps={{ "aria-label": "search", color: "green" }}
            endAdornment={
              <img alt="" onClick={(e) => getFilteredData(filterReqObj)} style={{ height: "25px", marginRight: "5px", cursor: "pointer" }} src={searchIcon}></img>

              // <SearchIcon
              //   onClick={(e) => getFilteredData(filterReqObj)}
              //   sx={{ color: "blue", cursor: "pointer" }}
              // />
            }
          />
        </Box>
      </Box>
      <Box className="filtersContainer" sx={{ gap: "1%" }}>
        {updatedFilters.filters.map((item) => {
          return (
            item.show && (
              <div className="checklistFilter">
                <Button
                  aria-describedby={id}
                  className="filter"
                  sx={{
                    boxShadow: "5px 5px 15px #0000000D",
                    borderRadius: "5px",
                    background: "white",
                  }}
                  onClick={(e) => handleClick(e, item.key)}
                  startIcon={item.key === "dateRange" && <CalendarMonthIcon />}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{item.title}</Box>
                    <div>
                      {
                        <KeyboardArrowDownIcon
                          style={{
                            visibility: item.key !== "dateRange" ? "" : "hidden",
                          }}
                        />
                      }
                    </div>
                  </div>
                </Button>
              </div>
            )
          );
        })}

        <div>
          <Button
            className="filterActionButton"
            sx={{ background: "white", paddingLeft: "10px" }}
            onClick={(e) => clearFilter()}
          >
            Clear Filters
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            className="filterActionButton"
            onClick={(e) => getFilteredData(filterReqObj)}
            sx={{ background: "#3D7CC9!important" }}
          >
            Apply Filters
          </Button>
        </div>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleClose()}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ boxShadow: "5px 5px 15px #00000029" }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: " 5px 12px 15px #00000029",
              width: "18.2vw",
              // width: "fit-content"

            }
          }}
        >
          {selectedFilter && (
            <div className="filterPopUp">

              <Box
                sx={{ p: '10px 5px 5px 5px' }}
                className="filterBody"
                style={{
                  height: selectedFilter.name.includes("dateRange")
                    ? "fit-content"
                    : "13rem",
                    marginTop: selectedFilter.name.includes("dateRange")?'-15px':'unset'
                }}
              >
                {selectedFilter.name.includes("dateRange") ? (
                  <>
                      <DateRangePicker
                      ranges={selectedOptions || {}}
                      onChange={handleSelect}
                      showDateDisplay={false}
                      // dateDisplayFormat={`dd-mmmm-yyy`}
                      showMonthArrow={false}
                      />
                  </>
                ) : (
                  <>
                    <Multiselect
                      options={selectedFilter.options} // Options to display in the dropdown
                      selectedValues={selectedOptions} // Preselected value to persist in dropdown
                      onSelect={selectItem} // Function will trigger on select event
                      onRemove={removeItem} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      showCheckbox={true}
                      avoidHighlightFirstOption={true}
                      style={{ searchBox: { "border-bottom": "none", "border": "none" }, chips: { background: "#3D7CC9" }, }}
                    />
                  </>
                )}
              </Box>
              <div className="" style={{background:"white"}}>
                {selectedFilter.name.includes("cityFilter") && (
                  <>
                    <Grid container sx={{ gap: ['20px', '20px', '13px'], padding: ['10px'] }}>
                      <Grid item sx={{ flex: 1 }}>
                        <ButtonHWA
                          onClick={() => selectAllOptions()}
                          variant="contained"
                        >
                          Select All
                        </ButtonHWA>
                      </Grid>

                      <Grid item sx={{ flex: 1 }}>
                        <ButtonHWA
                          onClick={() => clearAllOptions()}
                        >
                          Clear Filter
                        </ButtonHWA>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </div>
          )}
        </Popover>
      </Box>
    </>
  );
}

export default Filters;
