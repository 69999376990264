import React, { Fragment, useEffect, useState } from "react";
import "./centralSummaryModal.css";
import { CentralModal } from "../CentralModal/CentralModal";
import { centralPDSDocDataService } from "../../../_services";
import { useSelector } from "react-redux";
import { CircularProgress, Divider } from "@mui/material";
import moment from "moment";

export const CentralSummaryModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdsDocData, setPdsDocData] = useState({})
  const [isEmptyModal, setIsEmptyModal] = useState(false)
  let patientList = useSelector(state => state.centralPatients)

  const handleTimeStamp = (value) => {
    try {
      if (!moment(value).isValid()) {
        return 'NA'
      }
      return moment(value).format("DD MMM, hh:mm A");
    } catch (error) {
      return "NA";
    }
  };

  const getPDSDocData = () =>{
    let activePatientId = ''
    if(patientList?.activePatientId && !window.location.search.includes("accessToken")){
      activePatientId = patientList.activePatientId
    }
    else{
        const parts = window.location.pathname.split('/');
        const id = parts[3]; 
        activePatientId = id
      }
      const params = {
        "appId":activePatientId
      }

      if(activePatientId){
          setIsEmptyModal(true)
          centralPDSDocDataService(params)
          .then(res=>{

              if(res.status===200){
                    const approvalStatus = res?.approvalStatus || {}

                    setPdsDocData({
                        getBasicDetails:[
                            {value:approvalStatus?.OveralldischargeSummaryStatus || 'NA', label:'Overall Status'},
                            {value:approvalStatus?.PaymentMode || 'NA', label:'Payment Mode'},
                            {value:approvalStatus?.DocAssigned?.DocAssigned || 'NA', label:'Doc Assigned'},
                            {value:approvalStatus?.dischargeSummaryUploadDate ?handleTimeStamp(approvalStatus?.dischargeSummaryUploadDate): 'NA', label:'Last Updated At'},
                            {value:approvalStatus?.DocdischargeSummaryStatus || 'NA', label:'Doc Status'},
                            {value:approvalStatus?.DocdischargeSummaryMarkedBy  || 'NA', label:'Marked By'},
                            {value:approvalStatus?.DocdischargeSummaryMarkedAt ? handleTimeStamp(approvalStatus?.DocdischargeSummaryMarkedAt) :  'NA', label:'Marked At'},
                            {value:approvalStatus?.DocdischargeSummaryDeclineReason || 'NA', label:'Decline Reason'},
                            {value:approvalStatus?.InsDocdischargeSummaryStatus || 'NA', label:'InsDoc Status'},
                            {value:approvalStatus?.InsDocdischargeSummaryMarkedBy  || 'NA', label:'Marked By'},
                            {value:approvalStatus?.InsDocdischargeSummaryMarkedAt ? handleTimeStamp(approvalStatus?.DocdischargeSummaryMarkedAt) :  'NA', label:'Marked At'},
                            {value:approvalStatus?.InsDocdischargeSummaryDeclineReason || 'NA', label:'Decline Reason'},
                        ],
                        insUploadedDischargeSummary: approvalStatus?.insUploadedDischargeSummary || []
                    })
                }
          })
          .catch(error=>console.log('PDS Doc fetching error', error))
          .finally(()=>{
            setIsEmptyModal(false)
          })
      }
  }
  useEffect(() => {
    getPDSDocData()
  }, [])
  
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const renderModalData = () =>{
        return(
            <>
                {pdsDocData?.getBasicDetails?.length>0 && pdsDocData?.getBasicDetails.map((item, index)=>
                <Fragment key={`key-${index}`}>
                <div className="textReadOnlyRow">
                  <span className="textReadOnlyLabel summaryModalLabel">{item.label}</span>
                  <span className="textReadOnlyValue">{item.value}</span>
                </div>
                {index===3 || index===7 ? <Divider sx={{mb:'2px'}}/>:''}
                </Fragment>
                )}
               {pdsDocData?.insUploadedDischargeSummary?.length>0 ?<div className="attachmentDetailsContainer">
                <span className="attachmentDetailsText">Attachment Details</span>
                {pdsDocData?.insUploadedDischargeSummary?.map((item, index)=>
                <Fragment key={`key-${index}`}>
                <Divider sx={{mb:'2px'}}/>
                <div className="textReadOnlyRow">
                    <span className="textReadOnlyLabel summaryModalLabel">Attached At</span>
                    <span className="textReadOnlyValue">{item?.dateTimeStamp ? handleTimeStamp(item.dateTimeStamp) :'NA' }</span>
                </div>
                <div className="textReadOnlyRow">
                    <span className="textReadOnlyLabel summaryModalLabel">Uploaded By</span>
                    <span className="textReadOnlyValue">{item?.userName || 'NA'}</span>
                </div>
                <div className="textReadOnlyRow">
                    <span className="textReadOnlyLabel summaryModalLabel">Decline At</span>
                    <span className="textReadOnlyValue">{item?.declineTimeStamp ? handleTimeStamp(item?.declineTimeStamp) :'NA'}</span>
                </div>
                <div className="textReadOnlyRow">
                    <span className="textReadOnlyLabel summaryModalLabel">Decline Reason</span>
                    <span className="textReadOnlyValue">{item?.declineReason || 'NA'}</span>
                </div>
                </Fragment>
                )}
               </div>:""}
            </>
        )
  }

  return (
    <div className="textReadOnlyRow">
      <span className="textReadOnlyLabel">PDS Document</span>
      <CentralModal
        open={isModalOpen}
        onClose={handleModalClose}
        modalTitle={"Post Discharge Summary Documents"}
        sx={{ minHeight: "155px" }}
        modalButton={
          <div className="defaultModalButton" onClick={handleModalOpen}>
            View
          </div>
        }
      >
        {isEmptyModal?<div className="summaryModalLoader">
            <CircularProgress fontSize="small" />
          </div>
          : 
          pdsDocData.length===0 ? <div className="noDataFoundText">No Data Found</div>:
           renderModalData()}
      </CentralModal>
    </div>
  );
};
