import { Typography } from "@mui/material"
import ButtonHWA from "../../_components/ButtonHWA/ButtonHWA"

export const OwnerHeading = ({ children, sx, ...rest }) => {
  return <Typography data-component={`OwnerHeading`} component="h2" sx={{ fontSize: ['18px', '18px', '20px'], width: "100%", fontWeight: 'bold', textAlign: ['center'], ...sx }} {...rest}>{children}</Typography>
}
export const ProfileButton = ({ sx, ...rest }) => {
  return <ButtonHWA sx={{
    width: 'unset', fontSize: ['12px', '16px'], padding: ['10px'],
    lineHeight: [1.43, 1.43, 1.4, 1.4, 1.4], fontWeight: ['600', '600', '600', '600', '700'], borderRadius: "6px", ...sx
  }} {...rest}></ButtonHWA>
}