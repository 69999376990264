import { userConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

let user = JSON.parse(localStorage.getItem("user"))
console.log('this is user from storage',user)
const initialState = user
  ? getInitialState("authentication")
  : {
    loggedIn: false,
    loggingIn: true,
    user: {
      roles: {
        role: "",
      },
    },
    showRegisterUser: false,
  };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        showRegisterUser: false,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: Boolean(action.user),
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        user: {},
        error: action.error,
        loggingIn: false,
        loggedIn: false,
      };
    case userConstants.REFRESH_TOKEN_VERIFY_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: Boolean(action.user),
        user: action.user,
      };
    case userConstants.REFRESH_TOKEN_VERIFY_FAILURE:
      return {
        ...state,
        user: {},
        // error: action.error,
        loggingIn: false,
        loggedIn: false,
      };
    case userConstants.LOGOUT:
      localStorage.clear()
      return {
        ...state,
        loggedIn: false,
        isOtpScreen: false,
      };
    case userConstants.OTP_REQUEST:
      return {
        ...state,
        isOtpLoader: true,
        user: action.user,
      };
    case userConstants.OTP_SUCCESS:
      return {
        ...state,
        isOtpScreen: true,
        topText: "Verify Mobile No.",
        isOtpLoader: false,
        otpSuccess: true,
        otpResponse: action.otpResponse,
        showRegisterUser: action.otpResponse?.["status"] === "Not Registered",
      };
    case userConstants.OTP_FAILURE:
      return {
        ...state,
        isOtpLoader: false,
        isOtpScreen: true,
        otpSuccess: false,
        otpResponse: action.otpResponse,
        showRegisterUser: action.otpResponse?.["status"] === "Not Registered",
      };
    case userConstants.VERIFY_OTP_REQUEST:
      return {
        ...state,
        isOtpScreen: true,
        isVerifyLoader: true,
      };
    case userConstants.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isVerifyLoader: false,
        user: action.user,
        isOtpScreen: true,
      };
    case userConstants.VERIFY_OTP_FAILURE:
      return {
        ...state,
        isVerifyLoader: false,
        user: null,
      };

    case userConstants.SET_USER_MOBILE:
      return {
        ...state,
        userMobile: action.userMobile,
      };

    case userConstants.GET_ROLES_LIST_REQUEST:
      return {
        ...state,
        rolesListRequesting: true,
        rolesListActive: false,
      };

    case userConstants.GET_ROLES_LIST_SUCCESS:
      const { rolesListResponse } = action;

      return {
        ...state,
        rolesListResponse,
        rolesListRequesting: false,
        rolesListActive: true,
      };

    case userConstants.GET_ROLES_LIST_FAILED:
      return {
        ...state,
        rolesListRequesting: false,
        rolesListActive: false,
        rolesListFailed: true,
      };

    case userConstants.HANDLE_USER_REGISTER_SUCCESS:
      const { registerUserResponse } = action;

      return {
        ...state,
        showRegisterUser: false,
        isOtpScreen: registerUserResponse["status"] === "Success",
        response: {},
      };

    case userConstants.UPDATE_FULL_AUTH_STATE:
      const { newState } = action;

      return {
        ...newState,
      };
    default:
      return state;
  }
}
