import { ArrowBackIosNewRounded, KeyboardBackspaceRounded } from '@mui/icons-material';
import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { pcScoreActions } from '../../../_actions';
import { OwnerHeading } from '../OwnerCommonComponents';
import OwnersGraphStatus from './OwnersGraphStatus';
import PatientReviews from './PatientReview';

const OwnerTab = () => {
  const [openReviews, setopenreviews] = useState(false)
  const role = useSelector(state => state.authentication.user.roles.role)
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const BackIcon = isSmallScreen ? ArrowBackIosNewRounded : KeyboardBackspaceRounded
  const handleOpenreview = () => {
    setopenreviews((prevState) => !prevState)
  }
  const { hospital_id } = useSelector((state) => state.hospitals.currentHospitalData);

  useEffect(() => {
    if (openReviews === true) { dispatch(pcScoreActions.getHospitalReviewsAction({ hospitalId: hospital_id })) }
  }, [openReviews])

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", backgroundColor: '#3D7CC9', color: "#fff", borderRadius: '0px 0px 10px 10px', p: "17px 20px", mb: { xs: 2, md: 4 } }}>
        {openReviews && <BackIcon size='medium' sx={{ left: ['15px', '15px', "40px"], fontSize: ["34px", "40px"], cursor: "pointer" }} onClick={() => handleOpenreview()} />}
        <OwnerHeading>
          {!openReviews ? `Performance Report` : `Patient Reviews`}
        </OwnerHeading>
      </Box>
      {openReviews ? <PatientReviews /> : <OwnersGraphStatus handleOpenreview={handleOpenreview} />}
    </>
  );
}

export default OwnerTab;
