import { Box } from "@mui/material";
import { ContentState, convertToRaw, EditorState, getDefaultKeyBinding, KeyBindingUtil } from "draft-js";
import createMentionPlugin from "draft-js-mention-plugin";
import "draft-js-mention-plugin/lib/plugin.css";
import Editor from "draft-js-plugins-editor";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { groupInfoPopupActions, patientActions } from "../../../../../_actions";
import { mainCacheConstants } from "../../../../../_helpers";
import { useReduxCacheAndDispatch } from "../../../../../_helpers/useReduxCacheAndDispatch";
import useWindowSize from "../../../../../_helpers/useWindowSize";
import "./index.css";
import mentionsStyles from "./MentionsStyles.module.css";


const MessageInput = forwardRef(({ sendMessageFromMessageInput, onDomUpdate }, ref) => {
  const editorRef = useRef()

  const { hasCommandModifier } = KeyBindingUtil;
  const emptyEditorState = EditorState.createEmpty();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  //    here i have to initialsed with mentions
  const [suggestions, setSuggestions] = useState([]);
  const { isMobile } = useWindowSize()
  const [mentions, setMentions] = useState([]);

  const hospitals = useSelector((state) => state.hospitals);
  const memberSid = useSelector((state) => state.hospitals?.currentHospitalData?.sid);
  const privateChatMessagesActive = useSelector((state)=> state.chatBox.privateChatMessagesActive)
  const dispatch = useDispatch();

  const [userTaggingList, setUserTaggingList] = useState([]);
  const groupInfo = useSelector((state) => state.groupInfoPopup.groupInfo);
  const [currentMemberData, setCurrentMemberData] = useState("");
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const userId = useSelector(state => state.authentication.user.userId)
  useEffect(() => {
    if (Array.isArray(groupInfo) && groupInfo.length > 0) {

      let updatedUserTaggingList = groupInfo?.filter(item => {
        item['name'] = '@' + item.attributes.username
        item['id'] = item.memberSid
        return item.attributes.userId !== userId
      })

      setUserTaggingList(updatedUserTaggingList);
      setMentions(updatedUserTaggingList)
    }
  }, [groupInfo]);

  useEffect(() => {
    let temp = userTaggingList.length === 0;
    if(temp)
    fetchAndUpdateGroupMembers(!temp);
  }, []);

  const fetchAndUpdateGroupMembers = (sendCache) => {
    const {
      currentHospitalData: { sid }={},
    } = hospitals||{};

    const getNewCache = () => {
      if (sendCache) {
        const newMemberList = groupInfo.filter(
          (member) => memberSid !== member["memberSid"]
        );
        return newMemberList;
      }

      return null;
    };

    cacheAndDispatch({
      action: groupInfoPopupActions.getGroupInfoList,
      params: { sid },
      cacheOf: mainCacheConstants.groupInfoListCache,
      key: sid,
      newCache: getNewCache(),
    });
  };
  const onChange = (editorState) => {
    setEditorState(editorState);
    console.log('this is fired')

  };

  const onSearchChange = ({ value }) => {
    setSuggestions(customSuggestionsFilter(value, mentions));
  };

  const onExtractData = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
  };

  const onExtractMentions = () => {
    const contentState = editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    return mentionedUsers
  };

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      theme: mentionsStyles,
      positionSuggestions: (settings) => {
        return {
          left: settings.decoratorRect.left + 'px',
          top: settings.decoratorRect.top - 40 + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
          display: 'block',
          transform: 'scale(1) translateY(-100%)', // transition popover on the value of its height
          transformOrigin: '1em 0% 0px',
          transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)'
        }
      }
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);
  
  const myKeyBindingFn = (e) => {
    if (e.keyCode == 13 && e.altKey == false) {
      extractAndClearEditor();
      return "send message";
    }
    return getDefaultKeyBinding(e);
  };
  useImperativeHandle(ref, () => ({
    extractAndClearEditor,
  }));
  const extractAndClearEditor = () => {
    //to extract text from editor state

    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    var value
    try{
       value = blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    }catch{
      
    }
      if(isMobile){
        var contenteditable = document.querySelector('[contenteditable]')
        value=contenteditable.innerText
      }
    console.log("dragooon entered here", value);
    var mentions = onExtractMentions()

    mentions = mentions.map(item => {
      const { attributes: { username, userId, role, Mobile }, userIds,identity } = item
      let payload = { "name": username, "mobile": identity, "role": role, "userId": userId, "userIds": userIds }
      return payload

    })
    sendMessageFromMessageInput(value, mentions);

    // to empty the editor text
    const newEditorState = EditorState.push(
      editorState,
      ContentState.createFromText("")
    );
    setEditorState(EditorState.moveFocusToEnd(newEditorState));
    // let appointment_id= patientInfo.appointmentId

    // appointment_id && dispatch(patientActions.updatePatientsReadCount({ appointment_id, count: 0 }))
  };

  const customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) => (list.constructor.name === 'List'
      ? list.size
      : list.length);

    const get = (obj, attr) => (obj.get
      ? obj.get(attr)
      : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) => (
      !value || get(suggestion, 'name').toLowerCase().includes(value)
    ));
    return filteredSuggestions
  };

  useEffect(()=>{
    const updateFunction = (e)=>{
      onDomUpdate && onDomUpdate()
    }
    let element = editorRef.current
    element.addEventListener('DOMSubtreeModified', updateFunction)
    
    return ()=>{
      element.removeEventListener('DOMSubtreeModified', updateFunction)
    }
  }, [])
  return (
    <div className="messageInputContainer">
      {/* <button onClick={() => onExtractMentions()}>extract mentions</button> */}
      <div className={`editor ${privateChatMessagesActive && "privateEditor"}`} ref={editorRef}>
        <Editor
          editorState={editorState}
          onChange={onChange}
          plugins={plugins}
          placeholder={<Box sx={{ fontSize: ['14px', '9px', '12px'], display: 'flex', flexDirection: ['column', 'column', 'row'], color: '#666A70' }}>
            <div className={`${privateChatMessagesActive && "privateChatPlaceholder"}`}>
          Type @ to tag                    Press Alt + enter for new line
            </div>
          </Box>}
          keyBindingFn={myKeyBindingFn}
        />
        <MentionSuggestions
          onSearchChange={onSearchChange}
          suggestions={suggestions}
        />
      </div>
    </div>
  );
});

export default MessageInput;
