import { Button, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Modal from "@mui/material/Modal";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { constants } from "../../mixPanel/constants";
import { initialiseMixPanel, Mixpanel } from "../../mixPanel/MixPanel";
import { cacheStorageActions, configActions, homeStateActions, hospitalActions, patientActions } from "../../_actions";
import { cacheStorageConstants } from "../../_constants";
import { mainCacheConstants, useReduxCacheAndDispatch } from "../../_helpers";
import useNetwork from "../../_helpers/useNetwork";
import useWindowSize from "../../_helpers/useWindowSize";
import ChatBox from "../common/ChatBox";
import noIcon from "./../../assets/226589_circle_cross_icon.png";
import yesIcon from "./../../assets/3325077_check_circle_icon.png";
import alarmSong from "./../../assets/ambulance_sound.mp3";
import alarmImage from "./../../assets/Group 5865.png";
import logoImage from "./../../assets/Group 5869.png";
import { HospitalContainer } from "./components/HospitalContainer";
import { PatientList } from "./components/PatientList";
import "./style.css";


function SimpleContainer(props) {
  const dispatch = useDispatch();
  const network = useNetwork();
  const screenName = useSelector((state) => state.homeState.screenName);
  const [firstRender, setFirstRender] = useState(false);
  const isMounted = useRef(false);
  const { isMobile } = useWindowSize();

  const hospitalList = useSelector((state) => state.hospitals.hospitalList) ?? [
    { hospital_id: "", sid: "", friendly_name: "" },
  ];
  const patients = useSelector((state) => state.patients.patients);

  const notificationState = useSelector(
    (state) => state.homeState.notificationState
  );
  const sendAgainList = useSelector(state => state.cacheStorage.sendAgainList)
  const sendAgainListCount = useSelector(state => state.cacheStorage.sendAgainListCount)
  const isDrawer = useSelector((state) => state.hospitals.isDrawer)
  const { online } = network;
  useEffect(() => {
    dispatch({ type: cacheStorageConstants.SET_NETWORK_STATUS, online });
    if (sendAgainList.length > 0)
      dispatch(homeStateActions.updateSendAgainList(online));

    if (online && firstRender && sendAgainList.length === 0) {
      console.log("reload 1")
      window.location.reload();
    }
  }, [network]);
  useEffect(() => {
    if (sendAgainListCount === 0) {
      console.log("reload 2",sendAgainListCount)
      dispatch(cacheStorageActions.updateSendAgainListCount())
      window.location.reload();
    }
  }, [sendAgainListCount])

  const alarmNotifications = useSelector(
    (state) => state.homeState.alarmNotifications
  );
  const showAlarmNotification = useSelector(
    (state) => state.homeState.showAlarmNotification
  );
  const myRef = useRef();

  useEffect(() => {
    if (!alarmNotifications || (alarmNotifications && alarmNotifications.length === 0))
      dispatch(homeStateActions.toggleAlarmNotification(false));
  }, [alarmNotifications]);

  useEffect(() => {
    setFirstRender(true);
  }, []);

  const userInfo = useSelector((state) => state.authentication.user);
  const currentHospitalData = useSelector(
    (state) => state.hospitals?.currentHospitalData
  );
  const patientListActive = useSelector(
    (state) => state.patients.patientListActive
  );
  // const getRefreshedPatientList = (
  //   hospId = null,
  //   channelsid = null,
  //   apptId = null,
  //   sendingValue = false
  // ) => {
  //   if (sendingValue === false) {
  //     hospId = hospital_id;
  //     channelsid = sid;
  //     apptId = appointmentId;
  //   }
  //   if (hospId) {
  //     cacheAndDispatch({
  //       action: patientActions.getPatientList,
  //       params: {
  //         selectedHospitalId: hospId,
  //         channelsid,
  //       },
  //       cacheOf: mainCacheConstants.patientListsCache,
  //       key: channelsid,
  //     });
  //     let params = {
  //       appointmentId: apptId,
  //       // patientId,
  //       // leadId,
  //     };
  //     dispatch(patientActions.getPatientInfoAPIData(params));
  //   }
  // };

  // const getRefreshedChatBox = async (
  //   apptId = null,
  //   channelsid = null,
  //   lstMsgIdx = null,
  //   sendingValue = false
  // ) => {
  //   // to get patient Messages

  //   if (sendingValue === false) {
  //     apptId = appointmentId;
  //     channelsid = sid;
  //   }
  //   if ((showGeneralChatMessages === false || sendingValue) && apptId) {
  //     let params = {
  //       appointmentId: apptId,
  //       numberOfMessages: chatBoxConstants.MESSAGES_LIMIT,
  //     };

  //     if (sendingValue === true) {
  //       dispatch(
  //         homeStateActions.setState({
  //           showGeneralChatMessages: false,
  //           showChatBox: true,
  //         })
  //       );
  //     }
  //     cacheAndDispatch({
  //       action: chatBoxActions.getPatientMessages,
  //       params,
  //       cacheOf: mainCacheConstants.chatBoxMessagesCache,
  //       key: apptId,
  //     });
  //   } else if (channelsid) {
  //     if (sendingValue === false) {
  //       let lastMsgIndex = 0;

  //       await hospitalService
  //         .fetchLastIdexAPI({ channelSid: channelsid })
  //         .then((res) => {
  //           lastMsgIndex = res.lastMessageIndex;
  //           cacheAndDispatch({
  //             action: chatBoxActions.getGeneralChatMessages,
  //             params: {
  //               channelSid: channelsid,
  //               lastMsgIndex: lastMsgIndex,
  //             },
  //             cacheOf: mainCacheConstants.chatBoxGeneralChatMessagesCache,
  //             key: channelsid,
  //           });
  //           dispatch(
  //             hospitalActions.setCurrentHospitalData({
  //               data: { ...currentHospitalData, lastMsgIndex },
  //             })
  //           );
  //         });

  //       let data = currentHospitalData;
  //       dispatch({ type: hospitalConstants.SET_HOSPITAL_LIST_DATA, data });
  //     } else {

  //       dispatch(
  //         homeStateActions.setState({
  //           showGeneralChatMessages: true,
  //           showChatBox: true,
  //         })
  //       );

  //       await hospitalService
  //         .fetchLastIdexAPI({ channelSid: channelsid })
  //         .then((res) => {
  //           cacheAndDispatch({
  //             action: chatBoxActions.getGeneralChatMessages,
  //             params: {
  //               channelSid: channelsid,
  //               lastMsgIndex: parseInt(res.lastMessageIndex) + 1,
  //             },
  //             cacheOf: mainCacheConstants.chatBoxGeneralChatMessagesCache,
  //             key: channelsid,
  //           });
  //         }
  //         )
  //     }
  //   }
  // };

  const authentication = useSelector((state) => state.authentication);
  // const {getRefreshedPage } = useGetRefreshState({source:"B"})


  // for updating the state when notification is clicked
  // useEffect(() => {
  //   if (isMounted.current) {
  //     if (Object.keys(notificationState).length > 0) {
  //       const {
  //         appointmentId,
  //         hospitalId
  //         } = notificationState.data;
  //         console.log("dragon dragon this is called")
  //       getRefreshedPage({hospId:hospitalId,apptId:appointmentId});
  //     }
  //   } else {
  //     isMounted.current = true;
  //   }
  // }, [notificationState]);
  const GeneralChatMessagesActive = useSelector(state => state.chatBox.GeneralChatMessagesActive)
  // useEffect(() => {
  //   if (isMounted.current) {
  //     if (
  //       Object.keys(notificationState).length > 0 &&
  //       hospitalList.length > 0 &&
  //       patientListActive === true
  //     ) {
  //       updateStateWithNotification();
  //     }
  //   } else {
  //     isMounted.current = true;
  //   }
  // }, [hospitalList.length, patientListActive, patients, GeneralChatMessagesActive]);

  const updateStateWithNotification = () => {
    if (Object.keys(notificationState).length === 0) {
      return;
    }

    const {
      channelSid,
      appointmentId,
      leadId,
      hospitalId
    } = notificationState.data;

    // let item = hospitalList.find((item) => {
    //   if (item.hospital_id === hospitalId) {
    //     dispatch(homeStateActions.updateCurrentState({}));
    //   }
    //   return item.hospital_id === hospitalId;
    // });

    // dispatch(homeStateActions.updateSelectedCities([]))
    // dispatch(patientActions.updateSelectedMop({ dateRange: { startDate: null, endDate: new Date('') }, selectedMop: [] }));
    // dispatch(
    //   hospitalActions.setCurrentHospitalData({
    //     data: item,
    //   })
    // );
    // dispatch(
    //   hospitalActions.handleLastIndexAPI({
    //     params: { channelSid: channelSid },
    //   })
    // );

    // let newItem = {};

    // for (let key in patients) {
    //   for (let i = 0; i < patients[key].length; i++) {
    //     let curr = patients[key][i];
    //     if (curr["_id"] === appointmentId) {
    //       dispatch(homeStateActions.updateCurrentState({}));
    //       newItem = curr;
    //     }
    //   }
    // }
    // let currPatientId = item && item.patientId;
    // dispatch(
    //   patientActions.setPatientInfo({
    //     appointmentId,
    //     patientId: currPatientId,
    //     leadId,
    //     allInfo: newItem,
    //   })
    // );

    // dispatch(
    //   patientActions.getPatientInfoAPIData({
    //     appointmentId,
    //     currPatientId,
    //     leadId,
    //   })
    // );
    // dispatch(configActions.hideSpinningLoader());

  };
  // **************************************************************************
  useEffect(() => {
    initialiseMixPanel(authentication.user);
  }, [authentication]);

  const mainContainerRef = useRef(undefined);
  const cacheAndDispatch = useReduxCacheAndDispatch();

  const showHospitalList = useSelector(
    (state) => state.homeState.showHospitalList
  );

  const showChatBox = useSelector((state) => state.homeState.showChatBox);

  const totalHospitals = useSelector((state) => state.hospitals.totalHospitals);

  const handleClose = () => {
    dispatch(homeStateActions.deleteAlarmNotification());
    dispatch(homeStateActions.toggleAlarmSound(false));
  };

  useEffect(() => {
    if (showChatBox === true)
      Mixpanel.track(constants.CHANNEL_OPEN, {
        name: currentHospitalData?.friendly_name,
        source: "web",
      });
  }, [showChatBox]);
  useEffect(() => {
    const {
      hospital_id = "",
      sid = "",
      friendly_name = "",
    } = hospitalList?.[0] || [{ hospital_id: "", sid: "", friendly_name: "" }];

    if (totalHospitals === 1) {
      // setting the sid and hospitalData in background of hospitalList[0] --------------------

      dispatch(
        hospitalActions.setCurrentHospitalData({
          data: hospitalList[0] || [
            { hospital_id: "", sid: "", friendly_name: "" },
          ],
        })
      );
      // Mixpanel.track(constants.BLANK_ERROR, { source: "cardClicked", payload: hospitalList?.[0] });

      cacheAndDispatch({
        action: patientActions.getPatientList,
        params: {
          selectedHospitalId: hospital_id,
          sid,
        },
        cacheOf: mainCacheConstants.patientListsCache,
        key: sid,
      });

      if (!!hospital_id) {
        dispatch(
          homeStateActions.setState({
            showHospitalList: false,
            mainTitle: friendly_name,
          })
        );
      }
    } else {
      dispatch(
        homeStateActions.setState({
          showHospitalList: true,
        })
      );
    }
  }, [hospitalList]);
  const role = useSelector(state => state.authentication.user.roles.role)
  useEffect(() => {
    const { dispatch } = props;
    // dispatch(socketActions.getSocketConnectivity());
    dispatch(configActions.getVersionConfig());
    dispatch(configActions.getCommonData());
    dispatch(configActions.getDocumentList({ role: role }));

  }, []);

  const sendAlarmAction = (status) => {
    const {
      user_name: mobile,
      userId: loginUserId,
      name: loginUserName,
      roles: { role: loginUserRole },
    } = userInfo;
    const notificationInfo = alarmNotifications[0];
    const requestPayload = {
      mobile: mobile,
      hospId: notificationInfo.hospitalId,
      loginUserId: loginUserId,
      loginUserName: loginUserName,
      loginUserRole: loginUserRole,
      appId: notificationInfo.appointmentId,
      patientId: notificationInfo.patientId,
      leadId: notificationInfo.leadId,
      patientName: notificationInfo.patientName,
      channelSid: notificationInfo.channelSid,
      status: status,
    };
    dispatch(configActions.sendAlarmAction(requestPayload));
    dispatch(homeStateActions.toggleAlarmSound(false));
    handleClose();
  };

  return (
    <>
      <CssBaseline />
      <audio ref={myRef} src={alarmSong} style={{ position: 'absolute', opacity: 0 }} />
      <Grid
        container
        ref={mainContainerRef}
        sx={{ overflowY: "auto", height: `100%`, display: "flex", pt: 0,position:"relative" }}
        // columns={18}
        data-component="connectedHomeScreen"
      >
        {(!isMobile || screenName.hospitalList) && showHospitalList && (
          <Grid
            item
            md={3}
            lg={3}
            xl={2.6}
            sx={{
              background: "#F5F5F5",
              maxHeight: "100%",
              overflowY: "hidden",
              width: isMobile ? "100%" : "unset",
              maxWidth: "100%",
              overflowX: "hidden",
              lineHeight: "1",
              display: "flex",
              // flexBasis: ['unset', 'unset', "22%!important"],
              flexDirection: "column"
            }}
            className={isDrawer.hospital?'drawer-active':'drawer-nonactive'}
          >
            <HospitalContainer />
          </Grid>
        )}
        {((!isMobile&&patientListActive) || screenName.patientList) && 
        <Grid
          item
          md={3}
          lg={3}
          xl={2.6}
          sx={{
            background: "#f5f5f5 0% 0% no-repeat padding-box;",
            maxHeight: "100%",
            overflowY: "auto",
            maxWidth: "100%",
            overflowX: "hidden",
            position: "relative",
            width: ['100%', "100%", "unset"],
          }}
          id="patientList"
          className={isDrawer.patient ? "drawer-active":"drawer-nonactive"}
        >
        <div >
          {/* <button className={(isDrawer.patient?'drawer-closed':'drawer-open')+" drawer-arrow"} onClick={()=>setDrawer({...isDrawer, patient: true})}> arrow </button> */}
          <PatientList/>
        </div>
        </Grid>
        }

        {(!isMobile || screenName.chatBox) && <Grid
          item
          // md={showHospitalList ? 10 : 14}
          mx="auto"
          sx={{
            overflowY: "hidden",
            overflowX: "hidden",
            maxHeight: "100%",
            background: "#fff",
            width: isMobile ? "100%" : "unset",
            // marginTop:isMobile?"-13px":"unset",
            flex: 1
            // width :"100%"
          }}
        >
          {  showChatBox ? (
            <ChatBox />
          ) : (
            (!isMobile) &&
            <Box
              sx={{
                p: 2,
                maxWidth: "1000px",
                m: "auto",
                display: "grid",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src="/logo-black.svg"
                alt="Live from space album cover"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center",
                }}
              />
            </Box>
          )}
        </Grid>
        }
      </Grid>
      <Modal
        open={showAlarmNotification || false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            bottom: "0%",
            right: "0%",
            width: "42vw",
            bgcolor: "#00263E",
            borderRadius: "10px",
            boxShadow: 24,
            padding: "1rem 2rem 1rem 2rem",
            outline: "none",
          }}
        >
          <div className="alarmBox">
            <div className="alarmCross">
              <span onClick={handleClose} className="closeIcon">X</span>
            </div>
            <div className="alarmBody">
              <div className="alarmImage">
                <img src={alarmImage} alt="" />
              </div>
              {alarmNotifications && alarmNotifications.length > 0 && (
                <div className="alarmContent">
                  <div className="alarmHeading">
                    Is {alarmNotifications[0].patientName} admitted to the room?
                  </div>
                  <div className="alarmDetails">
                    <div>Doctor:{alarmNotifications[0].doctorName}</div>
                    <div style={{ borderTop: "1px solid grey" }}>
                      Disease:{alarmNotifications[0].disease}
                    </div>
                  </div>
                </div>
              )}
              <div className="alarmActions">
                <div
                  onClick={() => sendAlarmAction("no")}
                  style={{ background: "#E00000" }}
                  className="actionButton"
                >
                  <Button>
                    <img style={{ marginRight: "10px" }} src={noIcon} alt="" />No
                  </Button>
                </div>
                <div
                  onClick={() => sendAlarmAction("yes")}
                  style={{ background: "#009817", marginLeft: "20px" }}
                  className="actionButton"
                >
                  <Button>
                    <img style={{ marginRight: "10px" }} src={yesIcon} alt="" />Yes
                  </Button>
                </div>
              </div>
            </div>
            <div className="logoImage">
              <img src={logoImage} alt="" />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const connectedHomeScreen = connect()(SimpleContainer);
export default connectedHomeScreen;
