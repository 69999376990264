import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputAdornment, OutlinedInput} from '@mui/material';
import './centralInputField.css';

export const CentralInputField = (props) => {
    let {value='', placeholder,label, handleOnChange = ()=>{}, type = 'text', error = false, startAdornment='', disabled=false, helperText ='', name='', width = '47%',jsonDetails= '', sx={}} = props;
    const [inputValue, setInputValue] = useState('')
    useEffect(() => {
      if((jsonDetails && (jsonDetails.key==="amount_collected_admission" || jsonDetails.key ==="total_collected_at_discharge")) || jsonDetails?.disable){
        setInputValue(jsonDetails?.value);
      } else {
        setInputValue(inputValue);
      }
    });
    const onChange = (event) =>{
      setInputValue(event.target.value)
      handleOnChange(event)
    }

    if(jsonDetails && (jsonDetails.key==="amount_collected_admission" || jsonDetails.key ==="total_collected_at_discharge")){
        disabled = true
    }
    
  return (
    <FormControl sx={{ width: {width} , maxWidth:'360px', marginTop:'20px', ...sx}} variant="outlined" disabled={disabled || (jsonDetails && jsonDetails?.disable)}>
    <FormHelperText id="input-text-field-label" className='inputFieldLabel'>{label || (jsonDetails &&jsonDetails?.label)}</FormHelperText>
    <OutlinedInput
      id={name}
      startAdornment={startAdornment ? <InputAdornment position="start"className='inputStartAdornment'>{startAdornment}</InputAdornment>:''}
      onChange={onChange}
      name={name || (jsonDetails &&jsonDetails?.postKey)}
      margin="dense"
      value={value || inputValue  || typeof(inputValue) === "number" ? inputValue : ''}
      size="small"
      placeholder={placeholder || 'Enter Input'}
      type={type}
      error={error}
      className='inputFieldStyle'
      onWheel={(e) => e.target.blur()}
      sx={{height:'30px',fontSize:'12px', color:'#393939', lineHeight:'18px', paddingLeft:'10px'}}
    />
   {(error && helperText) ? <FormHelperText error id="" className='inputFieldError'>
      {helperText}
    </FormHelperText> :''}
  </FormControl>
  )
}
