import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { configActions } from '../../_actions';
import ChecklistWeb from './checklistWeb';
import MobileScreen from './MobileScreen';

function ChecklistSummary() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const role = useSelector(state => state.authentication.user.roles.role)
  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(configActions.getDocumentList({ role: role }));
  }, [])

  return (
    <>
      {isSmallScreen ? <MobileScreen /> : <ChecklistWeb />}
    </>
  )
}

const checklistSummary = connect()(ChecklistSummary);
export default checklistSummary;
