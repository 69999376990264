import { centralGetInitialState } from "../../_helpers/centralGetInitialState";
import { hospitalDataConstants } from "../../_constants";

export function centralHospitalData(
    state = centralGetInitialState("centralHospitalData"),
    action
  ) {
    switch (action.type) {
      case hospitalDataConstants.FETCH_HOSPITAL_DATA_REQUESTING:
        return {
          ...state,
        };
      case hospitalDataConstants.FETCH_HOSPITAL_DATA_FETCHED:
          const { listData } = action;
        return {
          ...state,
          listData
        };
      case hospitalDataConstants.FETCH_HOSPITAL_DATA_FAILED:
        return {
          ...state,
          hospitalDataFetchingError: action.error,
        };
      case hospitalDataConstants.SAVE_ACTIVE_CENTRAL_HOSPITAL_DATA:
          return {
            ...state,
            activeHospitalId: action.payload.id,
            activeHospitalName: action.payload.hospitalName,
          };
      default:
        return state;
    }
  }
  