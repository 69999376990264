import { alertActions } from ".";
import { configConstants } from "../_constants";
import { EXTERNAL_HOSPITAL_ROLES } from "../_helpers";
import { configService } from "../_services";

function getVersionConfig() {
  return (dispatch) => {
    dispatch(request());

    configService.fetchVersionConfig().then(
      (res) => {
        dispatch(success(res));
        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: configConstants.FETCH_VERSION_CONFIG,
    };
  }
  function success(versionConfigData) {
    /// to include the External users surgeryApprovers Group ----------
    let {
      result: [{ surgeryApprovers = [], templateUsers = [] }],
    } = versionConfigData;

    surgeryApprovers = [...surgeryApprovers, ...EXTERNAL_HOSPITAL_ROLES].filter(
      (role) => role.toLocaleLowerCase() !== "hospital"
    );

    templateUsers = [...templateUsers, ...EXTERNAL_HOSPITAL_ROLES].filter(
      (role) => role.toLocaleLowerCase() !== "hospital"
    );

    versionConfigData.result[0].surgeryApprovers = surgeryApprovers;
    // versionConfigData.result[0].templateUsers = templateUsers;
    /// to include the External users surgeryApprovers Group ----------

    return { type: configConstants.VERSION_CONFIG_FETCHED, versionConfigData };
  }
  function failure(error) {
    return { type: configConstants.VERSION_CONFIG_FAILED, error };
  }
}

function getCommonData() {
  return (dispatch) => {
    dispatch(request());

    configService.fetchCommonData().then(
      (res) => {
        dispatch(success(res));
        //   history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: configConstants.FETCH_COMMON_DATA,
    };
  }

  function success(commonData) {
    return { type: configConstants.COMMON_DATA_FETCHED, commonData };
  }

  function failure(error) {
    return { type: configConstants.COMMON_DATA_FAILED, error };
  }
}

const showSpinningLoader = () => {
  return {
    type: configConstants.SHOW_SPINNING_LOADER,
  };
};

const hideSpinningLoader = () => {
  return {
    type: configConstants.HIDE_SPINNING_LOADER,
  };
};

function getDocumentList(params) {
  return (dispatch) => {
    dispatch(request());

    configService.getDocumentList(params).then(
      (res = {}) => {
        dispatch(success(res));
        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: configConstants.FETCH_DOCUMENT_LIST,
    };
  }
  function success(res) {
    return { type: configConstants.DOCUMENT_LIST_FETCHED, res };
  }
  function failure(error) {
    return { type: configConstants.DOCUMENT_LIST_FAILED };
  }
}

const updateFullState = (newState) => {
  return {
    type: configConstants.UPDATE_FULL_CONFIG_STATE,
    newState,
  };
};
function sendAlarmAction(requestPayload) {
  return (dispatch) => {
    dispatch(request());
    configService.sendAlarmAction(requestPayload).then(
      (res = {}) => {
        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: configConstants.SEND_ALARM_ACTION,
    };
  }
  function success(res) {
    return { type: configConstants.SEND_ALARM_ACTION_SUCCESS, res };
  }
  function failure(error) {
    return { type: configConstants.SEND_ALARM_ACTION_FAILED };
  }
}

function getChannelSid() {
  return (dispatch) => {
    showSpinningLoader()
    configService.getChannelSid().then(
      (res) => {
        hideSpinningLoader()
       return res

      },
      (error) => {
     
      }
    );
  };

}
const getCommonOpthalData=(reqPayload)=>{
  return (dispatch)=>{
    configService.getCommonOpthalData(reqPayload).then(res=>{

      dispatch({type:configConstants.GET_OPTHAL_COMMON_DATA,payload:res})
    })
  }

}
export const configActions = {
  getVersionConfig,
  getCommonData,
  showSpinningLoader,
  hideSpinningLoader,
  getDocumentList,
  updateFullState,
  sendAlarmAction,
  getCommonOpthalData
  // getChannelSid
};
