import { KeyboardArrowRight } from "@mui/icons-material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Button, Grid, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable"; // The default
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import callIcon from "../../../../assets/callIcon.svg";
import drawerIcon from '../../../../assets/drawerArrow.png';
import filterIcon2 from '../../../../assets/Filter.svg';
import otSchedulerIcon from '../../../../assets/otSchedulerIcon.svg';
import searchIcon from '../../../../assets/search-icon.png';
import { chatBoxActions, homeStateActions, patientActions } from "../../../../_actions";
import DailogHWA from "../../../../_components/DailogHWA/DailogHWA";
import { hospitalConstants } from "../../../../_constants";
import { changeScreen, Debounce, EXTERNAL_HOSPITAL_ROLES, GetRandomColor, mainCacheConstants, useReduxCacheAndDispatch } from "../../../../_helpers";
import { getInitialState } from "../../../../_helpers/getInitialState";
import useWindowSize from '../../../../_helpers/useWindowSize';
import { Cards } from "../../../common/Cards";
import PatientListFilter from "../../commonComponents/ListFilter/ListFilter";
import OtScheduler from "../../commonComponents/OtScheduler/OtScheduler";
import ClickToCall from "./PatientListDialogs/ClickToCall";
import "./styles.css";




const searchInputDebounce = new Debounce();
const patientListColor = new GetRandomColor();

const patientTypes = [
  { objKey: "pendingPatient", title: "Pending Patients" },
  { objKey: "activePatient", title: "Active Patients" },
  { objKey: "tentativePatient", title: "Tentative Patients" },
  { objKey: "oldPatient", title: "Old Patients" },
];

function PatientList({}) {
  const { isMobile } = useWindowSize()
  const [mopFilter, setMopFilter] = useState([]);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const [filterName, setFilterName] = useState('')
  const [filterTitle, setFilterTitle] = useState('')
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }])
  const { hospital_id } = useSelector(
    (state) => state.hospitals.currentHospitalData||''
  );
  // const [selectedMops,setSelectedMops]=useState([])
  const selectedMops = useSelector((state) => state.patients.selectedMop);
  const {otDateRange}=useSelector(state=>state.patients)
  const [anchorEl, setAnchorEl] = useState(null);
  const totalHospitals = useSelector((state) => state.hospitals.totalHospitals);
  const showChatBox = useSelector((state) => state.homeState.showChatBox);
  const [value, setValue] = useState(0)
  const { availableTimeSlots: { [hospital_id]: { timings: { availableSlots, selectedDuration, selectedSlots, selectedSlotsData } = {} } = {}, [hospital_id]: slots } = {} } = useSelector(state => state.patients || {})

  const externalRoles = EXTERNAL_HOSPITAL_ROLES;

  const handleClick = (event, filterName) => {
    if (filterName === 'mopFilter') {
      setFilterName('mopFilter')
      setFilterTitle('Filter By')
    } else {
      setFilterTitle('OT Slots')
      setFilterName('otScheduler')
    }

    setAnchorEl(event.currentTarget);
    setMopFilter(selectedMops);
    setTimeout(() => {
      if (document.getElementById("search_input"))
        document.getElementById("search_input").placeholder = "Search";
    }, 0);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue(0)
  };

  const selectMop = (item) => {
    setMopFilter(item);
  };
  const removeMop = (item) => {
    setMopFilter(item);
  };
  const clearFilters = () => {
    if (mopFilter.length === 0) {
      toast.success("No Mop Filter is selected");
    } else {
      setMopFilter([]);
      dispatch(patientActions.updateSelectedMop({ dateRange: { startDate: null, endDate: new Date('') }, selectedMop: [] }));
      // setSelectedMops([])
      handleClose();
    }
  };
  const applyFilter = () => {
    dispatch(patientActions.updateSelectedMop(mopFilter));
    handleClose();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isMountedRef = useRef(null);

  const currentHospitalData = useSelector(
    (state) => state.hospitals.currentHospitalData
  );
 
  const isDrawer = useSelector((state) => state.hospitals.isDrawer)
  const mopArr = useSelector((state) => state.patients.mopArr);
  const checkUpdatedBuild = useSelector(
    (state) => state.homeState.checkUpdatedBuild
  );

  const showGeneralChatMessages = useSelector(
    (state) => state.homeState.showGeneralChatMessages
  );
  const patientInfo = useSelector((state) => state.patients.patientInfo);

  const patients = useSelector((state) => state.patients.patients);

  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  const [currentPatientList, setCurrentPatientList] = useState({});
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const updateCurrentStateToggle = useSelector(
    (state) => state.homeState.updateCurrentStateToggle
  );

  useEffect(() => {
    isMountedRef.current = true;

    let appointmentId = patientInfo.appointmentId;
    if (!showGeneralChatMessages && isMountedRef.current) {
      setTimeout(() => {
        let selectedDiv = document.getElementById(appointmentId);
        selectedDiv?.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 2000);
    }
    return () => (isMountedRef.current = false);
  }, [patientInfo.appointmentId, patients, showGeneralChatMessages]);
  // }, [patientInfo.appointmentId, patients]);
  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchInput(() => value);
  };

  const handleGroupInfoClick = () => {
    dispatch(
      homeStateActions.setState({
        showGroupInfoPopUp: true,
      })
    );
  };

  const handleGeneralChatClick = () => {

    const { sid, lastMessageIndex } = currentHospitalData;
    const initialPatientInfoState =
      getInitialState("patients")["patientInfo"];
    dispatch(patientActions.setPatientInfo(initialPatientInfoState));
    dispatch(chatBoxActions.privateChatOff())
    
    dispatch(
      homeStateActions.setState({
        showChatBox: true,
        showGeneralChatMessages: true,
      })
    );

    cacheAndDispatch({
      action: chatBoxActions.getGeneralChatMessages,
      params: {
        channelSid: sid,
        lastMsgIndex: lastMessageIndex,
      },
      cacheOf: mainCacheConstants.chatBoxGeneralChatMessagesCache,
      key: sid,
    });
    changeScreen(dispatch, navigate, { hospitalList: false, patientList: false, chatBox: true }, 'chatbox');
  };

  const checkOtFilter=({date})=>{
    
    const {startDate,endDate} =otDateRange
    const dateStart=new Date(startDate).getTime()
    const temp = new Date(endDate);
    temp.setDate(temp.getDate() + 1);
    const dateEnd=temp.getTime()

    if(dateStart<=date&&dateEnd>=date)
      console.log('dragon ',date,new Date(dateStart),new Date(date),new Date(dateEnd))
    return dateStart<=date&&dateEnd>=date
  }

  const updateCurrentPatientList = () => {
    if (isMountedRef.current) {
      let mopNames = [];
      if (selectedMops?.length > 0)
        mopNames = selectedMops
      const newFilterdList = {};
      for (const key in patients) {
        const filteredArr = patients[key]?.filter((item) => {
          const { FirstName = "" } = item ?? { FirstName: "" };
          const {AppointmentStartTime}=item

          const checkSearchString=!! FirstName.trim().toLowerCase().includes(searchInput.trim().toLowerCase())
          const checkMopFilter=mopNames?.length > 0?mopNames.includes(item.paymentMode):true
          const checkOtTime=(otDateRange&&otDateRange.startDate&&otDateRange.endDate)?checkOtFilter({date:AppointmentStartTime}):true
          return checkSearchString&&checkMopFilter&&checkOtTime
        
        });

        newFilterdList[key] = filteredArr;
      }

      setCurrentPatientList(() => {
        return newFilterdList;
      });
    }
  };

  const handleSearchInputSubmit = () => {
    updateCurrentPatientList();
  };

  const refreshPage = () => {
    dispatch(homeStateActions.checkUpdatedBuild(true));

    setTimeout(() => {
      console.log("reload 3")

      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    if (isMountedRef.current) {
      searchInputDebounce.debounce(updateCurrentPatientList, 100);
    }
  }, [searchInput]);
  useEffect(() => {
    updateCurrentPatientList();
  }, [selectedMops,otDateRange]);

  useEffect(() => {
    if (isMountedRef.current) {
      // setCurrentPatientList(patients)
      updateCurrentPatientList();
    }
  }, [patients]);

  const memoisedCurrentPatientList = useMemo(() => {
    return currentPatientList;
  }, [currentPatientList]);
  
  useEffect(() => {
    // to make the patient search empty on hospital click
    if (isMountedRef.current) {
      setSearchInput(() => "");
    }

  }, [currentHospitalData]);
  useEffect(()=>{
    dispatch(patientActions.getAvailableTimeSlots({ hospId: hospital_id }))

  },[hospital_id])

  // checking for supply and centeral dashboard user
  // checking for supply and centeral dashboard user 
  const allRoles = []
  const role = useSelector((state) => state.authentication.user.roles.role);
  const otherRoles = useSelector((state) => state.authentication.user.roles.otherRoles) 
  role &&  allRoles.push(role) ;
  otherRoles && allRoles.concat(otherRoles)
  const roleCheck = ()=>{
    let show = false
    // allRoles?.forEach((ele)=>{
    const rolesCombined=role?(otherRoles?[role,...otherRoles]:[role]):otherRoles

    rolesCombined?.forEach((ele)=>{
      (ele.toLowerCase().includes("central") || ele.toLowerCase().includes("cityops")) && (show = true)
    })
    return show
  }


  const [clickToCallOpen, setClickToCallOpen] = useState(false);
  const clickToCallClose = () => {
    setClickToCallOpen(false);
  };
  const pocListCache = useSelector((state) => state.cacheStorage.pocListCache);
  const navigate = useNavigate();

  const [drag, setDrag] = useState({})
  const [dragCount, setDragCount] = useState(0)
  const onClick = (event) => {
    // your code
    setClickToCallOpen(true)
    setDragCount(0)
  }

  const onDrop = (...args) => {
    // your code
    if (dragCount < 3) {
      onClick(...args)
    }
    setDragCount(0)
  }

  const onDrag = () => {
    setDrag({ dragging: true })
    setDragCount(dragCount + 1)
  }

  const onStop = (...args) => {
    const { dragging } = drag
    setDrag({ dragging: false })
    if (dragging) {
      onDrop(...args)
    } else {
      onClick(...args)
    }
  }

  function FilterTitle({ children, ...rest }) {
    return <Typography component={`h4`} variant="body1" sx={{ fontWeight: 600 }} {...rest} >{children}</Typography>
  }
  
  const renderFilterButton = () => {
   
    return (
      <Grid container sx={{ gap: ['20px', '20px', '0.8vw'], flexWrap: 'nowrap' ,flex:'0.4'}}>
        <Grid item>
          <Button aria-describedby={id} variant="text" onClick={(e) => handleClick(e, 'mopFilter')} sx={{ minWidth: "unset!important", background: ['white'], boxShadow: ["0px 3px 6px #00000029"], borderRadius: '8px', height: ['40px'], marginTop: ['0px'], position: ['relative'], right: ['0px'] }}> <img src={filterIcon2}></img></Button>
        </Grid>
        {(externalRoles?.includes(role) || roleCheck() ) &&
        <Grid item sx={{position:'relative'}}>
          <Button aria-describedby={id} variant="text" onClick={(e) => handleClick(e, 'otScheduler')} sx={{ minWidth: "unset!important", background: ['white'], boxShadow: ["0px 3px 6px #00000029"], borderRadius: '8px', height: ['40px'], marginTop: ['0px'], position: ['relative'], right: ['0px'] }}> <img src={otSchedulerIcon}></img></Button>
          <Tooltip title="OT slot for tomorrow not recorded yet" placement="right" arrow>
<>
          {selectedSlotsData===false&&<PriorityHighIcon sx={{position:'absolute',top:"-14px",right:"-7px",color:'#e00000'}}/>}
          {selectedSlotsData===true&&<CheckCircleIcon sx={{position:'absolute',top:"-14px",right:"-9px",color:'#0da00dcc'}}/>}
</>
          </Tooltip>
        </Grid>
  }
      </Grid>
    )

  }
  const handleChangeIndex = (index) => {
    setValue(index);
    if (index === 1) {
      setFilterTitle('Manage Slot Duration')
    } else {
      setFilterTitle('OT Slots')
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setFilterTitle('Manage Slot Duration')
    } else {
      setFilterTitle('OT Slots')
    }
  };
  const filterBody = () => {
    return (<>
      {filterName === 'mopFilter' && <PatientListFilter handleClose={handleClose} />}
      {filterName === 'otScheduler' && <>
        <ArrowBackIosIcon onClick={(e) => handleChange(e, 0)} sx={{ display: [value === 1 ? 'block' : 'none'], position: 'absolute', marginLeft: ['10px', '10px','10px','10px','20px'], marginTop: ['-44px'], color: "#FFFFFF",cursor:'pointer' }} />
        <OtScheduler handleClose={handleClose} value={value} handleChangeIndex={handleChangeIndex} handleChange={handleChange} />
      </>}
    </>
    
    )
  }

  return (
    <>
      <CssBaseline />

      {currentHospitalData&&pocListCache[currentHospitalData?.hospital_id] &&
        pocListCache[currentHospitalData?.hospital_id].length > 0 && (
          <Box
            sx={{
              width: ["100%", "100%", "22%"]
            }}
            style={{
              height: "93%",
              position: "fixed",
              zIndex: "99",
              pointerEvents: "none",
              bottom: "0px",
              padding: "20px 20px 20px 20px",
            }}
            data-component="PatientList"
          >
            <Draggable bounds="parent" onDrag={onDrag} // assume it's bound to `this`
              onStop={onStop} // assume it's bound to `this`
            >
              <div
                className={isDrawer.patient ? 'hideCallIcon':'showCallIcon'}
                style={{
                  width: "fit-content",
                  pointerEvents: "all",
                  position: "absolute",
                  bottom: "20px",
                  right: "0px",
                  height: "60px",
                }}
              >
                <img
                  className="callIconClick"
                  style={{ height: "80px", cursor: "pointer" }}
                  src={callIcon}
                  // onClick={(e) => {setClickToCallOpen(true)}}
                  alt="call icon"
                />
              </div>
            </Draggable>
          </Box>
        )}
      {getSearchAndGroupInfo({
        searchInput,
        handleSearchInputChange,
        handleSearchInputSubmit,
        handleGeneralChatClick,
        handleGroupInfoClick,
        showGeneralChatMessages,
        refreshPage,
        checkUpdatedBuild,
        handleClick,
        handleClose,
        id,
        anchorEl,
        mopArr,
        mopFilter,
        open,
        selectMop,
        removeMop,
        clearFilters,
        applyFilter,
        role,
        dispatch,
        navigate,
        renderFilterButton,
        isSmallScreen,
        totalHospitals,
        isMobile,
        showChatBox,
        isDrawer
      })}
      <div style={{ padding: "10px" }} className="patient-list-container" data-component="PatientList">
        {Object.values(memoisedCurrentPatientList)?.some((arr) =>
          Boolean(arr.length)
        ) ? (
          patientTypes?.map((obj, idx) => (
            <GetPatientList
              key={idx}
              currentPatientList={memoisedCurrentPatientList}
              obj={obj}
              idx={idx}
              isSmallScreen={isSmallScreen}
            />
          ))
        ) : (
          <Typography color="primary" variant="h6" align="center">
            No Patients Found
          </Typography>
        )}
      </div>

      <ClickToCall
        open={clickToCallOpen}
        handleClose={clickToCallClose}
        hospitalId={currentHospitalData?.hospital_id}
      />
      <DailogHWA
          open={anchorEl}
          onClose={handleClose}
          sx={{ display: ['block'], background: "transparent"}}
          PaperProps={{ sx: { overflowY: ["unset"], borderRadius: "10px", minWidth: ['unset'] , backgroundColor: "white",position:'absolute',left:["unset",'unset','unset',totalHospitals===1?'-1%':'24%',totalHospitals===1?'-0.5%':'21%'],top:['unset','unset','unset','15vh','10vh'] } }}
          title={filterTitle}
          contentStyling={{ padding: "0px" }}
          dataName="heythere"
          hideBackdrop={!isMobile}
        >
          {filterBody()}
        </DailogHWA>
    </>
  );
}

const containers_scrollPos = [0, 0, 0];
const toggleContainers = (listContainer, idx) => {
  setTimeout(() => {
    let targetDiv = document.getElementById(listContainer);
    targetDiv.scrollTop = containers_scrollPos[idx - 1];
  }, 0);
};
const connectedPatientList = connect()(PatientList);
export { connectedPatientList as PatientList };

const getSearchAndGroupInfo = ({
  searchInput,
  isMobile,
  handleSearchInputChange,
  handleSearchInputSubmit,
  handleGroupInfoClick,
  handleGeneralChatClick,
  showGeneralChatMessages,
  refreshPage,
  checkUpdatedBuild,
  handleClick,
  handleClose,
  id,
  anchorEl,
  mopArr,
  mopFilter,
  open,
  selectMop,
  removeMop,
  clearFilters,
  applyFilter,
  dispatch,
  role,
  navigate,
  renderFilterButton,
  isSmallScreen,
  totalHospitals,
  showChatBox,
  isDrawer
}) => {
  return (
    <>
      <Container
        disableGutters
        sx={{
          background: "#f1f1f1",
          position: "sticky",
          zIndex: 3,
          top: 0,
          p: "0rem !important",
        }}
      >
        <Container
          disableGutters
          sx={{
            background: "#FFBE00",
            p: ["1px .8rem !important"],
            m: 0,
            position: "sticky",
            zIndex: 3,
            top: 0,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            lineHeight: "1",
            minHeight: "60px",
            borderRadius: ['0 0 8px 8px', '0 0 8px 8px', '0 0 10px 10px']
          }}
        >
          {<Box onClick={() => {
            changeScreen(dispatch, navigate, { hospitalList: true, patientList: false, chatBox: false }, 'home');
            dispatch(patientActions.updateSelectedMop({ dateRange: { startDate: null, endDate: new Date('') }, selectedMop: [] }));

          }} sx={{ visibility: totalHospitals > 1 ? 'unset' : 'hidden', display: ["block", "block", "none"], cursor: "pointer" }}> <svg width="12.414" height="22.828" viewBox="0 0 12.414 22.828">
              <path id="Path_6109" data-name="Path 6109" d="M10,20,0,10,10,0" transform="translate(1 1.414)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </svg>
          </Box>}
          {showChatBox && <img className="drawer-icon" src={drawerIcon} onClick={()=>dispatch({type:hospitalConstants.CLOSE_PATIENT})} />}
          <Typography
            align="left"
            variant="h2"
            fontWeight="600"
            color="#fff"
            sx={{
              fontSize: ["18px !important", "18px", "clamp(15px, 5vw, 22px)"],
              position: ['absolute', 'absolute', 'relative'],
              marginLeft: [totalHospitals > 1 ? '30px' : "2px", totalHospitals > 1 ? '30px' : "2px", "0px"],
            }}
            className="patientListHeading"
          >
            Patients List

          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {role &&
              (role.toLowerCase().includes("cityops") ||
                role.toLowerCase().includes("centralmanager") ||
                role.toLowerCase().includes("centralteam") ||
                role.toLowerCase().includes("centraldashboard")) && (
                <div className="group-info-btn-container" style={{
                  height: "40px",
                  width: "40px",
                  background: "white",
                  borderRadius: "9px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  cursor: 'pointer'
                }}>
                  <InfoIcon sx={{ color: "#3D7CC9" }} aria-label="search" onClick={handleGroupInfoClick}>
                  </InfoIcon>
                </div>
              )}

            <GroupsIconCustom onClick={handleGeneralChatClick} sx={{
              position: 'relative',
              top: '3px',
              cursor: 'pointer',
              "&:hover": {
                "#bg": {
                  fill: "#3d7cc9"
                },
                "#shape": {
                  fill: "#fff"
                },
              },
              "#bg": {
                fill: showGeneralChatMessages && !isMobile ? "#3d7cc9" : "#fff"
              },
              "#shape": {
                fill: showGeneralChatMessages && !isMobile ? "#fff" : "#3d7cc9"
              },
            }} />
          </Box>
        </Container>
        <Box sx={{ padding: ["20px 20px 10px 10px"], margin: ['0.5em'], gap: ["10px"], background: "#f5f5f5" }} className="wrapper patient-search-container">
          <div className="search-input-container" style={{flex:'0.6'}}>
            <IconButton aria-label="search" onClick={handleSearchInputSubmit}>
              <img style={{ height: "20px" }} src={searchIcon} alt="" />
            </IconButton>
            <input
              value={searchInput}
              onChange={handleSearchInputChange}
              name="search"
              id="search"
              placeholder="Search Patient"
              style={{ paddingLeft: "10px" }}
            />

          </div>
          {renderFilterButton()}

        </Box>
      </Container>

      {/* {!!!checkUpdatedBuild && <BulidUpdateIcon refreshPage={refreshPage} />} */}
      {checkUpdatedBuild === false && (
        <BulidUpdateIcon refreshPage={refreshPage} />
      )}
    </>
  );
};

const fixScrollPosition = (cardId, listContainer, containerId) => {
  var objDiv = document.getElementById(listContainer);

  containers_scrollPos[containerId - 1] = objDiv.scrollTop;
};

const GetPatientList = (props) => {
  const {
    obj: { objKey, title },
    currentPatientList,
    idx,
    isSmallScreen
  } = props;

  if (currentPatientList && !currentPatientList[objKey]?.length) return <></>;

  return (
    <div className="patient-list-item" id={`container_${objKey}`}>
      <details open={true}>
        <summary
          onClick={() => {
            toggleContainers(`container_${objKey}`, idx);
          }}
        >
          <h3 style={{ fontWeight: "600" }} className="title">
            <span >{`${title} (${currentPatientList[objKey]?.length})`}</span>

            <div className="icon">
              <KeyboardArrowRight />
            </div>
          </h3>
        </summary>

        {currentPatientList[objKey]?.map((item, id) => {
          if (!item) return <></>;
          return (
            <div id={item._id} key={id} style={{ marginBottom: "25px" }}>
              <Cards
                cardId={`card${id}`}
                listContainer={`container_${objKey}`}
                isPatient={true}
                patientType={objKey}
                item={item}
                fixScrollPosition={fixScrollPosition}
                containerId={idx}
                isSmallScreen={isSmallScreen}
              />
            </div>
          );
        })}
      </details>
    </div>
  );
};

const BulidUpdateIcon = ({ refreshPage }) => (
  <Box sx={{ px: ".5rem" }}>
    <Button
      color="warning"
      fullWidth
      onClick={refreshPage}
      sx={{
        backgroundColor: "green",
        marginBottom: "5px",
        color: "#fff",
        ":hover": {
          backgroundColor: "green",
          color: "#fff",
        },
      }}
    >
      <Grid
        container
        sx={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          textTransform: "initial",
          gap: ".5rem",
          textAlign: "left",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item>
          <Box
            sx={{
              display: "grid",
              borderRadius: "100%",
              color: "#fff",
              p: ".2rem",
            }}
          >
            <img src="/app_update_icon.svg" alt="" />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            alignSelf: "center",
          }}
        >
          <Typography
            sx={{
              // fontSize: "clamp(18px, 10vw, 20px)",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Click here to update to latest version
          </Typography>
        </Grid>
      </Grid>
    </Button>
  </Box>
);

function GroupsIconCustom({ ...rest }) {
  return (
    <Box component={`svg`} width="58px" height="58px" viewBox="0 0 58 58" {...rest} >
      <defs>
        <filter id="Rectangle_18693" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood flood-opacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_10152" data-name="Group 10152" transform="translate(-151 -54)">
        <g transform="matrix(1, 0, 0, 1, 151, 54)" filter="url(#Rectangle_18693)">
          <rect id="bg" data-name="Rectangle 18693" width="40" height="40" rx="8" transform="translate(9 6)" fill="#fff" />
        </g>
        <g id="shape" data-name="Group 10151" transform="translate(167 68)" fill="#3d7cc9">
          <path id="Subtraction_1" data-name="Subtraction 1" d="M22.5,12H20V.8a1.986,1.986,0,0,0-.166-.8H22.5A2.5,2.5,0,0,1,25,2.5v7A2.5,2.5,0,0,1,22.5,12ZM6,12H2.5A2.5,2.5,0,0,1,0,9.5v-7A2.5,2.5,0,0,1,2.5,0H6.166A1.984,1.984,0,0,0,6,.8V12Z" transform="translate(0 13)" />
          <circle id="Ellipse_26" data-name="Ellipse 26" cx="4" cy="4" r="4" transform="translate(9)" />
          <circle id="Ellipse_27" data-name="Ellipse 27" cx="3" cy="3" r="3" transform="translate(1 5)" />
          <circle id="Ellipse_28" data-name="Ellipse 28" cx="3" cy="3" r="3" transform="translate(19 5)" />
          <path id="Rectangle_18691" data-name="Rectangle 18691" d="M2.5,0h7A2.5,2.5,0,0,1,12,2.5V14a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V2.5A2.5,2.5,0,0,1,2.5,0Z" transform="translate(7 10)" />
        </g>
      </g>
    </Box>
  )
}