import { Button } from '@mui/material'
import React from 'react'

function ButtonHWA({ children, onClick, sx, variant,...rest }) {
  const backgroundColor = variant === 'contained' ? "#3D7CC9" : "#F5F5F5"
  const textColor = variant === 'contained' ? "#FFFFFF" : "#666A70"
  return (
    <Button sx={{
      boxShadow: "0px 3px 6px #00000029", borderRadius: "6px"
      , fontWeight: '600', textTransform: "none", width: "100%", fontSize: ['14px', '14px', '14px', '14px','18px'], background: backgroundColor, color: textColor, ...sx
    }} onClick={onClick} variant={variant} {...rest}>{children}</Button>
  )
}

export default ButtonHWA