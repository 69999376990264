import { ArrowBack } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import Filter from "./Filter";
import SearchField from "./SearchField";
import { useSelector } from "react-redux";

const SearchAndFilter = ({ onBackClick, ClickEventHospital, showBackButton = true }) => {

  const { screenName } = useSelector(({ checklistSummary }) => checklistSummary);

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        {
          showBackButton && <Grid item xs={'auto'}><Button variant="contained" title="Back to Hospital Screen" onClick={(e) => { typeof (onBackClick) === 'function' && onBackClick(e) }}><ArrowBack /></Button></Grid>
        }
        <Grid item xs><SearchField /></Grid>
        {screenName != 'hospitalViewScreen' && <Grid item xs={`auto`}><Filter HospitalClickEvent={ClickEventHospital} /></Grid>}
      </Grid>
    </>
  );
}

export default SearchAndFilter;