import { getTokenFromLocal } from "../_helpers/handleResponseMiddleware";

let apiUrl = process.env.REACT_APP_BASE_URL;

export const sendFeedback = (params) => {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  }

  return fetch(`${apiUrl}/api/v2/submitPatientFeedback`, config).then(res => res.json())
}