import { Box, FormControl, TextField } from "@mui/material";

const OwnerInput = ({ sx = {}, id, onChange, value, label = '', placeholder = '', labelProps = {}, formControlProps = {}, ...restProps }) => {
  let rendomID = Math.random()
  const { sx: labelSx = {}, ...restLabel } = labelProps
  const { inputProps = {},...restFieldsProps } = restProps
  const { sx: inputSx = {}, ...restInputProps } = inputProps
  return (
    <FormControl fullWidth sx={{ mb: [2, 2, 2, 2, 3], ...sx }} {...formControlProps}>
      {label && <Box component={`label`} sx={{ mb: ['10px', '12px', '13px', '13px', '15px'], color: '#3D7CC9', fontSize: ['14px', '14px', '16px', '18px', '20px'], fontWeight: [600, 600, 500], ...labelSx }} htmlFor={id || rendomID} {...restLabel}>{label}</Box>}
      <TextField
        fullWidth
        placeholder={placeholder}
        onChange={(e) => onChange && onChange(e)}
        id={id || rendomID}
        value={value}
        inputProps={{
          sx: { height: ['30px', '30px', '50px'], px: ['6px', '6px', '8px', '10px', '12px'], fontSize: ['12px', '14px', '16px', '18px', '20px'], py: 0, ...inputSx },
          ...restInputProps
        }}
        {...restFieldsProps} />
    </FormControl>
  );
}

export default OwnerInput;