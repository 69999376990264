import { patientConstants } from "../_constants";
import { cleanObj } from "../_helpers";
import { getInitialState } from "../_helpers/getInitialState";

export function patients(
  state = getInitialState("patients"),
  action
) {
  switch (action.type) {
    case patientConstants.PATIENT_LIST_REQUESTING:
      return {
        ...state,
        isFetchingPatientList: true,
        patientListActive: false,
      };
    case patientConstants.PATIENT_LIST_FETCHED:
      const { patients } = action;
      var endOfChatArr = []
      for (let key in patients) {
        for (let i = 0; i < patients[key].length; i++) {
          let item = patients[key][i]
          if (!!item.disableChat === true) {
            endOfChatArr.push(item._id)
          }
        }
      }
      
      var mopArr = []
      for (let key in patients) {
        // eslint-disable-next-line no-loop-func
        patients[key].forEach(item => {
          if (item.paymentMode) mopArr.push({ name: item.paymentMode, id: item.paymentMode })
        })
      }
      mopArr = mopArr.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.place === value.place && t.name === value.name
        ))
      )
      return {
        ...state,
        patients,
        isFetchingPatientList: false,
        patientListActive: true,
        mopArr: mopArr,
        endOfChatArr: endOfChatArr
        // totalPatients: patient.length,
      };
    case patientConstants.PATIENT_LIST_FAILED:
      return {
        ...state,
        patientFetchingError: action.error,
        isFetchingPatientList: false,
        patientListActive: false,
      };

    case patientConstants.GET_PATIENT_INFO_REQUESTING:
      return {
        ...state,
        isFetchingPatientInfo: true,
        patientInfoActive: false,
      };

    case patientConstants.GET_PATIENT_INFO_FETCHED:
      const { patientInfoAPIData } = action;

      const { status = "" } = patientInfoAPIData;

      if (status !== "Success") {
        return {
          ...state,
          patientInfoAPIData: [],
          isFetchingPatientInfo: false,
          patientInfoActive: true,
        };
      }

      return {
        ...state,
        patientInfoAPIData,
        isFetchingPatientInfo: false,
        patientInfoActive: true,
      };
    case patientConstants.GET_PATIENT_INFO_FAILED:
      return {
        ...state,
        patientInfoFetchingError: action.error,
        isFetchingPatientInfo: false,
        patientInfoActive: false,
      };

    case patientConstants.SURGERY_ACCEPT_REJECT_REQUESTING:
      return {
        ...state,
        isFetchingSurgeryAcceptReject: true,
      };

    case patientConstants.SURGERY_ACCEPT_REJECT_SUCCESS:
      return {
        ...state,
        FetchingSurgeryAcceptRejectResponse: action.response,
        isFetchingSurgeryAcceptReject: false,
      };
    case patientConstants.SURGERY_ACCEPT_REJECT_FAILED:
      return {
        ...state,
        FetchingSurgeryAcceptRejectError: action.error,
        isFetchingSurgeryAcceptReject: false,
      };

    case patientConstants.SET_PATIENT_INFO:{
      var {patientInfo}=action
      patientInfo=cleanObj(patientInfo)
      return {
        ...state,
        patientInfo: patientInfo,
      };
    }

    case patientConstants.UPDATE_FULL_PATIENT_STATE:
      const { newState } = action;

      return {
        ...newState,
      };
    case patientConstants.EDIT_END_OF_CHAT_OBJECT: {
      const { payload } = action
      let temp = state.endOfChatArr || []
      temp.push(payload)
      return {
        ...state,
        endOfChatArr: temp
      }
    }
    case patientConstants.UPDATE_SELECTED_MOP: {
      const { payload:{selectedMop,dateRange} } = action
     return {
        ...state,
        selectedMop: selectedMop,
        otDateRange:dateRange
      }
    }
    case patientConstants.GET_AVAILABLE_TIME_SLOTS: {
      const { payload:{result,hospitalId,duration} } = action
      return {
        ...state,
        availableTimeSlots: {[hospitalId]:{timings:result,duration:duration}}
      }
    }
    case patientConstants.UPDATE_PATIENTS_READ_COUNT: {
      const {payload:{appointment_id,count}}   = action
      const patientsList={...state.patients}

      for (let key in patientsList) {
        patientsList[key].forEach(item => {
         if(item._id===appointment_id){
          console.log('changing to zero',item)
            item.unreadMessages=count
         }
        })
      }
      return {
        ...state,
        patients:patientsList
      }
    }
    case patientConstants.UPDATE_PATIENTS_LIST: {
      const {payload:{patientsList}}   = action
      return {
        ...state,
        patients:patientsList
      }
    }

    default:
      return state;
  }
}
