// ********** use this in the tracking file****************
// import { Mixpanel } from '../../../mixPanel/MixPanel';
// Mixpanel.track('login event',{id:"some id"});
import mixpanel from 'mixpanel-browser';
import { constants } from './constants';

// let env_check = process.env.NODE_ENV === 'production';
let env_check = true

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;

export const initialiseMixPanel = (userDetails) => {

  const mobile = localStorage.getItem('userMobile')
  const name = userDetails.name
  const role = userDetails.roles.role
  mixpanel.init('a7c3705a455ae50aae202986b35ccfa7');
  // const mobile=localStorage.getItem('userMobile')
  const userObj = JSON.parse(localStorage.getItem('user'))
  mixpanel.identify(mobile)
  // mixpanel.people.identify(mobile)
  mixpanel.people.set("name", name)
  mixpanel.people.set("phone", mobile)
  mixpanel.people.set("role", role)
  // mixpanel.people.set("userType", userType)
  Mixpanel.track(constants.LOGGED_IN, { user: mobile, source: 'web' })
}