import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { toast } from "react-toastify";
import { chatBoxActions, homeStateActions, pcScoreActions } from "../../../_actions";
import ButtonHWA from "../../../_components/ButtonHWA/ButtonHWA";
import SelectHWA from "../../../_components/SelectHWA/SelectHWA";
import { capitalizeFirstLetter, getTypeOfUrl, useReduxCacheAndDispatch } from "../../../_helpers";
import useNetwork from "../../../_helpers/useNetwork";
import { chatBoxService } from "../../../_services";
import { DailogHWA } from './../../../_components';
import CarouselList from "./CarouselList";
import "./index.css";
import useUploadDocumentData from "./UploadDocumentHooks";
import UploadDocumentThumbnail from "./UploadDocumentThumbnail";

function UploadDocument() {
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const networkState = useNetwork();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));


  const { online } = networkState;

  const uploadDocumentStatus = useSelector((state) => state.homeState.uploadDocumentStatus);
  const { previewUrls, uploadedDocuments, activeId, createPreviewUrls, removeDoc, changeActiveItem, documentListTags, updateCacheWithLoader, getSendFilePayload, updateSelectedFileProps } = useUploadDocumentData();
  const hospitalId = useSelector((state) => state.checklistSummary.selectedHospitalId);
  const { userId, name, roles: { role } } = useSelector((state) => state.authentication.user);
  const showGeneralChatMessages = useSelector((state) => state.homeState.showGeneralChatMessages);
  const privateChatMessagesActive = useSelector((state)=> state.chatBox.privateChatMessagesActive)
  const { documentCheck } = useSelector(state => state.config.versionConfigData.result[0]);
  const { data } = useSelector(state => state.config.documentList)

  const dispatch = useDispatch();
  const activeTab = useSelector(state => state.homeState.activeTab)
  const [fileName, setFileName] = useState(uploadDocumentStatus["key"] || 'selectDocument');
  const [showUploadDocument, setShowUploadedDocument] = useState(false);
  const [hardCodedDocumentTagging,setHardCodedDocumentTagging]=useState([])
  const [atTheTimeOf, setAtTheTimeOf] = useState('selectAtTheTimeOf')
  const [amountCollected, setAmountCollected] = useState('')

  const atTheTimeOfOptions = [
    {
      title:'Admission',
      key:'admission'
    },
    {
      title:'Discharge',
      key:'discharge'
    },
]

  const handleClose = () => {
    console.log('handleclose is called')

    dispatch(homeStateActions.setUploadDocumentStatus({ status: false }));
  };

  // to get the document tags
  useEffect(() => {
    fileUploadClick();
    initialiseSelectedFileProps()
    if(privateChatMessagesActive){
      updateSelectedFileProps('Attached File')
      setFileName('Attached File')
    }
  }, []);

  const initialiseSelectedFileProps = () => {

    if (uploadDocumentStatus['key']) {
      var modifiedData=[...data]
      modifiedData.push({"Key":"preAuthApprovalLetter","title":"Pre Auth Approval Letter"},{"Key":"queryLetterReply","title":"Query Letter"},{"Key":"declineLetter","title":"Decline Letter"})
      setHardCodedDocumentTagging([{"Key":"preAuthApprovalLetter","title":"Pre Auth Approval Letter"},{"Key":"queryLetterReply","title":"Query Letter"},{"Key":"declineLetter","title":"Decline Letter"}])
      updateSelectedFileProps(modifiedData?.find(item => {
        if (item.Key === uploadDocumentStatus["key"]) {
          console.log('dragon dragon got the key')
          return true
        }
      }))
    }
  }
  const fileUploadClick = () => {
    document.getElementById("uploadFileInput").value = null;
    document.getElementById("uploadFileInput").accept =
      acceptedDocuments[uploadDocumentStatus["docType"]];
    if (uploadDocumentStatus["docType"] === 'image' && uploadDocumentStatus["capture"] === true) {
      document.getElementById("uploadFileInput").capture = 'camera'
    }
    document.getElementById("uploadFileInput").click();
  };

  const acceptedDocuments = { image: "image/jpeg,image/jpg,image/png", excel: ".xlsx", pdf: ".pdf", doc: ".doc,.docx", csv: ".csv", video: "video/x-msvideo, .avi, video/x-matroska, .mkv, video/webm, video/mp4 ,video/quicktime,.MOV" };

  // to handle close without uploading the file
  const initialize = () => {
    console.log('input is initialised')
    document.body.onfocus = checkIt;
  };
  const checkIt = () => {
    if (document.getElementById("uploadFileInput") && document.getElementById("uploadFileInput").files.length) {
      console.log('input is closed 1')
    } else {
      console.log('input is closed 2')
      // handleClose();
    }
    document.body.onfocus = null;
  };
  // ********************************************

  const openUploadDocument = (docs) => {
    console.log('it is checking documents')
    if (!checkDocType(docs, uploadDocumentStatus["docType"])) {
      toast.error(`Please select documents of type ${uploadDocumentStatus["docType"]} only`)
      return
    }

    setShowUploadedDocument(true);
    createPreviewUrls({ uploadDocumentStatus: uploadDocumentStatus }, docs);
  };

  const docTypeConfig = {
    image: ["png",'jpeg','jpg','image'],
    excel: ["xlsx", "xml",'excel'],
    pdf: ["pdf"],
    doc: ["doc", "docx", 'msword'],
    csv: ["csv"],
    video: ["video/x-msvideo",'video',".avi", "video/x-matroska", ".mkv", "video/webm", "video/mp4", 'video/quicktime']
  }

  const checkDocType = (docs, docType) => {
    for (let i = 0; i < docs.length; i++) {
      const type = getTypeOfUrl({url:docs[i].name})
      // const checkDoc = docTypeConfig[docType]?.some(item => type.includes(item))
      const checkDoc = type===docType

      if (checkDoc === false) {
        return false
      }
    }
    return true
  }
  const showUploadDocumentTagging = () => {

    if ((showGeneralChatMessages && activeTab === 'home') || uploadDocumentStatus.disableDocumentTagging === true || uploadDocumentStatus["docType"] === 'video') {
      return false
    }
    return true
  }

  const sendFile = () => {
    if ((!fileName || fileName.length === 0 || fileName === 'selectDocument') && showUploadDocumentTagging()) {
      toast.error('Select Doc Type for the documents')
      console.log('this is fileName')
      return
    }

    const payload = getSendFilePayload({ uploadDocumentStatus: uploadDocumentStatus, type: activeTab })

    if (!payload) {
      return
    }

    const { reqPayload, addMessagePayload } = payload
    if(fileName==='extraCash/Cheque'){
      if(!atTheTimeOf || atTheTimeOf.length === 0 || atTheTimeOf === 'selectAtTheTimeOf'){
        toast.error('Select at the time of ?')
        return
      }
      
      if(amountCollected === null || isNaN(amountCollected) || amountCollected === '' || amountCollected < 0){
        toast.error('Amount should not be empty or negative')
        return
      }

      reqPayload.atTheTimeOf = atTheTimeOf
      addMessagePayload.atTheTimeOf = atTheTimeOf
      reqPayload.amountCollected = amountCollected
      addMessagePayload.amountCollected = amountCollected
      reqPayload.messageBody = `${fileName} of ${reqPayload.attributes?.patient_name} || Amount Collected: ${amountCollected} || At the time of: ${capitalizeFirstLetter(atTheTimeOf)}`
      addMessagePayload.MessageBody = `${fileName} of ${addMessagePayload.Attributes?.patient_name} || Amount Collected: ${amountCollected} || At the time of: ${capitalizeFirstLetter(atTheTimeOf)}`
    }

    if (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') { 
      updateCacheWithLoader({ uploadDocumentStatus: uploadDocumentStatus });
      chatBoxService.sendFile(reqPayload).then((res) => {
      });
    } else if (uploadDocumentStatus.typeOfUpload === 'uploadHospitalLogo') {
      dispatch(pcScoreActions.uploadHospitalLogoAction(reqPayload))
    } else if (uploadDocumentStatus.typeOfUpload === 'uploadHospitalDocuments') {
      dispatch(pcScoreActions.uploadHospitalDocumentsAction(reqPayload))
    } else if (uploadDocumentStatus.typeOfUpload === 'uploadPodDocument') {
      dispatch(chatBoxActions.uploadPodDocuments(reqPayload))
    }else {
      dispatch(
        chatBoxActions.sendFile(
          reqPayload,
          addMessagePayload,
          online,
          showGeneralChatMessages,
          documentCheck,
          null,
          privateChatMessagesActive
        )
      );
};
    handleClose();


}
const handleAmountCollected = (e) =>{
  const { value } = e.target;
  if (value.length <= 6) {
    setAmountCollected(value);
  }
}

const handleSelectDocument= (e) => {
  if(uploadedDocuments.length>1 && e.target.value==='extraCash/Cheque'){
    setFileName('selectDocument')
    toast.error('Could not upload more than one file for Extra Cash/Cheque')
  }
  else{
    setFileName(e.target.value)
  }
}

  return (
    <>
      <DailogHWA
        open={showUploadDocument}
        onClose={() => {
          handleClose();
        }}
        title={'Documents'}
        PaperProps={{ sx: { maxWidth: 'unset', width: ['unset', 'unset', 'unset', '47vw', '890px'], height: ['unset', 'unset', '75vh'] } }}
      >

        <Box className="uploadDocumentBody" sx={{ flexDirection: ["column", "column", "row"], p: ['0px 0px 0px 0px', '5', '15px 25px 35px 25px'], overflowY: "auto", height: "100%" }} >
          <Box className="uploadDocLeftContainer" sx={{ width: ['100%', '100%', '50%'], gap: "10px" }}>
            {showUploadDocumentTagging() && <Box sx={{ width: ['100%', '100%', '85%'] }} className="uploadDocumentSelect">
            {!privateChatMessagesActive &&
            <>
              <SelectHWA value={fileName}
                placeholder="Select Type Of Document" label="Select Type Of Document" disabled={uploadDocumentStatus["key"] ? true : false}
                onChange={handleSelectDocument}
              >
                <MenuItem disabled value='selectDocument'>
                  Select Type Of Document
                </MenuItem>
                {uploadDocumentStatus["key"] && (

                  <MenuItem value={fileName}>
                    {data?.find(item => {
                      { console.log('dragon dragon', item.Key, uploadDocumentStatus["key"]) }
                      if (item.Key === uploadDocumentStatus["key"]) {
                        console.log('dragon dragon got the key')
                        return true
                      }
                    })?.title
                    }
                  </MenuItem>
                )}
                {documentListTags?.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        updateSelectedFileProps(item);
                      }}
                      value={item?.Key}
                    >
                      {item?.title}
                    </MenuItem>
                  );
                })}
                {uploadDocumentStatus["key"] &&hardCodedDocumentTagging.map(item=>{
                return (
                  <MenuItem
                  onClick={() => {
                    updateSelectedFileProps(item);
                  }}
                  value={item?.Key}
                >
                  {item?.title}
                </MenuItem>
                )})}
              </SelectHWA>
              {fileName==='extraCash/Cheque' ?
              <div>
                <SelectHWA value={atTheTimeOf} 
                placeholder="Select At the time of" label="Select At the time of"
                onChange={(e) => setAtTheTimeOf(e.target.value)}
                formProps={{sx: { mt: "10px" } }}
                disabled={false}
              >
                 <MenuItem disabled value='selectAtTheTimeOf'>
                  Select At the time of
                </MenuItem>
                {atTheTimeOfOptions?.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        // updateSelectedFileProps(item);
                      }}
                      value={item?.key}
                    >
                      {item?.title}
                    </MenuItem>
                  );
                })}
              </SelectHWA>
              <input
              name="amount"
              id="amount"
              placeholder="Amount Collected"
              className="collectedAmountInput"
              value={amountCollected !== null ? amountCollected : ''}
              onChange={handleAmountCollected}
              type="number"
              inputMode="numeric"
            />
            </div>
                : ''}
              </>
            }

            </Box>}
            <Box sx={{ display: ['none', 'none', 'block'], fontWeight: '600', fontSize: ['14px', '14px', '14px', '14px', '20px'] }} className="leftContainerHeading">Preview</Box>
            {/* height: ['265px', '265px', '57vh'] */}
            <Grid container sx={{ padding: { xs: '5px 5px 24px 5px', lg: '10px 10px 37px 10px', xl: '20px 20px 43px 20px' }, height: ['265px', '265px', 'calc( 100% - 100px )'], background: "#f5f5f5", position: 'relative', borderRadius: "5px" }}>

              <Box sx={{ margin: "auto", height: "100%", width: "100%", background: 'white' }} className="leftContainerPreview" >
                <UploadDocumentThumbnail
                  previewUrl={previewUrls[activeId]}
                  uploadedDocument={uploadedDocuments[activeId]}
                  bigThumbnail={true}
                  docType={uploadDocumentStatus["docType"]}
                  showClose={true}
                />
              </Box>
              <Box sx={{ display: (uploadedDocuments[activeId]?.name) ? 'flex' : 'none', fontSize: "10px", textOverflow: "ellipsis", whiteSpace: "nowrap", justifyContent: "center", width: "100%", overflow: "hidden", fontWeight: "600", color: "#7A7A7A", position: 'absolute', left: "0px", bottom: "0px" }}>
                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: { xs: '10px', lg: '12px', xl: '16px' }, p: { xs: '5px 0px', lg: '10px 0px' }, lineHeight: '1.4' }}>
                  {uploadedDocuments[activeId]?.name}
                </Typography>
              </Box>
            </Grid>
          </Box>
          <Box className="uploadDocRightContainer" sx={{ display: "flex", justifyContent: ['space-between', 'space-between', 'normal'], paddingLeft: ['0px', '0px', '60px'], width: ['100%', '100%', '50%'], gap: "20px" }}>
            <Box sx={{ display: ["none", "none", 'block'], textAlign: "left", pl: "10px", fontWeight: '600', fontSize: ['14px', '14px', '14px', '14px', '20px'] }} className="rightContainerHeading ">
              Documents to be uploaded
            </Box>

         

            <Box sx={{ display: ['none', 'none', 'flex'], flexDirection: "column", gap: "20px", height: "44vh" }} className="thumbnailList">
              {uploadedDocuments.map((item, id) => {
                return (
                  <UploadDocumentThumbnail
                    previewUrl={previewUrls[id]}
                    uploadedDocument={item}
                    docType={uploadDocumentStatus["docType"]}
                    changeActiveItem={changeActiveItem}
                    id={id}
                    desktopUploadThumbnail={true}
                    selected={id === activeId ? true : false}
                    removeDoc={removeDoc}
                    showClose={true}
                    showSelected={true}
                    activeId={activeId}
                  />
                );
              })
              }
            </Box>
            {<CarouselList showUploadAnother={!uploadDocumentStatus.disableDocumentTagging && fileName!=='extraCash/Cheque'} uploadedDocuments={uploadedDocuments} previewUrls={previewUrls} uploadDocumentStatus={uploadDocumentStatus} changeActiveItem={changeActiveItem} activeId={activeId} removeDoc={removeDoc} fileUploadClick={fileUploadClick} showClose={true} />}
            <Box className="uploadDocumentActions" sx={{}}>
              {(!uploadDocumentStatus.disableDocumentTagging && fileName!=='extraCash/Cheque') && <ButtonHWA
                sx={{

                  display: ['none', 'none', 'flex']
                }}
                className="uploadActionButton"
                onClick={() => fileUploadClick()}
              >
                Add Another
              </ButtonHWA>
              }
              <ButtonHWA
                variant="contained"
                sx={{

                }}
                className="uploadActionButton"
                onClick={(e) => sendFile()}
              >
                Upload
              </ButtonHWA>
            </Box>
          </Box>
        </Box>

      </DailogHWA>
      <input
        style={{ visibility: "hidden", width: "0px" }}
        type="file"
        id="uploadFileInput"
        multiple
        onChange={(e) => {
          openUploadDocument(e.target.files);
          console.log('change is called')
          // e.target.value=null
          // e.target.files=null
        }}
        onClick={() => initialize()}
      />
    </>
  );
}

export default UploadDocument;
