import AnalyticsIcon from "@mui/icons-material/Analytics";
import ArticleIcon from "@mui/icons-material/Article";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { Box, Button, CardMedia, CircularProgress, FormControl, FormControlLabel, FormLabel, InputAdornment, MenuItem, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery } from "@mui/material";
import Paper from '@mui/material/Paper';
import { Container } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import videoDownload from '../../../../assets/videoDownload.svg';
import { cacheStorageActions, chatBoxActions } from "../../../../_actions";
import { DailogHWA } from "../../../../_components";
import ButtonHWA from "../../../../_components/ButtonHWA/ButtonHWA";
import SelectHWA from "../../../../_components/SelectHWA/SelectHWA";
import { convertNumberIntoWords, mainCacheConstants } from "../../../../_helpers";
import useNetwork from "../../../../_helpers/useNetwork";
import { chatBoxService } from "../../../../_services";
import { OwnerInput } from "../../../OwnerInfo/ownerComponent";
import MessageText from "../components/MessageBody";
import "./index.css";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import CalendarIcon from '@mui/icons-material/CalendarToday'; 

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

function ChatMessage({
  item,
  idx,
  id2,
  selectedMsgId,
  setSelectedMessage,
  scrollToChat,
  retrySendMessage,
  downloadChatMessage,
  showChatMedia,
  showGeneralChatMessages,
  generalGroupEditors,
  role,
  handleClick2,
  setSelectedMsgId,
  messageActions,
  handleUploadDocumentClick,
  handleDirectPreviewDocs
}) {
  // console.log("chatConsole",item)
  let { Author, AuthorName, MessageBody, DateCreated, AuthorRole, Attributes, hasMedia, Media, MessageSid, IsSync, Retry = false, } = item || {};
  const networkState = useNetwork();
  const { online } = networkState;

  const dispatch = useDispatch()
  const chatBox = useSelector(state => state.chatBox)
  var loggedInUser = false;
  const loggedUserNumber = localStorage.getItem("userMobile");
  const privateChatMessagesActive = useSelector((state)=>state.chatBox.privateChatMessagesActive);
  const patientName = useSelector((state) => state.patients.patientInfo.allInfo.FirstName)
  const paymentMode =  useSelector((state) => state.patients.patientInfo.allInfo.paymentMode)
  let [approveAmount, setApproveamout] = useState(-1)

  if (loggedUserNumber === Author) {
    loggedInUser = true;
    AuthorName = "You";
  }

  function isDeletedMessage() {
    return Attributes && !Attributes.botKey && Attributes.isVisible == false;
  }
  function isBotMessage() {
    return !!(Attributes && Attributes.botKey);
  }
  function isSystemGeneratedMessage() {
     MessageBody = MessageBody.replace('{patientName}',patientName)
    return !!(Attributes && Attributes.botMessageDetails)
  }

  function isVisibleMessage() {
    return !!(Attributes && !isBotMessage() && !isSystemGeneratedMessage() && Attributes.isVisible == true);
  }
  function isReplyMessage() {
    return !!Attributes.message_reply_text;
  }
  function isRetryMessage() {
    return !!(Retry == true);
  }
  function showLoader() {
    return IsSync == false && Retry === false && online && hasMedia
  }
  function isMediaBeforeDownload() {
    return !!(hasMedia && !item.mediaUrl);
  }
  function isMediaAfterDownload() {
    return !!(hasMedia && item?.mediaUrl);
  }
  function isTableMessage() {
    return !!(Attributes && Attributes.cashlessPodData);
  }
  function isDocApproval() {
    return !!(Attributes.docApproval == true && Author != localStorage.getItem('userMobile') && role.toLowerCase() === 'ins')
  }
  // need to add this role check ======>   role.toLowerCase().includes('central')
  const { otherRoles } = useSelector(state => state.authentication.user.roles)
  function isOpthalRevamp() {

    const checkRole = () => {

      var allRoles = otherRoles ? otherRoles : []
      allRoles.push(role)
      if (allRoles) {
        for (let i = 0; i < allRoles.length; i++) {
          if (allRoles[i].toLowerCase().includes("central")) {
            return true
          }
        }
      }
      return false
    }
    return !!(Attributes.opthalApproval == true && Author != localStorage.getItem('userMobile') && checkRole())
  }

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setApproveamout(() => value);
  };

  // Dialog Template====>    ["OPTHAL_TEMPLATE","BOT_MESSAGE_PREAUTH"]
  const [dialogTemplate, setDialogTemplate] = useState("")
  const GetDialogBody = () => {
    const [stickerValue, setStickerValue] = useState('')
    const [stickerCode, setStickerCode] = useState('')
    const [declineReason, setDeclineReason] = useState('')
    const [reqObj, setReqObj] = useState({})
    const [otherObj,setOtherObj] = useState({})
    const otpMobile = useSelector(state => state?.authentication?.user?.otpMobile)
    const opthalFormData = useSelector(state => state?.config?.opthalFormData)


    useEffect(() => {
      setReqObj({})

    }, [showActionDialog])
    return <>
      {
        dialogTemplate === "OPTHAL_TEMPLATE" && <>
          {showApproveBody === true &&
            <Box>
              <OwnerInput labelProps={{ className: "required" }} inputProps={{ sx: { height: ['30px', '30px', '40px', '40px', '50px'] } }} label="Sticker Value" id="bank-name" onChange={(e) => setReqObj((curr) => ({ ...curr, 'stickerValue': e.target.value }))}
                value={reqObj.stickerValue} />

              <Box component={`label`} sx={{ mb: ['10px', '12px', '13px', '13px', '15px'], color: '#3D7CC9', fontSize: ['14px', '14px', '16px', '18px', '20px'], fontWeight: [600, 600, 500] }}>Select Brand of Lens</Box>
              <SelectHWA formProps={{ fullWidth: true, sx: { mb: [2, 2, 2, 2, 3], mt: "10px" } }}
                selectProps={{
                  fullWidth: true,
                  value: reqObj.lensBrand,
                  sx: { height: ['30px', '30px', '50px'], fontSize: ['14px', '14px', '16px', '18px', '20px'], backgroundColor: '#F5F5F5' },
                  inputProps: { fontSize: ['12px', '14px', '16px', '18px', '20px'] }
                }}
                onChange={(e) => setReqObj((curr) => ({ ...curr, 'lensBrand': e.target.value }))}
              >
                <MenuItem value="" disabled>Type of Lens Brand</MenuItem>
                {opthalFormData && opthalFormData['LensBrand']?.map(item => {
                  return <MenuItem value={item} selected={reqObj.lensBrand === item}>{item}</MenuItem>
                })}
              </SelectHWA>
              
              {reqObj?.lensBrand === "Other" && <OwnerInput
                placeholder="Type Other lens brands"
                inputProps={{ sx: { height: ['30px', '30px', '40px', '40px', '50px'] } }} 
                id="bank-name" 
                onChange={(e) => setOtherObj((curr) => ({ ...curr, 'lensOtherBrand': e.target.value }))} 
                value={otherObj.lensOtherBrand} />}

              <Box component={`label`} sx={{ mb: ['10px', '12px', '13px', '13px', '15px'], color: '#3D7CC9', fontSize: ['14px', '14px', '16px', '18px', '20px'], fontWeight: [600, 600, 500] }}>Select Type of Lens</Box>
              <SelectHWA formProps={{ fullWidth: true, sx: { mb: [2, 2, 2, 2, 3], mt: "10px" } }}
                selectProps={{
                  fullWidth: true,
                  value: reqObj.typeOfLens,
                  sx: { height: ['30px', '30px', '50px'], fontSize: ['14px', '14px', '16px', '18px', '20px'], backgroundColor: '#F5F5F5' },
                  inputProps: { fontSize: ['12px', '14px', '16px', '18px', '20px'] }
                }}
                onChange={(e) => setReqObj((curr) => ({ ...curr, 'typeOfLens': e.target.value }))}
              >
                <MenuItem value="" disabled>Type of Lens</MenuItem>
                {opthalFormData && opthalFormData['LensType']?.map(item => {
                  return <MenuItem value={item} selected={reqObj.typeOfLens === item}>{item}</MenuItem>
                })}
              </SelectHWA>
              
              {reqObj.typeOfLens === "Other" && <OwnerInput 
                placeholder="Type lens"
                inputProps={{ sx: { height: ['30px', '30px', '40px', '40px', '50px'] } }} 
                id="bank-name" 
                onChange={(e) => setOtherObj((curr) => ({ ...curr, 'otherLens': e.target.value }))} 
                value={otherObj.otherLens} />}

              <ButtonHWA variant='contained' onClick={() => approveOpthalApi({ item: item, dispatch: dispatch, chatBox: chatBox, isMediaAfterDownload, setShowActionDialog, reqObj, otpMobile, otherObj })}>Approve</ButtonHWA>
            </Box>}
          {showApproveBody === false &&
            <Box>
              <Box component='textarea'
                aria-label="minimum height"
                minRows={3}
                placeholder="Decline Reason"
                sx={{
                  resize: "none",
                  fontSize: ['12px', '14px', '16px', '18px', '20px'],
                  fontWeight: '500',
                  fontFamily: 'inherit',
                  lineHeight: 1.4,
                  height: 'calc(4em * 1.4)',
                  border: '1px solid ',
                  borderColor: '#666A70',
                  borderRadius: '6px',
                  overflow: 'auto',
                  px: ['10px', '14px', '16px', '18px', '20px'],
                  py: ['10px', '14px', '16px', '18px', '20px'],
                  width: "100%",
                  mb: '10px',
                  color: 'rgb(0, 38, 62)',
                  "&:focus-visible": {
                    outlineColor: '#1976d2'
                  }
                }}
                value={declineReason}
                onChange={(e) => setDeclineReason(e.target.value)}
              />
              <ButtonHWA variant='contained' onClick={() => declineOpthal({ declineReason, item: item, dispatch, setShowActionDialog, chatBox: chatBox, reqObj, otpMobile })}>Decline</ButtonHWA>
            </Box>
          }
        </>
      }
      {
        dialogTemplate === "BOT_MESSAGE_PREAUTH" && <>
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <Typography sx={{ color: "#965ec7" }}>{dialogDetails.botMessageQuestion}</Typography>
            <Box sx={{ display: "flex" }}>
              Reply : <Typography sx={{ fontWeight: "600", ml: "10px",textTransform:"capitalize" }}>{dialogDetails?.botMessageAnswerInWords ? dialogDetails?.botMessageAnswerInWords :  dialogDetails.botMessageAnswer}</Typography>
            </Box>
          </Box>
          <Box sx={{ justifyContent: "space-between", display: "flex", gap: "10px", mt: "20px" }}>
            <ButtonHWA onClick={() => setShowActionDialog(false)}>Cancel</ButtonHWA>
            <ButtonHWA onClick={() => { setShowActionDialog(false); dispatch(chatBoxActions.sendBotMessageResponse({ channelSid: item.ChannelSid, messageSid: item.MessageSid, attributes: item.Attributes ? JSON.stringify(item.Attributes) : "", templateAnswer: dialogDetails.botMessageAnswer, leadId: item.LeadId, hospitalId: item.hospital_id, templateKey: item?.Attributes?.botMessageDetails?.templateKey,appointmentId:item?.Attributes?.appointment_id,messageIndex:item.MessageIndex })) }} variant="contained">Send</ButtonHWA>
          </Box>
          <></>
        </>
      }

    </>
  }
  const [showActionDialog, setShowActionDialog] = useState(false)
  const [showApproveBody, setShowApproveBody] = useState(null)
  const [dialogTitle, setDialogTitle] = useState(null)
  const [dialogDetails, setDialogDetails] = useState(null)



  const BotMessageBody = () => {
    const { Attributes: { botMessageDetails } } = item
    return (
      <>
        {
          <Box
            className={`chat__message ${loggedInUser ? "chat__reciever " : ""} botMessage ${loggedInUser && privateChatMessagesActive && "private_message_sender"}`}
            aria-describedby={selectedMsgId == idx ? id2 : ""}
            sx={{
              border: "1px solid blue",
              borderRadius: "10px",
              padding: ".5rem",
              pb: "0px!important"
            }}
          >
            <Box sx={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
            }}>
              <SmartToyOutlinedIcon
                sx={{
                  color: "#f8be32",
                }}
              />
              <Typography>
                {MessageBody} by {AuthorName}
                {!!AuthorRole && (
                  <Typography style={{ whiteSpace: "nowrap", display: "inline-block" }}>({AuthorRole})</Typography>
                )}
              </Typography>
            </Box>
            <MessageFooter
              loggedInUser={loggedInUser}
              DateCreated={DateCreated}
              isSync={IsSync}
              Retry={Retry}
              item={item}
              privateChatMessagesActive={privateChatMessagesActive}
            />
          </Box>
        }

      </>
    );
  }

  const SystemGeneratedMessageBody = () => {
    const { Attributes: { botMessageDetails: { confirmationDialogHeading = "Bot Message Confirmation",documentTaggingKey,messageOptions, messageResponseDetails, messageAnswer="", actionType: { inputType, actionText,replyType='text' } = {}, actionType } }, DateCreated, MessageSid, waitingForApiResponse,MessageIndex } = item
    const patients = useSelector((state) => state.patients);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const handleIconClick = () => {
      setIsPickerOpen((prevOpen) => !prevOpen);
    };
    var date = moment(DateCreated).calendar(null, {
      nextDay: "hh:mm A, DD-MMM",
      nextWeek: "hh:mm A, DD-MMM",
      lastDay: "hh:mm A, DD-MMM",
      lastWeek: "hh:mm A, DD-MMM",
      sameElse: "hh:mm A, DD-MMM",
    })
    if (date.includes("Today at")) {
      date = date.replace("Today at", '')
    }
    
    const [inputValue, setInputValue] = useState(messageAnswer);
    const [dateTimeInput,setDateTimeInput] = useState(null);

    const saveInputValue = (val, type = '') => {
      console.log({val})
    if (type === 'dateTime') {
        setInputValue(dayjs(val));
        setDateTimeInput(dayjs(val))
        // handleChange({},'dateTime')
    } else {
        setInputValue(val.target.value);
    }
}

  // console.log("DateTimeInput",dateTimeInput)
    const handleChange = (event,type='') => {
      setDialogTemplate("BOT_MESSAGE_PREAUTH")
      setDialogTitle(confirmationDialogHeading)
      console.log("dayJs",inputValue,dayjs(inputValue).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
      // botMessageAnswer condition is written for two different types bot message 1. radio btn; 2. inputfield 
      setDialogDetails({ botMessageQuestion: MessageBody, botMessageAnswer: (messageOptions && type!='dateTime' )? event.target.value : type==='dateTime' ? dayjs.utc(inputValue).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : inputValue, 
      botMessageAnswerInWords: (!messageOptions && type!='dateTime') ? convertNumberIntoWords(inputValue) : type==='dateTime' ? dayjs(inputValue).format('MM/DD/YYYY hh:mmA') : "" })
      setShowActionDialog(true)
    };

    return (
      <>
        <Box
          className={`chat__message ${privateChatMessagesActive && "private_message_recieved"} ${loggedInUser ? "chat__reciever " : ""} botMessage ${loggedInUser && privateChatMessagesActive && "private_message_sender"}`}
          aria-describedby={selectedMsgId == idx ? id2 : ""}
          sx={{
            boxShadow: "0px 3px 6px #00000029",
            borderRadius: "10px",
            padding: ".5rem",
            display: "flex",
            maxWidth: ["215px", "215px", "300px"],
            gap: ".5rem",
            color: "#646368",
            flexDirection: "column"
          }}
          key={MessageSid}
        >
          {isReplyMessage() && (
            <ReplyMessageBody item={item} scrollToChat={scrollToChat} />
          )}
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#965EC7!important", fontSize: "inherit", fontFamily: "inherit" }}>         {MessageBody}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              sx={{ paddingLeft: "10px", }}
              onChange={handleChange}
            >
              {messageOptions?.map(item => (
                <FormControlLabel
                  checked={messageResponseDetails && item.isSelected ? true : undefined}
                  disabled={messageResponseDetails ? true : undefined}
                  value={item.value}
                  control={
                    <Radio sx={{
                      color: '#965EC7',
                      '&.Mui-checked': {
                        color: '#965EC7',
                      },
                      "& svg": {
                        width: "0.6em",
                        height: "0.6em",
                        backgroundColor: "white",
                        borderRadius: "100%",
                        border: "1px solid",
                      },
                      "& svg[data-testid=RadioButtonUncheckedIcon]": {
                        "& path": {
                          fill: "white"
                        }
                      },
                    }} />}
                  label={
                    <Typography sx={{
                      fontSize: "inherit",
                      fontFamily: "inherit",
                      color: messageResponseDetails && !item.isSelected ? "rgb(100 99 104 / 42%)" : "inherit"
                    }}>
                      {item.value}
                    </Typography>} />))}
            </RadioGroup>
          </FormControl>
          {waitingForApiResponse && <Box sx={{ position: "absolute", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "-9px", background: "#f5f5f57a" }}>
            <CircularProgress sx={{ fontSize: "30px" }} />
          </Box>}
          {actionType && <>
            {inputType == "button" && <Box sx={{ width: "100%" }}>
              <ButtonHWA sx={{ fontSize: "inherit" }} onClick={(e) => {
                handleUploadDocumentClick({
                  event: e, documentKey:documentTaggingKey,openDialog: true, item: patients.patientInfo, typeOfUpload: "uploadQueryLetter", uploadDetails: {
                    extraMessageAttributes: {
                      "reply_role": role,
                      "reply_user_type": "",
                      "message_reply_index": MessageIndex,
                      "message_reply_text": MessageBody, uploadQueryLetter: messageAnswer
                    }, currMessageDetails: item
                  },
                })
              }} variant="contained">{actionText}</ButtonHWA>
            </Box>}
            {inputType == "input" && replyType!='dateTime' &&
              <TextField
                type={replyType}
                disabled={messageResponseDetails ? true : undefined}
                sx={{
                  backgroundColor: `${messageResponseDetails?"inherit":"white"}`,
                  '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                  }
                }}
                inputProps={{
                  sx: {
                    padding: "4.5px 10px",
                    height: "unset",
                    color: "#965EC7",
                    fontSize:"12px",
                    fontWeight:"600",
                    "&[disabled]":{
                      "-webkit-text-fill-color":"unset"
                    }
                  }
                }}
                value={inputValue}
                onChange={saveInputValue}
                InputProps={{
                  sx: {
                    paddingInline: "0"
                  },
                  startAdornment: (
                    <InputAdornment
                      position="end" sx={{ order: "2" }}>
                      {!messageResponseDetails && <SendIcon
                        id="sendIcon"
                        sx={{ fontSize: "30px", width: "22px", cursor: 'pointer', marginRight: "10px", fill: `${inputValue ? "#965EC7" : "#0000008a"}` }}
                        onClick={(e) => inputValue && handleChange(e)}
                      />}
                    </InputAdornment>)
                }}
              />
            }
            {
              inputType == "input" && replyType=='dateTime' && 
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDateTimePicker
                  label={actionText}
                  open={isPickerOpen}
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                  className="my-mobile-date-time-picker"
                  renderInput={(params) => (
                    <div  className="my-mobile-date-time-picker-input">
                      <input {...params.inputProps} />
                      <span onClick={handleIconClick}>
                         <CalendarIcon className="my-mobile-date-time-picker-icon" 
                        />
                      </span>
                    </div>
                  )}
                  value={inputValue || null}
                  onChange={(val)=>saveInputValue(val,'dateTime')}
                  onAccept={(e)=>handleChange(e,'dateTime')}
                />
              </LocalizationProvider>   
            }
          </>
          }
        </Box>
        <div className={`messageCreator ${privateChatMessagesActive && "privateMessageCreator"}`}>
          <Box sx={{ fontSize: "12px", fontStyle: "italic" }}>
            {<p style={{ marginBottom: "unset", color: "#965EC7" }}>{`System Generated; ${date}`}</p>}
            {messageResponseDetails && <p style={{ fontWeight: "600", marginTop: "unset" }}>{messageResponseDetails}</p>}
          </Box>
        </div>
      </>
    );
  };
  return (
    <>
      {isDeletedMessage() && (
        <DeletedMessageBody
          loggedInUser={loggedInUser}
          idx={idx}
          id2={id2}
          selectedMsgId={selectedMsgId}
          privateChatMessagesActive={privateChatMessagesActive}
        />
      )}
      {isBotMessage() && (
        <BotMessageBody
        />
      )}
      {isSystemGeneratedMessage() && (
        <SystemGeneratedMessageBody
        />
      )}
      {isVisibleMessage() && (
        <div className={`chat__message ${privateChatMessagesActive && "private_message_recieved"} ${loggedInUser ? "chat__reciever" : ""} ${loggedInUser && privateChatMessagesActive && "private_message_sender"}`}
          aria-describedby={selectedMsgId == idx ? id2 : ""}
          onClick={() => setSelectedMessage(item)}
        >
          {isRetryMessage() && (
            <RetryMessageBody retrySendMessage={retrySendMessage} item={item} showGeneralChatMessages={showGeneralChatMessages}  privateChatMessagesActive={privateChatMessagesActive} />
          )}
          {isReplyMessage() && (
            <ReplyMessageBody item={item} scrollToChat={scrollToChat} />
          )}

          {
            showLoader() && (
              <ShowLoaderBody item={item} />
            )
          }
          {isMediaBeforeDownload() && (
            <MediaBeforeDownloadBody
              downloadChatMessage={downloadChatMessage}
              item={item}
            />
          )}
          {isMediaAfterDownload() && (
            <MediaAfterDownloadBody
              item={item}
              loggedInUser={loggedInUser}
              showChatMedia={showChatMedia}
            />
          )}

          <MessageContent
            item={item}
            showGeneralChatMessages={showGeneralChatMessages}
            generalGroupEditors={generalGroupEditors}
            role={role}
            handleClick2={handleClick2}
            setSelectedMsgId={setSelectedMsgId}
            idx={idx}
            loggedInUser={loggedInUser}
            privateChatMessagesActive={privateChatMessagesActive}
          />
          {isTableMessage() && (
            <TableMessageBody item={item} scrollToChat={scrollToChat} handleUploadDocumentClick={handleUploadDocumentClick} handleDirectPreviewDocs={handleDirectPreviewDocs} />
          )}
          {isDocApproval() && (<DocApprovalBody approveAmount={approveAmount} handleAmountChange={handleAmountChange} checkForPaymentInput={paymentMode !== "Cash"} isMediaAfterDownload={isMediaAfterDownload} chatBox={chatBox} messageActions={messageActions} item={item} dispatch={dispatch} button1Text="Approve" button2Text="Suggest Changes" button1OnClick={(e) => approveDocChanges({ item: item, dispatch: dispatch, chatBox: chatBox, isMediaAfterDownload: isMediaAfterDownload, amount: Number(approveAmount) })} button2OnClick={(e) => suggestDocChanges({ item: item, messageActions: messageActions, isMediaAfterDownload: isMediaAfterDownload })} />)}
          {isOpthalRevamp() && (<DocApprovalBody approveAmount={approveAmount} handleAmountChange={handleAmountChange} checkForPaymentInput={paymentMode !== "Cash"} isMediaAfterDownload={isMediaAfterDownload} chatBox={chatBox} messageActions={messageActions} item={item} dispatch={dispatch} button1Text="Approve" button2Text="Decline"
            button1OnClick={() => {
              if (!isMediaAfterDownload()) {
                toast.error("Please Download First")
                return
              }
              setDialogTemplate("OPTHAL_TEMPLATE")
              setShowActionDialog(true); setShowApproveBody(true); setDialogTitle('Approve Discharge Summary')
            }}
            button2OnClick={() => {
              if (!isMediaAfterDownload()) {
                toast.error("Please Download First")
                return
              }
              setDialogTemplate("OPTHAL_TEMPLATE")
              setShowActionDialog(true); setShowApproveBody(false); setDialogTitle('Decline Discharge Summary')
            }} />)}
          <MessageFooter
            loggedInUser={loggedInUser}
            DateCreated={DateCreated}
            isSync={IsSync}
            Retry={Retry}
            item={item}
            privateChatMessagesActive={privateChatMessagesActive}
          />
        </div>
      )}

      <div className={`messageCreator ${privateChatMessagesActive && "privateMessageCreator"}`}>
        <Box component={`p`} sx={{ textAlign: loggedInUser ? 'right' : 'left', px: '7.5px', mt: '4px', mb: 0 }} className="chat__author_name">
          {!loggedInUser ? (
            <>
              {AuthorName && <span>{AuthorName}</span>} {AuthorRole && <span>({AuthorRole})</span>}
            </>
          ) : <span>You</span>}
        </Box>
      </div>
      <DailogHWA title={dialogTitle} open={showActionDialog} onClose={() => setShowActionDialog(false)} >{
        GetDialogBody()
      }</DailogHWA>
    </>
  );
}

export default ChatMessage;

const DeletedMessageBody = ({ loggedInUser, idx, id2, selectedMsgId,privateChatMessagesActive }) => {
  return (
    <div className={`chat__message ${privateChatMessagesActive && "private_message_recieved"} ${loggedInUser ? "chat__reciever " : ""} ${loggedInUser && privateChatMessagesActive && "private_message_sender"}`}
      aria-describedby={selectedMsgId == idx ? id2 : ""}
      style={{ paddingBottom: "1rem" }}
    >
      This message is deleted
    </div>
  );
};



const MessageFooter = ({ loggedInUser, DateCreated, isSync, Retry, item, privateChatMessagesActive }) => {
  return (
    <div className="messageFooter" style={{ fontSize: "15px!important" }}>
      <div className="timeStamp">
        <div
          className={`time ${privateChatMessagesActive && "private_time"}`}
          // style={{
          //   color: loggedInUser ? "white" : "",
          // }}
        >
          {moment(DateCreated).calendar(null, {
            nextDay: "hh:mm A, DD-MMM",
            nextWeek: "hh:mm A, DD-MMM",
            lastDay: "hh:mm A, DD-MMM",
            lastWeek: "hh:mm A, DD-MMM",
            sameElse: "hh:mm A, DD-MMM",
          })}
          &nbsp;
        </div>

        {Retry == false && (
          <div className="tick">
            {isSync == false && loggedInUser && (
              <svg viewBox="0 0 16 15" width="16" height="15">
                <path
                  fill="currentColor"
                  d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"
                ></path>
              </svg>
            )}
            {item && isSync != false && loggedInUser && (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="13.084" height="9" viewBox="0 0 13.084 9">
                  <path id="_7076555_ok_tick_check_approve_accept_icon" data-name="7076555_ok_tick_check_approve_accept_icon" d="M12.936,12.571a.279.279,0,0,0-.409.039L6.875,19.765l-1.65-1.374L9.478,13a.32.32,0,0,0-.036-.433.279.279,0,0,0-.409.039L3.382,19.765,1.51,18.207a.28.28,0,0,0-.408.051.32.32,0,0,0,.049.432l2.093,1.743a.278.278,0,0,0,.056.032.193.193,0,0,0,.124.034.284.284,0,0,0,.063-.007l.019-.007a.285.285,0,0,0,.041-.015c.008,0,.015-.01.022-.014a.264.264,0,0,0,.032-.021.3.3,0,0,0,.022-.023.273.273,0,0,0,.023-.023l1.2-1.526,1.886,1.57a.278.278,0,0,0,.056.032.271.271,0,0,0,.029.016.274.274,0,0,0,.1.017.284.284,0,0,0,.063-.007L7,20.485a.3.3,0,0,0,.041-.015l.022-.014a.289.289,0,0,0,.033-.022.268.268,0,0,0,.021-.021.3.3,0,0,0,.024-.024l5.832-7.385A.321.321,0,0,0,12.936,12.571Z" transform="translate(-0.499 -11.999)" fill="#45ff00" stroke="#45ff00" stroke-width="1" />
                </svg>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};


const ReplyMessageBody = ({ scrollToChat, item }) => {
  const { Attributes } = item;
  return (
    <>
      <div className="replyBody" onClick={() => scrollToChat(item)}>
        <div className="replyContainer">
          <div className="replyAuthor">
            <b>{Attributes.reply_name}</b>
          </div>
          <div className="replyText">{Attributes.message_reply_text}</div>
        </div>
        <div className="replyMedia">
          {Attributes["message_reply_media"] && (
            <div>
              {item.mediaUrl && item?.MediaType?.includes("image") && (
                <CardMedia
                  component="img"
                  sx={{ width: 40, height: 40 }}
                  image={item.mediaUrl && item.mediaUrl[0]}
                // alt="no preview available"
                ></CardMedia>
              )}
              {item.mediaUrl && !item?.MediaType?.includes("image") && (
                <div>
                  {!item.MediaType?.includes("application") && (
                    <ArticleIcon
                      style={{
                        height: "40px",
                        width: "40px",
                      }}
                    ></ArticleIcon>
                  )}
                  {item.MediaType?.includes("application") && (
                    <PictureAsPdfIcon
                      style={{
                        height: "40px",
                        width: "40px",
                      }}
                    ></PictureAsPdfIcon>
                  )}
                </div>
              )}

              {!item.mediaUrl && (
                <div>
                  <ArticleIcon
                    style={{
                      height: "40px",
                      width: "40px",
                    }}
                  ></ArticleIcon>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
const RetryMessageBody = ({ retrySendMessage, item, showGeneralChatMessages, privateChatMessagesActive }) => {
  return (
    <>
      <div className="retryContainer" style={{ height: item.Retry ? item.messageType === 'MEDIA' ? '46%' : '44%' : 'unset' }} onClick={() => retrySendMessage(item,showGeneralChatMessages,privateChatMessagesActive)}>
        <ReplayIcon> </ReplayIcon>Retry
      </div>
    </>
  );
};

const TableMessageBody = ({ item, handleUploadDocumentClick, handleDirectPreviewDocs }) => {
  const { Attributes: { cashlessPodData }, Attributes, ChannelSid, MessageSid } = item;
  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: "10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="messageTable">
              <TableCell sx={{ borderRadius: '0px 0px 0px 10px', borderBottom: "none" }} >Patient Name</TableCell>
              <TableCell align="right">Appointment ID</TableCell>
              <TableCell align="right">Doa</TableCell>
              <TableCell align="right">Zone</TableCell>
              <TableCell align="right">Claim Number</TableCell>
              <TableCell align="right">TPA Name</TableCell>
              <TableCell sx={{ borderRadius: '0px 0px 10px 0px', borderBottom: "none" }} align="right">Upload Document</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(cashlessPodData) && cashlessPodData?.map((row) => (
              <TableRow
                key={row.patientName}
                className="messageTableBody"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell sx={{ minWidth: "150px" }} component="th" scope="row">{row.patientName}</TableCell>
                <TableCell align="right">{row.appointmentId}</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{moment(row.doa).format('DD-MM-YYYY')}</TableCell>
                <TableCell align="right">{row.zone}</TableCell>
                <TableCell align="right">{row.claimNumber}</TableCell>
                <TableCell sx={{ minWidth: "150px" }} align="right">{row.tpaName}</TableCell>
                <TableCell align="right">
                  <Box sx={{ justifyContent: "space-between", display: "flex", gap: "10px" }}>

                    <ButtonHWA onClick={(e) => { handleUploadDocumentClick({ event: e, openDialog: true, typeOfUpload: "uploadPodDocument", visibleIcons: ['pdf'], uploadDetails: { appointmentId: row.appointmentId, attributes: Attributes, channelSid: ChannelSid, messageSid: MessageSid } }) }} sx={{ whiteSpace: "nowrap", position: 'relative' }} variant="contained">Upload
                      {row.podDocs && <CheckCircleIcon sx={{ position: 'absolute', top: "-17px", right: "-9px", color: '#0da00df2' }} />}
                    </ButtonHWA>
                    {row.podDocs && <ButtonHWA onClick={() => handleDirectPreviewDocs({ itemDetails: row, unsignedUrls: row.podDocs, title: "POD Documents" })} sx={{ whiteSpace: "nowrap" }} variant="contained">View</ButtonHWA>}
                  </Box>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>    </>
  );
};
const ShowLoaderBody = ({ item }) => {
  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", background: "transparent", position: "absolute", width: "100%", zIndex: 9 }}
      >
        <CircularProgress sx={{ fontSize: "30px" }} />
      </Box>
    </>
  );
};
const MediaBeforeDownloadBody = ({ item, downloadChatMessage }) => {
  return (
    <>
      <div className="msgMedia" style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            width: "120px"
          }}
          className="downloadMedia"
          style={{
            position: "absolute",
            background: "#666A70",
            borderRadius: "8px",
            padding: "4px 10px",
            color: "white",
            fontWeight: "600",
            fontSize: '14px',
            boxShadow: '0px 3px 6px #00000029'
          }}
          onClick={() =>
            downloadChatMessage(
              item.Media,
              item.MessageSid,
              item.Attributes.multiUrl
            )
          }
        >
          {!item.loadingMedia && (
            <Container
              disableGutters
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CloudDownloadOutlinedIcon
                style={{
                  marginRight: "4px",
                  marginBottom: "3px",
                }}
              ></CloudDownloadOutlinedIcon>
              <span>Download</span>
            </Container>
          )}

          {item.loadingMedia == true && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </Box>
        <div style={{}}>
          <ImageIcon
            style={{
              color: "black",
              fontSize: "40px",
            }}
          ></ImageIcon>
        </div>
      </div>
    </>
  );
};
const MediaAfterDownloadBody = ({ item, loggedInUser, showChatMedia }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  return (
    <>

      {console.log('item item', item)}
      <Box
        sx={{ maxWidth: ['200px', '200px', '277px'], flex: "display", justifyContent: "center", alignItems: "center", margin: "auto", marginBottom: "5px", minHeight: ['40px'] }}
        className="mediaContainer"
        onClick={() => {
          if (item.isSync == false && loggedInUser) {
            return
          } showChatMedia(item.mediaUrl, item.MediaType)
        }}
      >
        {item.MediaType?.includes("image") && (
          <>
            <img src={item.mediaUrl[0] || item.Media} alt="" />
            {item.mediaUrl.length > 1 && (
              <Box
                className="numDocsContainer"
                sx={{
                  position: "absolute",
                  left: loggedInUser ? isSmallScreen ? "-60px" : '-100px' : "",
                  right: loggedInUser ? "" : isSmallScreen ? "-60px" : '-100px',
                  marginTop: "-10px",
                  top: "0px"
                }}
              >
                +{item.mediaUrl.length - 1}
              </Box>
            )}
          </>
        )}
        {item.MediaType?.includes("video") && <Box sx={{ height: "40px", width: '50px', display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img src={videoDownload} style={{ height: "100%", width: "100%", objectFit: "contain" }}></img>
        </Box>
        }
        {item.MediaType?.includes("pdf") && (<PictureAsPdfIcon style={{ height: "50px", width: "50px", color: "black" }} />)}
        {!item.MediaType?.includes("pdf") &&
          item.MediaType?.includes("application") && (
            <AssignmentIcon
              style={{
                height: "50px",
                width: "50px",
                color: "black",
              }}
            />
          )}
        {!item.MediaType?.includes("pdf") &&
          item.MediaType?.includes("csv") && (
            <AnalyticsIcon
              style={{
                height: "50px",
                width: "50px",
                color: "black",
              }}
            />
          )}

        {item.mediaUrl.length > 1 && (
          <Box
            className="numDocsContainer"
            sx={{
              position: "absolute",
              left: loggedInUser ? isSmallScreen ? "-60px" : '-100px' : "",
              right: loggedInUser ? "" : isSmallScreen ? "-60px" : '-100px',
              marginTop: "-10px",
              top: "0px"
            }}
          >
            +{item.mediaUrl.length - 1}
          </Box>
        )}

      </Box>
    </>
  );
};

const MessageContent = ({
  item,
  showGeneralChatMessages,
  generalGroupEditors,
  role,
  handleClick2,
  setSelectedMsgId,
  idx,
  loggedInUser,
  privateChatMessagesActive
}) => {
  return (
    <div className="messageContainer">
      <div className="messageBody">
        <MessageText message={item} mentionedColor={privateChatMessagesActive?"#9ADCFF":"#3d7cc9"} />
      </div>

      {(!showGeneralChatMessages ||
        (showGeneralChatMessages && generalGroupEditors?.includes(role))) && (

          <div
            className="arrowContainer"
            variant="contained"
            id={`${item?.MessageSid}Arrow`}
            onClick={(e) => {
              handleClick2(e, `${item?.MessageSid}Arrow`);
              setSelectedMsgId(item?.MessageSid);
            }}
          >
            <Button sx={{ minWidth: 'unset' }}>
              <i
                className="arrow down"
                style={{
                  border: loggedInUser ? "solid rgb(217, 217, 217)" : "",
                  borderWidth: loggedInUser ? "0 3px 3px 0" : "",
                }}
              ></i>
            </Button>
          </div>
        )}
    </div>
  )
};

const DocApprovalBody = ({ messageActions, item, chatBox, dispatch, isMediaAfterDownload: isMediaAfterDownload, button1Text, button2Text, button1OnClick, button2OnClick, checkForPaymentInput, approveAmount, handleAmountChange }) => {
  console.log("DocApprovalBody", item, chatBox, checkForPaymentInput)
  let checkButtonDisabled = checkForPaymentInput && approveAmount === -1
  return (<div>
    {checkForPaymentInput ? <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px 10px 0px" }}>
      <span>Provisional Bill Amount: <input style={{
            margin: "8px 0",
            border: "1px solid #ccc",
            boxShadow: "inset 0 1px 3px #ddd",
            borderRadius: "4px",
            boxSizing: "border-box",
            padding: "12px 20px"
      }} min="0" type="number" onChange={handleAmountChange} placeholder="Enter here" /></span>
    </div> : null}
    <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 0px 10px 0px" }}>
        <Button disabled={checkButtonDisabled} onClick={(e) => { button1OnClick(e); }} style={{ borderRadius: "5px", color: "white", textTransform: "none!important", flex: "1", background: checkButtonDisabled ? "rgba(0, 0, 0, 0.12)" : "#FF8300", marginRight: "15px", cursor: checkButtonDisabled ? "no-drop" : "pointer" }}>{button1Text}</Button>
        <Button onClick={(e) => { button2OnClick(e); }} style={{ borderRadius: "5px", color: "white", textTransform: "none!important", flex: "1", background: "#00263E" }}>{button2Text}</Button>
    </div>
  </div>)
}

const approveDocChanges = async ({ item, chatBox, dispatch, isMediaAfterDownload, amount }) => {

  if (!isMediaAfterDownload()) {
    toast.error("Please Download First")
    return
  }
  const { Attributes: { appointment_id }, hospital_id, PatientId, LeadId, MessageSid, MessageBody, MessageIndex } = item
  const mobile = localStorage.getItem('userMobile')
  console.log('doraemon', item)
  let apporvalFailed = false
  await chatBoxService.approveDocChanges({ "appId": appointment_id, "status": "Approved", "hospitalId": hospital_id, "patientId": PatientId, "leadId": LeadId, "msgSid": MessageSid, "msgText": MessageBody, "msgIndex": MessageIndex, "mobile": mobile, amount }).then(res => {
    // toast.success(`${msgText} changes approved`)
    if (res.status === "Failed") {
      toast.error(res.description)
      apporvalFailed = true
      return
    }
  })
  if (apporvalFailed) {
    return
  }
  var cacheOf = mainCacheConstants.chatBoxMessagesCache
  var key = appointment_id
  var messages = chatBox.patientMessages
  messages = messages.map(data => {
    if (data.MessageSid == item.MessageSid) {
      data.Attributes.docApproval = false
    }
    return data
  })
  var newState = { ...chatBox, patientMessages: messages }
  dispatch(chatBoxActions.updateFullState(newState));
  dispatch(
    cacheStorageActions.setCacheStorageItem({
      cacheOf,
      key,
      value: messages,
    })
  );
}
const suggestDocChanges = ({ item, messageActions, isMediaAfterDownload }) => {
  if (!isMediaAfterDownload()) {
    toast.error("Please Download First")
    return
  }
  messageActions(0, item, 'suggestedChanges')
}
const approveOpthalApi = ({ item, chatBox, dispatch, stickerValue, stickerCode, setShowActionDialog, reqObj, otpMobile, otherObj }) => {


  if (!reqObj.stickerValue) {
    toast.error('Please fill the mandatory fields')
    return
  }
  if ( otherObj.lensOtherBrand && reqObj.lensBrand==="Other" ){
    reqObj={...reqObj,"lensBrand":"Other - " + otherObj.lensOtherBrand}
  }
  if ( otherObj.otherLens && reqObj.typeOfLens==="Other" ){
    reqObj={...reqObj,"typeOfLens":"Other - " + otherObj.otherLens}
  }

  const { hospital_id, MessageSid, LeadId, MessageIndex, MessageBody, Attributes: { document_key, appointment_id } } = item
  chatBoxService.dischargeSummaryStatusUpdate({
    // add lead id leadId
    // add message text msgText
    // add mobile mobile
    "mobile": otpMobile,
    "msgText": MessageBody,
    "leadId": LeadId,
    "appId": appointment_id,
    "key": document_key,
    "status": 'Approved',
    "hospitalId": hospital_id,
    "msgSid": MessageSid,
    "msgIndex": MessageIndex,
    ...reqObj
  }).then(res => {

  })

  var cacheOf = mainCacheConstants.chatBoxMessagesCache
  var key = appointment_id
  var messages = chatBox.patientMessages
  messages = messages.map(data => {
    if (data.MessageSid == item.MessageSid) {
      data.Attributes.opthalApproval = false
    }
    return data
  })
  var newState = { ...chatBox, patientMessages: messages }
  dispatch(chatBoxActions.updateFullState(newState));
  dispatch(
    cacheStorageActions.setCacheStorageItem({
      cacheOf,
      key,
      value: messages,
    })
  );
  setShowActionDialog(false)

}
const declineOpthal = ({ declineReason, item, dispatch, setShowActionDialog, chatBox, reqObj, otpMobile }) => {
  if (declineReason.length === 0) {
    toast.error('Please enter Decline Reason')
    return
  }
  const { hospital_id, MessageSid, MessageIndex, MessageBody, LeadId, Attributes: { document_key, appointment_id } } = item
  chatBoxService.dischargeSummaryStatusUpdate({
    "mobile": otpMobile,
    "msgText": MessageBody,
    "leadId": LeadId,
    "appId": appointment_id,
    "key": document_key,
    "status": "Declined",
    "hospitalId": hospital_id,
    "msgSid": MessageSid,
    "msgIndex": MessageIndex,
    "declineReason": declineReason
  }).then(res => {
  })

  setShowActionDialog(false)

}