import { Box } from "@mui/material"

export const FeedbackIconButton1 = (props) => (
  <Box component={`svg`} width="105" height="105" viewBox="0 0 105 105" {...props}>
    <defs>
      <filter id="Ellipse_26" x="0" y="0" width="105" height="105" filterUnits="userSpaceOnUse">
        <feOffset dx="5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_5821" data-name="Group 5821" transform="translate(-1773.5 -155.5)">
      <g transform="matrix(1, 0, 0, 1, 1773.5, 155.5)" filter="url(#Ellipse_26)">
        <circle id="Ellipse_26-2" data-name="Ellipse 26" cx="30" cy="30" r="30" transform="translate(17.5 17.5)" fill="#ff8300" />
      </g>
      <g id="consumer_reviews" data-name="consumer reviews" transform="translate(1802.394 189)">
        <g id="Group_5841" data-name="Group 5841">
          <g id="Group_5839" data-name="Group 5839">
            <g id="Group_5838" data-name="Group 5838">
              <path id="Path_6107" data-name="Path 6107" d="M123.657,64.071a.436.436,0,0,0,.019-.618l-2.524-2.493a.437.437,0,0,0-.618.028l-1.544,1.563,3.123,3.083,1.544-1.563Z" transform="translate(-90.971 -46.469)" fill="#fff" />
              <path id="Path_6108" data-name="Path 6108" d="M34.629,0H2.58A2.587,2.587,0,0,0,0,2.588V20.337a2.587,2.587,0,0,0,2.58,2.588H6.925l6.324,6.242-.118-6.242h6.741l1.686-1.711H11.394L11.465,25,7.626,21.214H2.58a.877.877,0,0,1-.875-.877V2.588a.875.875,0,0,1,.875-.874h32.05a.877.877,0,0,1,.875.874V20.337a.877.877,0,0,1-.875.877H30.583L28.9,22.925h5.732a2.587,2.587,0,0,0,2.582-2.588V2.588A2.589,2.589,0,0,0,34.629,0Z" fill="#fff" />
              <rect id="Rectangle_2807" data-name="Rectangle 2807" width="14.271" height="4.388" transform="translate(17.524 26.724) rotate(-45.35)" fill="#fff" />
            </g>
            <path id="Path_6109" data-name="Path 6109" d="M61.33,136.288l1.018-.466-.566-.561Z" transform="translate(-46.87 -103.288)" fill="#fff" />
            <path id="Path_6110" data-name="Path 6110" d="M66.286,115.3l-1.906,4.324.7.687,4.3-1.956Z" transform="translate(-49.209 -88.054)" fill="#fff" />
          </g>
          <g id="Group_5840" data-name="Group 5840" transform="translate(7.547 6.392)">
            <rect id="Rectangle_2808" data-name="Rectangle 2808" width="22.118" height="1.714" fill="#fff" />
            <rect id="Rectangle_2809" data-name="Rectangle 2809" width="22.118" height="1.714" transform="translate(0 4.115)" fill="#fff" />
            <rect id="Rectangle_2810" data-name="Rectangle 2810" width="15.945" height="1.714" transform="translate(0 8.228)" fill="#fff" />
          </g>
        </g>
      </g>
    </g>
  </Box>
)

export const FeedbackIconButton2 = (props) => (
  <Box component={`svg`} width="105" height="105" viewBox="0 0 105 105" {...props}>
    <defs>
      <filter id="Ellipse_26" x="0" y="0" width="105" height="105" filterUnits="userSpaceOnUse">
        <feOffset dx="5" dy="5" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="7.5" result="blur" />
        <feFlood floodOpacity="0.102" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_5821" data-name="Group 5821" transform="translate(-1773.5 -155.5)">
      <g transform="matrix(1, 0, 0, 1, 1773.5, 155.5)" filter="url(#Ellipse_26)">
        <circle id="Ellipse_26-2" data-name="Ellipse 26" cx="30" cy="30" r="30" transform="translate(17.5 17.5)" fill="#ff8300" />
      </g>
      <path id="Feedback_icon" data-name="Feedback icon" d="M583-6h6V0ZM567-6a4,4,0,0,1-4-4V-26a4,4,0,0,1,4-4h22a4,4,0,0,1,4,4v16a4,4,0,0,1-4,4Zm11-7.183,4.634,2.683-.995-5.464,3.86-3.806-5.251-.694L578-25.5l-2.25,5.035-5.25.694,3.859,3.806-.995,5.464L578-13.183Z" transform="translate(1243 220)" fill="#fff" />
    </g>
  </Box>
)

export const RatingIcon1 = (props) => (
  <Box component={`svg`} width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs>
      <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.648" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f7be00" />
        <stop offset="1" stop-color="#fff" />
      </radialGradient>
    </defs>
    <g id="Very_unhappy" data-name="Very unhappy" transform="translate(-64.368 -378.784)">
      <path id="Path_6099" data-name="Path 6099" d="M40,20A20,20,0,1,1,20,0,20,20,0,0,1,40,20" transform="translate(64.368 378.784)" fill="url(#radial-gradient)" />
      <path id="Path_6100" data-name="Path 6100" d="M211.659,154.571a1.745,1.745,0,0,1-3.491,0c0-.964,1.745-4.255,1.745-4.255s1.746,3.291,1.746,4.255" transform="translate(-116.438 247.969)" fill="#fff" />
      <path id="Path_6101" data-name="Path 6101" d="M194.5,107.36a2.909,2.909,0,0,1-3.013,3,2.864,2.864,0,0,1-2.987-2.977" transform="translate(-98.129 286.424)" fill="none" stroke="#00263e" stroke-linecap="round" strokeWidth="2" />
      <path id="Path_6102" data-name="Path 6102" d="M64.334,107.36a2.909,2.909,0,0,1-3.013,3,2.864,2.864,0,0,1-2.987-2.977" transform="translate(14.034 286.424)" fill="none" stroke="#00263e" stroke-linecap="round" strokeWidth="2" />
      <path id="Path_6103" data-name="Path 6103" d="M91.611,161.044a7.728,7.728,0,0,1,7.533-5A7.587,7.587,0,0,1,106.611,161" transform="translate(-14.743 245.74)" fill="none" stroke="#00263e" stroke-linecap="round" strokeWidth="2" />
    </g>
  </Box>
)

export const RatingIcon2 = (props) => (
  <Box component={`svg`} width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs>
      <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.648" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f7be00" />
        <stop offset="1" stop-color="#fff" />
      </radialGradient>
    </defs>
    <g id="Unhappy" transform="translate(-113.51 -378.784)">
      <path id="Path_6095" data-name="Path 6095" d="M390.934,20a20,20,0,1,1-20-20,20,20,0,0,1,20,20" transform="translate(-237.424 378.784)" fill="url(#radial-gradient)" />
      <path id="Path_6096" data-name="Path 6096" d="M419.844,95.063h0a1.486,1.486,0,0,1-1.5-1.463V88.526a1.5,1.5,0,0,1,3,0V93.6a1.486,1.486,0,0,1-1.5,1.463" transform="translate(-294.834 304.721)" fill="#00263e" />
      <path id="Path_6097" data-name="Path 6097" d="M549.834,95.063h0a1.486,1.486,0,0,1-1.5-1.463V88.526a1.5,1.5,0,0,1,3,0V93.6a1.486,1.486,0,0,1-1.5,1.463" transform="translate(-407.824 304.721)" fill="#00263e" />
      <path id="Path_6098" data-name="Path 6098" d="M442.545,166.349a7.728,7.728,0,0,1,7.533-5,7.588,7.588,0,0,1,7.467,4.961" transform="translate(-316.535 240.435)" fill="none" stroke="#00263e" stroke-linecap="round" strokeWidth="2" />
    </g>
  </Box>

)

export const RatingIcon3 = (props) => (
  <Box component={`svg`} width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs>
      <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.648" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f7be00" />
        <stop offset="1" stop-color="#fff" />
      </radialGradient>
    </defs>
    <g id="Neutral" transform="translate(-162.652 -378.784)">
      <path id="Path_6104" data-name="Path 6104" d="M741.867,20a20,20,0,1,1-20-20,20,20,0,0,1,20,20" transform="translate(-539.215 378.784)" fill="url(#radial-gradient)" />
      <path id="Path_6105" data-name="Path 6105" d="M770.777,113.94h0a1.687,1.687,0,0,1-1.5-1.818v-4.364a1.528,1.528,0,1,1,3,0v4.364a1.687,1.687,0,0,1-1.5,1.818" transform="translate(-596.625 285.844)" fill="#2a240e" />
      <path id="Path_6106" data-name="Path 6106" d="M900.768,113.94h0a1.687,1.687,0,0,1-1.5-1.818v-4.364a1.528,1.528,0,1,1,3,0v4.364a1.687,1.687,0,0,1-1.5,1.818" transform="translate(-709.616 285.844)" fill="#2a240e" />
      <line id="Line_5" data-name="Line 5" x2="20" transform="translate(173.152 405.784)" fill="none" stroke="#2a240e" stroke-linecap="round" strokeWidth="2" />
    </g>
  </Box>

)

export const RatingIcon4 = (props) => (
  <Box component={`svg`} width="40" height="40" viewBox="0 0 40 40" {...props}>
    <defs>
      <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.648" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f7be00" />
        <stop offset="1" stop-color="#fff" />
      </radialGradient>
    </defs>
    <g id="Happy" transform="translate(-211.794 -378.784)">
      <path id="Path_6083" data-name="Path 6083" d="M1092.8,20a20,20,0,1,1-20-20,20,20,0,0,1,20,20" transform="translate(-841.007 378.784)" fill="url(#radial-gradient)" />
      <path id="Path_6084" data-name="Path 6084" d="M1121.711,107.216h0a1.687,1.687,0,0,1-1.5-1.818v-4.364a1.528,1.528,0,1,1,3,0V105.4a1.687,1.687,0,0,1-1.5,1.818" transform="translate(-898.417 292.568)" fill="#2a240e" />
      <path id="Path_6085" data-name="Path 6085" d="M1251.7,107.216h0a1.687,1.687,0,0,1-1.5-1.818v-4.364a1.528,1.528,0,1,1,3,0V105.4a1.687,1.687,0,0,1-1.5,1.818" transform="translate(-1011.407 292.568)" fill="#2a240e" />
      <path id="Path_6086" data-name="Path 6086" d="M1159.981,180.294a7.728,7.728,0,0,1-7.533,5,7.588,7.588,0,0,1-7.467-4.961" transform="translate(-920.687 223.99)" fill="none" stroke="#2a240e" stroke-linecap="round" strokeWidth="2" />
    </g>
  </Box>
)

export const RatingIcon5 = (props) => (
  <Box component={`svg`} width="40" height="40" viewBox="0 0 40 40"  {...props}>
    <defs>
      <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.648" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f7be00" />
        <stop offset="1" stop-color="#fff" />
      </radialGradient>
      <clipPath id="clip-path">
        <path id="Path_6090" data-name="Path 6090" d="M1507.291,163.622l-15.023.059c-.317,0-.536.44-.416.841.625,2.084,2.714,7.329,7.9,7.329s7.315-5.282,7.96-7.384c.124-.4-.1-.845-.416-.845h0" transform="translate(-1491.819 -163.622)" fill="none" />
      </clipPath>
      <clipPath id="clip-path-2">
        <rect id="Rectangle_2795" data-name="Rectangle 2795" width="5" height="5" fill="none" />
      </clipPath>
    </defs>
    <g id="Very_happy" data-name="Very happy" transform="translate(-260.936 -378.784)">
      <path id="Path_6087" data-name="Path 6087" d="M1443.735,20a20,20,0,1,1-20-20,20,20,0,0,1,20,20" transform="translate(-1142.799 378.784)" fill="url(#radial-gradient)" />
      <path id="Path_6088" data-name="Path 6088" d="M1492.268,163.681c-.317,0-.536.44-.416.841.625,2.084,2.714,7.329,7.9,7.329s7.315-5.282,7.96-7.384c.124-.4-.1-.847-.417-.845Z" transform="translate(-1218.549 238.074)" fill="#2a240e" />
      <g id="Group_5824" data-name="Group 5824" transform="translate(273.27 401.696)" clip-path="url(#clip-path)">
        <path id="Path_6089" data-name="Path 6089" d="M1518.864,196.828c0,2.306-2.525,4.175-5.64,4.175s-5.64-1.869-5.64-4.175,2.525-4.175,5.64-4.175,5.64,1.869,5.64,4.175" transform="translate(-1505.376 -188.588)" fill="#e82122" />
      </g>
      <path id="Path_6091" data-name="Path 6091" d="M1590.937,112.767a2.909,2.909,0,0,1,3.014-3,2.864,2.864,0,0,1,2.986,2.977" transform="translate(-1306.001 284.017)" fill="none" stroke="#2a240e" stroke-linecap="round" strokeWidth="2" />
      <path id="Path_6092" data-name="Path 6092" d="M1463.366,112.767a2.909,2.909,0,0,1,3.013-3,2.864,2.864,0,0,1,2.987,2.977" transform="translate(-1192.431 284.017)" fill="none" stroke="#2a240e" stroke-linecap="round" strokeWidth="2" />
      <g id="Group_5826" data-name="Group 5826" transform="translate(263.936 396.784)" clip-path="url(#clip-path-2)">
        <path id="Path_6093" data-name="Path 6093" d="M1430.769,145.949a2.5,2.5,0,1,1-2.5-2.5,2.5,2.5,0,0,1,2.5,2.5" transform="translate(-1425.769 -143.449)" fill="#fff" />
      </g>
      <g id="Group_5829" data-name="Group 5829" transform="translate(292.936 396.784)" clip-path="url(#clip-path-2)">
        <path id="Path_6094" data-name="Path 6094" d="M1630.7,145.949a2.5,2.5,0,1,1-2.5-2.5,2.5,2.5,0,0,1,2.5,2.5" transform="translate(-1625.703 -143.449)" fill="#fff" />
      </g>
    </g>
  </Box>

)