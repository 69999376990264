import { Box, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notificationSound from "./assets/Notification.mp3";
import { WebSocketContext } from "./contexts/WebSocket";
import { getFirebaseToken, onMessageListener } from "./firebase";
import AllPopups from "./modules/common/AllPopups";
import Header from "./modules/common/Header";
import UploadDocument from "./modules/common/UploadDocument/UploadDocument";
import UserSanity from "./modules/common/UserSanity";
import Routing from './Routing';
import { cacheStorageActions, configActions, homeStateActions, userActions,documentsPopupActions } from "./_actions";
import { hospitalConstants } from "./_constants";
import { detectBrowser } from "./_helpers";
import useWindowSize from "./_helpers/useWindowSize";
// import AddToHomeScreen from '@ideasio/add-to-homescreen-react';
import { HeaderInterceptor } from "./_interceptors/HeaderInterceptor";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    button: {
      textTransform: 'none',
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#3D7CC9',
      // light: #3D7CC9,
      // dark: ,
    },
    // secondary: {

    // }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        }
      }
    }
  }
});

const App = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const authData = useSelector((state) => state.authentication);
  const notificationId = useSelector((state) => state.users.notificationId);
  const isMounted = useRef(false);
  const uploadDocumentStatus = useSelector(
    (state) => state.homeState.uploadDocumentStatus
  );
  const browserName = detectBrowser()
  const blockChatBox = useSelector(state => state.chatBox.blockChatBoxScreen)
  const showSanity = useSelector(state=> state.documentsPopup.userSanityPopup)
  const sid = useSelector((state)=> state.hospitals.currentHospitalData.sid)
  const { sendSocketMessage } = useContext(WebSocketContext);

  useEffect(() => {
    localStorage.getItem('userMobile') && initialiseNotificationToken();

    isMounted.current = true;
  }, [notificationId, localStorage.getItem('userMobile')]);

  const initialiseNotificationToken = () => {
    getFirebaseToken(setTokenFound).then((res) => {
      let userMobile = localStorage.getItem("userMobile");
      if (res && res.token && res.value && localStorage.getItem('userMobile')) {
        localStorage.setItem("fcmToken", res.token)
        dispatch(
          userActions.updateNotificationPermissions({
            fcmToken: res.token,
            mobile: localStorage.getItem('userMobile'),
          })
        );
      }
    });
  };

  onMessageListener()
    .then((payload) => {
      // toast.success(payload.notification.body);
    })
    .catch((err) => console.error("failed: ", err));
  var notificationEvent = {};
  useEffect(() => {
    navigator.serviceWorker && navigator.serviceWorker.addEventListener("message", (event) => {
      if (event.data === "playSound") {
        audio.play();
      } else {
        if (notificationEvent != event.data && event.data.data['syncChat'] == true) {
          dispatch(homeStateActions.updateCurrentState(event.data));
        }
        notificationEvent = event.data;
      }
    });
  }, []);
  const audio = new Audio(notificationSound);
  audio.muted = true;

  useEffect(() => {
    const setNotificationSound = () => {
      if (audio.muted) {
        audio.play().then(resetAudio);
      } else {
        document.removeEventListener("click", setNotificationSound);
      }
    };
    document.addEventListener("click", setNotificationSound);
    return () => {
      document.removeEventListener("click", setNotificationSound);
    };
  }, []);

  function resetAudio() {
    audio.pause();
    audio.currentTime = 0;
    audio.muted = false;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize()
  useEffect(()=>{
    
    if(isMobile){
      dispatch({type:hospitalConstants.OPEN_DRAWER})
    }
  },[isMobile])

  // auth interceptors
  useEffect(() => {
    HeaderInterceptor()
  }, [])
  
  useEffect(()=>{
    if(!sid){
      return
    }
    console.log('send socket check user sanity',sid)
    if(authData.user?.isExternalUser){
      let sanityPopupPayload = {
        action:"checkUserSanity",
        payload:{
           channelSid: sid
        }
      }
        sendSocketMessage( sanityPopupPayload )
    }
  },[sid,authData])


  useEffect(() => {
    var url = window.location.href;
    url = url.replace(window.location.origin, "");

    dispatch(configActions.showSpinningLoader());
    dispatch(userActions.checkAccessToken(navigate, url));
  }, []);

  const statesWithTTL = useSelector((state) => state.cacheStorage.statesWithTTL);
  const [handleTTLStates, setHandleTTLStates] = useState(false)
  //running interval check for removing stale data form cache storage
  useEffect(() => {
    const intervalId = setInterval(() => {
      setHandleTTLStates((prev) => !prev)
      // for demo make it 30000
    }, 1000*60*60*2);
    return () => {
      clearInterval(intervalId)
    }
  }, [])
  useEffect(() => {
    dispatch(cacheStorageActions.timerLimitCheck({ statesWithTTL }))
  }, [handleTTLStates])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        {<Header />}
        {/* <AddToHomeScreen /> */}
        <Box component="main" data-component="App" sx={{ height: 'calc(100% - 64px)' }}>
          <Box sx={{ bgcolor: "background.paper", height: '100%', display: 'flex', flexDirection: 'column' }} data-component="App" >
            <audio id="music" src="https://assets.mixkit.co/sfx/preview/mixkit-tile-game-reveal-960.mp3" />
            <Routing />
          </Box>
        </Box>
        {showSanity && <UserSanity/>}
        <AllPopups />
        <ToastContainer />
        {uploadDocumentStatus['status'] && <UploadDocument />}
      </>

    </ThemeProvider>
  );
};

export default App;
