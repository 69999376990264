import { toast } from "react-toastify";
import { reportingDataConstants } from "../../_constants";
import { getCentralReportingDataService } from "../../_services";
import { alertActions } from "../alert.actions";
import { configActions } from "../config.actions";
import { centralCommonDataActions } from "./centralCommonData.actions";

function getCentralReportingData(params) {
  return (dispatch, getState) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());
    getCentralReportingDataService(params).then(
      (res) => {
        let reportingData = res.data[0];
        if (reportingData) {
          dispatch(
            centralCommonDataActions.updateCentralCommonData(reportingData, true)
          );
        }
        // !!res?.description && toast.success(res.description)
        dispatch(success(res));
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        toast.error(error.description)
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
    dispatch(configActions.hideSpinningLoader());
  };

  function request() {
    return {
      type: reportingDataConstants.FETCH_REPORTING_DATA_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: reportingDataConstants.FETCH_REPORTING_DATA_FETCHED,
      reportingData: res,
    };
  }
  function failure(error) {
    return { type: reportingDataConstants.FETCH_REPORTING_DATA_FAILED, error };
  }
}

export const centralReportingDataActions = {
  getCentralReportingData,
};
