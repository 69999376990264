import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, MenuItem, RadioGroup, Select, styled, TextField, Typography } from "@mui/material";
import Radio from '@mui/material/Radio';
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import uniqid from "uniqid";
import crossIconOrange from '../../../../assets/crossIconOrange.svg';
import { constants } from "../../../../mixPanel/constants";
import { Mixpanel } from "../../../../mixPanel/MixPanel";
import { homeStateActions, patientActions } from "../../../../_actions";
import { allAppointmentSlots } from "../../../../_helpers";
import "./styles.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AcceptPopUp = ({ data }) => {
  const { setDispatchParams } = data;
  // const [timerOpen, setTimerOpen] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [tentativeRoom] = useState("");
  const [endTime, setEndTime] = useState("");
  const [values, setValues] = useState([]);
  const [currentPatientInfo, setCurrentPatientInfo] = useState([]);
  const [selected, setSelected] = useState("private");
  const versionConfigData = useSelector(
    (state) => state.config.versionConfigData
  );

  const patientListActive = useSelector(
    (state) => state.patients.patientListActive
  );

  const allInfo = useSelector((state) => state.patients.patientInfo.allInfo);

  const getFilteredSlots = allAppointmentSlots.filter((slot) => {
    const { startHour } = slot;
    const currentHour = moment().format("HH");
    const appointmentDate = moment(allInfo["AppointmentStartTime"]).format("D");
    const currentDate = moment().format("D");

    if (Number(appointmentDate) > Number(currentDate)) return true;

    return Number(startHour) > Number(currentHour);
  });

  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(
    JSON.stringify(getFilteredSlots[0])
  );

  const { roomTypes } = versionConfigData["result"][0];

  const handleAppointmentSlotChange = (e) => {
    let { value } = e.target;

    if (!value) {
      value =
        '{"label":"6:00 am - 7:00 am","startHour":"6","key":"6:00 - 7:00"}';
    }
    const { startHour } = JSON.parse(value);
    const currentHour = moment().format("HH");
    const hourDiff = Number(startHour) - Number(currentHour);
    const newStartTime = moment()
      .add("hour", `${hourDiff}`)
      .format("YYYY-MM-DDTHH:00:00Z");

    const newEndTime = moment()
      .add("hour", `${hourDiff + 1}`)
      .format("YYYY-MM-DDTHH:00:00Z");

    setStartTime(newStartTime);
    setEndTime(newEndTime);

    setSelectedAppointmentSlot(value);
  };

  useEffect(() => {
    handleAppointmentSlotChange({
      target: { value: JSON.stringify(getFilteredSlots[0]) },
    });
  }, []);

  useEffect(() => {
    setValues(() => roomTypes);
  }, [roomTypes]);

  useEffect(() => {
    const {
      FirstName,
      age,
      gender,
      AppointmentStartTime,
      category,
      doctorName,
      patientFirstName,
      doctorFirstName,
      patientAge
    } = allInfo;

    const finalFields = [
      { label: "Patient Name", value: FirstName || patientFirstName },
      { label: "Age", value: age || patientAge},
      { label: "Gender", value: gender },
      { label: "Category", value: category },
      {
        label: "IPD Date",
        value: moment(AppointmentStartTime).format("Do MMM YY, h:mm a"),
      },
      { label: "Doctor", value: doctorName || doctorFirstName},
    ];

    setCurrentPatientInfo(() => finalFields);
  }, [allInfo]);

  useEffect(() => {
    const params = {
      approvalStatus: "Accepted",
      room_type_key: selected,
      ot_slot_start: moment(startTime).format(),
      ot_slot_end: moment(endTime).format(),
      // tentative_room_no: tentativeRoom,
    };

    setDispatchParams((prevState) => ({
      ...prevState,
      ...params,
    }));
  }, [selected, startTime, endTime, tentativeRoom]);

  function handleChange(event) {
    setSelected(event.target.value);
  }

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }} className="accept-popup">
      {!patientListActive ? (
        <>
          <Container sx={{ height: "30vh", display: "grid", placeContent: "center" }} >
            <CircularProgress />
          </Container>
        </>
      ) : (
        <>

          {currentPatientInfo?.map((obj) => {
            const { label = "", value = "" } = obj;

            return (
              <Grid
                container
                className="patient-info-item"
                justifyContent="space-between"
                alignItems="center"
                key={uniqid()}
                sx={{ borderBottom: "1px solid #F5F5F5",flexWrap:"nowrap!important" }}
              >
                <Grid item xs={6} sm={6} md={6} lg={6} p={0} className="acceptPopUpfields" >
                  <Typography className="label-container" color="primary">
                    {label}:
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={6} p={0} className="acceptPopUpfields">
                  <div className="form-item-container">
                    <Typography
                      className="label-container"
                      color="textSecondary"
                      sx={{ textAlign: "right", fontWeight: "600",    width: "135px",
                      wordBreak: "break-word", }}
                    >
                      {value}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            );
          })}

          <Grid
            container
            className="patient-info-item"
            justifyContent="space-between"
            alignItems="center"
            sx={{ borderBottom: "1px solid #F5F5F5" }}

          >
            <Grid item xs={6} sm={6} md={6} lg={6} p={0} className="acceptPopUpfields">
              <Typography className="label-container" color="primary">
                OT Slot:
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} p={0} className="acceptPopUpfields" sx={{width:"162px"}}>
              <div className="form-item-container">
                <FormControl fullWidth required sx={{"fieldset":{border:"none"}}}>
                  <Select
                    className="select"
                    name="appointment-slot"
                    id="appointment-slot"
                    color="primary"
                    value={selectedAppointmentSlot}
                    onChange={handleAppointmentSlotChange}
                    size="small"
                    inputProps={{
                      name: "agent",
                      id: "age-simple",
                    }}
                    sx={{ m: "0", background: "#F5F5F5",fontSize:"12px" }}
                  >
                    {getFilteredSlots?.map((slot, index) => {
                      const { label = "" } = slot;

                      return (
                        <MenuItem key={uniqid()} value={JSON.stringify(slot)}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            className="patient-info-item"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={6} lg={6} p={0} className="acceptPopUpfields" >
              <Typography className="label-container" color="primary">
                Room Type:
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6} p={0} className="acceptPopUpfields" sx={{width:"162px"}}>
              <div className="form-item-container">
                <FormControl fullWidth required sx={{"fieldset":{border:"none"}}}>
                  {/* <InputLabel id="select-room-type">
                    Select Room Type
                  </InputLabel> */}

                  <Select
                    id="select-room-type"
                    name="select-room-type"
                    color="primary"
                    // label="Select Room Type"
                    value={selected}
                    onChange={handleChange}
                    inputProps={{
                      name: "agent",
                      id: "age-simple",
                      className:"arrowIcon"
                    }}
                    size="small"
                    className="select"
                    sx={{ m: "0", background: "#F5F5F5",fontSize:"12px" }}
                  >
                    {values?.map(({ label, key }, index) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

const RejectPopUp = ({ data }) => {
  const { setDispatchParams } = data;
  const [rejectionRemark, setRejectionRemark] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [textAreaOpen, setTextAreaOpen] = useState(false);
  const versionConfigData = useSelector(
    (state) => state.config.versionConfigData
  );

  const { surgeryRejectionReasons } = versionConfigData["result"][0];
  const handleChange = (reason) => {
    setTextAreaOpen(reason === "Other Reason" || reason === "Other");

    setRejectionReason(() => reason);
  };

  const handleRejectRemarkChange = (e) => {
    const { value } = e.target;

    setRejectionRemark(() => value);
  };

  useEffect(() => {
    const params = {
      approvalStatus: "Rejected",
      rejectionReason,
      rejectionRemark,
    };

    setDispatchParams((prevState) => ({
      ...prevState,
      ...params,
    }));
  }, [rejectionReason, rejectionRemark]);

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl component="fieldset" required>
        <RadioGroup
          row
          aria-label="gender"
          name="row-radio-buttons-group"
          className="radioButton"
        >
          {surgeryRejectionReasons.map((reason) => (
            <FormControlLabel
              value={reason}
              id={uniqid()}
              control={<Radio />}
              onClick={() => handleChange(reason)}
              label={reason}
              sx={{display:"flex",justifyContent:"space-between",width:"100%",marginLeft:"0px",marginRight:"0px",flexDirection:"row-reverse",color:"#64696F",fontWeight:"600",
              "span":{
                fontWeight: '600'
              }
            }}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <div className={`textField ${textAreaOpen ? "show" : "hide"}`}>
        <TextField
          onChange={handleRejectRemarkChange}
          value={rejectionRemark}
          variant="outlined"
          placeholder="Add Remark Here"
          multiline
          sx={{ width: "100%", overflowY: "hidden" }}
          rows="5"
          cols="33"
        />
      </div>
    </Box>
  );
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 5,
            color: "#FFFFFF",
            backgroundColor: "#FF8300",
            width: "24px",
            height: "24px",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#ff8300",
            },
          }}
        >
          <CloseIcon sx={{ fontSize: 20 }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PopUp({ data }) {
  const { popUpTitle, type = "accept" } = data;

  const dispatch = useDispatch();
  const [dispatchParams, setDispatchParams] = useState({});
  const patientInfo = useSelector((state) => state.patients.patientInfo);
  // console.log("patientInfo",patientInfo)
  const homeState = useSelector((state) => state.homeState);
  const user = useSelector((state) => state.authentication.user) ?? {};
  const hospital_id = useSelector(
    (state) => state.hospitals.currentHospitalData?.hospital_id
  );
  const sid = useSelector(
    (state) => state.hospitals.currentHospitalData?.sid
  );
  const handleClose = () => {
    dispatch(
      homeStateActions.setState({
        [popUpTitle]: false,
      })
    );
  };

  const allInfo = patientInfo?.allInfo;

  const dispatchRequestCheckFail = (updatedParams) => {
    const { approvalStatus } = updatedParams;

    if (approvalStatus === "Rejected") {
      if (!!!updatedParams.rejectionReason) return true;
    }

    if (approvalStatus === "Accepted") {
      if (
        [
          "room_type_key",
          "ot_slot_start",
          "ot_slot_end",
          // "tentative_room_no",
        ].some((key) => !!!updatedParams[key])
      )
        return true;
    }

    return false;
  };

  const handleDispatchRequest = (e) => {
    const { id } = e.target.closest("button");
    const { leadId, patientId, _id ,appointmentId} = allInfo;
    console.log("eCOnsoleallInfo",allInfo,leadId, patientId, _id ,appointmentId,id)
    const updatedParams = {
      ...dispatchParams,
      userId: user?.userId,
      appId: _id ? _id:appointmentId,
      lead_id: leadId,
      patientId,
      hospId: hospital_id,
      channelSid: sid,
      authorName: user?.name,
      authorRole: user?.roles?.role,
      mobileNumber: localStorage.getItem("userMobile"),
    };

    if (dispatchRequestCheckFail(updatedParams)) {
      swal({
        title: "Warning!",
        text: "Please fill out necessary details before submitting",
        icon: "warning",
      });

      return;
    }

    Mixpanel.track(constants.IPD_TRACKING, { aptId: _id ? _id : appointmentId, btnClick: id });
    handleClose();
    dispatch(patientActions.handleSurgeryAcceptReject(updatedParams));
    setDispatchParams(() => ({}));
  };

  return (
    <Box className="poppu">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={homeState[popUpTitle]}
        PaperProps={{
          sx: {
            background: "tranparent",
            overflow: "visible",
            width: '85%',
            maxWidth:"300px"

          }
        }}
      >
        <Box sx={{
          height: "60px", background: "#3D7CC9", borderRadius: "0px 0px 15px 15px",
          display: "flex", justifyContent: "center", alignItems: "center", color: "white"
        }}>
          <Box style={{ margin: "auto", fontSize: ['18px', '18px', '20px'], fontWeight: "600" }}>

            {type === "accept" ? "Review And Accept" : "Reason for Rejection"}
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: "30px",
            top: 0,
            zIndex: "1",
            transform: "translate(50%, -50%)",
            color: "#FFFFFF",
            backgroundColor: "#fff",
            width: "30px",
            height: "30px",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "#fff",
            },
          }}
        >
          <img src={crossIconOrange} alt="" />
        </IconButton>
        <DialogContent
          dividers
          sx={{
            height: "auto",
            width: "initial",
            borderColor: "#FFFFFF",
            overflowY: "auto",
          }}
        >
          {type === "accept" ? (
            <AcceptPopUp data={{ dispatchParams, setDispatchParams }} />
          ) : (
            <RejectPopUp data={{ dispatchParams, setDispatchParams }} />
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "0px 20px 20px 20px!important" }}>
          <Button
            autoFocus
            id={type}
            onClick={(e) => {
              handleDispatchRequest(e);
            }}
            sx={{
              width: "100%",
              backgroundColor: "#3D7CC9",
              color: "#FFFFFF",
              margin: "0px auto",
              fontWeight: "600",
              textTransform: "none!important"
            }}
            className="submitButton"
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}
