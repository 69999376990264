export const patientProps = {
  mop: "Payment Mode",
  pds: "Pds Status",
  bbs: "BB Status",
  bbsTime: 'BB Status Time',
  pdsTime: 'PDS Status Time'
}

export const hospitalViewProps = {
  mop: "paymentMode",
  fds : "fdsUploadHospChat",
  fdsTime:"fdsUploadHospChatTime",
  pb : "pbUploadHospChat",
  pbTime:"pbUploadHospChatTime",
  fb:"fbUploadHospChat",
  fbTime:"fbUploadHospChatTime"
}