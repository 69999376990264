import { alertConstants } from "../_constants";

function success(message) {
  return { type: alertConstants.SUCCESS, message };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}

const updateFullState = (newState) => {
  return {
    type: alertConstants.UPDATE_FULL_ALERT_STATE,
    newState,
  };
};

export const alertActions = {
  success,
  error,
  clear,
  updateFullState,
};
