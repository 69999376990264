import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ChecklistSummary from '../../modules/ChecklistSummary/';
import { useDispatch, useSelector } from 'react-redux';
import { checklistActions } from '../../_actions/checklist.actions';
import { useMediaQuery } from '@mui/material';
import "./index.css"
import MobileScreen from '../../modules/ChecklistSummary/MobileScreen';
import { getInitialState } from '../../_helpers/getInitialState';
import { addDays, subDays } from "date-fns";
import { useEffect } from 'react';
import { configActions, homeStateActions } from '../../_actions';
import { initialiseMixPanel } from '../../mixPanel/MixPanel';

export default function ScrollableTabsButtonPrevent() {
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const handleChange = (event, newValue) => {
    let setTab = newValue === 2 ? 'pending' : newValue === 1 ? 'old' : 'active'
    setValue(newValue);
    dispatch(checklistActions.changeChecklistActiveTab(setTab))
  };
  const authentication = useSelector((state) => state.authentication);
  const [value, setValue] = React.useState(0);
  const hospitalViewFilter = useSelector(
    (state) => state.checklistSummary.hospitalViewFilter
  );
  const [filterReqObj, setFilterRequestObj] = React.useState({
    hospitalViewScreen :{
      ...hospitalViewFilter,
      dateRangeHospitalView :[
        {
          startDate: value===2 ?  addDays(new Date(), 0): subDays(new Date(), 7),
          endDate: value===2 ?  addDays(new Date(), 7) : addDays(new Date(), 0),
          key: "selection",
        }
      ]
    }
  });
  useEffect(() => {
    initialiseMixPanel(authentication.user);
  }, [authentication]);

  const convertDate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  
  const getFilteredData = (filterObj) => {
    const {
      hospitalViewScreen: { searchTextHospitalView, mopFilterHospitalView, dateRangeHospitalView }
    } = filterObj;

    const mopFilterHospitalArr = !!filterObj['hospitalViewScreen']['mopFilter']
      ? filterObj['hospitalViewScreen']['mopFilter'].map((item) => {
        return item.name;
      })
      : [];
    
    const startDate =convertDate(dateRangeHospitalView[0].startDate)
    const endDate =convertDate(dateRangeHospitalView[0].endDate)
    
    dispatch(
        checklistActions.setChecklistFilters({
          filterObj: {
            search: searchTextHospitalView,
            mop: mopFilterHospitalArr,
            startDate,
            endDate,
          },
          screenName: "hospitalViewScreen",
        })
    );
  };
  useEffect(()=>{
    getFilteredData({
      ...filterReqObj,
      ['hospitalViewScreen']: {
        ...getInitialState("checklistSummary").hospitalViewFilter,
        dateRangeHospitalView: [
          {
            startDate: value===2 ?  addDays(new Date(), 0): subDays(new Date(), 7),
            endDate: value===2 ?  addDays(new Date(), 7) : addDays(new Date(), 0),
            key: "selection",
          },
        ],
      },
    });
  },[value])

  const role = useSelector(state => state.authentication.user.roles.role)
  useEffect(() => {
    dispatch(configActions.getVersionConfig());
    dispatch(configActions.getCommonData());
    dispatch(configActions.getDocumentList({ role: role }));
    dispatch(homeStateActions.setActiveTab('checklistSummary/hospitalView'))
  }, []);

  return (
    <Box sx={{ bgcolor: '#F5F5F5' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
        className='tabContainer'
      >
        <Tab label="Active Patients" />
        <Tab label="Old Patients" />
        <Tab label="Pending Patients" />
      </Tabs>
      {isSmallScreen ? <MobileScreen /> : <ChecklistSummary />}
    </Box>
  );
}