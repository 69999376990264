import swal from "sweetalert";
import {
  getTokenFromLocal,
  handleResponse
} from "../_helpers/handleResponseMiddleware";

let apiUrl = process.env.REACT_APP_BASE_URL;
let apiUrlOld = process.env.REACT_APP_BASE_URL_OLD;

let apiClientToken = process.env.REACT_APP_CLIENT_SECRET;

export function getGroupInfoList(params) {
  const { sid } = params;

  const channelSid = sid;
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },

    //Sid
    body: JSON.stringify({ channelSid }),
  };

  return fetch(`${apiUrl}/api/v2/getMembersList`, config)
    .then(handleResponse)
    .then((res) => {
      return { sid, groupInfo: res.result };
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

const addGroupMember = async (params) => {
  const { mobile, hospitalID } = params;

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ mobile, hospitalID }),
    redirect: "follow",
  };

  return fetch(`${apiUrlOld}/api/twilio/V2/adduser`, config)
    .then(handleResponse)
    .then((res) => {
      let {
        status = "error",
        description = "Something went wrong",
        result,
      } = res;

      let icon = status.toLowerCase() === "success" ? "success" : "error";

      ////////////Hard coded specific case ----------------------
      if (description === "Member has been invited to the group") {
        icon = "success";
        status = "Success";
      }
      ////////////Hard coded specific case ----------------------

      swal({
        title: status,
        text: description,
        icon,
      });

      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
};

const removeGroupMember = async (params) => {
  // const { mobile, hospitalID, userType, membersid } = params;

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
    redirect: "follow",
  };

  return fetch(`${apiUrlOld}/api/twilio/V2/removeuser`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      const {
        status = "error",
        description = "Something went wrong",
        result,
      } = response;

      swal({
        title: status,
        text: description,
        icon: status.toLowerCase() === "success" ? "success" : "error",
      });
      return response;
    });
};

export const groupInfoPopupService = {
  getGroupInfoList,
  addGroupMember,
  removeGroupMember,
};
