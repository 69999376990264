import { chatBoxConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function chatBox(
  state = getInitialState("chatBox"),
  action
) {
  switch (action.type) {
    //  to get chat messages
    case chatBoxConstants.GET_CHAT_MESSAGES_REQUESTING:
      return {
        ...state,
        isFetchingPatientChatMessages: true,
        patientChatMessagesActive: false,
        GeneralChatMessagesActive: false,
      };
    case chatBoxConstants.GET_CHAT_MESSAGES_FETCHED:
      const { patientMessages } = action;

      return {
        ...state,
        patientMessages,
        isFetchingPatientChatMessages: false,
        patientChatMessagesActive: true,
        GeneralChatMessagesActive: false,
      };
    case chatBoxConstants.GET_CHAT_MESSAGES_FAILED:
      return {
        ...state,
        fetchingPatientChatMessagesError: action.error,
        isFetchingPatientChatMessages: false,
        patientChatMessagesActive: false,
        GeneralChatMessagesActive: false,
      };

    //  to get general chat messages
    case chatBoxConstants.GENERAL_CHAT_MESSAGES_REQUESTING:
      return {
        ...state,
        isFetchingGeneralChatMessages: true,
        GeneralChatMessagesActive: false,
        patientChatMessagesActive: false,
      };
    case chatBoxConstants.GENERAL_CHAT_MESSAGES_FETCHED:
      const { generalChatMessages } = action;

      return {
        ...state,
        generalChatMessages,
        isFetchingGeneralChatMessages: false,
        GeneralChatMessagesActive: true,
        patientChatMessagesActive: false,
      };
    case chatBoxConstants.GENERAL_CHAT_MESSAGES_FAILED:
      return {
        ...state,
        fetchingGeneralChatMessagesError: action.error,
        isFetchingGeneralChatMessages: false,
        GeneralChatMessagesActive: false,
        patientChatMessagesActive: false,
      };

    // send chat messages
    case chatBoxConstants.SEND_CHAT_MESSAGES_REQUESTING:
      return {
        ...state,
        isSendingPatientChatMessage: true,
      };

    case chatBoxConstants.SEND_CHAT_MESSAGES_FETCHED:
      const { res } = action;
      return {
        ...state,
        sendingPatientChatMessageResponse: res,
        isSendingPatientChatMessage: false,
      };

    case chatBoxConstants.SEND_CHAT_MESSAGES_FAILED:
      return {
        ...state,
        isSendingPatientChatMessageError: action.error,
        isSendingPatientChatMessage: false,
      };

    // send chat file message
    case chatBoxConstants.SEND_CHAT_FILE_REQUESTING:
      return {
        ...state,
        isSendingPatientChatFile: true,
      };

    case chatBoxConstants.SEND_CHAT_FILE_FETCHED:
      return {
        ...state,
        isSendingPatientChatFile: false,
      };

    case chatBoxConstants.SEND_CHAT_FILE_FAILED:
      return {
        ...state,
        isSendingPatientChatFileError: action.error,
        isSendingPatientChatFile: false,
      };

    // send chat reply message
    case chatBoxConstants.SEND_CHAT_REPLY_REQUESTING:
      return {
        ...state,
        isSendingPatientChatReply: true,
      };

    case chatBoxConstants.SEND_CHAT_REPLY_FETCHED:
      return {
        ...state,
        isSendingPatientChatReply: false,
      };

    case chatBoxConstants.SEND_CHAT_REPLY_FAILED:
      return {
        ...state,
        isSendingPatientChatReplyError: action.error,
        isSendingPatientChatReply: false,
      };

    // set selected message
    case chatBoxConstants.SET_SELECTED_MESSAGE:
      return {
        ...state,
        selectedMessage: action.params,
      };
    case chatBoxConstants.CLEAR_CHAT_BOX:
      return {
        ...state,
        patientMessages: [],
      };

    case chatBoxConstants.ADD_CHAT_MESSAGE: {
      const { payload } = action;
      return {
        ...state,
        patientMessages: [...state.patientMessages, payload],
      };
    }

    case chatBoxConstants.SYNC_CHAT_MESSAGES_REQUESTING:
      return {
        ...state,
        isFetchingSyncChatMessages: true,
        syncChatMessagesActive: false,
      };
    case chatBoxConstants.SYNC_CHAT_MESSAGES_FETCHED:
      return {
        ...state,
        patientMessages: action.patientMessages,
        isFetchingSyncChatMessages: false,
        syncChatMessagesActive: true,
      };
    case chatBoxConstants.SYNC_CHAT_MESSAGES_FAILED:
      return {
        ...state,
        fetchingSyncChatMessagesError: action.error,
        isFetchingSyncChatMessages: false,
        syncChatMessagesActive: false,
      };

    case chatBoxConstants.DELETE_CHAT_MESSAGE:
      const { params } = action;
      if (params.showGeneralChatMessages) {
        return {
          ...state,
          generalChatMessages: state.generalChatMessages.map((item) => {
            if (params.MessageSid === item.MessageSid) {
              item.Attributes.isVisible = false;
              return item;
            } else return item;
          }),
        };
      }else if(params.privateChatMessagesActive){
        return {
          ...state,
          privateChatMessages: state.privateChatMessages.map((item) => {
            if(params.uploadLocalId === item.Attributes.uploadLocalId) {
              item.Attributes.isVisible = false;
              return item;
            } else return item;
          })
        }
      }
      else {
        return {
          ...state,
          patientMessages: state.patientMessages.map((item) => {
            if (params.uploadLocalId === item.Attributes.uploadLocalId) {
              item.Attributes.isVisible = false;
              return item;
            } else return item;
          }),
        };
      }

    case chatBoxConstants.GET_CHAT_TEMPLATES_REQUESTING:
      {
        return {
          ...state,
          isFetchingPatientChatTemplates: true,
          patientChatTemplatesActive: false,
        };
      }
    case chatBoxConstants.GET_CHAT_TEMPLATES_FETCHED:
      {

        var result = action.templates
        return {
          ...state,
          isFetchingPatientChatTemplates: false,
          patientChatTemplatesActive: true,
          chatTemplates: result?.result,
        };

      }
    case chatBoxConstants.GET_CHAT_TEMPLATES_FAILED:
      return {
        ...state,
        fetchingPatientChatMessagesError: action.error,
        isFetchingPatientChatTemplates: false,
        patientChatTemplatesActive: false,
      };

    // send chat template
    case chatBoxConstants.SEND_CHAT_TEMPLATES_REQUESTING:
      return {
        ...state,
        // isSendingPatientChatTemplate: true,
      };

    case chatBoxConstants.SEND_CHAT_TEMPLATES_FETCHED:
      return {
        ...state,
        // sendingPatientChatTemplateResponse: res,
        // isSendingPatientChatTemplate: false,
      };

    case chatBoxConstants.SEND_CHAT_TEMPLATES_FAILED:
      return {
        ...state,
        // isSendingPatientChatTemplateError: action.error,
        // isSendingPatientChatTemplate: false,
      };

    // send chat to insurance
    case chatBoxConstants.SEND_TO_INSURANCE_REQUESTING:
      return {
        ...state,
        // isSendingPatientChatTemplate: true,
      };

    case chatBoxConstants.SEND_TO_INSURANCE_FETCHED:
      return {
        ...state,
        // sendingPatientChatTemplateResponse: res,
        // isSendingPatientChatTemplate: false,
      };

    case chatBoxConstants.SEND_TO_INSURANCE_FAILED:
      return {
        ...state,
        // isSendingPatientChatTemplateError: action.error,
        // isSendingPatientChatTemplate: false,
      };

    // download chat message
    case chatBoxConstants.DOWNLOAD_CHAT_MESSAGE_REQUESTING:
      return {
        ...state,
        // isSendingPatientChatTemplate: true,
      };

    case chatBoxConstants.DOWNLOAD_CHAT_MESSAGE_FETCHED:
      return {
        ...state,
        // sendingPatientChatTemplateResponse: res,
        // isSendingPatientChatTemplate: false,
      };

    case chatBoxConstants.DOWNLOAD_CHAT_MESSAGE_FAILED:
      return {
        ...state,
        // isSendingPatientChatTemplateError: action.error,
        // isSendingPatientChatTemplate: false,
      };

    case chatBoxConstants.UPDATE_DOWNLOAD_CHAT_MESSAGE:
      const { url, id } = action.params;
      const { generalChatActive, privateChatMessagesActive } = action

      if (generalChatActive) {
        return {
          ...state,
          generalChatMessages: state.generalChatMessages.map((item) => {
            if (id === item.MessageSid) {
              item.mediaUrl = url;
              return item;
            } else return item;
          }),
        };
      }
      else if(privateChatMessagesActive){
        return {
          ...state,
          privateChatMessages: state.privateChatMessages.map((item) => {
            if (id === item.MessageSid) {
              item.mediaUrl = url;
              return item;
            } else return item;
          }),
        };
      }
      else {
        return {
          ...state,
          patientMessages: state.patientMessages.map((item) => {
            if (id === item.MessageSid) {
              item.mediaUrl = url;
              return item;
            } else return item;
          }),
        };
      }
    case chatBoxConstants.LOADING_DOWNLOAD_CHAT_MESSAGE: {
      const { generalChatActive, privateChatMessagesActive } = action
      if (generalChatActive) {
        return {
          ...state,
          generalChatMessages: state.generalChatMessages.map((item) => {
            if (action.params === item.MessageSid) {
              item.loadingMedia = true;
              return item;
            } else return item;
          }),
        };
      } else if( privateChatMessagesActive ) {
        return {
          ...state,
          privateChatMessages: state.privateChatMessages.map((item) => {
            if (action.params === item.MessageSid) {
              item.loadingMedia = true;
              return item;
            } else return item;
          }),
        }
      }
      else {
        return {
          ...state,
          patientMessages: state.patientMessages.map((item) => {
            if (action.params === item.MessageSid) {
              item.loadingMedia = true;
              return item;
            } else return item;
          }),
        };
      }
    }
    // sync chat message

    case chatBoxConstants.SYNC_CHAT_MESSAGE: {
      const { uploadLocalId, newMessage } = action;
      return {
        ...state,
        patientMessages: state.patientMessages.map((item) => {
          if (uploadLocalId == item.Attributes.uploadLocalId) {
            return newMessage;
          } else return item;
        }),
      };
    }

    case chatBoxConstants.UPDATE_FULL_CHATBOX_STATE:
      const { newState } = action;

      return {
        ...newState,
      };

    case chatBoxConstants.PRIVATE_CHAT_ON:
      return {
        ...state,
        privateChatMessagesActive:true,
        privateChatMessages:[]
      }
    case chatBoxConstants.PRIVATE_CHAT_MESSAGES:
      return{
        ...state,
        privateChatMessages: action.privateMessages
      }
    case chatBoxConstants.PRIVATE_CHAT_FAIL:
      return{
        ...state,
        privateChatMessages:[],
        fetchingPrivateChatMessagesError:action.error
      }
    case chatBoxConstants.PRIVATE_CHAT_OFF:
      return{
        ...state,
        privateChatMessagesActive:false
      }

    default:
      return state;

    // toggle has more messages
    case chatBoxConstants.HAS_MORE_MESSAGES: {
      const { value } = action
      return {
        ...state,
        hasMoreMessages: value
      }
    }
    case chatBoxConstants.BLOCK_CHATBOX_SCREEN: {
      const { value } = action
      return {
        ...state,
        blockChatBoxScreen: value
      }
    }
  }
}
