import React,{useState,useEffect} from "react";
import {Box,Drawer,Button,List,ListItem,ListItemButton,Badge,} from "@mui/material";
import axios from "axios";
import { getTokenFromLocal } from "../../../_helpers/handleResponseMiddleware";
import moment from "moment";
import Notification from '../../../assets/Notification.png'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function NotificationComponent() {
  let user = getTokenFromLocal();
  const token = user ? user.access_token : null;
  const [state, setState] = useState(false);
  const [notificationList,setNotificationList]=useState([])
  const [notificationListToShow,setNotificationListToShow]=useState([])
  const [count,setCount]=useState(0)
  const [notificationCount,setNotificationCount]=useState(0)

  useEffect(()=>{
if(notificationList.length<1){
  getNotificationData()
}
  },[])

  const handleVIew=()=>{
    let repeat=count*10 
    let dataToSet=notificationList.slice(repeat,repeat*2)
    setNotificationListToShow([...notificationListToShow,...dataToSet])
    setCount(count+1)
  }

  const getNotificationData=()=>{
    let apiUrl = process.env.REACT_APP_NOTIFICATION_URL;
    axios.post(`${apiUrl}/api/notificationData`, {},{
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    })
    .then(function (response) {
      if(response && response.data.result.notifications.length!==0)
      setNotificationList([...response.data.result.notifications])
      setCount(count+1)
      let dataToSet=response.data.result.notifications.slice(0,10)
      setNotificationListToShow([...dataToSet])
      setNotificationCount(response.data.result.unreadCount)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const readNotifications=()=>{
    axios.get("https://java-staging.pristoncare.com/hospitalChat/api/updateUnreadNotifications",{
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })
    .then(function (response) {
      console.log(response);
      if(response && response.data.status=="Success")
      setNotificationCount(0)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 ,height:'90vh',    overflow: 'auto'}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List sx={{    background: '#FFFFFF',    padding: '12px'
}}>
        {notificationListToShow.map((text, index) => (
          <ListItem key={text._created_at} disablePadding  sx={{    background: '#F5F5F5',
    fontSize:'12px',
    border: '2px solid white',
    borderRadius: '20px',
    marginBottom:'15px',
    }}>
            <ListItemButton sx={{display:'block'}}>

              <p style={{    color: '#000000',
              margin: '0px',
            fontSize: '12px'}}> {text.messageBody}</p>
              <p style={{    color: '#3D7CC9',
              margin: '0px',
    textAlign: 'end',
    fontSize: '10px'}}>
              {moment(text._created_at).format('DD-MM-YYYY | hh:mm A')}
              </p>
             
            </ListItemButton>
     
          </ListItem>
        ))}
      </List>
    
    </Box>
  );

  return (
    <div>
        <Button onClick={toggleDrawer("right", true)}> 
        <Badge badgeContent={notificationCount} color="success" >
        <img src={Notification} alt="notification" style={{    marginTop: '-2px',    height: '37px',
    marginRight: '-5px'}} onClick={()=>{if(notificationCount!==0){readNotifications()}}} />
    
         </Badge>
         </Button>
        <Drawer
          anchor="right"
          open={state}
          onClose={toggleDrawer("right", false)}
          sx={{boxShadow: '0px 0px 12px #00000029',borderRadius:'15px',maxHeight:'14rem'}}
        >
        <div style={{    display: 'flex',
    justifyContent: 'space-between',
    marginInline: '15px',
    alignItems: 'center'}}>
        <p style={{color:'#3A3A3A',fontSize:'14px',marginRight: "5%"}}>{notificationList.length} notifications </p>
        <HighlightOffIcon  sx={{    background: '#8484f9',
    color: 'white',
    borderRadius: '50%',
    border: '0px solid white'}} onClick={toggleDrawer("right", false)} />
   
        </div>
        
          {list("right")}
          {notificationList.length!==notificationListToShow.length &&
          <Button variant="outlined" sx={{margin: '0px 15px 15px 15px'}} onClick={()=>{handleVIew()}}>View all Notifications</Button>}
        </Drawer>
    </div>
  );
}
