import { configActions } from ".";
import { } from "../_constants";
import { checklistConstants } from "../_constants/checklist.constants";
import { checklistService } from "../_services/checklistService.service";
import { alertActions } from "./alert.actions";

function getChecklistHospital(data) {
  const {
    params = {},
    callback = null,
    fetchFromServiceFile = false,
    cache = undefined,
  } = data;

  return (dispatch) => {
    dispatch(request());
    // dispatch(configActions.showSpinningLoader());
    dispatch(configActions.showSpinningLoader());
    if (fetchFromServiceFile) {
      checklistService.getChecklistHospital(params).then(
        (res) => {
          res = Object.keys(res).map((key) => res[key]);
          dispatch(success(res));
          dispatch(configActions.hideSpinningLoader());

          //callback added for the support of the the caching work
          Boolean(callback) && callback(res);

          // dispatch(configActions.hideSpinningLoader());

          // history.push("/");
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      dispatch(configActions.hideSpinningLoader());

      dispatch(success(cache));
      // dispatch(configActions.hideSpinningLoader());
    }
  };

  function request() {
    return {
      type: checklistConstants.FETCH_HOSPITALSCREEN_REQUESTING,
    };
  }
  function success(response) {
    // const { status = "", description = "", result = [] } = response;
    // swal({
    //   title: status,
    //   text: description,
    //   icon: status.toLowerCase() === "success" ? "success" : "error",
    // });

    return {
      type: checklistConstants.FETCH_HOSPITALSCREEN_FETCHED,
      response,
    };
  }
  function failure(error) {
    return { type: checklistConstants.FETCH_HOSPITALSCREEN_FAILED, error };
  }
}
function getChecklistPatient(data) {
  const {
    params = {},
    callback = null,
    fetchFromServiceFile = false,
    cache = undefined,
  } = data;

  return (dispatch) => {
    dispatch(request());
    // dispatch(configActions.showSpinningLoader());
    dispatch(configActions.showSpinningLoader());

    if (fetchFromServiceFile) {
      checklistService.getChecklistPatient(params).then(
        (res) => {
          dispatch(configActions.hideSpinningLoader());

          dispatch(success(res));

          //callback added for the support of the the caching work
          Boolean(callback) && callback(res);

          // dispatch(configActions.hideSpinningLoader());

          // history.push("/");
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      dispatch(configActions.hideSpinningLoader());
      dispatch(success(cache));
      // dispatch(configActions.hideSpinningLoader());
    }
  };

  function request() {
    return {
      type: checklistConstants.FETCH_PATIENTSCREEN_REQUESTING,
    };
  }
  function success(response) {
    return {
      type: checklistConstants.FETCH_PATIENTSCREEN_FETCHED,
      patientMessages: response,
    };
  }
  function failure(error) {
    return { type: checklistConstants.FETCH_PATIENTSCREEN_FAILED, error };
  }
}

function getPatientsListPerformance(data) {
  const {
    params = {},
    callback = null,
    fetchFromServiceFile = false,
    cache = undefined,
  } = data;

  return (dispatch) => {
    dispatch(request());
    // dispatch(configActions.showSpinningLoader());
    dispatch(configActions.showSpinningLoader());

    if (fetchFromServiceFile) {
      checklistService.getPatientsListPerformance(params).then(
        (res) => {
          dispatch(configActions.hideSpinningLoader());
          console.log("Fianlres",res)
          dispatch(success(res));

          //callback added for the support of the the caching work
          Boolean(callback) && callback(res);

          // dispatch(configActions.hideSpinningLoader());

          // history.push("/");
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      dispatch(configActions.hideSpinningLoader());
      dispatch(success(cache));
      // dispatch(configActions.hideSpinningLoader());
    }
  };

  function request() {
    return {
      type: checklistConstants.FETCH_PATIENTSCREEN_REQUESTING,
    };
  }
  function success(response) {
    return {
      type: checklistConstants.FETCH_PATIENTSCREEN_FETCHED,
      patientMessages: response,
    };
  }
  function failure(error) {
    return { type: checklistConstants.FETCH_PATIENTSCREEN_FAILED, error };
  }
}

function changeChecklistScreen(screenName, hospitalId, filterStatus = null, ) {
  const payload = { screenName, hospitalId, filterStatus };

  return (dispatch) => {
    dispatch({ type: checklistConstants.CHANGE_CHECKLIST_SCREEN, payload });
  };
}

function changeChecklistActiveTab(activeTab) {
  const payload = { activeTab };

  return (dispatch) => {
    dispatch({ type: checklistConstants.CHANGE_ACTIVE_TAB, payload });
  };
}


function setChecklistFilters({ screenName, filterObj }) {
  var payload = {};
  if (screenName === "hospitalScreen") {
    payload["filterName"] = "hospitalFilter";
    payload["filterObj"] = filterObj;
  } else if (screenName === "patientScreen") {
    payload["filterName"] = "patientFilter";
    payload["filterObj"] = filterObj;
  }
  else if (screenName === "hospitalViewScreen") {
    payload["filterName"] = "hospitalViewFilter";
    payload["filterObj"] = filterObj;
  }
  return (dispatch) => {
    dispatch({ type: checklistConstants.SET_CHECKLIST_FILTERS, payload });
  };
}
const updateFullState = (newState) => {
  return {
    type: checklistConstants.UPDATE_FULL_STATE,
    newState,
  };
};

export const checklistActions = {
  getChecklistHospital,
  getChecklistPatient,
  getPatientsListPerformance,
  changeChecklistScreen,
  setChecklistFilters,
  updateFullState,
  changeChecklistActiveTab
};
