import { Box, FormControl, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { pcScoreActions } from "../../../_actions"
import { ProfileButton } from "../OwnerCommonComponents"
import { OwnerInput } from "../ownerComponent"

const BankAccountDetail = ({handlePageRender}) => {
  let formKey = {
    accountType: 'Savings',
    bankName: '',
    accountNo: '',
    IFSCCode: '',
    accountHolder: '',
  }
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const { hospital_id } = useSelector((state) => state.hospitals.currentHospitalData);

  const [formData, setFormData] = useState(formKey)
  function handdleSubmit() {
    if (formData.bankName && formData.accountNo && formData.IFSCCode && formData.accountHolder) {
      dispatch(pcScoreActions.saveHospitalBankDetailsAction({ hospitalId: hospital_id, ...formData },navigate,handlePageRender))
      setFormData(formKey)
    }
    else toast.error(`All fields are required!`)
  }

  useEffect(()=>{
    document.getElementById('dashboardWrapper').scrollTop = 0
  }, [])
  return (
    <>
      <Box component="form" sx={{ background: 'white', display: 'flex', flexDirection: 'column', maxWidth: '590px', padding: "30px", margin: '0 auto' }}>
        <FormControl fullWidth sx={{ mb: [2, 2, 2, 2, 3] }}>
          <Box component={`label`} sx={{ mb: ['10px', '12px', '13px', '13px', '15px'], color: '#3D7CC9', fontSize: ['14px', '14px', '16px', '18px', '20px'], fontWeight: [600, 600, 500] }} htmlFor={`account-type`}>Account Type</Box>
          <Select
            id="account-type"
            onChange={(e, a) => { setFormData({ ...formData, accountType: e.target.value }) }}
            value={formData.accountType}
            sx={{ height: ['30px', '30px', '50px'], fontSize: ['12px', '14px', '16px', '18px', '20px'] }}
          >
            <MenuItem value={"Savings"} selected={true}>Savings</MenuItem>
            <MenuItem value={"Current"}>Current</MenuItem>
            <MenuItem value={"Cash Credit"}>Cash Credit</MenuItem>
            <MenuItem value={"Loan Account"}>Loan Account</MenuItem>

          </Select>
        </FormControl>

        <OwnerInput label="Bank Name" placeholder="Enter Bank name" id="bank-name" value={formData.bankName} onChange={(e) => { setFormData({ ...formData, bankName: e.target.value }) }} />
        <OwnerInput label="Account Number" placeholder="Enter Account Number" id="account-number" value={formData.accountNo} onChange={(e) => { 
          let validNum=e.target.value
          if(validNum.length<=20){
          setFormData({ ...formData, accountNo: e.target.value.replace(/[^0-9]/gi,'') })}
         }} />
        <OwnerInput label="IFSC Code" placeholder="Enter IFSC Code" id="ifsc-code" value={formData.IFSCCode} onChange={(e) => { setFormData({ ...formData, IFSCCode: e.target.value }) }} />
        <OwnerInput label="Account Holder Name" placeholder="Enter Account Holder Name" id="account-holder-name" value={formData.accountHolder} onChange={(e) => { 
          setFormData({ ...formData, accountHolder: e.target.value.replace(/[^a-zA-Z ]/gi, '') })
          }} />

        <ProfileButton variant="contained" sx={{ minWidth: ['120px', '320px'],marginTop: ['20px',] }} size="small" type="button" onClick={handdleSubmit} >Submit</ProfileButton>
      </Box>
    </>
  )
}
export default BankAccountDetail