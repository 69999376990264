import { centralCommonDataConstants } from "../../_constants";
import { centralGetInitialState } from "../../_helpers/centralGetInitialState";
import { getValueAfterConvertStringToObjectKeys } from "../../central/helpers/utilities";

export function centralCommonData(state = centralGetInitialState("centralCommonData"), action) {
  switch (action.type) {
    case centralCommonDataConstants.FETCH_COMMON_DATA:
      return {
        ...state,
      };

    case centralCommonDataConstants.CENTRAL_COMMON_DATA_FETCHED:
      const { commonData } = action;
      return {
        ...state,
        commonData,
      };
    case centralCommonDataConstants.COMMON_DATA_FAILED:
      return {
        ...state,
        centralCommonDataFetchingError: action.error,
      };

    case centralCommonDataConstants.UPDATE_COMMON_DATA:
      const {payload:{reportingData, disable}}   = action
      let commonDataJson = {...state?.commonData}
      const data =  commonDataJson?.result?.data || []
      for (let item in data) {
        let pendingFields = 0
        data[item] && data[item]?.details?.forEach(element=>{
              let value = getValueAfterConvertStringToObjectKeys(reportingData, element.getKey)
              if (value) {
                element.value = value
                element.disable = element?.forceDisable ? false : !!value
              } else {
                if (element.functionType.includes('input')) {
                  element.value = value
                  element.disable = element?.forceDisable ? false : !!value
                  pendingFields++;
                } else if (element.functionType.includes('upload-file')) {
                  element.value = value
                }
              }
            })
        data[item].pendingFields = pendingFields
      }
      if (commonDataJson.result) { 
            commonDataJson.result.fetched = true
      }
      return {
        ...state,
        ...commonDataJson,
      };
      case centralCommonDataConstants.CLEAR_CENTRAL_COMMON_DATA:
        let commonDataJson1 = {...state?.commonData}
        if (commonDataJson1.result) { 
          commonDataJson1.result.fetched = false
        }
        return {
          ...state,
          ...commonDataJson1,
        };
      
    default:
      return state;
  }
}
