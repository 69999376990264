import { hadleLastMessageIndexUpdate, mainCacheConstants } from ".";
import { cacheStorageActions, chatBoxActions, documentsPopupActions, groupInfoPopupActions, homeStateActions, hospitalActions, patientActions } from "../_actions";
import { pcScoreConstants } from "../_constants";


const modifyObject = (payload, name, role, isSync, isVisible = true) => {
  let newPayload = {};
  for (let key in payload) {
    if (!(key === "hospital_id" || key === "hasMedia")) {
      newPayload[key.charAt(0).toUpperCase() + key.substring(1)] = payload[key];

      if (key === "attributes") {
        newPayload["Attributes"] = { ...payload[key], isVisible };
      }
    } else newPayload[key] = payload[key];
  }
  newPayload["AuthorName"] = name;
  newPayload["AuthorRole"] = role;
  newPayload["isSync"] = isSync;

  return newPayload;
};
export const handleSateWithSocket = (dispatch, getState, status, payload, sendSocketMessage = null) => {
  const {
    hospitals: {
      currentHospitalData: { sid = "" },
      currentHospitalData,
      hospitalList,
    },
    hospitals,
    patients,
    chatBox,
    groupInfoPopup,
    homeState,
    cacheStorage,
    authentication
  } = getState();

  const handleAppCacheSync = (params) => {
    const { cacheOf = "", key = "", value = "" } = params;

    // if (!Boolean(cacheStorage?.[cacheOf]?.[key])) return;

    // const getNewValue = () => {
    //   if (!Boolean(cacheStorage[cacheOf][key][value])) return value;

    //   if (Array.isArray(value))
    //     return [...cacheStorage[cacheOf][key][value], ...value];

    //   if (typeof value === "object" && value !== null)
    //     return { ...cacheStorage[cacheOf][key][value], ...value };

    //   return value;
    // };

    dispatch(
      cacheStorageActions.setCacheStorageItem({
        cacheOf,
        key,
        value,
      })
    );
  };

  const updatePatientListReadCount = ({ patientsList, appointmentId, count }) => {
    for (let key in patientsList) {
      for (let i = 0; i < patientsList[key].length; i++) {
        let item = patientsList[key][i]
        if (item._id === appointmentId ) {
            item.unreadMessages = item.unreadMessages + count
        }
      }
    }
    return patientsList;
  }
  const handleAppointmentFlow = ({ updatePatientList, channelSid, currHospitalSid, appointmentAndStateDetails: { count,appointmentId,appointmentPayload} = {} }) => {
    //to check if current patient list  is opened or not and if yes then return
    if (currHospitalSid === channelSid) {
      const newState={...patients}
      const patientsList=newState.patients
      let updatedPatientsList = updatePatientList({ patientsList, appointmentId, count })
      dispatch(patientActions.updatePatientsList({patientsList:updatedPatientsList}));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: mainCacheConstants.patientListsCache,
          key: channelSid,
          value: {sid:channelSid,patients:updatedPatientsList},
        })
      );
      return;
    }
    //to update the patient list in cache
    const cachedPatientsState = cacheStorage.patientListsCache[channelSid]
    if(cachedPatientsState){

      const newState = { ...cachedPatientsState }
      let updatedPatientsList = updatePatientList({ patientsList: newState.patients, appointmentId, count })
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: mainCacheConstants.patientListsCache,
          key: channelSid,
          value: {sid:channelSid,patients:updatedPatientsList},
        })
        );
      }
  }
  const handleMessageFlow = ({ messageAndStateDetails: { appointment_id, showGeneralChatMessages, channelSid, hospital_id,author, privateChatMessagesActive }, patientInfo, changesToChatMessages, socketEvent }) => {
    //to check if current message chatbox is opened or not and if yes then return
    // console.log('handle message flow',(appointment_id) && (appointment_id && patientInfo.appointmentId === appointment_id), !privateChatMessagesActive , !payload.messageData.attributes.chatType)
    if ((appointment_id) && ((appointment_id && patientInfo.appointmentId === appointment_id)) && (!payload.messageData.attributes.chatType )) {
      //if it is a patient message
      let { patientMessages } = chatBox;
      patientMessages = changesToChatMessages({ messages: patientMessages })
      let cacheOf = mainCacheConstants.chatBoxMessagesCache
      let key = appointment_id
      let newState = {
        ...chatBox,
        patientMessages: patientMessages,
      };

      if (socketEvent === "onMessageAdded") {
        let messageSocketPayload = {
          action:"readAppointment",
          payload:{
            hospitalId: hospital_id, 
            appointmentId: appointment_id 
          }
        }
        sendSocketMessage( messageSocketPayload )
      }
      dispatch(chatBoxActions.updateFullState(newState));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf,
          key,
          value: patientMessages,
        })
      )
 
      // dispatch(patientActions.updatePatientsReadCount({ appointment_id, count: patientInfo.allInfo.unreadMessages }))
      // if(patientInfo.allInfo.unreadMessages===0){
      // }
      // else {
      //   dispatch(patientActions.updatePatientsReadCount({ appointment_id, count: patientInfo.allInfo.unreadMessages+1 }))
      // }
      return
    } else if(payload.messageData.attributes.chatType === 'private' && ((appointment_id && patientInfo.appointmentId === appointment_id))){
      //if it is a private message
      let { privateChatMessages } = chatBox;
      privateChatMessages = changesToChatMessages({ messages: privateChatMessages })
      let cacheOf = mainCacheConstants.privateChatMessagesCache
      let key = appointment_id
      let newState = {
        ...chatBox,
        privateChatMessages: privateChatMessages,
      };

      dispatch(chatBoxActions.updateFullState(newState));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf,
          key,
          value: privateChatMessages,
        })
      )

      return
    } else if (showGeneralChatMessages && (channelSid === sid) && appointment_id === undefined) {
      //if it is a general chat message 
      let { generalChatMessages } = chatBox;
      generalChatMessages = changesToChatMessages({ messages: generalChatMessages })
      let key = channelSid
      let cacheOf = mainCacheConstants.chatBoxGeneralChatMessagesCache
      let newState = {
        ...chatBox,
        generalChatMessages: generalChatMessages,
      };
      dispatch(chatBoxActions.updateFullState(newState));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf,
          key,
          value: generalChatMessages,
        }))

      return;
    }

    //to update the message in cache
    if (appointment_id  && !payload.messageData.attributes.chatType) {
      // if it is a patient message
      const cachedPatientsState = cacheStorage.patientListsCache[channelSid]
      const loggedUserNumber = localStorage.getItem("userMobile");
      const isAuthor=loggedUserNumber===author
      if (!isAuthor && socketEvent === "onMessageAdded" && cachedPatientsState) {

        handleAppointmentFlow({ updatePatientList: updatePatientListReadCount, channelSid, currHospitalSid: currentHospitalData.sid, appointmentAndStateDetails: {count:1, appointmentId: appointment_id }})
      }
      let cacheOf = mainCacheConstants.chatBoxMessagesCache;
      let key = appointment_id;
      if (cacheStorage?.[cacheOf]?.[key]) { //if cache exists
        let cachePatientMessages = cacheStorage?.[cacheOf]?.[key] || [];
        cachePatientMessages = changesToChatMessages({ messages: cachePatientMessages })
        if (cachePatientMessages.length > 0) {
          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf,
              key,
              value: cachePatientMessages,
            })
          );
        }
      }

    } else if(appointment_id && payload.messageData.attributes.chatType === 'private'){
      // if it is a private message
      // const cachedPrivateState = cacheStorage.privateChatMessagesCache[channelSid]

      let cacheOf = mainCacheConstants.privateChatMessagesCache;
      let key = appointment_id;
      if (cacheStorage?.[cacheOf]?.[key]) { //if cache exists
        let cachePrivateMessages = cacheStorage?.[cacheOf]?.[key] || [];
        cachePrivateMessages = changesToChatMessages({ messages: cachePrivateMessages })
        if (cachePrivateMessages.length > 0) {
          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf,
              key,
              value: cachePrivateMessages,
            })
          );
        }
      }
    }
    else {
      //if it is a general chat message
      let cacheOf = mainCacheConstants.chatBoxGeneralChatMessagesCache;
      let key = channelSid;
      if (cacheStorage?.[cacheOf]?.[key]) { //if cache exists


        let cachePatientMessages = cacheStorage?.[cacheOf]?.[key] || [];

        cachePatientMessages = changesToChatMessages({ messages: cachePatientMessages })
        if (cachePatientMessages.length > 0) {
          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf,
              key,
              value: cachePatientMessages,
            })
          );
        }
      } else {
        //if cache doesn't exists
      }
    }
  }

  switch (status) {
    case "onSurgeryReject": {
      console.log('socket for surgery reject', payload)
      const { channelSid, appId: appointment_id } = payload


      const {
        patients: { pendingPatient },
      } = patients;

      const newPendingPatients = pendingPatient.map((item) => {
        if (appointment_id === item._id) {
          return { ...item, wasRejected: true };
        }

        return item;
      });

      const newState = {
        ...patients,
        patients: { ...patients.patients, pendingPatient: newPendingPatients },
      };
      // Mixpanel.track(constants.BLANK_ERROR, { source: "onSurgeryReject", patients: newState });
      dispatch(patientActions.updateFullState(newState));
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf: mainCacheConstants.patientListsCache,
          key: channelSid,
          value: { sid: channelSid, patients: newState["patients"] },
        })
      );

      break;
    }

    case "onSurgeryAccept": {
      const {
        messageData: {
          attributes: { appointment_id },
          channelSid,
        },
        messageData,
      } = payload;

      if (channelSid === currentHospitalData.sid) {
        const {
          patients: { pendingPatient, activePatient },
        } = patients;

        const newActivePatient = pendingPatient.find(
          (item) => appointment_id === item._id
        );
        if (!newActivePatient) {
          return
        }
        const newPendingPatients = pendingPatient.filter(
          (item) => appointment_id !== item._id
        );

        const newState = {
          ...patients,
          patients: {
            ...patients.patients,
            pendingPatient: newPendingPatients,
            activePatient: [newActivePatient, ...activePatient],
          },
        };
        // Mixpanel.track(constants.BLANK_ERROR, { source: "onSurgeryAccept", patients: newState });

        dispatch(patientActions.updateFullState(newState));
        dispatch(
          cacheStorageActions.setCacheStorageItem({
            cacheOf: mainCacheConstants.patientListsCache,
            key: channelSid,
            value: { sid: channelSid, patients: newState["patients"] },
          })
        );
      } else {
        if (cacheStorage.patientListsCache[channelSid]) {
          var cachePatients =
            cacheStorage.patientListsCache[channelSid].patients;
          if (!cachePatients)
            return
          var { pendingPatient, activePatient } = cachePatients
          const newActivePatient = pendingPatient.find(
            (item) => appointment_id === item._id
          );
          if (!newActivePatient) {
            return
          }
          const newPendingPatients = pendingPatient.filter(
            (item) => appointment_id !== item._id
          );
          const newPatientState = {
            ...cacheStorage.patientListsCache[channelSid],
            patients: {
              ...cachePatients,
              pendingPatient: newPendingPatients,
              activePatient: [newActivePatient, ...activePatient],
            },
          };
          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf: mainCacheConstants.patientListsCache,
              key: channelSid,
              value: newPatientState,
            })
          )

          console.log('appointment is added')
        }
      }

      const newMessage = modifyObject(messageData, "", "", true);

      let cacheOf = mainCacheConstants.chatBoxMessagesCache;
      let key = appointment_id;
      const cachePatientMessages = cacheStorage?.[cacheOf]?.[key];
      // return if cache is not present
      if (!cachePatientMessages) {
        return;
      } else {
        const newPatientMesages = [newMessage, ...cachePatientMessages];
        if (
          channelSid === sid ||
          appointment_id ===
          chatBox["patientMessages"]?.[0]?.["Attributes"]?.["appointment_id"]
        ) {

          var newState2 = {};
          newState2 = {
            ...chatBox,
            patientMessages: newPatientMesages,
          };
          dispatch(chatBoxActions.updateFullState(newState2));
        }
        dispatch(
          cacheStorageActions.setCacheStorageItem({
            cacheOf,
            key,
            value: newPatientMesages,
          })
        );
      }
      break;
    }

    case "onMessageAdded": {
      console.log("socket reached on message added")
      const {
        messageData: {
          attributes: {
            authorName,
            authorRole,
            uploadLocalId,
            appointment_id,
          },
          channelSid,
          messageIndex,
          createdAsDate,
          attributes,
          hospital_id,
          author
        },
        messageData,
      } = payload;

      //to update the latest timestamp in the patient list-----------
      const { patients: patientList, patientInfo } = patients;
      let newPatients = {};
      for (let key in patientList) {
        let temp = patientList[key].map((patient) => {
          if (appointment_id === patient["_id"]) {
            return { ...patient, lastMessageDate: createdAsDate };
          } else {
            if (!patient["lastMessageDate"]) {
              return { ...patient, lastMessageDate: 0 };
            } else {
              return patient;
            }
          }
        });

        newPatients[key] = temp;
        newPatients[key].sort(
          (a, b) => b["lastMessageDate"] - a["lastMessageDate"]
        );

        newPatients[key] = newPatients[key].filter(n => n)
      }

      const newPatientState = {
        ...patients,
        patients: newPatients,
      };
      dispatch(patientActions.updateFullState(newPatientState));
      // Mixpanel.track(constants.BLANK_ERROR, { source: "onMessageAdded", payload: newPatientState });


      //to update the latest timestamp in the hospital list-----------
      const newHospitaList = hospitalList.map((hospital) => {
        if (hospital["sid"] === channelSid) {
          return { ...hospital, lastMessageDate: createdAsDate };
        }

        return hospital;
      });
      const newHospitalState = {
        ...hospitals,
        hospitalList: newHospitaList,
      };
      dispatch(hospitalActions.updateFullState(newHospitalState));

      // to update the last messageIndex ------
      hadleLastMessageIndexUpdate({
        dispatch,
        getState,
        lastMessageIndex: messageIndex,
        sid: channelSid,
      });

      const user = JSON.parse(localStorage.getItem("user"));

      // to ignore the case of templates
      if (attributes.templateId && user && attributes.userId === user["userId"]) {
        return;
      }

      const { showGeneralChatMessages } = homeState
      const { privateChatMessagesActive } = chatBox

      const newMessage = modifyObject(
        messageData,
        authorName,
        authorRole,
        true
      );

      const changesToChatMessages = ({ messages }) => {

        let handleSocketActivity = true;
        for (let i = 0; i < messages.length; i++) {
          let id = messages[i].Attributes.uploadLocalId;
          if (id === uploadLocalId) handleSocketActivity = false;
        }
        if (handleSocketActivity) {
          messages = [newMessage, ...messages];
        }
        return messages;
      }
      handleMessageFlow({ messageAndStateDetails: { appointment_id, showGeneralChatMessages, channelSid, hospital_id, author, privateChatMessagesActive }, patientInfo, changesToChatMessages, socketEvent: "onMessageAdded" })
      return
    }

    case "readAppointment":{
      
      const {
        messageData: {
          result:{
            appointmentId:appointment_id,
            userId:user_id
          }
        },
      } = payload;

      const {userId} = authentication.user;
      const { patients: patientList } = patients

      if(user_id===userId){
        console.log('handle with socket count')
        let newPatients = {}
        for (let key in patientList) {
          let temp = patientList[key].map((patient) => {
            if (appointment_id === patient["_id"]) {
              return { ...patient, unreadMessages: 0 };
            }  
            else {
              return patient;
            }
          });
          newPatients[key] = temp
      }
      const newPatientState = {
        ...patients,
        patients: newPatients,
      };
      dispatch(patientActions.updateFullState(newPatientState));
    }
      break
    }

    case "onMessageRemoved":
      {
        const {
          messageData: {
            channelSid,
            messageSid,

            attributes: {
              appointment_id,
              authorName,
              authorRole,
            },
          },
          messageData,
        } = payload;

        const { patientInfo } = patients;
        const { showGeneralChatMessages } = homeState
        //this is the modified message
        const newMessage = modifyObject(
          messageData,
          authorName,
          authorRole,
          true,
          false
        );

        const changesToChatMessages = ({ messages }) => {
          messages = messages.map(item => {
            if (item.MessageSid === messageSid) {
              return newMessage
            } else {
              return item
            }
          })
          return messages
        }

        handleMessageFlow({ messageAndStateDetails: { appointment_id, showGeneralChatMessages, channelSid }, patientInfo, changesToChatMessages })
      }
      break;

    case "checkUserSanity":
      {
      const {
        messageData:{
          result:{
            membersList,
            showPopUp
          }
        }
      } = payload
    
      if(showPopUp){
        dispatch(documentsPopupActions.showSanityPopup())
        dispatch({ type: pcScoreConstants.GET_HOSPITAL_MEMBER_LIST, payload: membersList });
      }
      break
    }

    case "privateChatEvent":
      const {messageData:{result}} = payload
      console.log('private chat event',result)
      dispatch(documentsPopupActions.privateChatPopupSocket(result?.privateChatFlag))
      break
    case "onMemberRemoved":
      {
        const { memberData: [{ sid = "", memberSid = "" }] } = payload;

        const cacheOf = mainCacheConstants.groupInfoListCache;
        let key = sid;

        const groupInfoList =
          cacheStorage?.[cacheOf]?.[key] || groupInfoPopup?.["groupInfo"] || [];

        const newGroupInfoList = groupInfoList.filter(
          (member) => member["memberSid"] !== memberSid
        );

        const hospitalList = hospitals["hospitalList"] || [];

        const newHospitaList = hospitalList.filter(
          (hospital) => hospital["sid"] !== sid
        );

        //to sync the cahe for the group info
        handleAppCacheSync({
          cacheOf,
          key,
          value: newGroupInfoList,
          status,
        });

        const newGroupInfoState = {
          ...groupInfoPopup,
          groupInfo: newGroupInfoList,
        };

        const newHospitalState = {
          ...hospitals,
          hospitalList: newHospitaList,
        };

        dispatch(groupInfoPopupActions.updateFullState(newGroupInfoState));
        dispatch(hospitalActions.updateFullState(newHospitalState));
        dispatch(
          homeStateActions.setState({
            showChatBox: false,
            showGeneralChatMessages: false,
            showGroupInfoPopUp: false,
            showDocumentsPopup: false,
            showAcceptPopup: false,
            showRejectPopup: false,
            showHospitalList: false,
          })
        );
      }
      break;

    case "onBuildUpdated": {
      dispatch(homeStateActions.checkUpdatedBuild(false));
      break;
    }
    case "onChatDisable": {
      const {
        messageData: { appointmentId },
      } = payload;
      dispatch(patientActions.endOfChatObject(appointmentId));
      break;
    }
    case "onAppointmentCreated": {

      console.log('appointment socket is triggered', payload)
      const {
        messageData: { data, channelSid },
      } = payload;
      if (channelSid === currentHospitalData.sid) {
        const newPatientState = {
          ...patients,
          patients: {
            ...patients.patients,
            pendingPatient: [...patients.patients.pendingPatient, ...data],
          },
        };
        dispatch(
          cacheStorageActions.setCacheStorageItem({
            cacheOf: mainCacheConstants.patientListsCache,
            key: channelSid,
            value: newPatientState,
          })
        );
        // Mixpanel.track(constants.BLANK_ERROR, { source: "onAppointmentCreated", payload: newPatientState });
        dispatch(patientActions.updateFullState(newPatientState));
        console.log('appointment is added')

      } else {
        if (cacheStorage.patientListsCache[channelSid]) {
          const cachePatients =
            cacheStorage.patientListsCache[channelSid].patients;
          const newPatientState = {
            ...cacheStorage.patientListsCache[channelSid],
            patients: {
              ...cachePatients,
              pendingPatient: [
                ...cachePatients.pendingPatient,
                ...data,
              ],
            },
          };
          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf: mainCacheConstants.patientListsCache,
              key: channelSid,
              value: newPatientState,
            })
          )

          console.log('appointment is added')
        }

      }
      break;
    }

    case "onPreviousProvBill": {
      const {
        messageData: {
          attributes: {
            appointment_id,
          },
          messageSid,
        },
      } = payload;
      var cacheOf = mainCacheConstants.chatBoxMessagesCache
      var key = appointment_id
      if (patients.patientInfo.appointmentId === appointment_id) {
        var messages = chatBox.patientMessages
        messages = messages.map(item => {
          if (item.MessageSid === messageSid) {
            item.Attributes.docApproval = false
          }
          return item
        })
        var newState = { ...chatBox, patientMessages: messages }
        dispatch(chatBoxActions.updateFullState(newState));
        dispatch(
          cacheStorageActions.setCacheStorageItem({
            cacheOf,
            key,
            value: messages,
          })
        );
      } else {
        const cachePatientMessages = cacheStorage?.[cacheOf]?.[key] || [];

        if (cachePatientMessages.length > 0) {

          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf: mainCacheConstants.chatBoxMessagesCache,
              key: appointment_id,
              value: messages,
            })
          );
        }
      }
      break;
    }

    case "onAppointmentCancelled": {
      const updatePatientsWithCancelledAppointment = (patientArray) => {
        return patientArray.map((patient, i) => {
          if (payload.messageData.appointmentId === patient._id) {
            patient.appointmentStatus = 'Cancelled'
          }
          return patient
        });
      }

      try {
        if (hospitals?.currentHospitalData?.sid === payload?.messageData?.channelSid) {
          const newState = { ...patients }
          const activePatients = patients?.patients?.activePatient
          const pendingPatients = patients?.patients?.pendingPatient

          if (activePatients?.length > 0) newState.patients.activePatient = updatePatientsWithCancelledAppointment(patients?.patients?.activePatient)
          if (pendingPatients?.length > 0) newState.patients.pendingPatient = updatePatientsWithCancelledAppointment(patients?.patients?.pendingPatient)

          // Mixpanel.track(constants.BLANK_ERROR, { source: "onAppointmentCancelled", patients: newState });

          dispatch(patientActions.updateFullState(newState));
        }
        if (cacheStorage.patientListsCache[payload.messageData.channelSid]) {
          const patients = cacheStorage.patientListsCache[payload.messageData.channelSid]
          const newState = { ...patients }
          const activePatients = patients?.patients?.activePatient
          const pendingPatients = patients?.patients?.pendingPatient

          if (activePatients?.length > 0) newState.patients.activePatient = updatePatientsWithCancelledAppointment(patients?.patients?.activePatient)
          if (pendingPatients?.length > 0) newState.patients.pendingPatient = updatePatientsWithCancelledAppointment(patients?.patients?.pendingPatient)

          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf: mainCacheConstants.patientListsCache,
              key: cacheStorage.patientListsCache[payload.messageData.channelSid],
              value: newState,
            })
          );
        }
      }
      catch (err) {
        console.error("onAppointmentCancelled:", err)
      }
      break;
    }

    case "onPreviousDischargeSummary": {
      const {
        messageData: {
          attributes: {
            appointment_id,
          },
          messageSid,
        },
      } = payload;
      const cacheOf = mainCacheConstants.chatBoxMessagesCache
      const key = appointment_id
      if (patients.patientInfo.appointmentId === appointment_id) {
        let messages = chatBox.patientMessages
        messages = messages.map(item => {
          if (item.MessageSid === messageSid) {
            item.Attributes.opthalApproval = false
          }
          return item
        })
        const newState = { ...chatBox, patientMessages: messages }
        dispatch(chatBoxActions.updateFullState(newState));
        dispatch(
          cacheStorageActions.setCacheStorageItem({
            cacheOf,
            key,
            value: messages,
          })
        );
      } else {
        const cachePatientMessages = cacheStorage?.[cacheOf]?.[key] || [];

        if (cachePatientMessages.length > 0) {

          dispatch(
            cacheStorageActions.setCacheStorageItem({
              cacheOf: mainCacheConstants.chatBoxMessagesCache,
              key: appointment_id,
              value: messages,
            })
          );
        }
      }
      break;
    }
    case "updateDynamicBotMessage": {
      const {
        messageData: {
          channelSid,
          modifiedAttributes,
          modifiedAttributes: { appointment_id },
          messageSid,
        },
      } = payload;
      const { patientInfo } = patients;
      const { showGeneralChatMessages } = homeState

      const changesToChatMessages = ({ messages }) => {
        messages = messages.map(item => {
          if (item.MessageSid === messageSid) {
            item.Attributes = modifiedAttributes
            delete item.waitingForApiResponse
          }
          return item
        })
        return messages
      }
      handleMessageFlow({ messageAndStateDetails: { appointment_id, showGeneralChatMessages, channelSid }, patientInfo, changesToChatMessages })
      break;
    }
    case "onTentativeListEvent": {
      const {
        messageData: {
          channelSid,
          data
        },
      } = payload;


      const changesToAppointmentList = ({ patientsList }) => {

        for (let key in patientsList) {
          patientsList[key] = patientsList[key].filter(item => {
            return item._id != data[0]['leadId']
          })
        }
        return patientsList;
      }
      handleAppointmentFlow({ updatePatientList: changesToAppointmentList, channelSid, currHospitalSid: currentHospitalData.sid, appointmentAndStateDetails: { appointmentPayload: data[0] } })
      break;
    }
    case "onTentativeCreateEvent": {
      const {
        messageData: {
          channelSid,
          data
        },
      } = payload;
      const changesToAppointmentList = ({ patientsList, appointmentPayload }) => {
        const newPatientsList={...patientsList}
        newPatientsList["tentativePatient"]=[appointmentPayload,...newPatientsList["tentativePatient"]]
        return newPatientsList;
      }
      handleAppointmentFlow({ updatePatientList: changesToAppointmentList, channelSid, currHospitalSid: currentHospitalData.sid, appointmentAndStateDetails: { appointmentPayload: data[0] } })
      break;
    }
    default:
      break;
  }
};
