import randomColor from "randomcolor";
// import { useCallback, useState, useEffect } from "react";

// export const useRandomColor = (prams) => {
//   //   const [newColor, setNewColor] = useState(randomColor())

//   //   const getNewRandomColor = useCallback(() => {

//   //     return newColor;
//   //   }, [prams]);

//   //   useEffect(() => {
//   //     getNewRandomColor()
//   //   }, [prams])

//   const newColor = randomColor();

//   return newColor;
// };

export class GetRandomColor {
  colorMap = new Map();

  getNewColor(key) {
    if (this.colorMap.has(key)) return this.colorMap.get(key);

    this.colorMap.set(
      key,
      randomColor({
        luminosity: "dark",
      })
    );
    return this.colorMap.get(key);
  }
}
