import {
  getTokenFromLocal,
  handleResponse
} from "../_helpers/handleResponseMiddleware";

export const configService = {
  fetchVersionConfig,
  fetchCommonData,
  getDocumentList,
  sendAlarmAction,
  getChannelSid,
  getCommonOpthalData
};

let apiUrl = process.env.REACT_APP_BASE_URL;
let commonApiUrl = process.env.REACT_APP_BASE_URL_COMMON;


export function fetchVersionConfig() {
  // const { appointmentId, patientId, leadId } = params;
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const params={key:"VersionConfigHospitalChatWeb"}
  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // mobileplatform: "web",
      "Content-Type": "application/json",
    },

    body: JSON.stringify(params),
  };

  return fetch(`${commonApiUrl}/api/getVersionConfigForHospitalChatKey`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function fetchCommonData() {
  // const { appointmentId, patientId, leadId } = params;2fd
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      // mobileplatform: "web",
      "Content-Type": "application/json",
    },
  };

  return fetch(`${commonApiUrl}/api/getcommondata?Name=hospChatSchema`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function getDocumentList(params) {
  // const { appointmentId, patientId, leadId } = params;2fd
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,

    },
    body: JSON.stringify(params),
  };
  return fetch(`${apiUrl}/api/listofDocsRoleWise`, config).then(handleResponse)
    .then
    ((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}
export function sendAlarmAction(payload) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: JSON.stringify({ ...payload }),
  };
  return fetch(`${apiUrl}/api/sendAlarmBotMessage`, config).then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function getChannelSid(params) {
  // const { appointmentId, patientId, leadId } = params;
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // mobileplatform: "web",
      "Content-Type": "application/json",
    },

    body: JSON.stringify(params),
  };

  return fetch(`${apiUrl}/api/getHospitalSid`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function getCommonOpthalData(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };

  return fetch(
    `${apiUrl}/api/getOphthalCommonData`,
    config
  )
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}