import { alertActions, configActions } from ".";
import { groupInfoPopupConstants } from "../_constants";
import { groupInfoPopupService } from "../_services";
import { pcScoreActions } from "./pcScore.actions";

// import { history } from "../_helpers";

function getGroupInfoList(data) {
  const {
    params = {},
    callback = null,
    fetchFromServiceFile = false,
    cache = undefined,
  } = data;

  return (dispatch) => {
    dispatch(request());
    // dispatch(configActions.showSpinningLoader());

    if (fetchFromServiceFile) {
      groupInfoPopupService.getGroupInfoList(params).then(
        (res) => {
          const { sid, groupInfo } = res;

          dispatch(success(groupInfo));

          //callback added for the support of the the caching work
          Boolean(callback) && callback(groupInfo);

          // dispatch(configActions.hideSpinningLoader());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      dispatch(success(cache));
      // dispatch(configActions.hideSpinningLoader());
    }
  };

  function request() {
    return {
      type: groupInfoPopupConstants.GROUP_INFO_LIST_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: groupInfoPopupConstants.GROUP_INFO_LIST_FETCHED,
      groupInfo: res,
    };
  }
  function failure(error) {
    return { type: groupInfoPopupConstants.GROUP_INFO_LIST_FAILED, error };
  }
}

const addGroupMember = (params) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());

    groupInfoPopupService.addGroupMember(params).then(
      (res) => {
        dispatch(success(res));
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        dispatch(configActions.hideSpinningLoader());
      }
    );
  };

  function request() {
    return {
      type: groupInfoPopupConstants.ADD_GROUP_MEMBER_REQUESTING,
    };
  }
  function success(addMemberResponse) {
    return {
      type: groupInfoPopupConstants.ADD_GROUP_MEMBER_SUCCESS,
      addMemberResponse,
    };
  }
  function failure(error) {
    return { type: groupInfoPopupConstants.ADD_GROUP_MEMBER_FAILED, error };
  }
};

const removeGroupMember = (params, callback = null,pcScoreTab=null) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());
    groupInfoPopupService.removeGroupMember(params).then(
      (res) => {
        dispatch(success(res));
        res?.status !== "Failed" && !!callback && callback(true);
        dispatch(configActions.hideSpinningLoader());

        if(pcScoreTab){
            const {sid}=pcScoreTab
          dispatch(pcScoreActions.getHospitalMembersListAction({
            channelSid: sid,
            listType: true
          }))
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        dispatch(configActions.hideSpinningLoader());
      }
    );
  };

  function request() {
    return {
      type: groupInfoPopupConstants.REMOVE_GROUP_MEMBER_REQUESTING,
    };
  }
  function success(removeMemberResponse) {
    return {
      type: groupInfoPopupConstants.REMOVE_GROUP_MEMBER_SUCCESS,
      removeMemberResponse,
    };
  }
  function failure(error) {
    return { type: groupInfoPopupConstants.REMOVE_GROUP_MEMBER_FAILED, error };
  }
};

const updateFullState = (newState) => {
  return {
    type: groupInfoPopupConstants.UPDATE_FULL_GROUP_MEMBER_STATE,
    newState,
  };
};

export const groupInfoPopupActions = {
  getGroupInfoList,
  addGroupMember,
  removeGroupMember,
  updateFullState,
};
