import { toast } from 'react-toastify';
import { chatBoxConstants } from '../_constants/chatBox.constants';
import { cleanObj } from '../_helpers';
import { getTokenFromLocal, handleResponse } from "../_helpers/handleResponseMiddleware";
let apiUrl = process.env.REACT_APP_BASE_URL;
let commonApiUrl = process.env.REACT_APP_BASE_URL_COMMON;

export function getPatientMessages(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  params=cleanObj(params)
  // if(params["appointmentId"]){
  //   if(params["leadId"]){
  //     delete params["leadId"]
  //   }
  // }
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };


  return fetch(`${apiUrl}/api/v2/getPatientMessages`, config)
    .then(handleResponse)
    .then((res) => {
      return res.result;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      return response;
    });
}

export function getGeneralChatMessages(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  params['limit'] = chatBoxConstants.MESSAGES_LIMIT
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(params),
  };

  return fetch(`${apiUrl}/api/getGeneralChatMessages`, config)
    .then(handleResponse)
    .then((res) => {
      return res.result;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function sendMessage(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const formData = new FormData();
  params=cleanObj(params)
  Object.keys(params).forEach((key) => {
    if (key === "attributes") {
      formData.append(key, JSON.stringify(params[key]));
    } else {
      formData.append(key, params[key]);
    }
  });

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      // 'Content-Type':"multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: formData,
  };

  return fetch(`${apiUrl}/api/v2/sendMessage`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function deleteMessage(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };
  return fetch(`${apiUrl}/api/HospitalChat/deleteMessage`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function sendFile(params) {

  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const formData = new FormData();
  console.log('dragon params',params)
  params=cleanObj(params)
  Object.keys(params).forEach((key) => {
    if (key === "attributes") {
      formData.append(key, JSON.stringify(params[key]));
    } else if (key === "files") {
      console.log('dragon in service',params[key])
      for (let i = 0; i < params[key].length; i++) {
        let item = params[key][i];
        if(item)
        formData.append(key, item, item.name);
      }
    } else {
      formData.append(key, params[key]);
    }
  });


  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      // 'Content-Type':"multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: formData,
  };

  return fetch(`${apiUrl}/api/v2/sendMessage`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function sendReply(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  params=cleanObj(params)

  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (key === "attributes") {
      formData.append(key, JSON.stringify(params[key]));
    } else {
      formData.append(key, params[key]);
    }
  });

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      // 'Content-Type':"multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: formData,
  };


  return fetch(`${apiUrl}/api/v2/sendMessage`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function getChatTemplates(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "GET",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
  };

  return fetch(
    `${apiUrl}/api/HospChatTemplates?role=OT%20Staff&appointmentId=${params}`,
    config
  )
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function sendChatTemplate(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };


  return fetch(`${apiUrl}/api/appointment/${params.appointmentId}/sendHospChatTemplates`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function sendToInsurance(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };


  return fetch(`${apiUrl}/api/HospitalChat/syncToInsDocs`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}
export function canUploadDocumentCheck(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/canUploadFileBill`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function downloadChatMessage(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };


  return fetch(`${commonApiUrl}/api/getSignedUrl`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}


export function uploadToS3(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };


  return fetch(`${commonApiUrl}/api/v2/save/multipleImage`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);
}

export function approveDocChanges(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  if (params.amount && params.amount < 0) {
    delete params["amount"]
  }
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };
  return fetch(`${apiUrl}/api/provBillStatusUpdate`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      toast.error(error)
      console.error(error);
    })
    .then(response => response);

}
export function getFinalBillDataByOCR(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
   const formData = new FormData();
   Object.keys(params).forEach((key) => {
    if (key === "attributes") {
      formData.append(key, JSON.stringify(params[key]));
    } else if (key === "files") {
      
      console.log('dragon in service',params[key])
        let item = params[key];
        formData.append("bill", item, item.name);
    } else {
      formData.append(key, params[key]);
    }
  });
  const config = {
    method: "POST",
    headers: {
      // mobileplatform: "web",
      // 'Content-Type':"multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    // redirect: "follow",
    body: formData,
  };
  return fetch(`http://65.0.124.73/ocr/api/getFinalBillDataByOCR`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response => response);

}

export function dischargeSummaryStatusUpdate(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
    },
    // redirect: "follow",
    body: JSON.stringify({ ...params }),
  };
  return fetch(`${apiUrl}/api/dischargeSummaryStatusUpdate`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      toast.error(error)
      console.error(error);
    })
    .then(response => response);
}

export function uploadPodDocuments(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (key === "files") {
      for (let i = 0; i < params[key].length; i++) {  
        let item = params[key][i];
        if(item)
        formData.append(key, item, item.name);
      }
     
    } else {
      formData.append(key, params[key]);
    }
  });
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
    body: formData,
  };

  // return fetch(`${apiUrl}/api/uploadPodDoc`, config)
    return fetch(`${apiUrl}/api/uploadPodDoc`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}
export function sendBotMessageResponse(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const formData = new FormData();
  Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
  });
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
    body: formData,
  };

  // return fetch(`${apiUrl}/api/uploadPodDoc`, config)
    return fetch(`${apiUrl}/api/sendBotMessageResponse`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function sendQueryLetter (params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    if (key === "files") {
      for (let i = 0; i < params[key].length; i++) {  
        let item = params[key][i];
        if(item)
        formData.append(key, item, item.name);
      }
     
    } else {
      formData.append(key, params[key]);
    }  });
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
    },
    redirect: "follow",
    body: formData,
  };

  // return fetch(`${apiUrl}/api/uploadPodDoc`, config)
    return fetch(`${apiUrl}/api/uploadQueryLetter`, config)
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export const chatBoxService = {
  getPatientMessages,
  getGeneralChatMessages,
  sendMessage,
  deleteMessage,
  sendFile,
  sendReply,
  getChatTemplates,
  sendChatTemplate,
  sendToInsurance,
  downloadChatMessage,
  uploadToS3,
  approveDocChanges,
  canUploadDocumentCheck,
  getFinalBillDataByOCR,
  dischargeSummaryStatusUpdate,
  uploadPodDocuments,
  sendBotMessageResponse,
  sendQueryLetter
};
