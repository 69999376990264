import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { checklistActions } from "../../_actions/checklist.actions";
import { mainCacheConstants, useReduxCacheAndDispatch } from "../../_helpers";
import { patientProps ,hospitalViewProps} from "./checklistConstant";

function getFormatedDate(date) {
  return new Date(date).toISOString().split("T")[0];
}

export const useFetchHospital = () => {
  const isMounted = useRef(false);
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const response = useSelector(
    (state) => state.cacheStorage.checklistHospitalScreenCache
  );
  const hospitalFilter = useSelector((state) => state.checklistSummary.hospitalFilter);
  const [filteredHospitals, setFilteredHospitals] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [cityName, setCityName] = useState([]);
  const [searchKeys, setSearchKeys] = useState("");
  const screenName = useSelector((state) => state.checklistSummary.screenName);
 
  const [startDateState, setStartDateState] = useState(
    getFormatedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  );
  const [endDateState, setEndDateState] = useState(getFormatedDate(new Date()));


  function fetchData(startDate, endDate) {
    cacheAndDispatch({
      action: checklistActions.getChecklistHospital,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      cacheOf: mainCacheConstants.checklistHospitalScreenCache,
      key: "hospitalScreen",
      fetchApi: true,
    });
  }

  function filterAndfetchHospital({ search, city, startDate, endDate } = {}) {
    city !== undefined && Array.isArray(city)
      ? setCityName(city)
      : console.error("city not a type of Array!");
    search !== undefined && setSearchKeys(search.toLocaleLowerCase());
    !!startDate && setStartDateState(startDate);
    !!endDate && setEndDateState(endDate);
  }

  useEffect(() => {
    if (isMounted.current) {
      filterAndfetchHospital(hospitalFilter);
    } else {
      isMounted.current = true;
    }
  }, [hospitalFilter]);

  const filterHospitalArray = (HopitalsArray) => {

    setFilteredHospitals(
      HopitalsArray.filter((hospital) => {
        let checkCity = !!cityName.length
          ? cityName.some(
            (city) =>
              hospital?.city.toLocaleLowerCase() ===
              city.toLocaleLowerCase()
          )
          : true;
        let checkSearchKey = !!searchKeys
          ? hospital?.hospitalName?.toLocaleLowerCase().includes(searchKeys)
          : true;
        return checkCity && checkSearchKey;
      })
    );

  };

  function loadMore(reset = false) {
    const count = reset ? 1 : pageNumber;
    const countedHospital = filteredHospitals.slice(0, count * 10);
    setHospitals(countedHospital);
    if (hospitals.length !== countedHospital.length) setPageNumber(count + 1);
    else setPageNumber(0);
  }

  useEffect(() => {
    filterHospitalArray(response?.hospitalScreen || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, searchKeys, cityName]);

  useEffect(() => {
    if (screenName === "hospitalScreen") {
      fetchData(startDateState, endDateState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateState, endDateState, screenName]);

  useEffect(() => {
    loadMore(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredHospitals]);

  return { hospitals, filterAndfetchHospital, loadMore, pageNumber };
};

export const useFetchPatient = ({ hospital } = {}) => {
  const isMounted = useRef(false);
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const [startDateState, setStartDateState] = useState(
    getFormatedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
  );
  const [endDateState, setEndDateState] = useState(getFormatedDate(new Date()));
  const [hospitalId, setHospitalId] = useState(hospital);
  const [searchKeys, setSearchKeys] = useState("");
  const [MOP, setMOP] = useState([]);
  const [PDS, setPDS] = useState("");
  const [BBS, setBBS] = useState("");
  const [pageNumberP, setPageNumberP] = useState(1);
  const [filterStatus, setFilterStatus] = useState("");
  const patientsData = useSelector(
    (state) => state.cacheStorage.checklistPatientScreenCache
  );
  const patientFilter = useSelector((state) => state.checklistSummary.patientFilter);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [patients, setPatients] = useState([]);

  // this will run whenever there is a change in my filter state
  useEffect(() => {
    if (isMounted.current) {
      filterAndfetchPatient(patientFilter);
    } else {
      isMounted.current = true;
    }
  }, [patientFilter]);
  //this is to set fresh state of filters
  const filterAndfetchPatient = ({
    hospital,
    startDate,
    endDate,
    filterStatus,
    search,
    mop,
    pds,
    bbs,
  } = {}) => {
    !!hospital && setHospitalId(hospital);
    filterStatus !== undefined && setFilterStatus(filterStatus);
    pds !== undefined && setPDS(pds);
    bbs !== undefined && setBBS(bbs);
    mop !== undefined && Array.isArray(mop)
      ? setMOP(mop)
      : console.error("MOP is not a type of Array!");
    search !== undefined && setSearchKeys(search.toLocaleLowerCase().trim());
    !!startDate && setStartDateState(startDate);
    !!endDate && setEndDateState(endDate);
  };

  //this is for api call
  useEffect(() => {
    !!hospitalId && fetchData(startDateState, endDateState, hospitalId, filterStatus);
  }, [hospitalId, startDateState, endDateState, filterStatus]);


  //api call 
  function fetchData(startDate, endDate, hospital_id, filter_status = null) {
    var params = {
      startDate: startDate,
      endDate: endDate,
      hospitalId: hospital_id,
    };

    if (!!filter_status) {
      params["filter_status"] = filter_status;
    }
    !!hospital_id &&
      cacheAndDispatch({
        action: checklistActions.getChecklistPatient,
        params: params,
        cacheOf: mainCacheConstants.checklistPatientScreenCache,
        key: "patientScreen",
        fetchApi: true,
      });
  }
  //this will get me the filtered array
  useEffect(() => {
    let filterdPatients =
      patientsData?.patientScreen?.filter((patient) => {
        let checkSearchKey = !!searchKeys
          ? patient?.PatientFirstName?.toLocaleLowerCase().includes(searchKeys)
          : true;
        let checkMOP = !!MOP.length
          ? MOP.some(
            (mop) =>
              patient[patientProps.mop]?.toLocaleLowerCase() ===
              mop.toLocaleLowerCase()
          )
          : true;
        let checkbbs = !!BBS.length
          ? BBS.some(
            (bbs) =>
              patient[patientProps.bbs]?.toLocaleLowerCase() ===
              bbs.toLocaleLowerCase()
          )
          : true;
        let checkpds = !!PDS.length
          ? PDS.some(
            (pds) =>
              patient[patientProps.pds]?.toLocaleLowerCase() ===
              pds.toLocaleLowerCase()
          )
          : true;

        return checkSearchKey && checkbbs && checkpds && checkMOP;
      }) || [];
    setFilteredPatients(filterdPatients);
  }, [patientsData, searchKeys, MOP, PDS, BBS]);
 
  //useEffect for load more
  useEffect(() => {
    loadMoreP(true);
  }, [filteredPatients]);
  //this is for infinite load
  function loadMoreP(reset = false) {
    const count = reset ? 1 : pageNumberP;
    const countedPatients = filteredPatients.slice(0, count * 10);
    setPatients(countedPatients);
    if (patients.length !== countedPatients.length) setPageNumberP(count + 1);
    else setPageNumberP(0);
  }

  return { patients, filterAndfetchPatient, loadMoreP, pageNumberP };
};


export const useFetchHostpitalPatient = ({ hospital } = {}) => {
  const isMounted = useRef(false);
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const response = useSelector(
    (state) => state.cacheStorage.checklistHospitalPatientScreenCache
  );
  const hostpitalPatientFilter = useSelector((state) => state.checklistSummary.hospitalViewFilter);
  const [filteredHostpitalPatientFilters, setFilteredHostpitalPatientFilters] = useState([]);
  const [hospitalPatients, setHospitalPatientFilters] = useState([]);
  const [pageNumberPH, setPageNumber] = useState(1);
  const [cityName, setCityName] = useState([]);
  const [searchKeys, setSearchKeys] = useState("");
  const screenName = useSelector((state) => state.checklistSummary.screenName);
  const activeTab = useSelector((state) => state.checklistSummary.activeTab);
  const isFetchingSurgeryAcceptReject = useSelector((state)=>state.patients.isFetchingSurgeryAcceptReject)
  const [startDateState, setStartDateState] = useState();
  const [MOP, setMOP] = useState([]);
  const hospitalId = useSelector((state)=> state.hospitals.currentHospitalData.hospital_id)
  const [endDateState, setEndDateState] = useState();
  const [fetchDataCheck,setFetchDataCheck] = useState(false)
  function fetchData(startDate, endDate) {
    cacheAndDispatch({
      action: checklistActions.getPatientsListPerformance,
      params: {
        startDate: startDate,
        endDate: endDate,
        "hospitalId" : hospitalId,
        "patientType" : activeTab
      },
      cacheOf: mainCacheConstants.checklistHospitalPatientScreenCache,
      key: "hospitalViewScreen",
      fetchApi: true,
    });
  }
  useEffect(()=>{
    if(activeTab==='pending'){
      setStartDateState(getFormatedDate(new Date()))
      setEndDateState(getFormatedDate(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)))
    }
    else{
      fetchData(getFormatedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormatedDate(new Date()));
    }
  },[activeTab])

  useEffect(() => {
    console.log("MOP",MOP,response,patientProps)
    let filterdPatients =
    response?.hospitalViewScreen?.filter((patient) => {
      console.log("MOP 2",patient.patientFirstName,searchKeys , patient?.patientFirstName?.toLocaleLowerCase().includes(searchKeys))
        let checkSearchKey = !!searchKeys
          ? patient?.patientFirstName?.toLocaleLowerCase().includes(searchKeys)
          : true;
        let checkMOP = !!MOP.length
          ? MOP.some(
            (mop) =>
              patient[hospitalViewProps.mop]?.toLocaleLowerCase() ===
              mop.toLocaleLowerCase()
          )
          : true;
        return checkSearchKey && checkMOP;
      }) || [];
      console.log("response",response.hospitalViewScreen)
      console.log("filterdPatients",filterdPatients)
      setFilteredHostpitalPatientFilters(filterdPatients);
  }, [response, searchKeys, MOP]);
  
  function filterAndfetchHospitalPatients({ search, startDate, endDate ,mop} = {}) {
    search !== undefined && setSearchKeys(search.toLocaleLowerCase());
    mop !== undefined && Array.isArray(mop)
    ? setMOP(mop)
    : console.error("MOP is not a type of Array!");
    !!startDate && setStartDateState(startDate);
    !!endDate && setEndDateState(endDate);
  }
  // console.log("hostpitalPatientFilter",hostpitalPatientFilter)
  useEffect(() => {
    if (isMounted.current) {
      console.log("hostpitalPatientFilter",hostpitalPatientFilter)
      filterAndfetchHospitalPatients(hostpitalPatientFilter);
    } else {
      isMounted.current = true;
    }
  }, [hostpitalPatientFilter]);

  const filterHospitalArray = (HopitalsArray) => {
    console.log("HopitalsArray", HopitalsArray);
    let filterdHospitalPatients =
    HopitalsArray?.filter((patient) => {
        let checkSearchKey = !!searchKeys
          ? patient?.PatientFirstName?.toLocaleLowerCase().includes(searchKeys)
          : true;
        return checkSearchKey;
      }) || [];
    setFilteredHostpitalPatientFilters(filterdHospitalPatients);

  };

  function loadMorePH(reset = false) {
    const count = reset ? 1 : pageNumberPH;
    const countedHospital = filteredHostpitalPatientFilters.slice(0, count * 10);
    console.log("loadMorePH", countedHospital, filteredHostpitalPatientFilters)
    setHospitalPatientFilters(filteredHostpitalPatientFilters);
    if (hospitalPatients.length !== countedHospital.length) setPageNumber(count + 1);
    else setPageNumber(0);
  }

  // useEffect(() => {
  //   filterHospitalArray(response?.hospitalViewScreen || []);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [response, searchKeys, cityName]);

  useEffect(() => {
    if (screenName === "hospitalViewScreen" || !isFetchingSurgeryAcceptReject ) {
      // console.log("DateState",startDateState,endDateState)
      fetchData(startDateState, endDateState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateState, endDateState, screenName,isFetchingSurgeryAcceptReject]);

  useEffect(() => {
    loadMorePH(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredHostpitalPatientFilters]);

  return { hospitalPatients, filterAndfetchHospitalPatients, loadMorePH, pageNumberPH };

};
