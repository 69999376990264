import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { useState } from 'react';
import { downloadFileInBrowser } from '../../../_helpers';
import UploadDocumentThumbnail from './UploadDocumentThumbnail';

function PreviewDocument({ previewUrls, docType, documentTitle,allDocs,showCustomHeader=true }) {
  const [activeId, setActiveId] = useState(0)
  const changeActiveItem = (id) => { setActiveId(id) };
  const removeDoc = () => {

  }

  const handleDownload=()=>{
    
    downloadFileInBrowser({previewUrl:[allDocs[activeId]],documentTitle:documentTitle})
  }
  return (<>
    <Box className="uploadDocumentHeader"
      sx={{
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: ['18px', '18px', '22px'],
        fontWeight: ['600', '600', '700'],
        py: ['17px'],
        position: 'sticky',
        top: 0,
        display:showCustomHeader?'inherit':'none'
      }}
    >
      Preview
    </Box>
    {console.log('these are the preview urls', previewUrls)}
    <Box sx={{ padding: ["10px 10px 10px 10px"] }}>
      <Typography
        variant="h5"
        sx={{
          color: "#7A7A7A",
          fontWeight: ["600", "600", "medium"],
          fontSize: ["14px", "14px", "clamp(1.2rem, 5vw, 1.8rem)"],
        }}
      >
        {documentTitle}
      </Typography>
    </Box>
    <Box sx={{ flexDirection: ["column", "column", "row"], p: ['0px 20px 0px 20px'],overflow:"auto" }}>
      <Box sx={{ height: "265px",position:'relative' }}>

        <UploadDocumentThumbnail
          previewUrl={previewUrls[activeId]}
          uploadedDocument={previewUrls[activeId]}
          bigThumbnail={true}
          docType={docType}
        />
      </Box>
      <Box className="previewDocumentsContainer" sx={{ overflowX: "auto", display: "flex", py: "15px", gap: "10px" }}>
        {previewUrls.map((item, id) => {
          return (
            <Box sx={{ background: "#F5F5F5", borderRadius: '5px', width: ['110px', 'auto'], height: ['76px', 'auto'] }} >
              <UploadDocumentThumbnail
                previewUrl={previewUrls[id]}
                uploadedDocument={item}
                docType={docType}
                changeActiveItem={changeActiveItem}
                id={id}
                activeId={activeId}
                showSelected={true}
                selected={id === activeId ? true : false}
                removeDoc={removeDoc}
                showRemoveDoc={false}
              />
            </Box>
          )
        })}

      </Box>
      <Grid container sx={{justifyContent:"center"}}>
        <Button
          variant="contained"
          sx={{
            background: "#3D7CC9!important",
            color: "white!important",
            marginTop: "5px",
          }}
          className="uploadActionButton"
        onClick={(e) => handleDownload()}
        >
          Download
        </Button>     
      </Grid>
    </Box>
  </>)
}
export default PreviewDocument


