import { CropSquareRounded, SquareRounded } from "@mui/icons-material";
import { Autocomplete, Box, Button, Checkbox, Dialog, Divider, Grid, List, ListItem, Slide, styled, TextField, Typography } from "@mui/material";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { checklistActions } from "../../../_actions/checklist.actions";
import { SettingIcon } from "./FIlterComponent/filterIcons";
import { formatDate } from "./FIlterComponent/formatDate";
import './style.css';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
  }
`;

const Filter = ({ HospitalClickEvent }) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }])

  const { screenName } = useSelector(({ checklistSummary }) => checklistSummary);
  const [filter, setFilter] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen} sx={{ padding: 0, minWidth: '40px', height: '40px', borderColor: 'white', backgroundColor: '#fff' }}>
        <SettingIcon />
      </Button>

      <StyledDialog
        fullScreen
        scroll="paper"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: { backgroundColor: 'transparent', pt: '65px', pointerEvents: 'none' }
        }}
      >
        <Box sx={{ pointerEvents: 'all', overflow: 'auto', height: 'calc(100vh - 65px)' }}>
          <Box sx={{ backgroundColor: "#fff", height: '100%', borderRadius: '10px 10px 0 0', padding: '15px', color: "#3d7cc9", position: 'relative' }}>
            {screenName === 'hospitalScreen' && <HospitalFilter closeDialog={handleClose} />}
            {screenName === 'patientScreen' && <PatientFilter closeDialog={handleClose} />}
          </Box>
        </Box>
      </StyledDialog>
    </>
  );
}

export default Filter;

function FilterTitle({ children, ...rest }) {
  return <Typography component={`h4`} variant="body1" sx={{ fontWeight: 600 }} {...rest} >{children}</Typography>
}

function CustomListItem({ children, name, onChange, checked }) {
  return (
    <ListItem sx={{ py: 0 }}>
      <Typography sx={{ mr: 'auto' }}>{children}</Typography>
      <Checkbox checked={!!checked} onChange={onChange} sx={{ p: '5px', color: "#3d7cc9" }} icon={<CropSquareRounded />} checkedIcon={<SquareRounded />} inputProps={{ name: name }} />
    </ListItem>
  )
}

function CitySearchField({ onChange, cityData }) {
  const citys = useSelector(({ checklistSummary }) => checklistSummary?.cityArr)
  const [defaultCity, setDefaultCity] = useState([])
  useEffect(() => {
    setDefaultCity(cityData)
  }, [cityData])
  return (
    <Box>
      <FilterTitle >Select City</FilterTitle>
      <Autocomplete
        multiple
        id="city-names"
        size="small"
        value={defaultCity}
        options={citys.map((option) => option?.name)}
        renderInput={(params) => <TextField {...params} placeholder="Start typing here" />}
        sx={{ mt: 1 }}
        onChange={(e, data) => {
          let filterCity = citys.filter(({ name }) => data.includes(name))
          onChange(e, filterCity)
        }}
      />
    </Box>
  )
}

function HospitalFilter({ closeDialog }) {
  const dispatch = useDispatch()
  const { filterStatus, hospitalFilter } = useSelector(({ checklistSummary }) => checklistSummary)
  const [getCity, setgetCity] = useState([])
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }])

  function handleApplyFilter() {

    const filterObj = {
      ...hospitalFilter,
      startDate: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
      city: getCity
    }

    dispatch(checklistActions.setChecklistFilters({ screenName: 'hospitalScreen', filterObj }))
    closeDialog && closeDialog()
  }

  function handleClearFilter() {
    const filterObj = {
      ...hospitalFilter,
      startDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      city: []
    }
    dispatch(checklistActions.setChecklistFilters({ screenName: 'hospitalScreen', filterObj }))
  }

  useEffect(() => {
    const { startDate, endDate, city } = hospitalFilter

    setDateRange([{
      startDate: !!startDate ? new Date(startDate) : new Date(),
      endDate: !!endDate ? new Date(endDate) : new Date(),
      key: 'selection'
    }])
    setgetCity(city)
  }, [hospitalFilter])

  return <>
    <Box sx={{ pb: '30px', overflow: 'auto', height: '100%' }}>
      <Box>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <FilterTitle >Start Date</FilterTitle>
            <TextField size="small" value={formatDate(dateRange[0].startDate)} InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={6}>
            <FilterTitle >End Date</FilterTitle>
            <TextField size="small" value={formatDate(dateRange[0].endDate)} InputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DateRangePicker
            ranges={dateRange}
            showDateDisplay={false}
            onChange={item => setDateRange([item.selection])}
            dateDisplayFormat={`dd-mmmm-yyy`}
            showMonthArrow={false}
          />
        </Grid>
      </Box>

      <CitySearchField cityData={getCity} onChange={(e, data) => { setgetCity(data.map(({ id }) => id)) }} />
    </Box>
    <Box sx={{ display: 'flex', position: 'absolute', left: 0, right: 0, bottom: 0, backgroundColor: '#fff', zIndex: 1 }}>
      <Button variant="outlined" color="primary" sx={{ width: '50%', borderRadius: '5px 5px 0px 0px' }} onClick={handleClearFilter}>Clear Filters</Button>
      <Button variant="contained" color="primary" sx={{ width: '50%', borderRadius: '5px 5px 0px 0px' }} onClick={handleApplyFilter}>Apply Filters</Button>
    </Box>
  </>
}

function PatientFilter({ closeDialog }) {
  const { filterStatus, patientFilter } = useSelector(({ checklistSummary }) => checklistSummary)
  const dispatch = useDispatch()

  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }])

  const [mopState, setMopState] = useState({ Cash: false, Cashless: false })
  const [pdsState, setPdsState] = useState({ Done: false, Pending: false })
  const [bbsState, setBbsState] = useState({ Done: false, Pending: false })

  function changeObjToArray(object) {
    let data = []
    for (const key in object) {
      if (object[key] === true) {
        data.push(key)
      }
    }
    return data
  }

  function handleClearFilter() {
    const filterObj = {
      ...patientFilter,
      startDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      mop: [],
      pds: [],
      bbs: []
    }
    dispatch(checklistActions.setChecklistFilters({ screenName: 'patientScreen', filterObj }))
  }

  function handleApplyFilter() {
    const filterObj = {
      ...patientFilter,
      startDate: moment(dateRange[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(dateRange[0].endDate).format("YYYY-MM-DD"),
      mop: changeObjToArray(mopState),
      pds: changeObjToArray(pdsState),
      bbs: changeObjToArray(bbsState)
    }
    dispatch(checklistActions.setChecklistFilters({ screenName: 'patientScreen', filterObj }))
    closeDialog && closeDialog()
  }

  useEffect(() => {
    const { startDate, endDate, mop, pds, bbs } = patientFilter
    setMopState({ Cash: mop?.includes('Cash'), Cashless: mop?.includes('Cashless') })
    setPdsState({ Done: pds?.includes('Done'), Pending: pds?.includes('Pending') })
    setBbsState({ Done: bbs?.includes('Done'), Pending: bbs?.includes('Pending') })
    setDateRange([{
      startDate: !!startDate ? new Date(startDate) : new Date(),
      endDate: !!endDate ? new Date(endDate) : new Date(),
      key: 'selection'
    }])
  }, [patientFilter])

  return <>
    <Box sx={{ pb: '30px', overflow: 'auto', height: '100%' }}>
      <Box>
        <FilterTitle ><Box component={`span`}>Select Mode of Payment</Box></FilterTitle>
        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <CustomListItem onChange={(e, isChecked) => setMopState({ ...mopState, Cash: isChecked })} name="cash" checked={mopState.Cash}><Box component={`span`}>Cash </Box></CustomListItem>
          <CustomListItem onChange={(e, isChecked) => setMopState({ ...mopState, Cashless: isChecked })} name="cashless" checked={mopState.Cashless}><Box component={`span`}>Cashless </Box></CustomListItem>
        </List>
      </Box>
      {!filterStatus && <Box>
        <FilterTitle ><Box component={`span`}>Provisional Discharge Summary</Box></FilterTitle>
        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <CustomListItem onChange={(e, isChecked) => setPdsState({ ...pdsState, Done: isChecked })} name="done" checked={pdsState.Done}><Box component={`span`}>Done </Box></CustomListItem>
          <CustomListItem onChange={(e, isChecked) => setPdsState({ ...pdsState, Pending: isChecked })} name="pending" checked={pdsState.Pending}><Box component={`span`}>Pending </Box></CustomListItem>
        </List>
      </Box>}
      {!filterStatus && <Box>
        <FilterTitle><Box component={`span`}>Bill Breakup Summary</Box></FilterTitle>
        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <CustomListItem onChange={(e, isChecked) => setBbsState({ ...bbsState, Done: isChecked })} name="done" checked={bbsState.Done}><Box component={`span`}>Done </Box></CustomListItem>
          <CustomListItem onChange={(e, isChecked) => setBbsState({ ...bbsState, Pending: isChecked })} name="pending" checked={bbsState.Pending}><Box component={`span`}>Pending </Box></CustomListItem>
        </List>
      </Box>}
      <Divider sx={{ mb: 2 }} />
      <Box>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6}>
            <FilterTitle >Start Date</FilterTitle>
            <TextField size="small" value={formatDate(dateRange[0].startDate)} InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={6}>
            <FilterTitle >End Date</FilterTitle>
            <TextField size="small" value={formatDate(dateRange[0].endDate)} InputProps={{ readOnly: true }} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DateRangePicker
            ranges={dateRange}
            showDateDisplay={false}
            onChange={item => setDateRange([item.selection])}
            dateDisplayFormat={`dd-mmmm-yyy`}
            showMonthArrow={false}
          />
        </Grid>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', position: 'absolute', left: 0, right: 0, bottom: 0, backgroundColor: '#fff', zIndex: 1 }}>
      <Button variant="outlined" color="primary" sx={{ width: '50%', borderRadius: '5px 5px 0px 0px' }} onClick={handleClearFilter}>Clear Filters</Button>
      <Button variant="contained" color="primary" sx={{ width: '50%', borderRadius: '5px 5px 0px 0px' }} onClick={handleApplyFilter}>Apply Filters</Button>
    </Box>
  </>
}