import { hospitalActions } from "../_actions";

export const hadleLastMessageIndexUpdate = (data) => {
  const { lastMessageIndex, sid, getState, dispatch } = data;

  const {
    hospitals,
    hospitals: { currentHospitalData },
  } = getState();

  const hospitalList = hospitals.hospitalList || [];

  const newHospitaList = hospitalList.map((hospital) => {
    if (sid === hospital["sid"]) return { ...hospital, lastMessageIndex };

    return hospital;
  });

  const newCurrentHospitalData = {
    ...currentHospitalData,
    lastMessageIndex:(currentHospitalData&&currentHospitalData["sid"] === sid)? lastMessageIndex: currentHospitalData?currentHospitalData["lastMessageIndex"]:null,
  };

  const newHospitalState = {
    ...hospitals,
    hospitalList: newHospitaList,
    currentHospitalData: newCurrentHospitalData,
  };

  dispatch(hospitalActions.updateFullState(newHospitalState));
};
