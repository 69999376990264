import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CentralInputField } from '../component/CentralInputField/CentralInputField';
import CentralDateTimePicker from '../component/CentralDateTimePicker/CentralDateTimePicker';
import { CentralSelect } from '../component/CentralSelect/CentralSelect';
import { CentralModal } from '../component/CentralModal/CentralModal';
import { CentralFileUpload } from '../component/CentralUploadFile/CentralUploadFile';
import moment from 'moment';
import { CentralSummaryModal } from '../component/centralSummaryModal/CentralSummaryModal';

const renderCentralCommonComponent = (details) =>{
    let commonComponent = ''
    switch(details.functionType){
        case 'text-read-only':
        commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel'>{details.label}</span>
             <span className='textReadOnlyValue'>{details.format? moment(details.value).format(details.format): (details.value || '--')}</span>
            </div>
            )
        break;
        case 'text-read-only-style': 
        commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel'>{details.label}</span>
             <span className='textReadOnlyValue' style={{color:details.class}}>{details.value || '--'}</span>
            </div>
            )
        break;
        case 'ok-surgery':
            commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel'>{details.label}</span>
             {details.value ?<div className='okSurgeryValueContainer'>
                <CheckCircleIcon sx={{fontSize:'11px', color:'white'}}/>
                <span className='textReadOnlySurgeryValue'>{details.value}</span>
             </div>:'--'}
            </div>
            )
        break;
        case 'modal-read-only':
            commonComponent = (
            <div className='textReadOnlyRow'>
             <span className='textReadOnlyLabel'>{details.label}</span>
             {details.value? <CentralModal modalTitle = {details.label} description = {details.value}/>:'--'}
            </div>
            )
        break;
        case 'input-text':
            commonComponent = (
            <CentralInputField jsonDetails = {details}/>
            )
        break;
        case 'input-numeric':
            const isPreValue = details.key === "amount_collected_admission"? 'Rs.':''
            commonComponent = (
            <CentralInputField type = {"number"} jsonDetails = {details} startAdornment = {isPreValue}/>
            )
        break;
        case 'input-dropDown':
            commonComponent = (
            <CentralSelect options = {details.options} jsonDetails = {details}/>
            )
        break;
        case 'input-date-time':
            commonComponent = (
            <CentralDateTimePicker jsonDetails = {details}/>
            )
        break;
        case 'upload-file':
            commonComponent = (
            <CentralFileUpload uniqueKey='admissionUploadFile' jsonDetails = {details}/>
            )
        break;
        case 'upload-file-discharge':
            commonComponent = (
            <CentralFileUpload uniqueKey='dischargeUploadFile' jsonDetails = {details}/>
            )
        break;
        case 'summary-modal':
            commonComponent = (
            <CentralSummaryModal />
            )
        break;
        default: console.log('Invalid Function Type');
    }
    return commonComponent
}

function getValueAfterConvertStringToObjectKeys (obj, keys) {
    if (!keys || keys === null || typeof keys !== "string" || obj === null || !obj) {
        return ""
    }
    let allKeysArr = keys.split(".")
    let objWithValue = obj
    for (let i = 0;i<allKeysArr.length;i++) {
        if (objWithValue[allKeysArr[i]]!==0 && !objWithValue[allKeysArr[i]]) {
            return null
        }
        objWithValue = objWithValue[allKeysArr[i]]
    }
    return objWithValue
}

export {
    renderCentralCommonComponent,
    getValueAfterConvertStringToObjectKeys
}