import { Close, InfoOutlined } from "@mui/icons-material";
import { Button, Container, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import "./styles.css";

const RemoveUserPopUp = ({ data }) => {
  const {
    showPopup,
    handleShowPopup,
    popUpHeading,
    popUpContent,
    handleSubmit,
  } = data;

  const handlePopupClose = () => {
    handleShowPopup(false);
  };

  return (
    <div className="remove-user-popUp-container">
      <Dialog
        open={showPopup}
        onClose={handlePopupClose}
        aria-labelledby="add-user-popUp"
        className="add-user-popUp"
        fullWidth={true}
        maxWidth="xs"
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
      >
        <DialogTitle sx={{ position: "relative" }} >
          <IconButton
            aria-label="close"
            size="small"
            onClick={handlePopupClose}
            sx={{
              position: "absolute",
              top: "50%",
              right: "1rem",
              transform: "translate(0, -50%)",
              color: "#3D7CC9",
              background: "#fff",
              width: "1.5rem",
              height: "1.5rem",
              ":hover": {
                color: "#3D7CC9",
                background: "#fff",
                filter: "bightness(1.2)",
              },
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Container
            sx={{
              m: "0 !important",
              p: "0 !important",
              display: "grid",
              gap: ".7rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InfoOutlined
              color="warning"
              sx={{
                fontSize: "clamp(30px, 10vw, 55px)",
                mx: "auto",
                display: "block",
              }}
            />

            <Typography
              variant="h3"
              color="black"
              align="center"
              sx={{
                fontSize: "clamp(20px, 5vw, 25px)",
                fontWeight: "500",
              }}
            >
              {popUpHeading}
            </Typography>

            <Typography
              component="p"
              color="#7A7A7A"
              align="center"
              sx={
                {
                  // fontSize: "clamp(20px, 5vw, 25px)",
                }
              }
            >
              {popUpContent}
            </Typography>

            <div className="btns-group">
              <div className="btn-item">
                <Button
                  fullWidth
                  elevation={2}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ textTransform: "capitalize" }}
                >
                  Yes
                </Button>
              </div>
              <div className="btn-item">
                <Button
                  fullWidth
                  elevation={2}
                  variant="outlined"
                  color="warning"
                  onClick={handlePopupClose}
                  sx={{ textTransform: "capitalize" }}
                > No </Button>
              </div>
            </div>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RemoveUserPopUp;
