export const cacheStorageConstants = {
  GET_CACHE_ITEM: "GET_CACHE_ITEM",
  SET_CACHE_ITEM: "SET_CACHE_ITEM",
  CLEAR_CACHE_ITEM: "CLEAR_CACHE_ITEM",
  UPDATE_FULL_CACHE_STATE: "UPDATE_FULL_CACHE_STATE",

  SYNC_CACHED_MESSAGES:"SYNC_CACHED_MESSAGES",

// handling message in case of api failure
  ADD_TO_RETRY_LIST:"ADD_TO_RETRY_LIST",
  ADD_TO_SEND_AGAIN_LIST:"ADD_TO_SEND_AGAIN_LIST",
  EMPTY_SEND_AGAIN_LIST:"EMPTY_SEND_AGAIN_LIST",

  // set network status
  SET_NETWORK_STATUS:"SET_NETWORK_STATUS",

  GET_CHAT_POC_LIST:"GET_CHAT_POC_LIST",
  UPDATE_SEND_AGAIN_LIST_COUNT:"UPDATE_SEND_AGAIN_LIST_COUNT",
  ADD_TTL_TO_STATE:"ADD_TTL_TO_STATE",
  UPDATE_TTL_OF_STATE:"UPDATE_TTL_OF_STATE"




};
