import Sockette from "sockette";
import { homeStateActions } from "../_actions";
import { socketConstants } from "../_constants";
import { handleSateWithSocket } from "../_helpers";
import { getTokenFromLocal } from "../_helpers/handleResponseMiddleware";
export const socketService = { getSocketConnectivity };

let apiUrl = process.env.REACT_APP_BASE_URL;
let socketEnv = process.env.REACT_APP_SOCKET;
const generateLocalId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const localDeviceId = generateLocalId(20);

export function getSocketConnectivity(dispatch, getState) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      mobileplatform: "web",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  // `wss://vwqzgx6ejk.execute-api.ap-south-1.amazonaws.com/socket?authorization=bearer%20${token}&mobilePlatform=iOS&appName=Hospital%20App%20iOSs&mobilePlatform=desktop&appName=Hostpital_web_app&deviceId=${localDeviceId}`,
  let ws = new Sockette(
    `wss://wk42v0hu0l.execute-api.ap-south-1.amazonaws.com/${socketEnv}?authorization=bearer%20${token}&mobilePlatform=iOS&appName=Hospital%20App%20iOSs&mobilePlatform=desktop&appName=Hostpital_web_app&deviceId=${localDeviceId}`,
    {
      timeout: 5e3,
      maxAttempts: 100,
      onopen: (e) => {
        dispatch(success(e));
        return { status: "connected", data: e };
      },
      onmessage: (e) => {
        console.log('this is message 1',e.data)
        console.log('this is message 2',JSON.parse(e.data))
        msgReceive(JSON.parse(e.data));

        return { status: "message_receive", data: e };
      },
      onreconnect: (e) => {
        return { status: "reconnecting", data: e };
      },
      onmaximum: (e) => {
        return { status: "stop_attempting", data: e };
      },
      onclose: (e) => {
        ws.open();
        if (ws.open) {
        } else {
          return { status: "closed", data: e };
        }
      },
      onerror: (e) => {
        return { status: "error", data: e };
      },
    }
  );
  return function cleanup() {
    ws && ws.close();
    ws = null;
  };
  function request(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_REQUESTING, payload };
  }
  function success(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_SUCCESS, payload };
  }
  function msgReceive(payload) {
    let eventType = payload.event;
    console.log('socket message',payload)
    switch (eventType) {
      case "onMemberAdded":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_MEMBER_REMOVED, payload };

      case "onMemberRemoved":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_MEMBER_REMOVED, payload };

      case "onMessageAdded":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_MESSAGE_RECEIVE, payload };

      case "onMessageRemoved":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_MESSAGE_REMOVED, payload };

      case "onSurgeryAccept":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_SURGERY_ACCEPT, payload };

      case "onSurgeryReject":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        // dispatch(patientActions.handleSocketSurgeryReject(payload))
        return { type: socketConstants.SOCKET_SURGERY_REJECT, payload };
      case "onBuildUpdated":
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_BUILD_UPDATE };
      case "patientArrivalTimeEvent":
        {
          dispatch(homeStateActions.toggleAlarmNotification(true))
          dispatch(homeStateActions.addAlarmNotification(payload))
          dispatch(homeStateActions.toggleAlarmSound(true))
          setTimeout(() => {
            dispatch(homeStateActions.deleteAlarmNotification())
          }, 60000);
          setTimeout(() => {
            dispatch(homeStateActions.toggleAlarmSound(false))
          }, 10000);
          return { type: socketConstants.SOCKET_ALARM_RECEIVED };
        }

      case "onChatDisable": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_BUILD_UPDATE };
      }

      case "onAppointmentCancelled": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        return { type: socketConstants.SOCKET_BUILD_UPDATE };
      }

      case "onPreviousProvBill": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case 'onAppointmentCreated': {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case "onPreviousDischargeSummary": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      case "updateDynamicBotMessage": {
        handleSateWithSocket(dispatch, getState, eventType, payload);
        break;
      }
      default:
        return { type: socketConstants.SOCKET_MESSAGE_RECEIVE, payload };
    }
  }
  function reconnecting(payload) {
    return {
      type: socketConstants.SOCKET_CONNECTION_RECONNECTING,
      payload,
    };
  }
  function onmaximum(payload) {
    return {
      type: socketConstants.SOCKET_CONNECTION_STOP_ATTEMPT,
      payload,
    };
  }
  function failure(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_FAILED, payload };
  }
  function closed(payload) {
    return { type: socketConstants.SOCKET_CONNECTION_CLOSED, payload };
  }

}
