import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { groupInfoPopupActions } from "../../../_actions";
import { validateInput } from "../../../_helpers";
import "./styles.css";

const AddUserPopUp = ({ data }) => {
  const { showAddUserPopUp, setShowAddUserPopUp } = data;

  const hospital_id = useSelector((state) => state.hospitals.currentHospitalData.hospital_id) ?? "";

  const dispatch = useDispatch();

  const [isInvaildInput, setIsInvaildInput] = useState({
    error: false,
    des: "Only digits are allowed",
  });
  const [addUserPhoneInput, setAddUserPhoneInput] = useState("");

  const clearTheState = () => {
    setIsInvaildInput(() => ({
      error: false,
      des: "",
    }));

    setAddUserPhoneInput(() => "");
  };

  const handlePopupClose = () => {
    setShowAddUserPopUp(false);
    clearTheState();
  };

  const handleAddUserPhoneChange = (e) => {
    const { value } = e.target;

    if (!validateInput(value, setIsInvaildInput)) return;
    setAddUserPhoneInput(value);
  };

  const handleAddUserPhoneSubmit = () => {
    if (!validateInput(addUserPhoneInput, setIsInvaildInput)) return;

    if (addUserPhoneInput.length !== 10 || isInvaildInput["error"]) return;

    const params = { hospitalID: hospital_id, mobile: addUserPhoneInput };

    dispatch(groupInfoPopupActions.addGroupMember(params));
    setShowAddUserPopUp(false);
    setAddUserPhoneInput("");
  };

  useEffect(() => {
    return () => {
      clearTheState();
    };
  }, []);

  return (
    <div className="add-user-popUp-container">
      <Dialog
        open={showAddUserPopUp}
        onClose={handlePopupClose}
        aria-labelledby="add-user-popUp"
        className="add-user-popUp"
        fullWidth={true}
        maxWidth="xs"
        PaperProps={{
          style: { borderRadius: "15px" },
        }}
      >
        <DialogTitle sx={{ background: "#3D7CC9", p: "1rem 0", position: "relative" }} >
          <Typography
            variant="h5"
            component="div"
            align="center"
            color="#fff"
            fontWeight="bold"
          > Add User </Typography>

          <IconButton
            aria-label="close"
            size="small"
            onClick={handlePopupClose}
            sx={{
              position: "absolute",
              top: "50%",
              right: "1rem",
              transform: "translate(0, -50%)",
              color: "#3D7CC9",
              background: "#fff",
              width: "1.5rem",
              height: "1.5rem",
              ":hover": {
                color: "#3D7CC9",
                background: "#fff",
                filter: "bightness(1.2)",
              },
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <TextField
            value={addUserPhoneInput}
            onChange={handleAddUserPhoneChange}
            label="Enter Mobile No."
            variant="filled"
            name="mobile"
            helperText={isInvaildInput["error"] && isInvaildInput["des"]}
            error={isInvaildInput["error"]}
            fullWidth
            autoFocus
            InputProps={{
              disableUnderline: true,
            }}
            sx={{
              m: "clamp(1em, 5vw, 2em) auto",
              border: "none",
            }}
          />

          <div className="btns-group">
            <div className="btn-item">
              <Button
                fullWidth
                elevation={2}
                variant="contained"
                color="primary"
                disabled={isInvaildInput["error"]}
                onClick={handleAddUserPhoneSubmit}
                sx={{ textTransform: "capitalize" }}
              >
                Submit
              </Button>
            </div>
            <div className="btn-item">
              <Button
                fullWidth
                elevation={2}
                variant="outlined"
                color="warning"
                onClick={handlePopupClose}
                sx={{ textTransform: "capitalize" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddUserPopUp;
