import { homeStateConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function homeState(state = getInitialState("homeState"), action) {
  switch (action.type) {
    case homeStateConstants.SET_STATE:
      const { newState } = action;
      return {
        ...state,
        ...newState,
      };

    case homeStateConstants.UPDATE_FULL_HOMESTATE_STATE: {
      const { newState } = action;
      return {
        ...newState,
      };
    }
    case homeStateConstants.TOGGLE_CHECK_UPDATED_BUILD: {
      const { value } = action
      return {
        ...state,
        checkUpdatedBuild: value
      }
    }
    case homeStateConstants.TOGGLE_ALARM_NOTIFICTION: {
      const { value } = action
      return {
        ...state,
        showAlarmNotification: value
      }
    }
    case homeStateConstants.ADD_ALARM_NOTIFICATION: {
      const { payload: { messageData } } = action
      return {
        ...state,
        alarmNotifications: [...state.alarmNotifications, messageData]
        // ,
        // showAlarmNotification:value
      }
    }
    case homeStateConstants.DELETE_ALARM_NOTIFICATION: {
      let arr = state.alarmNotifications
      if (arr.length > 0) {
        arr.shift()
      }
      return {
        ...state,
        alarmNotifications: arr
        // ,
        // showAlarmNotification:value
      }
    }
    case homeStateConstants.TOGGLE_ALARM_SOUND: {
      const { value } = action
      return {
        ...state,
        alarmSound: value
      }
    }
    case homeStateConstants.UPDATE_CURRENT_STATE: {
      const { payload } = action
      return {
        ...state,
        notificationState: Object.keys(payload).length > 0 ? { ...payload, data: { ...payload.data, attributes: JSON.parse(payload.data.attributes) } } : {}
      }
    }
    case homeStateConstants.UPDATE_SELECTED_CITIES: {
      const { payload } = action
      return {
        ...state,
        selectedCities: payload
      }
    }
    case homeStateConstants.UPLOAD_DOCUMENT_STATUS: {
      const { status, key = null, docType = null, files = [], patientDetails = null,capture=false,disableDocumentTagging,typeOfUpload,uploadDetails,...rest } = action.payload
      return {
        ...state,
        uploadDocumentStatus: { status, key, docType, files, patientDetails ,capture,disableDocumentTagging,typeOfUpload,uploadDetails,...rest}
      }
    }
    case homeStateConstants.SET_APP_TAB: {
      const { payload } = action
      return {
        ...state,
        activeTab: payload
      }
    }
    case homeStateConstants.SET_SCREEN_NAME:{
      const {payload}=action
      return {
        ...state,
        screenName:payload
      }
    }
    default:
      return state;
  }
}
