import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { chatBoxActions, configActions, groupInfoPopupActions, homeStateActions, hospitalActions, patientActions } from "../_actions";
import { chatBoxConstants } from "../_constants";
import { changeScreen, mainCacheConstants, useReduxCacheAndDispatch } from "../_helpers";
import { configService, hospitalService } from "../_services";



export const useGetRefreshState = ({source}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const cacheAndDispatch = useReduxCacheAndDispatch()
    const { pathname } = useLocation()
    const [searchParams] = useSearchParams();
    const currentHospitalData = useSelector(state => state.hospitals.currentHospitalData)
    const patientInfo = useSelector(state => state.patients.patientInfo)
    const hospitalList = useSelector((state) => state.hospitals.hospitalList)
    const patients = useSelector((state) => state.patients.patients);
    const activeTab = useSelector((state) => state.homeState.activeTab);
    var hospitalId = searchParams.get('hospId') || currentHospitalData?.hospital_id
    var appointmentId = searchParams.get('apptId') || patientInfo?.appointmentId
    const [finalAppointmentId,setFinalAppointmentId]=useState(appointmentId)
    const [finalHospitalId,setFinalHospitalId]=useState(hospitalId)
    const [checkDynamicValues,setCheckDynamicValues]=useState({checkHospId:false,checkApptId:false})

    const blockChatBoxScreen = searchParams.get('onlyChatBox')
    //will enter the refresh page from here
    const getRefreshedPage = async ({ hospId = null, apptId = null }) => {
        hospitalId = hospId || hospitalId
        appointmentId = apptId || appointmentId
        setFinalAppointmentId(appointmentId)
        setFinalHospitalId(hospitalId)
        setCheckDynamicValues(prevState=>{return{
            ...prevState,
            checkHospId:true,
            checkApptId:true,
        }})

        var sid = currentHospitalData.sid
        //refresh state is not configured for general chat right now 
        // var flag=0
        if (hospitalId) {
            // flag=1
            sid = await getSid({ hospId: hospitalId })
            sid = sid['result']
        }
        //this is necessary for @mention feature
        if (!!sid) {
            getGroupMemberList({ sid })
        }
        setActiveTab()
        // const screenName = setActiveScreen()
        getRefreshedHospitalList()
        if (hospitalId && sid) {
            getRefreshedPatientList({ hospId: hospitalId, sid: sid })
        }
        if (appointmentId) {
            console.log('this is appointment id', appointmentId)
            getRefreshedChatBox({ apptId: appointmentId, blockChatBox: blockChatBoxScreen })
        } else if (sid) {
            // if(flag===0)
            getRefreshedChatBox({ channelsid: sid })
        }
    }
    //run refresh on hospital list
    const getRefreshedHospitalList = () => {
        cacheAndDispatch({
            action: hospitalActions.getHospitalList,
            params: {
                mobile: localStorage.getItem('userMobile'),
            },
            cacheOf: mainCacheConstants.hospitalListCache,
            key: localStorage.getItem('userMobile'),
        });
    }
    //run refresh on patient list
    const getRefreshedPatientList = ({ hospId, sid }) => {
        console.log('dragon dragon 1')
        // Mixpanel.track(constants.BLANK_ERROR, { source: "refreshedPage", payload: hospId });
        cacheAndDispatch({
            action: patientActions.getPatientList,
            params: {
                selectedHospitalId: hospId,
                sid,
            },
            cacheOf: mainCacheConstants.patientListsCache,
            key: sid,
        });
    }
    //run refresh on chatbox
    const getRefreshedChatBox = ({ apptId, channelsid, blockChatBox, leadId }) => {
        if (apptId || leadId) {
            dispatch(
                homeStateActions.setState({
                    showGeneralChatMessages: false,
                    showChatBox: true,
                })
            );
            if (blockChatBox) {
                dispatch(chatBoxActions.setBlockChatBoxScreen(true))
            }
            getPatientMessages({ params: { hospitalId: hospitalId, appointmentId: apptId, numberOfMessages: chatBoxConstants.MESSAGES_LIMIT, leadId } });
            getPatientInfo({ appointmentId: apptId, leadId });
        }
        if (channelsid) {
            dispatch(
                homeStateActions.setState({
                    showGeneralChatMessages: true,
                    showChatBox: true,
                })
            );
            getGeneralChatMessages({ channelsid, limit: chatBoxConstants.MESSAGES_LIMIT })
        }
    }
    //to set selected hospital as current hospital
    useEffect(() => {
      
        let hospId=checkDynamicValues.checkHospId? finalHospitalId:hospitalId
        console.log("dragon dragon with hospitalId",hospId,checkDynamicValues.checkHospId)

        if(hospId)
        {if (hospitalList && hospitalList.length > 0) {
            
            hospitalList.forEach((item) => {
                if (item.hospital_id === hospId) {

                    dispatch(
                        hospitalActions.setCurrentHospitalData({
                            data: item,
                        })
                    );
                }
            });
        }

        if (hospitalList && hospitalList.length === 1 && hospitalList[0]['hospital_id']?.length > 0 && !appointmentId && activeTab === 'home') {
            changeScreen(dispatch, navigate, { hospitalList: false, patientList: true, chatBox: false }, 'patients')
        }
        setCheckDynamicValues(prevState=>{return{
            ...prevState,
            checkHospId:false,
        }})}
    }, [hospitalList])
    //to set selected patient as current patient 
    useEffect(() => {

        let apptId=checkDynamicValues.checkApptId?finalAppointmentId:appointmentId

        for (let key in patients) {
            for (let i = 0; i < patients[key].length; i++) {
                let curr = patients[key][i];
                if ((apptId && curr["_id"] === apptId)) {

                    dispatch(
                        patientActions.setPatientInfo({
                            appointmentId:apptId,
                            patientId: curr.patientId,
                            leadId: curr.leadId,
                            allInfo: curr,
                        })
                    );
                }
            }
        }
        setCheckDynamicValues(prevState=>{return{
            ...prevState,
            checkApptId:false,
        }})
        console.log('counter++ patients', patients)
    }, [patients])
    //to set patient info
    const getPatientInfo = (params) => { dispatch(patientActions.getPatientInfoAPIData(params)) }
    //to get patient messages
    const getPatientMessages = ({ params, params: { appointmentId: apptId, leadId } }) => {
        console.log('this is apptId')
        const key = apptId || leadId
        cacheAndDispatch({
            action: chatBoxActions.getPatientMessages,
            params,
            cacheOf: mainCacheConstants.chatBoxMessagesCache,
            key: key,
        });
    }
    //to get general chat messages
    const getGeneralChatMessages = async ({ channelsid }) => {
        dispatch(
            homeStateActions.setState({
                showGeneralChatMessages: true,
                showChatBox: true,
            })
        );

        await hospitalService
            .fetchLastIdexAPI({ channelSid: channelsid })
            .then((res) => {
                cacheAndDispatch({
                    action: chatBoxActions.getGeneralChatMessages,
                    params: {
                        channelSid: channelsid,
                        lastMsgIndex: parseInt(res.lastMessageIndex) + 1,
                    },
                    cacheOf: mainCacheConstants.chatBoxGeneralChatMessagesCache,
                    key: channelsid,
                });
            }
            )
    }
    //to set active tab
    const setActiveTab = () => {
        if (pathname === '/checklistSummary/hospitalView') {
            dispatch(homeStateActions.setActiveTab('checklistSummary/hospitalView'))
        }
        else if (pathname === '/checklistSummary') {
            dispatch(homeStateActions.setActiveTab('checklistSummary'))
        }
        else if (pathname.includes("owner")) {
            dispatch(homeStateActions.setActiveTab('owner'))
        }
        else {
            dispatch(homeStateActions.setActiveTab('home'))
        }
    }
    //to set active screen
    // const setActiveScreen = () => {
    //     var screenName = pathname.replace('/', '')
    //     var payload = {}
    //     if (screenName === 'patients') {
    //         payload = { hospitalList: false, patientList: true, chatBox: false }
    //     } else if (screenName === 'chatbox') {
    //         payload = { hospitalList: false, patientList: false, chatBox: true }
    //     } else {
    //         payload = { hospitalList: true, patientList: false, chatBox: false }
    //     }
    //     dispatch(homeStateActions.setScreenName(payload))
    // }
    // to get member list for chatbox  
    const getGroupMemberList = ({ sid }) => {
        console.log('call 4')
        cacheAndDispatch({
            action: groupInfoPopupActions.getGroupInfoList,
            params: { sid: sid },
            cacheOf: mainCacheConstants.groupInfoListCache,
            key: sid
        })
    }
    const getSid = async ({ hospId }) => {
        dispatch(configActions.showSpinningLoader())
        var val = await configService.getChannelSid({ hospId: hospId })
        dispatch(configActions.hideSpinningLoader())
        return val
    }
    return { getRefreshedPage }

}