import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checklistActions } from "../../../_actions/checklist.actions";
import { useFetchHospital, useFetchHostpitalPatient, useFetchPatient } from "../checklistSummaryData";
import Filters from "./components/Filters";
import ChecklistTable from "./components/Table";
import { getTokenFromLocal } from "../../../_helpers/handleResponseMiddleware";

function ChecklistWeb() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const convertDate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  const filterStatus = useSelector(
    (state) => state.checklistSummary.filterStatus
  );
  const screenName = useSelector((state) => state.checklistSummary.screenName);
  const { hospitals, filterAndfetchHospital, loadMore, pageNumber } = useFetchHospital();
  const { patients, filterAndfetchPatient, loadMoreP, pageNumberP } = useFetchPatient();
  const { hospitalPatients, filterAndfetchHospitalPatients, loadMorePH, pageNumberPH } = useFetchHostpitalPatient();
  let user = getTokenFromLocal();
  const hospitalId = user ? user.hospitalId : null
  const getFilteredData = (filterObj) => {
    const {
      hospitalScreen: { searchTextHospital, cityFilter, dateRangeHospital },
      patientScreen: {
        bbsFilter,
        mopFilter,
        pdsFilter,
        searchTextPatient,
        dateRangePatient,
        filterStatus
      },
      hospitalViewScreen: { searchTextHospitalView, mopFilterHospitalView, dateRangeHospitalView }
    } = filterObj;

    console.log("filterObj",filterObj)
    const cityArr = !!cityFilter
      ? cityFilter.map((item) => {
        return item.name;
      })
      : [];

    const mopArr = !!mopFilter
      ? mopFilter.map((item) => {
        return item.name;
      })
      : [];

    const mopFilterHospitalArr = !!filterObj['hospitalViewScreen']['mopFilter']
      ? filterObj['hospitalViewScreen']['mopFilter'].map((item) => {
        return item.name;
      })
      : [];
    const pdsArr = !!pdsFilter
      ? pdsFilter.map((item) => {
        return item.name;
      })
      : [];
    const bbsArr = !!bbsFilter
      ? bbsFilter.map((item) => {
        return item.name;
      })
      : [];
    const startDate =
      screenName === "hospitalScreen"
        ? convertDate(dateRangeHospital[0].startDate)
        : screenName === "hospitalViewScreen" ?
          convertDate(dateRangeHospitalView[0].startDate)
          : convertDate(dateRangePatient[0].startDate);
    const endDate =
      screenName === "hospitalScreen"
        ? convertDate(dateRangeHospital[0].endDate)
        : screenName === "hospitalViewScreen" ?
          convertDate(dateRangeHospitalView[0].endDate)
          : convertDate(dateRangePatient[0].endDate);

    if (screenName === "hospitalScreen") {
      dispatch(
        checklistActions.setChecklistFilters({
          filterObj: {
            search: searchTextHospital,
            city: cityArr,
            startDate,
            endDate,
          },
          screenName: "hospitalScreen",
        })
      );
    }
    if (screenName === 'hospitalViewScreen') {
      console.log("dispatch",searchTextHospitalView,mopFilterHospitalArr,startDate,endDate)
      dispatch(
        checklistActions.setChecklistFilters({
          filterObj: {
            search: searchTextHospitalView,
            mop: mopFilterHospitalArr,
            startDate,
            endDate,
          },
          screenName: "hospitalViewScreen",
        })
      );
    }
    else {
      dispatch(
        checklistActions.setChecklistFilters({
          filterObj: {
            search: searchTextPatient,
            startDate,
            endDate,
            pds: pdsArr,
            bbs: bbsArr,
            mop: mopArr,
            filterStatus
          },
          screenName: "patientScreen",
        })
      );
      // filterAndfetchPatient({
      //   search: searchText,
      //   startDate: startDate,
      //   endDate: endDate,
      //   pds: pdsArr,
      //   bbs: bbsArr,
      //   mop: mopArr,
      // });
    }
  };
  const fetchMoreItems = () => {
    console.log("called fetchMore", screenName)
    if (screenName === "hospitalScreen") {
      loadMore();
    } else if (screenName === "hospitalViewScreen") {
      loadMorePH();
    }else {
      loadMoreP();
    }
  };

  // const changeScreen = (
  //   screenName,
  //   hospitalId = null,
  //   documentStatus = null
  // ) => {
  //   dispatch(
  //     checklistActions.changeChecklistScreen(
  //       screenName,
  //       hospitalId,
  //       documentStatus
  //     )
  //   );
  //   dispatch(
  //     checklistActions.setChecklistFilters({
  //       filterObj: {
  //           search: searchTextHospitalView,
  //           mop: mopFilterHospitalArr,
  //           startDate,
  //           endDate,
  //       },
  //       screenName: "hospitalViewScreen",
  //     })
  //   );
  // };


  useEffect(() => {
    if (pathname === '/checklistSummary') {
      dispatch(checklistActions.changeChecklistScreen('hospitalScreen'))
    }
    else if (pathname === '/checklistSummary/hospitalView') {
      dispatch(checklistActions.changeChecklistScreen('hospitalViewScreen', hospitalId))
    }
    else {
      // dispatch(checklistActions.changeChecklistScreen('patientScreen'))
    }
  }, [pathname])
  return (
    <>
      <div>
        <Filters
          screenName={screenName}
          filterStatus={filterStatus}
          getFilteredData={getFilteredData}
        />
        <ChecklistTable
          screenName={screenName}
          fetchMoreItems={fetchMoreItems}
          hasMoreItems={
            screenName === "hospitalScreen"
              ? pageNumber == 0 || hospitals.length < 10
                ? false
                : true
              : screenName === "hospitalViewScreen" ? pageNumberPH == 0 || hospitalPatients.length < 10
              : pageNumberP == 0 || patients.length < 10
                ? false
                : true
          }
          screenData={screenName === "hospitalScreen" ? hospitals : screenName === "hospitalViewScreen" ? hospitalPatients : patients}
          fetchPatient={screenName === "hospitalViewScreen" ? filterAndfetchHospitalPatients : filterAndfetchPatient}
          filterStatus={filterStatus}
        />
      </div>
    </>
  );
}

export default ChecklistWeb
