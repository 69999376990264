import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { homeStateActions } from "../../../_actions";
import "./styles.css";

const GenericPopUp = ({ data }) => {
  const { Body, popUpTitle } = data;
  const showPopup = useSelector((state) => state.homeState[popUpTitle]);
  const dispatch = useDispatch();

  const handlePopUpClose = () => {
    dispatch(
      homeStateActions.setState({
        [popUpTitle]: false,
      })
    );
  };

  const handleClickOpen = () => {
    dispatch(
      homeStateActions.setState({
        [popUpTitle]: false,
      })
    );
  };

  return (
    <div className="generic-popUp-Container">
      {/* <Button variant="contained" onClick={handleClickOpen}>
        Open PopUP
      </Button> */}

      <Modal
        open={showPopup}
        onClose={handlePopUpClose}
        aria-labelledby="generic-popUp"
        className="generic-popUp"
      >
        <Box
          className="content-container"
          disableGutters
          sx={{
            m: "auto",
            p: "2rem",
            maxWidth: "1800px",
            display: "flex",
            minHeight: "100vh",
            alignContent: "center",
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          <Body data={{ ...data, handlePopUpClose }} />
        </Box>
      </Modal>
    </div>
  );
};

export default GenericPopUp;
