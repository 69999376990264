import { Box, Typography } from "@mui/material";
import AccordianCustom from "../AccordianCustom";

const HospitalList = ({ hospitals, onHospitalSelect }) => {
  const PassSelectedHospital = (hospitalId, click_event) => {
    if (hospitalId) {
      onHospitalSelect({
        hospitalId: hospitalId,
        where: click_event
      })
    }
  }

  return (
    <>
      {hospitals?.length ? hospitals.map(({ hospitalId, hospitalName, city, bbDone, pdsDone, pdsPending, bbUploadedPending, appointmentCount }, i) => (
        <AccordianCustom
          title={hospitalName}
          subtitle={`City: ${city}`}
          key={i}
        >
          <Box >
            <Typography component={`h5`} variant="caption" sx={{ lineHeight: 1.5, mb: ['10px'] }}>Surgery Count:</Typography>
            <Typography onClick={() => { PassSelectedHospital(hospitalId, "all") }} component={`p`} variant="caption" sx={{ lineHeight: 1.5, color: '#3d7cc9', textDecoration: 'underline' }}>{appointmentCount}</Typography>

            <Typography component={`h5`} variant="caption" sx={{ lineHeight: 1.5, mb: ['10px'], mt: ['20px'] }}>Provisional Discharge Summary:</Typography>
            <Box sx={{ mt: '10px' }}>
              <Typography variant="caption" sx={{ lineHeight: 1.5, color: '#666a70', display: 'inline-block', minWidth: '70px', paddingRight: '5px' }}>Done:</Typography>
              <Typography onClick={() =>  PassSelectedHospital(hospitalId, "PDS-Done") } variant="caption" sx={{ lineHeight: 1.5, color: '#009817', display: 'inline-block', textDecoration:'underline' }}>{pdsDone}</Typography>
            </Box>
            <Box sx={{ mt: '10px' }}>
              <Typography variant="caption" sx={{ lineHeight: 1.5, color: '#666a70', display: 'inline-block', minWidth: '70px', paddingRight: '5px' }}>Pending:</Typography>
              <Typography onClick={() =>  PassSelectedHospital(hospitalId, "PDS-PENDING") } variant="caption" sx={{ lineHeight: 1.5, color: '#E00000', display: 'inline-block', textDecoration: 'underline' }}>{pdsPending}</Typography>
            </Box>

            <Typography component={`h5`} variant="caption" sx={{ lineHeight: 1.5, mb: ['10px'], mt: ['20px'] }}>Bill Breakup Summary:</Typography>
            <Box sx={{ mt: '10px' }}>
              <Typography variant="caption" sx={{ lineHeight: 1.5, color: '#666a70', display: 'inline-block', minWidth: '70px', paddingRight: '5px' }}>Done:</Typography>
              <Typography onClick={() =>  PassSelectedHospital(hospitalId, "BB-Done") } variant="caption" sx={{ lineHeight: 1.5, color: '#009817', display: 'inline-block', textDecoration:'underline' }}>{bbDone}</Typography>
            </Box>
            <Box sx={{ mt: '10px' }}>
              <Typography variant="caption" sx={{ lineHeight: 1.5, color: '#666a70', display: 'inline-block', minWidth: '70px', paddingRight: '5px' }}>Pending:</Typography>
              <Typography onClick={() => PassSelectedHospital(hospitalId, "BB-Pending") } variant="caption" sx={{ lineHeight: 1.5, color: '#E00000', display: 'inline-block', textDecoration:'underline' }}>{bbUploadedPending}</Typography>
            </Box>
          </Box>
        </AccordianCustom>
      ))
        : null //<Typography component={`h1`} variant={`h1`} >Empty Data</Typography>
      }
    </>
  );
}

export default HospitalList;