export const groupInfoPopupConstants = {
  GROUP_INFO_LIST_REQUESTING: "GROUP_INFO_LIST_REQUESTING",
  GROUP_INFO_LIST_FETCHED: "GROUP_INFO_LIST_FETCHED",
  GROUP_INFO_LIST_FAILED: "GROUP_INFO_LIST_FAILED",
  ADD_GROUP_MEMBER_REQUESTING: "ADD_GROUP_MEMBER_REQUESTING",
  ADD_GROUP_MEMBER_SUCCESS: "ADD_GROUP_MEMBER_SUCCESS",
  ADD_GROUP_MEMBER_FAILED: "ADD_GROUP_MEMBER_FAILED",
  REMOVE_GROUP_MEMBER_REQUESTING: "REMOVE_GROUP_MEMBER_REQUESTING",
  REMOVE_GROUP_MEMBER_SUCCESS: "REMOVE_GROUP_MEMBER_SUCCESS",
  REMOVE_GROUP_MEMBER_FAILED: "REMOVE_GROUP_MEMBER_FAILED",
  UPDATE_FULL_GROUP_MEMBER_STATE: "UPDATE_FULL_GROUP_MEMBER_STATE",

};
