import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import noListImage from "./../../../../../assets/noList.png";
import { tableData } from "./../../tableData";
import "./index.css";
import TableRow from "./TableRow";
function ChecklistTable({
  screenName,
  screenData,
  fetchMoreItems,
  hasMoreItems,
  fetchPatient,
  filterStatus,
}) {
  const [updatedTableData, setUpdatedTableData] = useState(
    tableData[screenName]
  );
  const isFetchingData = useSelector(state => state.checklistSummary.isFetchingData)
  const activeTab = useSelector(state => state.checklistSummary.activeTab)
  useEffect(() => {
    setUpdatedTableData((curr) => ({
      ...curr,
      columns: tableData[screenName].columns.map((item) => {
        console.log("item",item)
        if (filterStatus) {
          if (
            (item.key === "PDS" && filterStatus.includes(item.key)) ||
            (item.key === "BB" && filterStatus.includes(item.key)) ||
            item.key === ""
          ) {
            item.show = true;
          } else {
            item.show = false;
          }
        } else {
          item.show = true;
        }
        return item;
      }),
    }));
  }, [filterStatus, screenName]);

  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );
  console.log("screenData",screenData)
  return (
    <div id="scrollableDiv" style={{ height: "80%", overflow: "auto" }}>
      <InfiniteScroll
        dataLength={screenData.length} //This is important field to render the next data
        next={fetchMoreItems}
        hasMore={hasMoreItems}
        style={{ overflow: " unset!important" }}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
        endMessage={
          <p style={{ textAlign: "center", display: "none" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <table className="checklistTable">
          {/* defining the width of the column */}
          <colgroup>
            <col style={{ width: "22%" }} />
            <col style={{ width: "22%" }} />
            <col style={{ width: "12%" }} />
            <col style={{ width: "22%" }} />
            {filterStatus === "" && <col style={{ width: "22%" }} />}
          </colgroup>

          <thead style={{ position: "sticky", top: "0" }}>
            {/* table header for patient and hospital screen */}
            <tr>
              {updatedTableData["columns"].map((item) => {
                  if(activeTab==='pending' && (item.title==='Final Discharge Summary' || item.title==='Provisional Bill' || item.title==='Final Bill')){
                    
                    return  ''
                  }
                  else if ((activeTab==='active' || activeTab==='old') && (item.title==='Accept/Reject')){
                    
                    return ''
                  }
                  else{
                   
                    return item.show && <th style={{ background: "#00263E",
                    color: "white",
                    padding: "0.5% 0.5%",
                    height: "50px"}}>{item.title}</th>
                  }
              })}
            </tr>
          </thead>
          <tbody>
            {screenData &&
              screenData.length > 0 &&
              screenData.map((item) => {
                return (
                  <TableRow
                    fetchPatient={fetchPatient}
                    data={item}
                    screenName={screenName}
                    filterStatus={filterStatus}
                  />
                );
              })}
          </tbody>
        </table>
        {(isFetchingData == false && screenData && screenData.length == 0) && (
          <div className="noDataFound">
            <div style={{fontSize:"2rem"}}>Oops! No List Found</div>
            <div style={{height:"265px"}}> <img style={{height:"100%",width:"100%",objectFit:"contain"}} src={noListImage} alt="no list img" /></div>
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default ChecklistTable;
