import { KeyboardArrowDown, Logout } from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppBar, Avatar, Box, Button, Container, IconButton, Popover, Toolbar, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logoMobile from '../../../assets/mobileLogo.svg';
import { alertActions, cacheStorageActions, chatBoxActions, configActions, documentsPopupActions, groupInfoPopupActions, homeStateActions, hospitalActions, patientActions, socketActions, userActions } from "../../../_actions";
import { checklistActions } from "../../../_actions/checklist.actions";
import { changeScreen } from "../../../_helpers";
import { resetSelectedStatesToInitial } from "../../../_helpers/getInitialState";
import useWindowSize from "../../../_helpers/useWindowSize";
import { headerTabs } from "./HeaderData";
import NotificationComponent from './Notification'
import "./index.css";

const Header = () => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const open2 = Boolean(anchorEl2);
  const id = open2 ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mainTitle = useSelector((state) => state.homeState.mainTitle);
  const showChatBox = useSelector((state) => state.homeState.showChatBox);
  const checkUpdatedBuild = useSelector(
    (state) => state.homeState.checkUpdatedBuild
  );
  const blockChatBox=useSelector(state=>state.chatBox.blockChatBoxScreen)

  const logoutAnchorEL = useSelector((state) => state.homeState.logoutAnchorEL);
  const role = useSelector(state => state.authentication.user.roles?.role) || ''
  const loggedIn = useSelector((state) => state.authentication.loggedIn);
  const name = useSelector((state) => state.authentication.user.name);
  const { isMobile } = useWindowSize();
  const [openDrawer, setOpenDrawer] = useState(false)

  const handlePopOverClick = (event, dispatch) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = (dispatch) => {
    setAnchorEl2(null);
  };

  const setActiveTab = (tab) => {
    console.log("tab1",tab)
    dispatch(homeStateActions.setActiveTab(tab))
    if (tab === 'home') {
      changeScreen(dispatch, navigate, { hospitalList: true, patientList: false, chatBox: false }, 'home')
    }
  };
  const toggleDrawer = (value) => {
    setOpenDrawer(value)
  }

    //to trigger PWA installation

let deferredPrompt="something";
const [tempState,setTempState]=useState()

window.addEventListener('beforeinstallprompt', (e) => {
  console.log('installation event is fired',e)
  setTempState(e)
    deferredPrompt = e;
});


const handleInstallAppClick=async(e)=>{
  toggleDrawer(false)
  console.log('this is deferredPrompt',tempState)
  if (tempState !== null) {
    tempState.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      deferredPrompt = null;
    }
  }
}




  const activeTab = useSelector((state) => state.homeState.activeTab);
  const handleLogoutClick = (dispatch) => {
    resetSelectedStatesToInitial(dispatch, [
      {
        action: userActions.updateFullState,
        stateName: "authentication",
        addtionalStateData: { loggingIn: false },
      },
      { action: alertActions.updateFullState, stateName: "alert" },
      { action: hospitalActions.updateFullState, stateName: "hospitals" },
      { action: patientActions.updateFullState, stateName: "patients" },
      { action: chatBoxActions.updateFullState, stateName: "chatBox" },
      {
        action: documentsPopupActions.updateFullState,
        stateName: "documentsPopup",
      },
      {
        action: groupInfoPopupActions.updateFullState,
        stateName: "groupInfoPopup",
      },
      { action: configActions.updateFullState, stateName: "config" },
      { action: homeStateActions.updateFullState, stateName: "homeState" },
      {
        action: cacheStorageActions.updateFullState,
        stateName: "cacheStorage",
      },
      {
        action: socketActions.updateFullState,
        stateName: "socketConnectivity",
      },
      {
        action: checklistActions.updateFullState,
        stateName: 'checklistSummary'
      }
    ]);

    dispatch(userActions.logout());
    handleClose()
    localStorage.clear();
  };

  return (
    <>
      <AppBar
        sx={{
          m: "0 !importa50nt",
          p: "0 !important",
          top: "0",
          boxShadow: "none",
          background: "#3D7CC9",
          position: 'static'
        }}
      >
        <Toolbar
          sx={{
            background: "#00263E",
            m: "0 !important",
            p: "0 !important",
            boxShadow: "0px 3px 6px #00000029",
            height: "64px",
            borderRadius: "0 0 10px 10px",
          }}
        >
          <Container
            sx={{
              m: "0",
              mx: "auto",
              px: "1rem !important",
              display: "flex",
              w: "100vw",
              maxWidth: "100% !important",
              gridTemplate: "auto/ 1fr 1fr 1fr",
              justifyContent: "space-between",
              alignItems: "center",
              height: '100%'
            }}
          >

            <img
              src={isMobile ? logoMobile : "https://img.pristyncare.com/new_brand%2Felements%2Flogo.svg"}
              alt="PristynCare-logo"
              style={{
                maxWidth: isMobile ? "26px" : "150px",
              }}
            />
            <Typography
              variant="h1"
              fontWeight="bold"
              align="center"
              className="responsiveHead"
              sx={{ display: ['block', 'block', (mainTitle==='Hospital Chat')?'none':'block'], color: "#fff", fontSize: "1.8rem", whiteSpace: "nowrap", }}
            >
              {!!mainTitle?.length ? mainTitle : "Hospital Chat"}
            </Typography>

            {loggedIn ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: "flex-end",
                  alignItems: "center",
                  height:"100%"
                }}
              >
                <Box sx={{ display: ['none', 'none', 'flex'],height:"100%" }}>
                  {headerTabs.map((item, i) => {
                      // console.log("item.key",item.key)
                    return (
                      (!item.roles || item.roles.some((item)=>item.toLowerCase()===role.toLowerCase())) &&
                      <div
                        className="headerTab 1"
                        id={item.title}
                        style={{ position:"relative",fontWeight: activeTab == item.key ? 700 : '', cursor: "pointer" }}
                        key={i}
                        onClick={() => { setActiveTab(item.key); navigate(`/${item.key}`) }}
                      >
                        <div>

                          {item.title}
                        </div>
                        {activeTab == item.key && <div style={{ position: "absolute", bottom: "4px", width: "100%", height: "4px", background: "#3276cf" ,minWidth:"48px",borderRadius:"5px"}}></div>}
                      </div>

                    );
                  })}
                </Box>
                <Box>
                  <NotificationComponent />
                </Box>
                <Box
                  sx={{
                    display: ['none', 'none', 'flex'],
                    justifyContent: "center",
                    alignItems: "center",
                    gap: ".5rem",
                    fontWeight: "500",
                    whiteSpace: "nowrap"
                  }}
                >
                  <Avatar></Avatar>

                  <Typography
                    sx={{
                      fontWeight: "500",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {name}
                  </Typography>

                  <Box>
                    <IconButton
                      onClick={(e) => handlePopOverClick(e, dispatch)}
                      aria-describedby={"logout-popOver"}
                      sx={{
                        color: "#fff",
                        p: 0,
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>
                  </Box>
                </Box>
                <Box onClick={() => toggleDrawer(true)} sx={{ display: ['flex', 'flex', 'none'],visibility:blockChatBox?"hidden":'unset' }}>
                  <MoreVertIcon />
                </Box>

                <Popover
                  id={id}
                  open={open2}
                  anchorEl={anchorEl2}
                  onClose={() => handleClose(dispatch)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Button
                    aria-label="logout"
                    onClick={() => handleLogoutClick(dispatch)}
                    color="warning"
                    sx={{ p: ".5em 1em !important" }}
                    startIcon={<Logout />}
                  >
                    Logout
                  </Button>
                </Popover>
              </Box>
            ) : (
              <div> </div>
            )}
          </Container>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        PaperProps={{ style: { height: "278px", minWidth: "250px", background: "#00263E", color: "white", padding: "10px 0px" } }}
      >
        {headerTabs.map((item, i) => {
          return (
            (!item.roles || item.roles.some((item)=>item.toLowerCase()===role.toLowerCase())) &&
            <div
              className="headerTab 2"
              style={{ position: "relative", cursor: "pointer", display: "flex" }}
              key={i}
              onClick={() => { setActiveTab(item.key); navigate(`/${item.key}`); toggleDrawer(false) }}
            >
              {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
                <div style={{ height: "20px", width: "4px", background: activeTab == item.key ? "white" : "#00263E" }}></div>
              </Box>}
              <div style={{ textDecoration: activeTab == item.key ? "underline" : "", width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
                {item.title}
              </div>

            </div>

          );
        })}
        <div
          className="headerTab 3"
          style={{ position: "relative", cursor: "pointer", display: "flex" }}
          key={'logout'}
          onClick={() => { navigate(`/login`); toggleDrawer(false); handleLogoutClick(dispatch) }}
        >
          {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
            <div style={{ height: "20px", width: "4px", background: "#00263E" }}></div>
          </Box>}
          <div style={{ width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
            Logout
          </div>
        </div>
        {/* {console.log('this is temp state',tempState)} */}
        {tempState&&<div
          className="headerTab 3"
          style={{ position: "relative", cursor: "pointer", display: "flex" }}
          key={'installApp'}
          onClick={(e)=>{handleInstallAppClick(e)}}
          // onClick={() => { navigate(`/login`); toggleDrawer(false); handleLogoutClick(dispatch) }}
        >
          {<Box sx={{ display: ['block', 'block', 'none'], padding: "0px 10px 0px 10px", width: "40px" }}>
            <div style={{ height: "20px", width: "4px", background: "#00263E" }}></div>
          </Box>}
          <div id="installApp" style={{ width: "calc(100% - 40px)", padding: "10px 0px 10px 0px", height: "40px" }}>
            Install to home screen
          </div>
        </div>}
      </Drawer>
    </>

  );
};

export default Header;
