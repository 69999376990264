export const hospitalConstants = {
  HOSPITAL_LIST_REQUESTING: "HOSPITAL_LIST_REQUESTING",
  HOSPITAL_LIST_FETCHED: "HOSPITAL_LIST_FETCHED",
  HOSPITAL_LIST_FAILED: "HOSPITAL_LIST_FAILED",
  LAST_INDEX_API_REQUESTING: "LAST_INDEX_API_REQUESTING",
  LAST_INDEX_API_FETCHED: "LAST_INDEX_API_FETCHED",
  LAST_INDEX_API_FAILED: "LAST_INDEX_API_FAILED",
  SET_HOSPITAL_LIST_SID: "SET_HOSPITAL_LIST_SID",
  SET_HOSPITAL_LIST_DATA: "SET_HOSPITAL_LIST_DATA",
  UPDATE_FULL_HOSPITAL_STATE: "UPDATE_FULL_HOSPITAL_STATE",
  UPDATE_LAST_MESSAGE_INDEX: "UPDATE_LAST_MESSAGE_INDEX",
  MAKE_CITY_LIST:"MAKE_CITY_LIST",
  CLOSE_PATIENT:'CLOSE_PATIENT',
  CLOSE_HOSPITAL:'CLOSE_HOSPITAL',
  OPEN_DRAWER:'OPEN_DRAWER'
};
