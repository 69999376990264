import { IconButton } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import Popover from "@mui/material/Popover";
import { Box } from "@mui/system";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { homeStateActions } from "../../../_actions";
import crossIconOrange from './../../../assets/crossIconOrange.svg';
import galleryIcon from "./../../../assets/galleryIcon.png";
import captureIcon from './../../../assets/icon-camera.svg';
import csvIcon from "./../../../assets/icon-csv.svg";
import docIcon from "./../../../assets/icon-doc.svg";
import pdfIcon from "./../../../assets/icon-pdf.svg";
import videoIcon from './../../../assets/icon-video.svg';
import xlsIcon from "./../../../assets/icon-xls.svg";


const DocumentPopover = forwardRef(({ }, ref) => {
  const dispatch = useDispatch();
  const PathName = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [documentKey, setDocumentKey] = useState("");
  const [filterStatus, setFilterStatus] = useState('')
  // const [docType, setDocType] = useState();
  const [patientDetails, setPatientDetails] = useState()
  const [openDialog, setOpenDialog] = useState(false)
  const [visibleIcons,setVisibleIcons]=useState([])
  const [disableDocumentTagging,setDisableDocumentTagging]=useState(false)
  const [typeOfUpload,setTypeOfUpload]=useState(null)
  const [uploadDetails,setUploadDetails]=useState(null)
  const activeTab = useSelector(state => state.homeState.activeTab)
  useImperativeHandle(ref, () => ({
    handleClick,
  }));

  
  
  const handleClick = ({event, documentKey = null, item = null, filterStatusNow = '',visibleIcons=[],disableDocumentTagging=false,typeOfUpload=null,openDialog,uploadDetails}) => {
    dispatch(homeStateActions.setUploadDocumentStatus({ status: false }))
    setAnchorEl(event);
    setVisibleIcons(visibleIcons)
    setDocumentKey(documentKey);
    setPatientDetails(item)
    console.log('this is filter Status', uploadDetails,typeOfUpload)
    setFilterStatus(filterStatusNow)
    setTypeOfUpload(typeOfUpload)
    setDisableDocumentTagging(disableDocumentTagging)
    setUploadDetails(uploadDetails)
    if(openDialog){
      setOpenDialog(openDialog)
    }
    // dispatch(homeStateActions.setUploadDocumentStatus({status:true,key:documentKey}))
  };

  const handleClose = () => {
    setAnchorEl(null);

  };

  const openUploadDocument = ({ documentType, capture = false }) => {
    console.log('this is capture', capture)
    var payload = {
      status: true,
      key: documentKey,
      docType: documentType,
      patientDetails: patientDetails,
      disableDocumentTagging:disableDocumentTagging,
      typeOfUpload:typeOfUpload,
      uploadDetails:uploadDetails
    }

    if (capture === true) {
      payload['capture'] = true
    }
    console.log('this is payload', payload)
    dispatch(
      homeStateActions.setUploadDocumentStatus(payload)
    );
    handleClose()
    // let length = document.getElementById().files.length;
  };

  const icons = [
    { src: captureIcon, key: "image", capture: true, docType: "image/*", showMobile: true, showDesktop: false },
    { src: videoIcon, key: "video", docType: "video/x-msvideo, .avi, video/x-matroska, .mkv, video/webm, video/mp4", showMobile: true, showDesktop: true },
    { src: galleryIcon, key: "image", docType: "image/*", showMobile: true, showDesktop: true },
    { src: xlsIcon, key: "excel", docType: ".xlsx", showMobile: true, showDesktop: true },
    { src: pdfIcon, key: "pdf", docType: ".pdf", showMobile: true, showDesktop: true },
    { src: docIcon, key: "doc", docType: ".doc,.docx", showMobile: true, showDesktop: true },
    { src: csvIcon, key: "csv", docType: ".csv", showMobile: true, showDesktop: true },

  ];
  useEffect(() => {
    console.log("PathNamePathName", PathName)
    if (PathName.pathname.includes("owner/profile")) {
      setOpenDialog(true)
    }
  }, [])

  const uploadIcons = ({ size, viewPort }) => {
    var displayIcons=[...icons]
    if(visibleIcons&&visibleIcons.length>0)
    displayIcons=displayIcons.filter(item=>{
      return visibleIcons.includes(item.key)
    })
    return (
      // <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "8px 0px 15px 15px", color: "white" }}>
      //   <Box sx={{ display: 'flex', flexDirection: ['row', 'row', (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? "row" : 'column'], flex: "0 0 33.33%", flexWrap: "wrap", justifyContent: "center",gap:["10px"]}}>
      <>
        {displayIcons.map((item, index) => {
          // if (TabInfo && index === 2) return;
          const showView = viewPort === 'desktop' ? item.showDesktop : item.showMobile
          return (<>
            {
              showView && <Box style={{ display: "", width: size.width, height: size.height,cursor:'pointer' }}
                onClick={() => {
                  openUploadDocument({ documentType: item.key, capture: item.capture });
                }}
              >
                <img style={{ width: "100%", height: "100%", objectFit: "contain" }} src={item.src} alt="item img"></img>
              </Box>
            }
          </>);
        })}
      </>
      //   </Box>
      // </Box>
    )
  }
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover2" : undefined;
  // console.log("activeTab",activeTab)
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            background: "none", boxShadow: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? "5px 5px 15px #00000029!important" : "none", maxHeight: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? 'none' : "unset!important", display: "flex", justifyContent: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? '' : "flex-start", marginTop: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? '0px' : "-30px", height: "unset!important", width: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? document.getElementById('uploadChecklistWidth')?.offsetWidth : 'none',
            backgroundColor: "transparent",
          }
        }}
        style={{ boxShadow: "5px 5px 15px #00000029!important" }}
        sx={{
          background: "none",
          display: ['none', 'none', openDialog ? 'none' : 'block']
        }}
      >
        <Box sx={{
          position: "relative",
          mt: "10px",
          "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            visibility: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? '' : 'hidden',
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            left: "10px"
          }
        }}>
          <Box className="documentUploadPopover" sx={{ background: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? "white" : "transparent", overflow: "hidden", margin: (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? '0px' : "-10px 0px 0px -8px" }} >
            {(activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') && <div className="popoverTitle">Select File Type</div>}
            <div className="popoverIcons" >
              {activeTab === "home" && <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "8px 0px 15px 15px", color: "white" }}>
                <Box sx={{ display: 'flex', flexDirection: ['row', 'row', (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? "row" : 'column'], flex: "0 0 33.33%", flexWrap: "wrap", justifyContent: "center", gap: ["10px"], }}>
                  {uploadIcons({ size: { width: '40px', height: "40px" }, viewPort: "desktop" })}
                </Box>
              </Box>}

              {(activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') && <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "8px 0px 15px 15px", color: "white" }}>
                <Box sx={{ display: 'flex', flexDirection: ['row', 'row', (activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView') ? "row" : 'column'], flex: "0 0 33.33%", flexWrap: "wrap", justifyContent: "flex-start", gap: ["20px"] }}>
                  {uploadIcons({ size: { width: '50px', height: "50px" }, viewPort: "desktop", marginLeft: "15px", gap: ["20px"] })}
                </Box>
              </Box>}

            </div>
          </Box>
        </Box>
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
      </Popover>
      <Dialog onClose={handleClose} open={anchorEl} sx={{
        display: ['block', 'block', openDialog ? 'block' : 'none'],
        ".MuiBackdrop-root": {
          backgroundColor: "rgb(0 38 62 / 40%)",

        }
      }}
        PaperProps={{
          sx: {
            margin: "auto",
            overflow: "visible",
            width: "280px",
            borderRadius: "8px"
          }
        }}>
        <Box sx={{ height: "60px", background: '#3D7CC9', display: "flex", justifyContent: "center", alignItems: "center", color: "white", fontWeight: "600", fontSize: "18px", borderRadius: "8px" }}>Select File Type</Box>


        <Box sx={{ display: "flex", flexDirection: "column", borderRadius: "8px 0px 15px 15px", color: "white" }}>
          <Box sx={{ display: 'flex', flexDirection: ['row', 'row', ((activeTab === 'checklistSummary' || activeTab ==='checklistSummary/hospitalView')||activeTab === 'owner'||openDialog) ? "row" : 'column'], flex: "0 0 33.33%", flexWrap: "wrap", justifyContent: "center", gap: ["20px 40px"], padding: "20px" }}>
            {uploadIcons({ size: { height: "50px", width: "50px" }, viewPort: "mobile" })}
            <IconButton
              aria-label="close"
              onClick={() => handleClose()}
              sx={{
                position: "absolute",
                right: "30px",
                top: 0,
                zIndex: "1",
                transform: "translate(50%, -50%)",
                color: "#FFFFFF",
                backgroundColor: "#fff",
                width: "30px",
                height: "30px",
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "#fff",
                },
                display: ['flex', 'flex', 'flex'],
              }}
            >
              <img src={crossIconOrange} alt="close" />
            </IconButton>
          </Box>
        </Box>
      </Dialog>

    </>
  );
});

export default DocumentPopover;
