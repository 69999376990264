import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { FormControl, Select } from '@mui/material';
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import React from 'react';


function SelectHWA({ disabled, value, onChange, children,selectProps,formProps,placeholder,label }) {
    const {sx:formSx,...restFormProps}=formProps||{}
    const {sx:selectSx,inputProps,...restSelectProps}=selectProps||{}
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        "label + &": {}, "& .MuiInputBase-input": {
            borderRadius: 4,
            [theme.breakpoints.down('md')]: {
                // styles
                backgroundColor: "#F5F5F5",
                borderRadius: "6px",
                fontSize: "18px",
                padding: "11px 0px 6px 20px"
            },
            [theme.breakpoints.up('md')]: {
                // styles
                backgroundColor: "#F5F5F5",
                fontSize: "14px",
                borderRadius: "6px",
                padding: "11px 0px 6px 11px"
            },
            [theme.breakpoints.up('xl')]: {
                // styles
                fontSize: "20px",
                borderRadius: "6px",
                padding: "11px 0px 6px 20px",
                backgroundColor: "#F5F5F5",

            },
            fontSize: ['14px', '14px', '14px', '14px', '20px'],
            position: "relative", transition: theme.transitions.create(["border-color", "box-shadow"]), fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"',].join(","), "&:focus": { borderRadius: 4, borderColor: "#80bdff", boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)", },
        },
        "& svg": {
            color: "#3D7CC9"
        }
    }));
    return (

        <FormControl
            placeholder={placeholder}
            sx={{ width: "100%", borderRadius: '10px', background: ['#f5f5f5'] ,...formSx }}
            className="uploadDocumentFormSelect"
            disabled={disabled}
            data-name="hello1"
            {...restFormProps}
        >
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                placeholder={placeholder}
                select
                onChange={onChange}
                inputProps={{
                    sx: {
                        fontSize: ['12px', '14px', '16px', '14px', '20px'],
                        padding:{xs:'10px 0px 10px 10px',xl:'10px 0px 10px 20px'},
                        paddingRight:'32px!important',
                        lineHeight:'1.4',
                        ...inputProps
                    }
                }}
                // input={<BootstrapInput />}
                IconComponent={ SelectArrow }
                sx={{
                     background: ['#f5f5f5'], width: "100%", borderRadius: ["6px", '6px', 'unset'], color: { xs: 'black', lg: '#666A70' },  '& fieldset': {
                        border: 'none',
                      },...selectSx 
                }}
                {...restSelectProps}
            >
            {children}
            </Select>
        </FormControl>

    )
}

export default SelectHWA
const SelectArrow = ({...rest}) => <KeyboardArrowDownRounded  {...rest}  sx={{ color: '#3D7CC9!important', width: '35px!important', height: '35px !important',right:'0px!important',top:'unset!important' }}  />