import { toast } from "react-toastify";
import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let centralBaseUrl = process.env.REACT_APP_CENTRAL_BASE_URL;

export function getCentralReportingDataService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;

        let currentDate = new Date();
        // Get date upcoming 11 days
        currentDate.setDate(currentDate.getDate() + 11);
        let currentISODate = currentDate.toISOString();

        // Get date 11 days before
        let fifteenDaysAgo = new Date(Date.now() - (11 * 24 * 60 * 60 * 1000));
        let fifteenDaysAgoISO = fifteenDaysAgo.toISOString();
    const paramsData =  {
      ...params,
      appDate :{"startDate":fifteenDaysAgoISO,"endDate":currentISODate}
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // mobileplatform: "web",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...paramsData }),
    };
    return fetch(
      `${centralBaseUrl}/getReportingData`,
      requestOptions
    )
      .then(handleResponse)
      .then(res => res)
      .catch(function (error) {
        console.error(error);
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }

export function updateCentralReportingData(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // mobileplatform: "web",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...params }),
    };
    return fetch(
      `${centralBaseUrl}/updateCentralDashRepoting`,
      requestOptions
    )
      .then(handleResponse)
      .then(res => {
        if(res.status===200){
          toast.success(res.message)
        }
      })
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }
