import swal from "sweetalert";
import { alertActions, configActions, homeStateActions } from ".";
import { hospitalConstants } from "../_constants";
import { hadleLastMessageIndexUpdate } from "../_helpers";
import { hospitalService } from "../_services";

window.hospListFetchCounter = 0;

function getHospitalList(data) {
  const {params = {},callback = null,fetchFromServiceFile = false,cache = undefined}= data;
  const { userId } = params;
  return (dispatch) => {

    dispatch(configActions.showSpinningLoader());
    dispatch(request({ userId }));

    if (fetchFromServiceFile) {
    hospitalService.getHospitalList(params).then(
      (res) => {
        if (!!!res.length) {
          dispatch(
            homeStateActions.setState({
              loadingSpinnerText: "We are setting things for you",
            })
          );

          if (window.hospListFetchCounter > 2) {
            dispatch(configActions.hideSpinningLoader());
            dispatch(homeStateActions.setState({ noHospitalsFound: true }));
            delete window.hospListFetchCounter;
            delete window.hospListFetchCounterTimer;

            swal({
              title: "No Hospitals Found!",
              text: "You don't belong to any hospital, please contact Admin",
              icon: "warning",
            });

            dispatch(
              homeStateActions.setState({
                loadingSpinnerText: "This is taking a little bit longer",
              })
            );

            return;
          }

          window.hospListFetchCounterTimer = setTimeout(() => {
            dispatch(hospitalActions.getHospitalList(params));
            window.hospListFetchCounter = window.hospListFetchCounter + 1;
          }, 3000);
        } else {
          dispatch(success(res));
          Boolean(callback) && callback(res);
          dispatch(configActions.hideSpinningLoader());

          delete window.hospListFetchCounter;
          delete window.hospListFetchCounterTimer;

          dispatch(
            homeStateActions.setState({
              loadingSpinnerText: "This is taking a little bit longer !",
            })
          );

          // dispatch(configActions.hideSpinningLoader());
        }

        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));

        dispatch(
          homeStateActions.setState({
            loadingSpinnerText: "This is taking a little bit longer !",
          })
        );
      }
    );
    }else{
      dispatch(success(cache));
      dispatch(configActions.hideSpinningLoader());
    }
  };

  function request(user) {
    return { type: hospitalConstants.HOSPITAL_LIST_REQUESTING, user };
  }
  function success(hospital) {
    return { type: hospitalConstants.HOSPITAL_LIST_FETCHED, hospital };
  }
  function failure(error) {
    return { type: hospitalConstants.HOSPITAL_LIST_FAILED, error };
  }
}

const setCurrentHospitalSid = (params) => {
  const { sid } = params;
  return {
    type: hospitalConstants.SET_HOSPITAL_LIST_SID,
    sid,
  };
};

const setCurrentHospitalData = (params) => {
  const { data } = params;
  return {
    type: hospitalConstants.SET_HOSPITAL_LIST_DATA,
    data,
  };
};

const handleLastIndexAPI = (data) => {
  const {
    params = {},
    callback = null,
    // fetchFromServiceFile = false,
    fetchFromServiceFile = true,
    cache = undefined,
  } = data;

  return (dispatch, getState) => {
    dispatch(request());

    if (fetchFromServiceFile) {
      hospitalService.fetchLastIdexAPI(params).then(
        (res) => {
          dispatch(success(res));

          const { lastMessageIndex, channelSid: sid } = res;

          hadleLastMessageIndexUpdate({
            dispatch,
            getState,
            lastMessageIndex,
            sid,
          });

          //callback added for the support of the the caching work
          Boolean(callback) && callback(res);
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      dispatch(success(cache));
    }
  };

  function request(user) {
    return { type: hospitalConstants.LAST_INDEX_API_REQUESTING, user };
  }
  function success(response) {
    return {
      type: hospitalConstants.LAST_INDEX_API_FETCHED,
      lastIndexAPIResponse: response,
    };
  }
  function failure(error) {
    return { type: hospitalConstants.LAST_INDEX_API_FAILED, error };
  }
};

const updateFullState = (newState) => {
  return {
    type: hospitalConstants.UPDATE_FULL_HOSPITAL_STATE,
    newState,
  };
};


export const hospitalActions = {
  getHospitalList,
  setCurrentHospitalSid,
  setCurrentHospitalData,
  handleLastIndexAPI,
  updateFullState,
};
