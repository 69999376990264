import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { configActions } from "../_actions";
import { rolesAccessList } from "./RoutingConstants";
import { useGetRefreshState } from "./useGetRefreshState";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const loggedIn = useSelector((state) => state?.authentication?.loggedIn)
  const loggingIn = useSelector((state) => state?.authentication?.loggingIn)
  const authentication = useSelector((state) => state.authentication)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isMounted = useRef(false);
  const notificationState = useSelector(
    (state) => state.homeState.notificationState
  );


  // this hook is used to set the state of app on refresh
  const {getRefreshedPage } = useGetRefreshState({source:"A"})

  useEffect(() => {
    if (isLoading) dispatch(configActions.showSpinningLoader());
    else dispatch(configActions.hideSpinningLoader());
  }, [isLoading])

  useEffect(() => {
    if (!loggingIn) setIsLoading(false)
    if (!loggedIn && !isLoading) navigate('/login')

    if(loggedIn&&!isLoading){
      checkRoleAccess()
      getRefreshedPage({})
    }

  }, [loggingIn, loggedIn, isLoading])
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    return
    }
      if (Object.keys(notificationState).length > 0) {
        const {
          appointmentId,
          hospitalId
          } = notificationState.data;
        getRefreshedPage({hospId:hospitalId,apptId:appointmentId});
      }
     
  }, [notificationState]);

  const checkRoleAccess=()=>{
    const role = authentication?.user?.roles?.role
    console.log("role1",role)
      if(!role){
        return
      }
   for (const tab in rolesAccessList)
   {
    console.log("role2",role?.toLowerCase())
    if(pathname.includes(tab)&&!rolesAccessList[tab].includes(role?.toLowerCase()||"")){
      console.log("role3")
      navigate('/home')
    }
   }
  }
  return (
    <>
      {(!isLoading && loggedIn) && children}
    </>
  )
}
export default ProtectedRoute