import { Button, useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect, useDispatch, useSelector } from "react-redux";
import uniqid from "uniqid";
import { configActions } from "../../../../_actions";
import { GetRandomColor } from "../../../../_helpers";
import { Cards } from "../../../common/Cards";
import { useNavigate } from "react-router-dom";
const hospitalListingColor = new GetRandomColor();

function HospitalList(props) {
  const array = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  const { hospitalList = [], isHospitalActive, fetchMoreData, hasMore } = props;
  const { data } = props;
  // const customResizeObserver = useResizeObserver();
  const currentHospitalData = useSelector(state => state.hospitals.currentHospitalData);
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const isMountedRef = useRef(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const navigate = useNavigate('')
  const {role, otherRoles } = useSelector(state => state.authentication.user.roles)
  const [showToDoList,setShowToDoList] = useState(false)
  const validRoles = ["cityops", "centraldashboard"]

  // Function to find a matching role from validRoles
  function findMatchRole(role, otherRoles) {
    if (role && validRoles.includes(role.toLowerCase())) return true
    if (otherRoles && otherRoles.find(r => validRoles.includes(r.toLowerCase()))) return true
    return false
    // return validRoles.includes(role.toLowerCase()) || otherRoles.find(r => validRoles.includes(r.toLowerCase()));
  }
  useEffect(()=>{
    let isValidRoleForCentral = findMatchRole(role, otherRoles)
    if(isValidRoleForCentral){
      setShowToDoList(true)
    } 
    else{
      setShowToDoList(false)
    }
  },[role,otherRoles])

  useEffect(() => {
    isMountedRef.current = true;
    dispatch(configActions.hideSpinningLoader());
    return () => isMountedRef.current = false;
  }, []);

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const setHeightWidth = ({ width, height }) => {
    if (isMountedRef.current) {
      setDimensions({ width, height });
    }
  };

  const handleResizeFromObserver = (entry) => {

    const {
      contentRect: { width },
    } = entry[0];

    if (isMountedRef.current) {
      setHeightWidth({
        width,
        height: window.innerHeight,
      });
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {

      // customResizeObserver({
      //   selector: "#main-hospital-list-contianer",
      //   callback: handleResizeFromObserver,
      // });
    }

    // window.addEventListener("resize", handleResize, false);
  }, []);
  const notificationState = useSelector(
    (state) => state.homeState.notificationState
  );
  useEffect(() => {
    let sid = currentHospitalData?.sid
    let idx = 0;
    for (let i = 0; i < hospitalList.length; i++) {
      if (hospitalList[i]['sid'] === sid) {
        idx = i;
        break;
      }
    }
    listRef.current?.scrollToItem(idx, 'center');

  }, [hospitalList.length, notificationState])


  const Row = ({ index, style }) => {
    let item = hospitalList[index];
    return (
      <div
        id={item?.sid}
        key={uniqid()}
        className="cardContainer"
        style={style}
      >
        {
          <Cards
            className="card"
            isHospital={true}
            item={item}
            sid={item?.sid}
            data={{
              ...data,

              newRandomColor: hospitalListingColor.getNewColor(hospitalList),
            }}
            isSmallScreen={isSmallScreen}
          />
        }
      </div>
    );
  };
  return (
    <React.Fragment>
      <CssBaseline />
      {/* hospital list */}

      {isHospitalActive ? (
        <Container
          id="main-hospital-list-contianer"
          disableGutters
          sx={{
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            m: "0 !important",
            px: ".5em",
            pb: ".5em",
            overflow: "hidden",
            flexGrow: 1,
            position: "relative",
          }}
        >
          {hospitalList && hospitalList.length > 0 && (
            <Box sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "scroll",
              height: "100%",
              paddingLeft: ['10px', '20px', '0px'],
              paddingRight: ['10px', '20px', '0px']
            }}
              id="hospitalList"

            >

              <InfiniteScroll
                dataLength={hospitalList}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollableTarget="hospitalList"
                loader={<h4>Loading...</h4>}
              >
                {hospitalList.map(item => {
                  return <Box
                    id={item?.sid}
                    key={uniqid()}
                    className="cardContainer"
                    sx={{ margin: ['10px'] }}
                  >
                    {
                      <>
                      <Cards
                        className="card"
                        isHospital={true}
                        item={item}
                        sid={item?.sid}
                        data={{
                          ...data,

                          newRandomColor: hospitalListingColor.getNewColor(hospitalList),
                        }}

                        
                        isSmallScreen={isSmallScreen}
                        />
                        { item._id==='Dy1BVqLISF' && console.log('OOOOO',item)}

                      </>
                    }
                  </Box>
                })}
                 {
                  isSmallScreen && showToDoList && <Button sx={{
                    position:'fixed',
                    bottom:'1rem',
                    right:'1rem',
                    boxShadow:'2px 4px 10px 0px #00000040',
                    height:'40px',
                    width:'120px',
                    backgroundColor:'#FFC400',
                    color:'#000',
                    borderRadius:'10px',
                    border:'1px solid #FFEAA6',
                    fontSize:'14px'
                    }}
                    onClick={()=>navigate('/central')}
                  >
                      To-Do List
                  </Button>
                 }
              </InfiniteScroll>
            </Box>
            // <List
            //   ref={listRef}
            //   className="List"
            //   itemCount={hospitalList.length}
            //   itemSize={70}
            //   height={dimensions.height - 210}
            //   overscanCount={10}
            // >
            //   {Row}
            // </List>
          )}
        </Container>
      ) : (
        <></>
      )}

    </React.Fragment>
  );
}

const connectedHospitalList = connect()(HospitalList);
export { connectedHospitalList as HospitalList };
