import { useMediaQuery } from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { useEffect, useState } from 'react';

const HighChartCustom = ({ width = [], height = [], chart, overRightWidth, data, plotOptions,...restOptions }) => {
  const isMobileScreen = useMediaQuery(theme => theme.breakpoints.up("xs"));
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const isMediumScreen = useMediaQuery(theme => theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const isExtraLargeScreen = useMediaQuery(theme => theme.breakpoints.up("lg"));

  const [chartWidth, setChartWidth] = useState(125)
  const [chartHeight, setChartHeight] = useState(65)

  const [xsWidth, smWidth, mdWidth, lgWidth, xlWidth] = width
  const [xsHeight, smHeight, mdHeight, lgHeight, xlHeight] = height



  const options = {
    chart: {
      type: 'area',
      height: chartHeight,
      width: chartWidth,
      ...chart,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false
    },
    yAxis: [{
      visible: false
    }],
    xAxis: [{
      visible: false,
      type: 'datetime',
    }],
    plotOptions: {
      series: {
        lineWidth: 1,
        ...plotOptions,
        pointInterval: 24 * 3600 * 1000 // one day
      }
  },
    series: [
      {
        name:"Surgeries",
        data: (data?.length > 0) ? data : [0, 7],
      }
    ],
    credits: {
      enabled: false
    },
  }


  useEffect(() => {
    // -------- set width ----------
    if (isExtraLargeScreen && xlWidth) setChartWidth(xlWidth)
    else if (isLargeScreen && lgWidth) setChartWidth(lgWidth)
    else if (isMediumScreen && mdWidth) setChartWidth(mdWidth)
    else if (isSmallScreen && smWidth) setChartWidth(smWidth)
    else setChartWidth(xsWidth && 125)
    // -------- set height----------
    if (isExtraLargeScreen && xlHeight) setChartHeight(xlHeight)
    else if (isLargeScreen && lgHeight) setChartHeight(lgHeight)
    else if (isMediumScreen && mdHeight) setChartHeight(mdHeight)
    else if (isSmallScreen && smHeight) setChartHeight(smHeight)
    else setChartHeight(xsHeight && 65)

  }, [isMobileScreen, isSmallScreen, isMediumScreen, isLargeScreen, isExtraLargeScreen])


  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={{ ...options, ...restOptions }} />
    </>
  );
}

export default HighChartCustom;
