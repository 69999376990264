import { useDispatch, useSelector } from "react-redux";
import { cacheStorageActions } from "../_actions";

export const useReduxCacheAndDispatch = () => {
  const dispatch = useDispatch();
  const cacheStorage = useSelector((state) => state.cacheStorage);

  const customDispatch = (data) => {
    const { action, params, cacheOf, key, newCache = null, fetchApi = false } = data;

    const getCache = (newCache) => {
      let cache = newCache || cacheStorage?.[cacheOf]?.[key];
      if (!!fetchApi === true) {
        return null
      }

      return cache;
    };

    const currentCache = getCache(newCache);

    const callback = (resFromActionFile) => {
      dispatch(
        cacheStorageActions.setCacheStorageItem({
          cacheOf,
          key,
          value: resFromActionFile,
        })
      );
    };

    if (!!newCache) callback(newCache);
    Boolean(currentCache)
      ? dispatch(action({ fetchFromServiceFile: false, cache: currentCache }))
      : dispatch(action({ params, callback, fetchFromServiceFile: true }));
  };

  // return [finalResult, customDispatch];
  return customDispatch;
};
