export const userConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  OTP_REQUEST: "OTP_REQUEST",
  OTP_SUCCESS: "OTP_SUCCESS",
  OTP_FAILURE: "OTP_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  SET_USER_MOBILE: "SET_USER_MOBILE",
  GET_ROLES_LIST_REQUEST: "GET_ROLES_LIST_REQUEST",
  GET_ROLES_LIST_SUCCESS: "GET_ROLES_LIST_SUCCESS",
  GET_ROLES_LIST_FAILED: "GET_ROLES_LIST_FAILED",
  HANDLE_USER_REGISTER_REQUEST: "HANDLE_USER_REGISTER_REQUEST",
  HANDLE_USER_REGISTER_SUCCESS: "HANDLE_USER_REGISTER_SUCCESS",
  HANDLE_USER_REGISTER_FAILED: "HANDLE_USER_REGISTER_FAILED",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",
  ACCESS_TOKEN_VERIFY_RQUEST: "ACCESS_TOKEN_VERIFY_RQUEST",
  ACCESS_TOKEN_VERIFY_SUCCESS: "ACCESS_TOKEN_VERIFY_SUCCESS",
  ACCESS_TOKEN_VERIFY_FAILURE: "ACCESS_TOKEN_VERIFY_FAILURE",
  REFRESH_TOKEN_VERIFY_RQUEST: "REFRESH_TOKEN_VERIFY_RQUEST",
  REFRESH_TOKEN_VERIFY_SUCCESS: "REFRESH_TOKEN_VERIFY_SUCCESS",
  REFRESH_TOKEN_VERIFY_FAILURE: "REFRESH_TOKEN_VERIFY_FAILURE",
  UPDATE_FULL_USERS_STATE: "UPDATE_FULL_USERS_STATE",
  UPDATE_FULL_AUTH_STATE: "UPDATE_FULL_AUTH_STATE",
  UPDATE_NOTIFICATION_PERMISSIONS:"UPDATE_NOTIFICATION_PERMISSIONS"
};
