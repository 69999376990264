import { Button, Container, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userActions } from "../../_actions";

const RegisterNewUser = ({ changeScreen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMobile = localStorage.getItem("userMobile");
  const [isFormNotVerifed, setIsFormNotVerifed] = useState(true);
  const [formData, setFormData] = useState({
    mobile: userMobile,
    role: "",
    name: "",
  });

  const rolesListActive = useSelector(
    (state) => state.authentication?.rolesListActive
  );
  const rolesListResponse = useSelector(
    (state) => state.authentication?.rolesListResponse
  );

  const handleFormVerification = () => {
    setIsFormNotVerifed(() =>
      Object.values(formData).some((value) => !!!value.length)
    );
  };

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    dispatch(userActions.hadleUserRegister({ params: formData, navigate }));
    // dispatch(
    //   userActions.getOtp({
    //     mobile: userMobile,
    //   })
    // );
  };

  useEffect(() => {
    handleFormVerification();
  }, [formData]);

  useEffect(() => {
    handleFormVerification();

    dispatch(userActions.getRolesList());
  }, []);

  return (
    <form action="" onSubmit={handleFormSubmit}>
      <Container
        sx={{
          mt: "15vh",
          overflow: "auto",
          maxWidth: "500px !important",
          display: "grid",
          gap: "1rem",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "stretch",
        }}
      >
        <div
          className="img-container"
          style={{ justifySelf: "stretch", marginBottom: ".5em", position: "relative" }}
        >
          <img
            src="/logo-black.svg"
            alt="PristynCare-logo"
            style={{
              display: "block",
              width: "100%",
              margin: "auto",
              maxWidth: "15rem",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center",
            }}
          />
          {/* <span onClick={()=>changeScreen()} style={{position:"absolute",left:"10px",top:"15px",color:"#c7c7c7"}}><ArrowBackIosIcon></ArrowBackIosIcon></span> */}
        </div>

        <TextField
          fullWidth
          name="mobile"
          label="Phone"
          value={formData["mobile"]}
          disabled
        />

        <TextField
          autoFocus
          fullWidth
          required
          name="name"
          label="Enter Name"
          onChange={handleFormChange}
          value={formData["name"]}
        />

        <FormControl fullWidth required>
          <InputLabel id="role-select">Select Role</InputLabel>

          <Select
            name="role"
            id="role-select"
            label="Select Role"
            placeholder="Please Select a Role"
            onChange={handleFormChange}
            value={formData["role"]}
            disabled={!rolesListActive}
            color="primary"
          >
            {rolesListResponse?.result?.roles?.hospital?.map((item, index) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isFormNotVerifed}
          sx={{ mt: 3, mb: 2 }}
        > Send </Button>
      </Container>
    </form>
  );
};

export default RegisterNewUser;
