import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// setup fake backend
// import { configureFakeBackend } from "./_helpers";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import WebSocketProvider from './contexts/WebSocket';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./_helpers";
// configureFakeBackend();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
          <PersistGate persistor={persistor}>
          <WebSocketProvider>
            <App />
            </WebSocketProvider>
          </PersistGate>
        </BrowserRouter>

    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


//staging environment
// REACT_APP_BASE_URL_COMMON="http://13.233.108.135"
// REACT_APP_BASE_URL="http://65.0.124.73/hospitalChat"
//`wss://vwqzgx6ejk.execute-api.ap-south-1.amazonaws.com/socket?authorization=bearer%20${token}&mobilePlatform=iOS&appName=Hospital%20App%20iOSs&mobilePlatform=desktop&appName=Hostpital_web_app&deviceId=${localDeviceId}`,


// production environment 
// REACT_APP_BASE_URL_COMMON="https://pristyntech.com/java"
// REACT_APP_BASE_URL="https://pristyntech.com/hospitalChat"
//`wss://vwqzgx6ejk.execute-api.ap-south-1.amazonaws.com/production?authorization=bearer%20${token}&mobilePlatform=iOS&appName=Hospital%20App%20iOSs&mobilePlatform=desktop&appName=Hostpital_web_app&deviceId=${localDeviceId}`,