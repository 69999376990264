import { homeStateConstants } from "../_constants";
import { handleChatBoxActions } from "../_helpers";

function setState(newState) {
  return { type: homeStateConstants.SET_STATE, newState };
}

function updateSendAgainList(networkStatus) {
  return (dispatch, getState) => {
    handleChatBoxActions(
      dispatch,
      getState,
      "updateSendAgainList",
      networkStatus
    );
  };
}

const updateFullState = (newState) => {
  return {
    type: homeStateConstants.UPDATE_FULL_HOMESTATE_STATE,
    newState,
  };
};

function checkUpdatedBuild(value) {
  return { type: homeStateConstants.TOGGLE_CHECK_UPDATED_BUILD, value };
}
function toggleAlarmNotification(value) {
  return { type: homeStateConstants.TOGGLE_ALARM_NOTIFICTION, value };
}
function toggleAlarmSound(value) {
  return { type: homeStateConstants.TOGGLE_ALARM_SOUND, value };
}
function addAlarmNotification(payload) {
  return { type: homeStateConstants.ADD_ALARM_NOTIFICATION, payload };
}
function deleteAlarmNotification() {
  return { type: homeStateConstants.DELETE_ALARM_NOTIFICATION };
}
function updateCurrentState(payload) {
  return { type: homeStateConstants.UPDATE_CURRENT_STATE, payload }
}
function updateSelectedCities(payload) {
  return { type: homeStateConstants.UPDATE_SELECTED_CITIES, payload }
}
function setUploadDocumentStatus(payload) {
  return { type: homeStateConstants.UPLOAD_DOCUMENT_STATUS, payload }
}
function setActiveTab(payload) {
  return { type: homeStateConstants.SET_APP_TAB, payload }
}
function setScreenName(payload){
  return {type:homeStateConstants.SET_SCREEN_NAME,payload}
}
function setUserSanityScreen(){
  return {type:homeStateConstants.USER_SANITY_POPUP}
}

const homeStateActions = {
  setState,
  updateSendAgainList,
  updateFullState,
  checkUpdatedBuild,
  toggleAlarmNotification,
  addAlarmNotification,
  deleteAlarmNotification,
  toggleAlarmSound,
  updateCurrentState,
  updateSelectedCities,
  setUploadDocumentStatus,
  setActiveTab,
  setScreenName,
  setUserSanityScreen
};

export default homeStateActions