export const homeStateConstants = {
  SET_STATE: "SET_STATE",
  UPDATE_FULL_HOMESTATE_STATE: "UPDATE_FULL_HOMESTATE_STATE",
  TOGGLE_CHECK_UPDATED_BUILD:"TOGGLE_CHECK_UPDATED_BUILD",
  TOGGLE_ALARM_NOTIFICTION:"TOGGLE_ALARM_NOTIFICTION",
  ADD_ALARM_NOTIFICATION:"ADD_ALARM_NOTIFICATION",
  DELETE_ALARM_NOTIFICATION:"DELETE_ALARM_NOTIFICATION",
  TOGGLE_ALARM_SOUND:"TOGGLE_ALARM_SOUND",
  UPDATE_CURRENT_STATE:"UPDATE_CURRENT_STATE",
  UPDATE_SELECTED_CITIES:"UPDATE_SELECTED_CITIES",
  UPLOAD_DOCUMENT_STATUS:"UPLOAD_DOCUMENT_STATUS",
  SET_APP_TAB:"SET_APP_TAB",
  SET_SCREEN_NAME:"SET_SCREEN_NAME",
  USER_SANITY_POPUP:'USER_SANITY_POPUP',
};
