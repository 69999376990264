import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {ButtonHWA} from '../../../_components';
import { toast } from "react-toastify";
import { documentsPopupActions } from '../../../_actions';
import './style.css';

export default function UserSanityTable() {
    const [staffMembers, setStaffMembers] = useState(null)
    const { hospitalMembers } = useSelector(({ pcScore }) => pcScore)
    const { hospital_id } = useSelector((state) => state.hospitals.currentHospitalData);
    const [ removedStaffMember,setRemovedStaff ] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (hospitalMembers) {   
            console.log('hospital member',hospitalMembers)         
          let members = hospitalMembers.map(hospitalMember => {
            const { attributes, memberSid } = hospitalMember
            return {
              membersid: memberSid,
              name: attributes?.username,
              mobile: attributes?.Mobile,
              role: attributes?.role,
              userId: attributes.userId,
              remove:false
            }
          })
          setStaffMembers(members)
        }
      }, [hospitalMembers])

    const checkUserMember = (member)=>{
        for(let i=0;i<removedStaffMember.length;i++){
            if(member.membersid===removedStaffMember[i].membersid){
                return i
            }
        }
        return -1
    }

    const setUserStatus = (member,status,elemId,idx)=>{
        const {membersid,userId} = member
        let userDetail={
            hospitalID: hospital_id, 
            userType: "External", 
            membersid: membersid, 
            userId: userId,
            remove:status
        }

        let checkUser = checkUserMember(member)
        let tempRemoveData = [...removedStaffMember]

        if(checkUser>-1){
            tempRemoveData[checkUser] = userDetail
            if(elemId==='member'+idx){
                document.getElementById('removed'+idx).classList.remove('active-sanity-status')
            }
            else if(elemId==='removed'+idx){
                document.getElementById('member'+idx).classList.remove('active-sanity-status')
            }
        }
        else{
            tempRemoveData.push(userDetail)
        }
        document.getElementById(elemId).classList.add('active-sanity-status')
        setRemovedStaff(tempRemoveData)
    }

    const submitSanity = ()=>{
        if(removedStaffMember?.length===staffMembers?.length){
            console.log('removing staff member',removedStaffMember)
            dispatch(documentsPopupActions.submitSanityPopup({membersList:removedStaffMember}))
        }
        else{
           toast.error('Please select all users')
        }
    }

    return(
        <div>
            <h5 className='table-heading'> Remove Hospital Chat Acccess from folks no longer associated with the Hospital </h5>
            <div className='table-container'>
            <div className='table'>
                <div className='table-cell table-head'>Name</div>
                <div className='table-cell table-head'>Official Number</div>
                <div className='table-cell table-head'>Role</div>
                <div className='table-cell table-head statusButton'>Status</div>
            </div>
                {staffMembers?.map((user,index)=>{
                    return(
                        <div className='table' key={user.key}> 
                        <div className='table-cell table-body'> {user.name} </div>
                        <div className='table-cell table-body'> {user.mobile} </div>
                        <div className='table-cell table-body'> {user.role} </div>
                        <div className='table-cell table-body statusButton'> 
                            <ButtonHWA sx={{fontSize:['10px','10px','14px','14px','14px']}}
                            onClick={()=>setUserStatus(user,false,'member'+index,index)}
                            id={'member'+index}
                            > Still a member </ButtonHWA>

                            <ButtonHWA sx={{fontSize:['10px','10px','14px','14px','14px']}} 
                            variant='contained'
                            onClick={()=>setUserStatus(user,true,'removed'+index,index)}
                            id={'removed'+index}
                            > Remove </ButtonHWA>
                        </div>
                    </div>
                    )
                })}
            </div>
            <ButtonHWA sx={{fontSize:['10px','10px','14px','14px','14px'],marginTop:['1%','1%','2%','2%','2%']}}
            variant='contained'
            onClick={()=>submitSanity()}
            disabled={removedStaffMember?.length!==staffMembers?.length}
            > Submit </ButtonHWA>
        </div>
    )
}