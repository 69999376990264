import { CancelRounded, PersonAdd } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uniqid from "uniqid";
import CloseOrangeIcon from '../../../assets/crossIconOrange.svg';
import leaveGroupIcon from '../../../assets/leaveGroup.svg';
import searchIcon from '../../../assets/search-icon.png';
import { groupInfoPopupActions } from "../../../_actions";
import { Debounce, GetRandomColor, mainCacheConstants, roleConstants, useReduxCacheAndDispatch } from "../../../_helpers";
import AddUserPopUp from "../AddUserPopUp";
import RemoveUserPopUp from "../RemoveUserPopUp";
import "./styles.css";

const teamGroupColor = new GetRandomColor();

const searchInputDebounce = new Debounce();

const GroupInfoBody = ({ data }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const versionConfigData = useSelector(state => state.config.versionConfigData);
  const groupInfo = useSelector((state) => state.groupInfoPopup.groupInfo);
  const isGroupInfoListActive = useSelector(
    (state) => state.groupInfoPopup.isGroupInfoListActive
  );

  const hospitals = useSelector(state => state.hospitals);

  const user = useSelector(state => state.authentication.user);
  const currentUserRole = useSelector(state => state.authentication.user.roles.role);

  const { handlePopUpClose } = data;
  const [popUpData, setPopUpData] = useState([]);
  const [filteredMap, setFilteredMap] = useState(undefined);
  const [searchFilteredMap, setSearchFilteredMap] = useState(undefined);
  const [searchInput, setSearchInput] = useState("");
  const [currentMemberData, setCurrentMemberData] = useState("");
  const [hasAddRemoveUserAuth, setHasAddRemoveUserAuth] = useState(false);
  const [showAddUserPopUp, setShowAddUserPopUp] = useState(false);
  const [showRemoveUserPopUp, setShowRemoveUserPopUp] = useState(false);
  const [showLeaveGroupPopUp, setShowLeaveGroupPopUp] = useState(false);

  const cacheAndDispatch = useReduxCacheAndDispatch();

  const fetchAndUpdateGroupMembers = (sendCache) => {
    const {
      currentHospitalData: { sid }={},
    } = hospitals||{};

    const getNewCache = () => {
      if (sendCache) {
        const { memberSid } = currentMemberData;

        const newMemberList = groupInfo.filter(
          (member) => memberSid !== member["memberSid"]
        );

        return newMemberList;
      }

      return null;
    };

    cacheAndDispatch({
      action: groupInfoPopupActions.getGroupInfoList,
      params: { sid },
      cacheOf: mainCacheConstants.groupInfoListCache,
      key: sid,
      newCache: getNewCache(),
    });
  };

  const handleSearchInputChange = (e) => {
    const { value } = e.target;

    setSearchInput(() => value);
  };

  const removeUserBtnClick = (e) => {
    setShowRemoveUserPopUp(true);

    let { id } = e.target.closest("button");
    id = JSON.parse(id);

    setCurrentMemberData(() => ({ ...id }));
  };

  const handleUserRemove = () => {
    const {
      currentHospitalData: { hospital_id },
    } = hospitals;

    const { phone, memberSid, userType, userId } = currentMemberData;

    const params = {
      hospitalID: hospital_id,
      userId,
      userType,
      membersid: memberSid,
    };

    dispatch(
      groupInfoPopupActions.removeGroupMember(
        params,
        fetchAndUpdateGroupMembers
      )
    );

    setShowRemoveUserPopUp(false);
  };

  const handleLeaveGroup = () => {
    const {
      currentHospitalData: { hospital_id },
    } = hospitals;

    const match = groupInfo.find(
      ({ attributes: { userId, userID } }) =>
        userId === user["userId"] || userID === user["userId"]
    ) || { attributes: {} };

    const {
      identity = "",
      memberSid = "",
      attributes: { userType = "", userId = "", userID = "" },
    } = match;

    const params = {
      hospitalID: hospital_id,
      userId: userId || userID,
      userType,
      membersid: memberSid,
    };

    dispatch(
      groupInfoPopupActions.removeGroupMember(
        params,
        fetchAndUpdateGroupMembers
      )
    );

    setShowLeaveGroupPopUp(false);
  };

  const setNewMapForFilterdGroupList = () => {
    const newFilterdMap = new Map();

    const keys = [...filteredMap?.keys()];

    keys.forEach((key) => {
      const allItems = filteredMap.get(key);
      const filteredItems = allItems?.filter((item) => {
        const {
          identity = "",
          attributes: { username = "" },
        } = item;

        return !searchInput.length
          ? true
          : // identity is used to search with mobile number
          // : [identity, username].some((item) =>
          [username].some((item) =>
            item
              .trim()
              .toLowerCase()
              .includes(searchInput.trim().toLowerCase())
          );
      });

      newFilterdMap.set(key, filteredItems);
    });

    setSearchFilteredMap(() => newFilterdMap);
  };

  const handleSearchInputSubmit = () => {
    setNewMapForFilterdGroupList();
  };

  useEffect(() => {
    searchInputDebounce.debounce(() => {
      if (filteredMap) setNewMapForFilterdGroupList();
    }, 500);
  }, [searchInput]);

  useEffect(() => {
    setSearchFilteredMap(filteredMap);
  }, [filteredMap]);

  useEffect(() => {
    setHasAddRemoveUserAuth(
      roleConstants[currentUserRole?.toLowerCase()]?.["key"] === "cityops"
      // true
    );
  }, [currentUserRole]);

  useEffect(() => {
    if (groupInfo) {
      setPopUpData(() => groupInfo);
    }

    return () => setPopUpData(() => []);
  }, [groupInfo]);

  useEffect(() => {
    if (!popUpData) return;

    const filteredMap = new Map();


    popUpData &&
      popUpData?.forEach((item) => {
        const { attributes } = item;
        let { role } = attributes;
        let { userFlag } = item
        role = role?.toLowerCase();

        if (filteredMap.has(role)) {
          filteredMap.set(role, [...filteredMap.get(role), item]);
        } else {
          ///we are skipping the undefined for now -----------
          if (role && userFlag == false) {
            filteredMap.set(role, [item]);
          }
        }
      });

    setFilteredMap(filteredMap);
  }, [popUpData]);

  useEffect(() => {
    fetchAndUpdateGroupMembers(false);
  }, []);
  const groupInfoActions = ({ inHeader }) => {
    return (

      <Box
        className="actions-container"
        disableGutters
        sx={{
          display: inHeader ? ['none', 'none', 'flex'] : ["flex", 'flex', 'none'],
          alignItems: "center",
          justifyContent: "flex-end",
          maxWidth: "unset",
        }}
      >
        <Box className="search-input-container" sx={{ m: 0 }}>

          <IconButton aria-label="search" onClick={handleSearchInputSubmit}>
            <img style={{ height: "20px", maxWidth: '100%' }} src={searchIcon} alt="search" />
          </IconButton>
          <input
            value={searchInput}
            onChange={handleSearchInputChange}
            name="search"
            id="search"
            placeholder={data["searchPlaceHolderText"]}
          />
        </Box>
        <Box sx={{ marginLeft: "10px", display: "flex", gap: "10px" }}>
          {(hasAddRemoveUserAuth) && (
            <Button
              disableElevation
              variant="contained"
              sx={{
                color: "#000",
                borderRadius: "5px",
                textTransform: "capitalize",
                backgroundColor: "#fff",
                boxShadow: '0px 3px 6px #00000029',
                width: '40px',
                minWidth: 'auto',
                p: ['7.5px'],
                ":hover": {
                  background: "white",
                  boxShadow: "0px 3px 6px #00000029"
                }
                // ":hover": {
                //   background: "none!important",
                //   color: "none!important",
                //   filter: "brightness(1.8)",
                // },
              }}
              onClick={() => setShowAddUserPopUp(true)}
            >
              <PersonAdd
                sx={{
                  color: "#3276cf",
                  // ":hover": {
                  //   color: "inherit",
                  // },
                }}
              />            </Button>
            // <Button
            //   disableElevation
            //   variant="contaiend"
            //   sx={{
            //     color: "#000",
            //     borderRadius: "5px",
            //     width: '40px',
            //     textTransform: "capitalize",
            //     backgroundColor: "#fff",
            //     ":hover": {
            //       background:"white",
            //       boxShadow:"0px 3px 6px #00000029",
            //       // filter: "brightness(1.8)",
            //     },
            //   }}
            //   onClick={() => setShowAddUserPopUp(true)}
            // >
            //               <img src={leaveGroupIcon}/>

            //   {/* <PersonAdd
            //       sx={{
            //         color: "#3276cf",
            //         ":hover": {
            //           color: "inherit",
            //         },
            //       }}
            //     /> */}
            //   {/* Add Member */}
            // </Button>
          )}

          <Button
            disableElevation
            variant="contained"
            sx={{
              color: "#000",
              borderRadius: "5px",
              textTransform: "capitalize",
              backgroundColor: "#fff",
              boxShadow: '0px 3px 6px #00000029',
              width: '40px',
              minWidth: 'auto',
              p: ['7.5px'],
              ":hover": {
                background: "white",
                boxShadow: "0px 3px 6px #00000029"
              }
              // ":hover": {
              //   background: "none!important",
              //   color: "none!important",
              //   filter: "brightness(1.8)",
              // },
            }}
            onClick={() => setShowLeaveGroupPopUp(true)}
          >
            <img src={leaveGroupIcon} />
          </Button>
        </Box>

      </Box>
    )
  }

  return (
    <Box sx={{
      position: 'relative',
      backgroundColor: '#fff',
      flex: 1,
      borderRadius: ['8px', '15px'],
      marginTop:['50px','50px','0px']
    }}>

      <Box
        sx={{
          position: "sticky",
          top: 0,
        }}
      >
        <Box className="modal-header" sx={{ borderRadius: ['8px', '8px', "0px 0px 15px 15px"], display: ['flex'] }}>
          <Box className="title-container" sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: ['18px', '18px', '24px'], color: "#fff", textAlign: ['center', 'center', 'left'], fontWeight: '600' }} > {data["headerTitle"]} </Typography>
          </Box>
          {groupInfoActions({ inHeader: true })}

          {/* {!isSmallScreen && <Box
            className="actions-container"
            disableGutters
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-end",
              maxWidth: "unset",
            }}
          >
            <div className="search-input-container">
              <input
                value={searchInput}
                onChange={handleSearchInputChange}
                name="search"
                id="search"
                placeholder={data["searchPlaceHolderText"]}
              />
              <IconButton
                aria-label="search"
                onClick={handleSearchInputSubmit}
                sx={{ height: "29px" }}
              >
                <Search color="primary" />
              </IconButton>
            </div>
            {hasAddRemoveUserAuth && (
              <Button
                disableElevation
                variant="contaiend"
                startIcon={
                  <PersonAdd
                    sx={{
                      color: "#3D7CC9",
                      ":hover": {
                        color: "inherit",
                      },
                    }}
                  />
                }
                sx={{
                  color: "#000",
                  borderRadius: "5px",
                  textTransform: "capitalize",
                  backgroundColor: "#fff",
                  ":hover": {
                    background: "#0003",
                    color: "#fff",
                    filter: "brightness(1.8)",
                  },
                }}
                onClick={() => setShowAddUserPopUp(true)}
              >
                Add Member
              </Button>
            )}
            <Button
              disableElevation
              variant="contaiend"
              endIcon={
                <ExitToApp
                  sx={{
                    color: "#3D7CC9",
                    ":hover": {
                      color: "inherit",
                    },
                  }}
                />
              }
              sx={{
                color: "#000",
                borderRadius: "5px",
                textTransform: "capitalize",
                backgroundColor: "#fff",
                ":hover": {
                  background: "#0003",
                  color: "#fff",
                  filter: "brightness(1.8)",
                },
              }}
              onClick={() => setShowLeaveGroupPopUp(true)}
            >
              Leave Group
            </Button>
          </Box>} */}

          <Box className="close-modal" sx={{ right: ['30px'] }}>
            <IconButton sx={{ p: 0 }} onClick={handlePopUpClose}> <img src={CloseOrangeIcon} alt="close" /> </IconButton>
          </Box>
        </Box>
      </Box>

      <Box className="teams-container" sx={{ pl: ['20px'], pr: ['20px', '10px'], py: ['10px'] }}>
        <Grid item sx={{marginBottom:"20px"}}> {groupInfoActions({ inHeader: false })} </Grid>
        <Grid container spacing={2} sx={{height: '100%'}}>

          {!isGroupInfoListActive || !searchFilteredMap ? (
            <>
              <Grid
                item
                height="200px"
                sx={{
                  placeContent: "center",
                }}
              >
                <CircularProgress
                  size="50px"
                  sx={{
                    color: "#444",
                  }}
                />
              </Grid>
            </>
          ) : searchFilteredMap?.size &&
            [...searchFilteredMap.values()].every(
              (arr) => !arr.length
            ) ? (
            <Grid item xs="12" alignSelf={`center`}>
              <Typography align="center" variant="h4" sx={{ fontSize: ['18px', '1.5rem', '2.125rem'], color: ['#666A70', '#666A70', '#1976d2'], fontWeight: ['600', '600', '700'] }}>
                No Results !
              </Typography>
            </Grid>
          ) : (<>
            {[...searchFilteredMap.keys()]?.map((team) => (
              <RenderTeam
                data={{
                  team,
                  map: searchFilteredMap,
                  hasAddRemoveUserAuth,
                  versionConfigData,
                  removeUserBtnClick,
                }}
                key={uniqid()}
              />
            ))
            }</>
          )}
        </Grid>
      </Box>

      <AddUserPopUp
        data={{
          showAddUserPopUp,
          setShowAddUserPopUp,
        }}
      />

      <RemoveUserPopUp
        data={{
          showPopup: showRemoveUserPopUp,
          handleShowPopup: setShowRemoveUserPopUp,
          handleSubmit: handleUserRemove,
          popUpHeading: "Are You Sure",
          popUpContent: "You Want To Remove The User",
        }}
      />

      <RemoveUserPopUp
        data={{
          showPopup: showLeaveGroupPopUp,
          handleShowPopup: setShowLeaveGroupPopUp,
          handleSubmit: handleLeaveGroup,
          popUpHeading: "Are You Sure",
          popUpContent: "You Want To Leave This Group",
        }}
      />
    </Box>
  );
};

export default GroupInfoBody;

const RenderTeam = ({ data }) => {
  const { team, map } = data;
  const members = map.get(team);
  const teamLength = map.get(team).length;

  if (!teamLength) return <> </>;

  return (
    <Grid item key={uniqid()} xs={12} sm={12} md={12} lg={12}>
      <Box className="team-item">
        <Box className="team-title-container" sx={{ textAlign: 'center' }}>
          <h3 className="team-title"> {`${roleConstants[team?.toLowerCase()]?.["title"] || team} (${teamLength})`} </h3>
        </Box>

        <Grid
          spacing={3}
          container
          columns={20}
          className="team-members-container"
        >
          {members?.map((member) => (
            <Grid lg={4} md={6.666} sm={10} xs={20} item key={uniqid()}>
              <RenderTeamMembers data={{ ...data, member, team }} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Grid>
  );
};

const RenderTeamMembers = ({ data }) => {
  const {
    member,
    team,
    hasAddRemoveUserAuth,
    versionConfigData,
    removeUserBtnClick,
  } = data;
  const { attributes, identity: phone, sid, memberSid } = member;
  let {
    username: person = phone,
    userType,
    userId = "",
    userID = "",
  } = attributes;

  if (!!!userId) userId = userID;

  return (
    <div
      className={`team-member-item ${hasAddRemoveUserAuth && "has-addRemove-auth"
        }`}
    >
      <div className="avatar-container">
        {/* <Avatar
          className="avatar"
          // sx={{ bgcolor: teamGroupColor.getNewColor(team) }}
          sx={{ bgcolor: "#64696F" }}
        >
          {getInitials(person)}
        </Avatar> */}
      </div>
      <Box sx={{ fontSize: ['14px', '14px', '1rem'] }} className="content-container">
        <p className={makeEllipsis(person) ? "person ellipsis" : "person"}>
          {person}
        </p>

        {versionConfigData["result"][0]["maskNumber"] ? (
          <div className="phone">
            <p>{phone}</p>
          </div>
        ) : (
          <a className="phone" href={`tel:+${phone}`}>
            <p>{phone}</p>
          </a>
        )}
      </Box>

      {hasAddRemoveUserAuth && (
        <div className="btn-container">
          <Tooltip
            title={
              !!!userId
                ? "This user cannot be removed, Please contact admin"
                : ""
            }
          >
            <IconButton
              aria-label="remove_member"
              id={JSON.stringify({ sid, memberSid, phone, userId, userType })}
              onClick={(e) => !!userId && removeUserBtnClick(e)}
              sx={{
                opacity: !!userId ? "1" : ".3",
                cursor: !!userId ? "pointer" : "not-allowed",
                p: 0
              }}
            >
              <CancelRounded />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const makeEllipsis = (person = "") => {
  const match = person.match(/\s/);
  const lengthMatch = person.length >= 11;

  return !!!match && lengthMatch;
};
