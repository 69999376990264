import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import React from "react";
import { toast } from "react-toastify";
import UpgradedToast from "./modules/common/ToastMessage";
import { store } from "./_helpers";
var firebaseConfig = {
  apiKey: "AIzaSyAilWRA5jQoSbHKnhriOHM05CyiHqslN6g",
  authDomain: "hospital-app-6a354.firebaseapp.com",
  databaseURL: "https://hospital-app-6a354.firebaseio.com",
  projectId: "hospital-app-6a354",
  storageBucket: "hospital-app-6a354.appspot.com",
  messagingSenderId: "151644699555",
  appId: "1:151644699555:web:1966c4cd8159f3fb09b816",
  measurementId: "G-ZQWXZ45FW1",
};

export const getFirebaseToken = async (setTokenFound) => {
  let res = await isSupported();
  if (!res) {
    return { value: false };
  } else {
    return getToken(messaging, {
      vapidKey: "BA8F6CKhtIrNGub9iKWO0MwZE2Ve8X_kabYMdnYXKkSNl_IbrpYlSpRYTkF0i0HEtx3C2mPO6v7P_YRXPxudoCc",
    })
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound(true);
          return { value: true, token: currentToken };
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          setTokenFound(false);
          return { value: false };
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        // catch error while creating client token
        console.error(err)
      });
  }
};
export const onMessageListener = () =>
  new Promise(async (resolve) => {
    var res = await isSupported();
    if (!res) {
      let payload = {};
      resolve(payload);
    } else {
      onMessage(messaging, (payload) => {

        let permission = Notification.permission;
        if (permission === "granted") {
          let showNotification = document.visibilityState !== "visible";
          if(showNotification){
            showNotification();
            return
          }
        } else if (permission === "default") {
          requestAndShowPermission();
        } else {
          alert("Use normal alert");
        }

        function requestAndShowPermission() {
          Notification.requestPermission(function (permission) {
            if (permission === "granted") {
              showNotification();
            }
          });
        }
        function showNotification() {
          let title = "Pristyn Care";
          let icon = 'logo20x20.png'; //this is a large image may take more time to show notifiction, replace with small size icon
          let body = payload?.notification?.body;

          let notification = new Notification(title, { body, icon });

          notification.onclick = () => {
            notification.close();
            window.parent.focus();
          }
        }
        // toast.success(payload.notification.body)
        const state = store.getState();
        const appointmentId = state.patients.patientInfo.appointmentId;
        const channelSid = state.hospitals.currentHospitalData.sid;
        const generalChatActive = state.homeState.showGeneralChatMessages;
        if (
          !(
            (payload.data.appointmentId &&
              appointmentId == payload.data.appointmentId) ||
            (payload.data.channelSid == channelSid && generalChatActive)
          )
        ) {
          setTimeout(() => {
            toast.success(<UpgradedToast payload={payload}></UpgradedToast>);
          }, 1000);
        }
        resolve(payload);
      });
    }
  });
const firebaseApp = initializeApp(firebaseConfig);
var messaging
messaging = isSupported().then((res) => {
  if (res) messaging = getMessaging(firebaseApp);
});
initializeApp(firebaseConfig);
