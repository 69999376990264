import { AccessTimeOutlined, Cancel, CloudUpload } from "@mui/icons-material";
import { Box, ClickAwayListener, Collapse, Divider, Grid, Portal, Typography } from "@mui/material";
import moment from "moment";
import { useRef, useState } from "react";
import { hospitalViewProps} from "../../checklistConstant";
import AccordianCustom from "../AccordianCustom";
import DocumentPopover from "./../../../common/UploadDocument/DocumentPopover";
import { useSelector } from "react-redux";
import { Cards } from "../../../common/Cards";


const HospitalViewList = ({ hospitals }) => {
  return (
    <>
      {hospitals?.map((hospital, i) => {
        return (<HospitalViewAccordianItem hospital={hospital} key={i} />)
      })}
      {/* {patients.length === 0 && <Typography component={`h2`} variant={`h3`} >Empty Data</Typography>} */}
    </>
  );
}


export default HospitalViewList;

function HospitalViewAccordianItem({ hospital }) {
  const infoContainerRef = useRef(null);
  const childRef = useRef(null)
  const uploadDocument = (event, documentKey = null) => {
    childRef.current.handleClick({event:event, documentKey:documentKey, item:hospital,visibleIcons:['capture','image','pdf','doc','csv','excel']});
  };
  const activeTab = useSelector(state => state.checklistSummary.activeTab)
  console.log("hospitalData",hospital[hospitalViewProps.fds],hospital[hospitalViewProps.pb],hospital[hospitalViewProps.fb])
  return (
    <>
      <AccordianCustom
        title={hospital.patientFirstName}
        subtitle={`Doctor: ${hospital.doctorFirstName}`}
      >

        <Typography component={`h5`} variant="caption" sx={{ lineHeight: 1.5, mb: ['10px'] }}>IPD Date : {activeTab === "pending" ? hospital["ipdDate"] : ''}</Typography>
        {activeTab!="pending" && <Typography component={`p`} variant="caption" sx={{ lineHeight: 1.5, color: '#00263E' }}>{hospital["ipdDate"]}</Typography>}

       {activeTab != "pending" ? 
       <>
       <Typography component={`h5`} variant="caption" sx={{ lineHeight: 1.5, mb: ['10px'], mt: ['20px'] }}>Final Discharge Summary:</Typography>
        <Status 
          statusText={hospital[hospitalViewProps.fdsTime]} 
          status={hospital[hospitalViewProps.fds]} 
          isLoading={hospital?.fdsLoading} 
          info_data={hospital} 
          portalRef={infoContainerRef} 
          onClick={(e) => { uploadDocument(e, "finalDischargeSummary") }}
          uploadText="Upload FDS"
        />

        <Typography component={`h5`} variant="caption" sx={{ lineHeight: 1.5, mb: ['10px'], mt: ['20px'] }}>Provisional Bill:</Typography>
        <Status 
          statusText={hospital[hospitalViewProps.pbTime]} 
          status={hospital[hospitalViewProps.pb]} 
          statusColor={hospital[hospitalViewProps.pbTime] === "Delayed" && 'red'} 
          isLoading={hospital?.pbLoading} info_data={hospital} 
          portalRef={infoContainerRef} 
          onClick={(e) => { uploadDocument(e, "provBill") }} 
          uploadText="Upload PB"
        />

        <Typography component={`h5`} variant="caption" sx={{ lineHeight: 1.5, mb: ['10px'], mt: ['20px'] }}>Provisional Bill:</Typography>
        <Status 
          statusText={hospital[hospitalViewProps.fbTime]} 
          status={hospital[hospitalViewProps.fb]} 
          statusColor={hospital[hospitalViewProps.fbTime] === "Delayed" && 'red'} 
          isLoading={hospital?.fbLoading} info_data={hospital} 
          portalRef={infoContainerRef} 
          onClick={(e) => { uploadDocument(e, "finalBill") }} 
          uploadText="Upload PB"
        /> </>:  <Cards item={hospital} hospitalViewType={true}/>}
      </AccordianCustom>
      <Box sx={{ position: 'relative' }} ref={infoContainerRef} />
      <DocumentPopover ref={childRef} />
    </>
  )
}


function Status({ status, statusText, statusColor, info_data, isLoading, uploadText = 'Upload File', portalRef, onClick }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={{ mt: '10px', display: 'flex', alignItems: 'center' }}>
      <Typography variant="caption" sx={{ lineHeight: 1.5, color: '#666a70', display: 'inline-block', minWidth: '70px', paddingRight: '5px' }}>Status:</Typography>
      {(!status || isLoading ) ? (
        <>
          <Typography variant="caption" sx={{ lineHeight: '20px', color: '#3D7CC9', display: 'inline-flex', verticalAlign: 'middle', alignItems: 'center' }}>
            {isLoading ? <AccessTimeOutlined sx={{ color: 'danger' }} /> : <label style={{ cursor: 'pointer' }} onClick={onClick}>
              {/* <input type="file" accept=".jpg, .jpeg, .png, .mp4" hidden onChange={getFileInfo} /> */}
              <CloudUpload sx={{ fontSize: '20px', mr: 1.5, verticalAlign: 'middle' }} /> {uploadText}
            </label>}
          </Typography>
        </>

      ) : (
        <>
          <Typography variant="caption" sx={{ lineHeight: 1.5, color: (!!statusColor ? statusColor: '#009817'), display: 'inline-block', textDecoration: 'underline' }}>
            {statusText}
          </Typography>
          <Typography variant="caption" sx={{ lineHeight: 1.5, display: 'inline-block', pt: '5px', ml: 4, verticalAlign: 'middle' }}>
            <InfoIcon onClick={() => { setIsOpen(!isOpen) }} />
            {portalRef?.current && <Portal container={portalRef.current}>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <ClickAwayListener onClickAway={() => { console.log('clicked away');setIsOpen(false) }}>
                  <Box sx={{
                    backgroundColor: '#3D7CC9',
                    boxShadow: "2px 2px 6px #0000000D",
                    borderRadius: '5px',
                    padding: "10px 8px",
                    color: "#fff",
                    position: 'relative',
                    mt: -4
                  }}>
                    <Box onClick={() => setIsOpen(!isOpen)} sx={{ position: 'absolute', top: "-12px", right: "10px", "&::before": { content: `""`, background: '#3d7cc9', position: 'absolute', height: '18px', width: '18px', left: '3px', top: '3px', borderRadius: '50%', } }}>
                      <Cancel sx={{ color: "#f5f5f5", position: 'relative' }} />
                    </Box>
                    {/* content */}
                    <Grid container>
                      <Grid item xs={4}><Typography variant="caption" sx={{ lineHeight: 1.5,fontWeight:'600' }}>Uploaded By</Typography></Grid><Divider orientation="vertical" flexItem sx={{ mx: 1.5, borderColor: '#f5f5f5' }} />
                      <Grid item xs><Typography variant="caption" sx={{ lineHeight: 1.5,fontWeight:'600' }}>{info_data.PDSStatusUserName}</Typography></Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}><Typography variant="caption" sx={{ lineHeight: 1.5,fontWeight:'600' }}>Uploaded on</Typography></Grid><Divider orientation="vertical" flexItem sx={{ mx: 1.5, borderColor: '#f5f5f5' }} />
                      <Grid item xs><Typography variant="caption" sx={{ lineHeight: 1.5,fontWeight:'600' }}>{moment(info_data.PDSStatusUpdatedTime).format('DD-MM-yyyy')}</Typography></Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}><Typography variant="caption" sx={{ lineHeight: 1.5,fontWeight:'600' }}>Upload Time</Typography></Grid><Divider orientation="vertical" flexItem sx={{ mx: 1.5, borderColor: '#f5f5f5' }} />
                      <Grid item xs><Typography variant="caption" sx={{ lineHeight: 1.5,fontWeight:'600' }}>{moment(info_data.PDSStatusUpdatedTime).format('LT')}</Typography></Grid>
                    </Grid>
                  </Box>
                </ClickAwayListener>
              </Collapse>
            </Portal>}
          </Typography>
        </>
      )}
    </Box>
  )
}

function InfoIcon(prop) {
  return <svg width="20" height="23" viewBox="0 0 20 23" {...prop}>
    <g id="Group_10135" data-name="Group 10135" transform="translate(-239 -282)">
      <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10" transform="translate(239 283)" fill="#3d7cc9" />
      <text id="i" transform="translate(247 299)" fill="#fff" fontSize="16" fontFamily="Poppins-Medium, Poppins" fontWeight="500"><tspan x="0" y="0">i</tspan></text>
    </g>
  </svg>
}
