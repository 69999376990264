import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import closeOrangeIcon from '../../assets/crossIconOrange.svg';

const DailogHWA = ({ children, title, open, onClose, PaperProps = {}, closeProps = {}, contentStyling, ...restProps }) => {
  const { sx: paperSx = {}, ...restPaperProps } = PaperProps

  return (
    <Dialog open={open} onClose={onClose} {...restProps} PaperProps={{
      sx: { borderRadius: ['8px'], minWidth: ['300px', '300px', '300px', '300px', '370px'], overflow: 'visible', ...paperSx },
      ...restPaperProps
    }}>
      <DialogTitle sx={{ borderRadius: ['5px'], position: 'relative', backgroundColor: '#3D7CC9', color: '#fff', fontSize: ['18px', '18.5px', '19px', '19.5px', '20px'], lineHeight: ['1.4'], textAlign: 'center', p: ['18px 0px 17px 0px'] }}>
        {title}
        <Box onClick={onClose} component={`img`} src={closeOrangeIcon} sx={{ position: 'absolute', right: 0, top: 0, transform: 'translate(-20px, -50%)', cursor: 'pointer', ...closeProps }} />
      </DialogTitle>
      <DialogContent sx={{ p: '15px!important', ...contentStyling }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default DailogHWA;