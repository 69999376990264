import { checklistConstants } from "../_constants/checklist.constants";
import { getInitialState } from "../_helpers/getInitialState";

export function checklistSummary(
  state = getInitialState("checklistSummary"),
  action
) {
  switch (action.type) {
    //  to get chat messages
    case checklistConstants.FETCH_HOSPITALSCREEN_REQUESTING:
      return {
        ...state,
        isFetchingData: true
      };
    case checklistConstants.FETCH_PATIENTSCREEN_REQUESTING
      :
      return {
        ...state,
        isFetchingData: true
      };
    case checklistConstants.FETCH_HOSPITALSCREEN_FETCHED:
      {

        var cityArr = []
        const { response } = action
        response?.forEach((item) => {
          if (item.city) {
            cityArr.push({ name: item.city, id: item.city })
          }
        });
        cityArr = cityArr.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.place === value.place && t.name === value.name
          ))

        )
        cityArr = cityArr.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        return {
          ...state,
          isFetchingData: false,
          cityArr: cityArr
        };
      }
    case checklistConstants.FETCH_PATIENTSCREEN_FETCHED:
      {

        var mopArr = []
        const { patientMessages } = action
        patientMessages?.forEach((item) => {
          if (item["Payment Mode"]) {
            mopArr.push({ name: item["Payment Mode"], id: item["Payment Mode"] })
          }
          if (item["paymentMode"]) {
            mopArr.push({ name: item["paymentMode"], id: item["paymentMode"] })
          }
        });
        mopArr = mopArr.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.place === value.place && t.name === value.name
          ))
        )
        return {
          ...state,
          isFetchingData: false,
          mopArr: mopArr
        };
      }
    case checklistConstants.CHANGE_CHECKLIST_SCREEN: {
      const { screenName, hospitalId = null, filterStatus } = action.payload
      return {
        ...state,
        screenName: screenName,
        selectedHospitalId: hospitalId,
        filterStatus: filterStatus
      }

    }

    case checklistConstants.CHANGE_ACTIVE_TAB: {
      const { activeTab } = action.payload
      return {
        ...state,
        activeTab:activeTab
      }

    }


    case checklistConstants.SET_CHECKLIST_FILTERS: {
      var { payload } = action
      return {
        ...state,
        [payload["filterName"]]: payload["filterObj"]
      }
    }
    case checklistConstants.UPDATE_FULL_STATE:
      const { newState } = action;

      return {
        ...newState,
      };


    default: return state
  }
}
