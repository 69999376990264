import { socketConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function socketConnectivity(
  state = getInitialState("socketConnectivity"),
  action
) {
  const { payload } = action;
  switch (action.type) {
    case socketConstants.SOCKET_CONNECTION_REQUESTING:
      
      return {
        ...state,
        socketConfig: {
          socketData: {},
          isConnectingSocket: true,
          isConnectionSocketError: false,
        },
      };
    case socketConstants.SOCKET_CONNECTION_SUCCESS:
      
      return {
        ...state,

        socketConfig: {
          socketData: payload,
          isConnectingSocket: false,
          isConnectionSocketError: false,
          socketConnect:true
        },
      };
    case socketConstants.SOCKET_CONNECTION_CLOSED:
      
      return {
        ...state,

        socketConfig: {
          isConnectionSocketError: true,
          socketData: payload,
          isConnectingSocket: false,
        },
      };
    case socketConstants.SOCKET_CONNECTION_FAILED:
      
      return {
        ...state,

        socketConfig: {
          isConnectionSocketError: true,
          socketData: payload,
          isConnectingSocket: false,
        },
      };
    case socketConstants.SOCKET_MESSAGE_RECEIVE:
      
      return {
        ...state,

        socketMessage: payload,
      };
    case socketConstants.SOCKET_MESSAGE_REMOVED:
      
      return {
        ...state,

        socketMessage: payload,
      };
    case socketConstants.SOCKET_SURGERY_ACCEPT:
      
      return {
        ...state,
        socketSurgery: payload,
      };
    case socketConstants.SOCKET_SURGERY_REJECT:
      return {
        ...state,
        socketSurgery: payload,
      };
    case socketConstants.SOCKET_CONNECTION_RECONNECTING:
      
      return {
        ...state,

        socketConfig: {
          socketData: payload,
          isConnectingSocket: true,
          isConnectionSocketError: false,
        },
      };
    case socketConstants.SOCKET_CONNECTION_STOP_ATTEMPT:
      
      return {
        ...state,

        socketConfig: {
          isConnectionSocketError: true,
          socketData: payload,
          isConnectingSocket: false,
        },
      };

    case socketConstants.UPDATE_FULL_SOCKET_STATE:
      const { newState } = action;

      return {
        ...newState,
      };

    default:
      return state;
  }
}
