
export const centralGetInitialState = (stateName) => {
    const allInitialStates = {
      authentication: {
        loggedIn: false,
        loggingIn: true,
        showRegisterUser: false,
        user: {
          name: "",
          roles: {
            role: "",
          },
        },
        error: {},
        isOtpLoader: false,
        topText: "Welcome Back",
        isOtpScreen: false,
        isVerifyLoader: false,
        response: {
          status: "",
        },
        rolesListResponse: {
          result: {
            roles: { hospital: [] },
          },
        },
        rolesListRequesting: false,
        rolesListActive: false,
        rolesListFailed: false,
      },
      users: {
        authentication: {
          user: {
            username: "",
            userId: "",
            roles: {
              role: "",
            },
          },
        },
        notificationId: "",
      },
      alert: {},
      centralCommonData : {},
      centralPatients:{
        centralPatients:{},
        activePatientId:null,
        activePatientName:null
      },
      reportingData:{
        reportingData:[],
        activePatientId:null,
        activePatientName:null
      },
      centralHospitalData:{
        listData : [],
        activeHospitalId:null,
        activeHospitalName:null
      },
    };
  
    return allInitialStates?.[stateName] || {};
  };
  
  export const resetCentralSelectedStatesToInitial = (
    dispatch,
    statesData = [{ stateName: "", action: "", addtionalStateData: {} }]
  ) => {
    statesData.forEach(
      ({ stateName = "", action = "", addtionalStateData = {} }) => {
        dispatch(
          action({
            ...centralGetInitialState(stateName),
            ...addtionalStateData,
          })
        );
      }
    );
  };
  