import { alertActions, configActions } from ".";
import { documentsPopupConstants } from "../_constants";
import { documentsPopupService } from "../_services";
// import { history } from "../_helpers";

function getDocumentsList(params, fetchForGeneralChat = false) {
  return (dispatch) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());

    if (fetchForGeneralChat) {
      documentsPopupService.getDocumentsListOfGeneralChat(params).then(
        (res) => {
          dispatch(success(res));
          dispatch(configActions.hideSpinningLoader());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      documentsPopupService.getDocumentsList(params).then(
        (res) => {
          dispatch(success(res));
          dispatch(configActions.hideSpinningLoader());
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    }
  };

  function request() {
    return {
      type: documentsPopupConstants.DOCUMENTS_LIST_REQUESTING,
    };
  }
  function success(documents) {
    return { type: documentsPopupConstants.DOCUMENTS_LIST_FETCHED, documents };
  }
  function failure(error) {
    return { type: documentsPopupConstants.DOCUMENTS_LIST_FAILED, error };
  }
}

const updateFullState = (newState) => {
  return {
    type: documentsPopupConstants.UPDATE_FULL_DOCUMENTS_POPUP_STATE,
    newState,
  };
};

function showSanityPopup(){
  return {
    type: documentsPopupConstants.SHOW_SANITY_POPUP,
  }
}


function submitSanityPopup(params){
  return(dispatch)=>{
    // dispatch(requestSanityMember());
    documentsPopupService.removeMembers(params)
    .then((res)=>{
      dispatch(hideSanityPopup())
    },
    (error)=>{
      dispatch(alertActions.error(error));
    }
    )  
  }
}

  function hideSanityPopup(){
    return{
      type:documentsPopupConstants.HIDE_SANITY_POPUP,
    }
  }
const privateChatPopupSocket = (params) =>{
  console.log('privateChatPopupSocket',params)
  return {
    type: documentsPopupConstants.PRIVATE_POPUP_CHAT_SOCKET,
    showPrivateChatPopup: params
  }
}

export const documentsPopupActions = {
  getDocumentsList,
  updateFullState,
  showSanityPopup,
  submitSanityPopup,
  privateChatPopupSocket
};
