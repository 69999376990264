export const pcScoreConstants = {
  GET_ALL_HOSPITAL_DETAILS: 'GET_ALL_HOSPITAL_DETAILS',
  FETCHING_ALL_HOSPITAL_DETAILS:'FETCHING_ALL_HOSPITAL_DETAILS',
  GET_HOSPITAL_SCORE_DETAILS: 'GET_HOSPITAL_SCORE_DETAILS',
  GET_HOSPITAL_REVIEW: 'GET_HOSPITAL_REVIEW',
  GET_HOSPITAL_MEMBER_LIST: 'GET_HOSPITAL_MEMBER_LIST',
  UPLOAD_HOSPITAL_LOGO: 'UPLOAD_HOSPITAL_LOGO',
  UPLOAD_HOSPITAL_DOCUMENTS: 'UPLOAD_HOSPITAL_DOCUMENTS',
  ADD_HOSPITAL_MEMBER: 'ADD_ADD_HOSPITAL_MEMBER',
  SAVE_HOSPITAL_BANK_DETAILS: 'SAVE_HOSPITAL_BANK_DETAILS',
  SAVE_HOSPITAL_BILLING_ADDRESS: 'SAVE_HOSPITAL_BILLING_ADDRESS',
  SET_HOSPITAL_LOGO:'SET_HOSPITAL_LOGO',
  DELETE_HOSPITAL_DOCUMENT:'DELETE_HOSPITAL_DOCUMENT',
  GET_HOSPITAL_DOCLIST:"GET_HOSPITAL_DOCLIST",
}