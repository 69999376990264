import { getTokenFromLocal, handleResponse } from "../_helpers/handleResponseMiddleware";

let apiUrl = process.env.REACT_APP_BASE_URL;
let apiClientToken = process.env.REACT_APP_CLIENT_SECRET;

export function getHospitalList(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...params }),
  };
  return fetch(
    `${apiUrl}/api/hospitalList`,
    requestOptions
  )
    .then(handleResponse)
    .then(function (response) {
      // localStorage.setItem('hospital', JSON.stringify(response.data));
      return response.result;
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export function fetchLastIdexAPI(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const requestOptions = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...params }),
  };
  return fetch(`${apiUrl}/api/getLastMessageIndex`, requestOptions)
    .then(handleResponse)
    .then(function (response) {
      // localStorage.setItem('hospital', JSON.stringify(response.data));
      return response.result;
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function (response) {
      // always executed
      return response;
    });
}

export const hospitalService = {
  fetchLastIdexAPI,
  getHospitalList,
};
