import { toast } from "react-toastify";
import { alertActions } from ".";
import { cacheStorageConstants, chatBoxConstants } from "../_constants";
import { cleanObj, handleChatBoxActions } from "../_helpers";
import { chatBoxService } from "../_services";
import { cacheStorageActions } from "./cacheStorage.actions";
function getPatientMessages(data) {
  const {
    params = {},
    callback = null,
    fetchFromServiceFile = false,
    cache = undefined,
  } = data;

  return (dispatch) => {
    dispatch(request());

    if (fetchFromServiceFile) {
      chatBoxService.getPatientMessages(params).then(
        (res) => {
          dispatch(success(res));

          //callback added for the support of the the caching work
          Boolean(callback) && callback(res);

        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      dispatch(success(cache));
      // dispatch(configActions.hideSpinningLoader());
    }
  };

  function request() {
    return {
      type: chatBoxConstants.GET_CHAT_MESSAGES_REQUESTING,
    };
  }
  function success(response) {
    const { status = "", description = "", result = [] } = response;

    return {
      type: chatBoxConstants.GET_CHAT_MESSAGES_FETCHED,
      patientMessages: response,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.GET_CHAT_MESSAGES_FAILED, error };
  }
}

function sendMessage(
  reqPayload,
  addMessagePayload,
  id,
  onlineStatus,
  generalChatActive,
  updateSendAgainList,
  privateChatMessagesActive
) {
  const {
    hospId,
    mobileNumber,
    messageBody,
    attributes,
    messageType,
    attributes: { patientId, uploadLocalId },
  } = reqPayload;

  return (dispatch, getState) => {
    dispatch(request());

    if (addMessagePayload != null)
      handleChatBoxActions(
        dispatch,
        getState,
        "addMessage",
        addMessagePayload,
        false,
        generalChatActive,
        privateChatMessagesActive
      );

    if (onlineStatus) {
      chatBoxService
        .sendMessage(reqPayload)
        .then(
          (res) => {


            if(updateSendAgainList){
              dispatch(cacheStorageActions.updateSendAgainListCount())
            }
            dispatch(success(res));
            handleChatBoxActions(
              dispatch,
              getState,
              "updateMessage",
              res,
              false,
              generalChatActive,
              privateChatMessagesActive
            );
          },
          (error) => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        )
        .catch((err) => {
          reqPayload['generalChatActive']=generalChatActive
          reqPayload['privateChatMessagesActive'] = privateChatMessagesActive

          dispatch({
            type: cacheStorageConstants.ADD_TO_RETRY_LIST,
            reqPayload,
          });
          dispatch({
            type: cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST,
            reqPayload,
          });
          console.log('handleChatBox before',privateChatMessagesActive)
          handleChatBoxActions(
            dispatch,
            getState,
            "updateMessageLocally",
            {
              uploadLocalId: uploadLocalId,
              retryStatus: true,
              reqPayload
            },
            generalChatActive,
            privateChatMessagesActive
          );
        });
    } else {
      reqPayload['generalChatActive']=generalChatActive
      reqPayload['privateChatMessagesActive'] = privateChatMessagesActive
      dispatch({
        type: cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST,
        reqPayload,
      });
    }
  };

  function request() {
    return {
      type: chatBoxConstants.SEND_CHAT_MESSAGES_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: chatBoxConstants.SEND_CHAT_MESSAGES_FETCHED,
      res,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.SEND_CHAT_MESSAGES_FAILED, error };
  }
}
function sendFile(
  reqPayload,
  addMessagePayload,
  onlineStatus,
  generalChatActive,
  documentCheck,
  updateSendAgainList,
  privateChatMessagesActive
) {

  const {
    hospId,
    mobileNumber,
    messageBody,
    key = "",
    attributes: { patientId, uploadLocalId, appointment_id },
    messageType,
  } = reqPayload;

  // debugger
  return async (dispatch, getState) => {
    dispatch(request());
    const addMessagePayloadFunction = () => {
      if (addMessagePayload != null)
        handleChatBoxActions(
          dispatch,
          getState,
          "addMessage",
          addMessagePayload,
          true,
          generalChatActive,
          privateChatMessagesActive
        );
    };


    if (onlineStatus) {
      const sendFileApi = () => {
        chatBoxService
          .sendFile(reqPayload)
          .then(
            (res) => {

              if(updateSendAgainList){
                dispatch(cacheStorageActions.updateSendAgainListCount())
              }
              dispatch(success(res));
              handleChatBoxActions(
                dispatch,
                getState,
                "updateMessage",
                res,
                true,
                generalChatActive,
                privateChatMessagesActive
              );
            },
            (error) => {
              dispatch(failure(error));
              dispatch(alertActions.error(error));
            }
          )
          .catch(async(err) => {
            reqPayload['documentCheck']=documentCheck
            reqPayload['generalChatActive']=generalChatActive
            reqPayload['privateChatMessagesActive'] = privateChatMessagesActive
          
        const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      
        });
            let files=reqPayload['files']
            var arr=[]
            for(let i=0;i<files.length;i++){
              var fileUrl=await toBase64(files[i])
              arr.push({fileUrl:fileUrl,fileName:files[i].name})
            }
            reqPayload['files']=arr
            dispatch({
              type: cacheStorageConstants.ADD_TO_RETRY_LIST,
              reqPayload,
            });
            dispatch({
              type: cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST,
              reqPayload
            });
            handleChatBoxActions(
              dispatch, 
              getState, 
              "updateMessageLocally", 
              { uploadLocalId: uploadLocalId, retryStatus: true, reqPayload },
              false,
              generalChatActive,
              privateChatMessagesActive
              );
          });
      };
      if (documentCheck?.includes(key)&&appointment_id) {
        chatBoxService
          .canUploadDocumentCheck({
            appointmentId: appointment_id,
            docKey: key,
          })
          .then((res) => {
            if (res.result == true) {
              addMessagePayloadFunction();
              sendFileApi();
            } else {
              toast.error(res.description);
            }
          });
      } else {
        addMessagePayloadFunction();
        sendFileApi();
      }
    } else {
      addMessagePayloadFunction();
      reqPayload['documentCheck']=documentCheck
      reqPayload['generalChatActive']=generalChatActive
      reqPayload['privateChatMessagesActive'] = privateChatMessagesActive
    
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);

  });
      let files=reqPayload['files']
      var arr=[]
      for(let i=0;i<files.length;i++){
        var fileUrl=await toBase64(files[i])
        arr.push({fileUrl:fileUrl,fileName:files[i].name})
      }
      reqPayload['files']=arr
      dispatch({
        type: cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST,
        reqPayload
      });
    }
  };

  function request() {
    return {
      type: chatBoxConstants.SEND_CHAT_FILE_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: chatBoxConstants.SEND_CHAT_FILE_FETCHED,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.SEND_CHAT_FILE_FAILED, error };
  }
}
function sendReply(
  reqPayload,
  addMessagePayload,
  id,
  onlineStatus,
  generalChatActive,
  updateSendAgainList,
  privateChatMessagesActive
) {
  const {
    hospId,
    mobileNumber,
    messageBody,
    attributes: { uploadLocalId },
    messageType,
  } = reqPayload;

  return (dispatch, getState) => {
    dispatch(request());
    if (addMessagePayload != null)
      handleChatBoxActions(
        dispatch,
        getState,
        "addMessage",
        addMessagePayload,
        false,
        generalChatActive,
        privateChatMessagesActive
      );

    if (onlineStatus) {
      chatBoxService
        .sendReply(reqPayload)
        .then(
          (res) => {
            if(updateSendAgainList){
              dispatch(cacheStorageActions.updateSendAgainListCount())
            }
            dispatch(success(res));
            handleChatBoxActions(
              dispatch,
              getState,
              "updateMessage",
              res,
              false,
              generalChatActive,
              privateChatMessagesActive
            );

            // history.push("/");
          },
          (error) => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        )
        .catch((err) => {
          reqPayload['generalChatActive']=generalChatActive
          reqPayload['privateChatMessagesActive'] = privateChatMessagesActive
          dispatch({
            type: cacheStorageConstants.ADD_TO_RETRY_LIST,
            reqPayload,
          });
          dispatch({
            type: cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST,
            reqPayload,
          });
          handleChatBoxActions(dispatch, getState, "updateMessageLocally", {
            uploadLocalId: uploadLocalId,
            retryStatus: true,
            reqPayload
          });
        });
    } else {
      reqPayload['generalChatActive']=generalChatActive
      reqPayload['privateChatMessagesActive'] = privateChatMessagesActive
      dispatch({
        type: cacheStorageConstants.ADD_TO_SEND_AGAIN_LIST,
        reqPayload,
      });
    }
  };

  function request() {
    return {
      type: chatBoxConstants.SEND_CHAT_REPLY_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: chatBoxConstants.SEND_CHAT_REPLY_FETCHED,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.SEND_CHAT_REPLY_FAILED, error };
  }
}
function deleteMessage(params) {
  const {
    hospId,
    mobileNumber,
    messageBody,
    attributes,
    messageType,
    appointmentId,
  } = params;
  return (dispatch) => {
    dispatch(request());

    chatBoxService.deleteMessage(params).then(
      (res) => {
        dispatch(success(res));
        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: chatBoxConstants.DELETE_CHAT_MESSAGES_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: chatBoxConstants.DELETE_CHAT_MESSAGES_FETCHED,
      res,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.DELETE_CHAT_MESSAGES_FAILED, error };
  }
}
function setSelectedMessage(params) {
  const { hospId, mobileNumber, messageBody, attributes, messageType } = params;
  return (dispatch) => {
    dispatch({
      type: chatBoxConstants.SET_SELECTED_MESSAGE,
      params,
    });
  };
}

function clearChatbox() {
  return {
    type: chatBoxConstants.CLEAR_CHAT_BOX,
  };
}

function deleteChatMessage(params) {
  return (dispatch) => {
    dispatch({
      type: chatBoxConstants.DELETE_CHAT_MESSAGE,
      params,
    });
  };
}
function getChatTemplates(params) {
  return (dispatch) => {
    dispatch(request());

    chatBoxService.getChatTemplates(params).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: chatBoxConstants.GET_CHAT_TEMPLATES_REQUESTING,
    };
  }
  function success(response) {
    return {
      type: chatBoxConstants.GET_CHAT_TEMPLATES_FETCHED,
      templates: response,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.GET_CHAT_TEMPLATES_FAILED, error };
  }
}

function sendChatTemplate(reqPayload, addMessagePayload) {
  const {
    Attributes: { uploadLocalId },
  } = addMessagePayload;
  return (dispatch, getState) => {
    dispatch(request());
    handleChatBoxActions(dispatch, getState, "addMessage", addMessagePayload);
    chatBoxService.sendChatTemplate(reqPayload).then(
      (res) => {
        dispatch(success(res));
        handleChatBoxActions(dispatch, getState, "updateMessageLocally", {
          retryStatus: false,
          uploadLocalId: uploadLocalId,
          isSync: true,
          reqPayload
        });
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: chatBoxConstants.SEND_CHAT_TEMPLATES_REQUESTING,
    };
  }
  function success(response) {
    return {
      type: chatBoxConstants.SEND_CHAT_TEMPLATES_FETCHED,
      response,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.SEND_CHAT_TEMPLATES_FAILED, error };
  }
}

function sendToInsurance(params) {
  return (dispatch) => {
    dispatch(request());

    chatBoxService.sendToInsurance(params).then(
      (res) => {
        dispatch(success(res));

        // history.push("/");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: chatBoxConstants.SEND_TO_INSURANCE_REQUESTING,
    };
  }
  function success(response) {
    return {
      type: chatBoxConstants.SEND_TO_INSURANCE_FETCHED,
      response,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.SEND_TO_INSURANCE_FAILED, error };
  }
}

function downloadChatMessage(params, id, generalChatActive, privateChatMessagesActive) {
  return (dispatch) => {
    dispatch(request());

    chatBoxService.downloadChatMessage(params).then(
      (res) => {
        dispatch(success(res));
        //  dispatch(chatBoxActions.updateDownloadChatMessage(res['result'][0],id))
        dispatch(
          chatBoxActions.updateDownloadChatMessage(
            {
              url: res?.["result"],
              id: id,
            },
            generalChatActive,
            privateChatMessagesActive
          )
        );
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {
      type: chatBoxConstants.DOWNLOAD_CHAT_MESSAGE_REQUESTING,
    };
  }
  function success(res) {
    return {
      type: chatBoxConstants.DOWNLOAD_CHAT_MESSAGE_FETCHED,
      res,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.DOWNLOAD_CHAT_MESSAGE_FAILED, error };
  }
}

function updateDownloadChatMessage(params, generalChatActive, privateChatMessagesActive) {
  return (dispatch) => {
    dispatch({
      type: chatBoxConstants.UPDATE_DOWNLOAD_CHAT_MESSAGE,
      params,
      generalChatActive,
      privateChatMessagesActive
    });
  };
}
function loadingDownloadChatMessage(params, generalChatActive, privateChatMessagesActive) {
  return (dispatch) => {
    dispatch({
      type: chatBoxConstants.LOADING_DOWNLOAD_CHAT_MESSAGE,
      params,
      generalChatActive,
      privateChatMessagesActive
    });
  };
}

const updateFullState = (newState) => {
  return {
    type: chatBoxConstants.UPDATE_FULL_CHATBOX_STATE,
    newState,
  };
};

function retrySendMessage(reqPayload,generalChatActive, privateChatMessagesActive) {
  const {
    Attributes: { uploadLocalId },
  } = reqPayload;
  // debugger
  return (dispatch, getState) => {
    handleChatBoxActions(dispatch, getState, "updateMessageLocally", {
      retryStatus: false,
      reqPayload,
      uploadLocalId: uploadLocalId,
    },false,generalChatActive, privateChatMessagesActive);
    handleChatBoxActions(dispatch, getState, "updateRetryList", {
      uploadLocalId: uploadLocalId,
    });
  };
}
function getGeneralChatMessages(data) {
  const {
    params = {},
    callback = null,
    fetchFromServiceFile = false,
    cache = undefined,
  } = data;

  return (dispatch) => {
    dispatch(request());
    // dispatch(configActions.showSpinningLoader());

    if (fetchFromServiceFile) {
      chatBoxService.getGeneralChatMessages(params).then(
        (res) => {
          dispatch(success(res));

          //callback added for the support of the the caching work
          Boolean(callback) && callback(res);

          // dispatch(configActions.hideSpinningLoader());

          // history.push("/");
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      dispatch(success(cache));
      // dispatch(configActions.hideSpinningLoader());
    }
  };

  function request() {
    return {
      type: chatBoxConstants.GENERAL_CHAT_MESSAGES_REQUESTING,
    };
  }
  function success(response) {
    // const { status = "", description = "", result = [] } = response;
    // swal({
    //   title: status,
    //   text: description,
    //   icon: status.toLowerCase() === "success" ? "success" : "error",
    // });

    return {
      type: chatBoxConstants.GENERAL_CHAT_MESSAGES_FETCHED,
      generalChatMessages: response,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.GENERAL_CHAT_MESSAGES_FAILED, error };
  }
}
function fetchMoreMessages(reqBody, generalChatActive) {
  return (dispatch, getState) => {
    dispatch(request());
    if (!generalChatActive) {
      chatBoxService.getPatientMessages(reqBody).then(
        (res) => {
          handleChatBoxActions(
            dispatch,
            getState,
            "fetchMoreMessages",
            res,
            false,
            generalChatActive
          );
          if (res.length < chatBoxConstants.MESSAGES_LIMIT) {
            dispatch(chatBoxActions.hasMoreMessages(false));
          }
          // dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    } else {
      chatBoxService.getGeneralChatMessages(reqBody).then(
        (res) => {
          let newRes = res.map((item) => {
            let mention_data = item.Attributes.mention_data;
            if (mention_data && mention_data.length > 0) {
              for (let i = 0; i < mention_data.length; i++) {
                item.MessageBody = item.MessageBody.replace(
                  mention_data[i]["name"],
                  `<span style="text-decoration:underline;">${mention_data[i]["name"]}</span>`
                );
              }
            }
            return item;
          });
          handleChatBoxActions(
            dispatch,
            getState,
            "fetchMoreMessages",
            newRes,
            false,
            generalChatActive
          );
          if (res.length < chatBoxConstants.MESSAGES_LIMIT) {
            dispatch(chatBoxActions.hasMoreMessages(false));
          }
          // dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
    }
  };

  function request() {
    return {
      type: chatBoxConstants.FETCH_MORE_MESSAGES_REQUESTING,
    };
  }
  function success(response) {
    return {
      type: chatBoxConstants.FETCH_MORE_MESSAGES_FETCHED,
      messages: response,
      generalChatActive,
    };
  }
  function failure(error) {
    return { type: chatBoxConstants.FETCH_MORE_MESSAGES_FAILED, error };
  }
}

const hasMoreMessages = (value) => {
  return (dispatch) => {
    dispatch({ type: chatBoxConstants.HAS_MORE_MESSAGES, value });
  };
};
const setBlockChatBoxScreen=(value)=>{
  return (dispatch)=>{
    dispatch({ type: chatBoxConstants.BLOCK_CHATBOX_SCREEN, value });
  }
}

const getFinalBillDataByOCR=(reqPayload)=>{
  const {key,leadId,files}=reqPayload
  chatBoxService.getFinalBillDataByOCR({docType:key,leadId:leadId,files:files[files.length-1]})
}

const uploadPodDocuments=(reqPayload)=>{
  return (dispatch,getState) => {
    chatBoxService.uploadPodDocuments(reqPayload).then(
      (res) => {
        handleChatBoxActions(
          dispatch,
          getState,
          "updatePodDocsMessage",
          {
            messageSid: reqPayload.messageSid,
            channelSid: reqPayload.channelSid,
            appointmentId:reqPayload.appointmentId,
            podDocs:res?.result
          },
          false
        );        
        toast.success(res.description)
        // dispatch(success(res));
      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  }
}

const sendBotMessageResponse=(reqPayload)=>{
  return (dispatch,getState) => {
    handleChatBoxActions(
        dispatch,
        getState,
        "updateDynamicBotMessage",
        {
         messageSid:reqPayload.messageSid,
         channelSid:reqPayload.channelSid
        },
        false
      );   
    chatBoxService.sendBotMessageResponse(reqPayload).then(     
      (res) => {
          
        if(res.status==="Failed"){
          toast.error(res.description)
          handleChatBoxActions(
            dispatch,
            getState,
            "updateWithPreAuthResponse",
            {
             messageSid:reqPayload.messageSid,
             channelSid:reqPayload.channelSid,
            },
            false
          );
        }else if(res.status==="Success"){
          toast.success(res.description)
          // dispatch(success(res));
          handleChatBoxActions(
            dispatch,
            getState,
            "updateWithPreAuthResponse",
            {
             messageSid:reqPayload.messageSid,
             channelSid:reqPayload.channelSid,
             modifiedAttributes:res.result.attributes
            },
            false
          );
        }
        
      }
      
    )
      .catch(error => {
        toast.error(error.description)
        handleChatBoxActions(
          dispatch,
          getState,
          "updateWithPreAuthResponse",
          {
           messageSid:reqPayload.messageSid,
           channelSid:reqPayload.channelSid,
          },
          false
        );
      })
  }
}

const sendQueryLetter=(params)=>{
  return (dispatch,getState) => {
    params=cleanObj(params) 
   chatBoxService.sendQueryLetter(params).then(    
      (res) => {   
        toast.success(res.description)
        // dispatch(success(res));
      }
    )
      .catch(error => {
        toast.error(error.description)
      })
  }
}

const privateChatOn =(data)=>{
  const {
    params = {},
    callback = null,
    fetchFromServiceFile = false,
    cache = undefined,
  } = data;

  return (dispatch) => {
    dispatch(request());

    if(fetchFromServiceFile){
      chatBoxService.getPatientMessages(params).then(
        (res) =>{
          dispatch(success(res))
          Boolean(callback) && callback(res);
        },
        (err) => {
          dispatch(failure(err))
          dispatch(alertActions.error(err))
        }
      )
    }
    else{
      dispatch(success(cache));
    }
  }

  function request(){
    return{
      type: chatBoxConstants.PRIVATE_CHAT_ON
    }
  }

  function success(response){
    return{
      type: chatBoxConstants.PRIVATE_CHAT_MESSAGES,
      privateMessages:response
    }
  }

  function failure(error){
    return {
      type: chatBoxConstants.PRIVATE_CHAT_FAIL,
      error
    }
  }
}

const privateChatOff = ()=>{
  return {
    type: chatBoxConstants.PRIVATE_CHAT_OFF
  }
}
export const chatBoxActions = {
  getPatientMessages,
  getGeneralChatMessages,
  sendMessage,
  deleteMessage,
  setSelectedMessage,
  sendFile,
  sendReply,
  clearChatbox,
  deleteChatMessage,
  getChatTemplates,
  sendChatTemplate,
  sendToInsurance,
  downloadChatMessage,
  updateDownloadChatMessage,
  loadingDownloadChatMessage,
  updateFullState,
  retrySendMessage,
  fetchMoreMessages,
  hasMoreMessages,
  setBlockChatBoxScreen,
  uploadPodDocuments,
  sendBotMessageResponse,
  sendQueryLetter,
  privateChatOn,
  privateChatOff
};
