import { userConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function users(state = getInitialState("users"), action) {
  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GETALL_SUCCESS:
      return {
        items: action.users,
      };
    case userConstants.GETALL_FAILURE:
      return {
        error: action.error,
      };

    case userConstants.UPDATE_FULL_USERS_STATE:
      const { newState } = action;

      return {
        ...newState,
      };
    case userConstants.UPDATE_NOTIFICATION_PERMISSIONS: {
      const { token } = action
      return {
        ...state,
        notificationId: token
      }
    }
    case userConstants.GET_ROLES_LIST_SUCCESS: {
      const { rolesListResponse:{result} } = action
      return {
        ...state,
        userRolesList: result
      }
    }
    default:
      return state;
  }
}
