import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let centralBaseUrl = process.env.REACT_APP_CENTRAL_AWS_URL;

export function getCentralHospitalListService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        // mobileplatform: "web",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...params }),
    };
    return fetch(
      `${centralBaseUrl}/centralReport/count`,
      requestOptions
    )
      .then(handleResponse)
      .then(res => res)
      .catch(function (error) {
        console.error(error);
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }
