import { Button, Grid, IconButton, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Popover from "@mui/material/Popover";
import { Box } from "@mui/system";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import drawerIcon from '../../../../assets/drawerArrow.png';
import filterIcon2 from '../../../../assets/Filter.svg';
import searchIcon from '../../../../assets/search-icon.png';
import { homeStateActions } from "../../../../_actions";
import ButtonHWA from "../../../../_components/ButtonHWA/ButtonHWA";
import DailogHWA from "../../../../_components/DailogHWA/DailogHWA";
import { hospitalConstants, patientConstants } from "../../../../_constants";
import { Debounce } from "../../../../_helpers";
import useWindowSize from '../../../../_helpers/useWindowSize';
import { HospitalList } from "../HospitalListing";

// import { hospitals } from "../../../../_reducers/hospital.reducer";
const searchInputDebounce = new Debounce();

function HospitalContainer(props) {
  const { isMobile } = useWindowSize()
  const isMounted = useRef(false);

  const hospitalList = useSelector((state) => state.hospitals.hospitalList) ?? [
    { hospital_id: "", sid: "", friendly_name: "" },
  ];
  const currentHospitalCity = useSelector(state => state.hospitals.currentHospitalData?.hospital_city)

  const isFetchingHospitalList = useSelector(
    (state) => state.hospitals.isFetchingHospitalList
  );
  const cityArr = useSelector(
    (state) => state.hospitals.cityArr
  );
  const isHospitalActive = useSelector(
    (state) => state.hospitals.isHospitalActive
  );

  const showChatBox = useSelector((state) => state.homeState.showChatBox);

  const [sortedHospitalList, setSortedHospitalList] = useState([]);
  const [currentHospitalList, setCurrentHospitalList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [cityFilter, setCityFilter] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [screenData, setScreenData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const PAGE_SIZE = 20
  const selectedCities = useSelector(state => state.homeState.selectedCities)
  const dispatch = props.dispatch

  const updateCurrentHospitalList = () => {
    let cityNames = []
    if (selectedCities.length > 0) {
      cityNames = selectedCities.map(item => item.name)
    }
    const newHospitalList = sortedHospitalList.filter((list) => {
      const { friendly_name = "" } = list;
      if (cityNames.length > 0) {
        return friendly_name.toLowerCase().includes(searchInput.toLowerCase().trim()) && cityNames.includes(list.hospital_city)
      } else
        return friendly_name.toLowerCase().includes(searchInput.toLowerCase().trim());
    });

    setCurrentHospitalList(newHospitalList);
  };

  useEffect(() => {

    if ((currentHospitalList.length > 0 && currentHospitalList[0]["hospital_id"].length === 0) || currentHospitalList.length === 0) {
      setScreenData([])
      setHasMore(false)
      return
    } else {
      fetchMoreData({ pageNumber: 1 })
    }
    setHasMore(true)
  }, [currentHospitalList])
  const updateSortedList = () => {
    const newList = [...hospitalList];
    newList.sort((a, b) => b["lastMessageDate"] - a["lastMessageDate"]);
    setSortedHospitalList(() => newList);
  };

  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchInput(() => value);
  };

  const handleSearchInputSubmit = () => {
    updateCurrentHospitalList();
  };

  useEffect(() => {
    // searchInputDebounce.debounce(updateCurrentHospitalList, 0);
    updateCurrentHospitalList();
  }, [sortedHospitalList, selectedCities]);

  useEffect(() => {
    // sortingDebounce.debounce(updateSortedList, 50);
    updateSortedList();
  }, [hospitalList]);

  useEffect(() => {
    if (isMounted.current) {
      searchInputDebounce.debounce(updateCurrentHospitalList, 300);
    } else {
      isMounted.current = true
    }
  }, [searchInput]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setCityFilter(selectedCities)
    setTimeout(() => {
      document.getElementById('search_input').placeholder = "Search"
    }, 0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const selectCity = (item) => {
    setCityFilter(item)

  }
  const removeCity = (item) => {
    setCityFilter(item)

  }
  const clearFilters = () => {
    if (cityFilter.length === 0) {
      toast.success("No City Filter is selected")
    } else {
      setCityFilter([])
      dispatch(homeStateActions.updateSelectedCities([]))
      // setSelectedCities([])
      handleClose()
    }
  }
  const applyFilter = () => {
    const { dispatch } = props;
    var togglePatientList = true
    for (let i = 0; i < cityFilter.length; i++) {
      let item = cityFilter[i]
      // changed double equal to triple can cause error
      if (item.name === currentHospitalCity) { 
        togglePatientList = false
      }
    }
    if (togglePatientList) {
      var data = {}
      dispatch({ type: patientConstants.PATIENT_LIST_REQUESTING })
      dispatch({ type: hospitalConstants.SET_HOSPITAL_LIST_DATA, data })
    }
    dispatch(homeStateActions.updateSelectedCities(cityFilter))

    //  setSelectedCities(cityFilter)
    handleClose()
  }

  const renderCityFilter = () => {

    const filterBody = () => {
      return (
        <Box className="filterPopUp" sx={{width:['300px','300px','300px','280px','370px'],p: ['15px 13px 20px 13px', '15px 13px 20px 13px', '15px 13px 20px 13px', '10px', '20px 20px 25px 20px']}}>
          <div className="filterBody">
            <Multiselect
              options={cityArr} // Options to display in the dropdown
              selectedValues={cityFilter} // Preselected value to persist in dropdown
              onSelect={selectCity} // Function will trigger on select event
              onRemove={removeCity} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
              showCheckbox={true}
              avoidHighlightFirstOption={true}
              style={{searchBox: { "border-bottom":"none","border":"none"},chips: { background: "#3D7CC9" },} }      
            />
          </div>
          <Grid container sx={{ gap: ['20px', '20px', '13px'], paddingTop: ['20px', '20px','20px', '5px', '45px'] }}>
          <Grid  item sx={{ flex: 1 }}> <ButtonHWA onClick={() => applyFilter()}  variant="contained">Apply</ButtonHWA></Grid>
          <Grid item sx={{ flex: 1 }}><ButtonHWA onClick={() => clearFilters()}  >Clear</ButtonHWA></Grid>

            {/* <Button sx={{ textTransform: "none", flex: "1",fontSize: "calc(12px + (20 - 12) * ((100vw - 360px) / (1920 - 360)))!important" }} onClick={() => applyFilter()} variant="contained">Apply </Button>
            <Button sx={{ textTransform: "none", background: "#f5f5f5", flex: "1",fontSize: "calc(12px + (20 - 12) * ((100vw - 360px) / (1920 - 360)))!important" }} onClick={() => clearFilters()} variant="">Clear </Button> */}
          </Grid>
        </Box>
      )
    }

    return (<>

      {
       <DailogHWA
       open={anchorEl}
       onClose={handleClose}
       sx={{ display: ['block'], background: "transparent"}}
       PaperProps={{ sx: { overflowY: ["unset"], borderRadius: "10px", minWidth: ['unset'] , backgroundColor: "white",position:'absolute',left:["unset",'unset','unset','0%'],top:['unset','unset','unset','15vh','10vh'] } }}
       title={'Select City'}
       contentStyling={{ padding: "0px" }}
       dataName="heythere"
       hideBackdrop={!isMobile}
     >
          {filterBody()}


        </DailogHWA>
      }
      {
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          sx={{ display: ['none'] }}
        >

          {filterBody()}
        </Popover>

      }
    </>
    )

  }
  const renderFilterButton = () => {
    return (

      <Button
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
        sx={{ 
          p: ['8.5px'],
          minWidth: "unset!important", 
          background: ['white'], 
          boxShadow: ["0px 3px 6px #00000029"], 
          borderRadius: '8px', 
          height: ['40px'], 
          position: ['relative'], 
          right: ['10px'],
          top: ['calc(50% - 20px)'],
       }}
      >
        <img src={filterIcon2} style={{maxWidth: '100%', display: 'block'}} alt="setting" />
      </Button>
    )

  }

  const fetchMoreData = ({ pageNumber = null } = {}) => {
    pageNumber = pageNumber || pageNo
    var displayData = currentHospitalList.slice(0, PAGE_SIZE * pageNumber)
    if (currentHospitalList.length === 0 || currentHospitalList[0]["hospital_id"].length === 0)
      return
    if ((PAGE_SIZE * pageNo >= currentHospitalList.length)) {
      setHasMore(false)
    }
    setPageNo(pageNumber + 1)
    setScreenData(displayData)
  }


  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        sx={{
          position: "sticky",
          zIndex: 1,
          top: 0,
          px: "0 !important",
          background: "#F5F5F5",
        }}
        data-component="HospitalContainer"
      >
        <Box
          sx={{
            background: "#3D7CC9",
            px: ".5rem !important",
            py: ["10px !important"],
            m: 0,
            lineHeight: "1",
            display: "flex",
            justifyContent: ["flex-start", "flex-start", "space-between"],
            alignItems: "center",
            minHeight: "60px",
            borderRadius: "0 0 10px 10px",
            position: 'relative'
          }}
          disableGutters
        >
          {showChatBox && <img className="drawer-icon" src={drawerIcon} onClick={()=>dispatch({type:hospitalConstants.CLOSE_HOSPITAL})} />}
          <Typography
            align="center"
            variant="h2"
            fontWeight="600"
            color="#fff"
            sx={{
              fontSize: ["18px", "18px", "clamp(15px, 5vw, 22px)"],
              marginLeft: ["20px", "20px", "0px"],
              width:['auto','auto','100%']
            }}
          >
            Hospital List
          </Typography>

        </Box>
        {renderCityFilter()}

        <Container
          className="wrapper"
          disableGutters
          sx={{
            display: "flex",
            padding: ["20px 20px 10px 20px!important","20px 20px 10px 20px!important","20px 20px 10px 10px!important"],
            gap: ["10px"],
            alignItems: 'center'
            // py: ".5rem",
          }}
        >
          <div className="search-input-container" style={{ flex: 2 }}>
            <IconButton aria-label="search" onClick={handleSearchInputSubmit}>
              <img style={{ height: "20px", maxWidth: '100%' }} src={searchIcon} alt="search" />
            </IconButton>
            <input
              value={searchInput}
              onChange={handleSearchInputChange}
              name="search"
              id="search"
              placeholder="Search Hospital"
              style={{ paddingLeft: "20px" }}
            />
          </div>
          {renderFilterButton()}

        </Container>
      </Container>
      {/* hospital list */}
      {isHospitalActive && screenData?.length ? (
        <HospitalList
          hospitalList={screenData}
          isFetchingHospitalList={isFetchingHospitalList}
          isHospitalActive={isHospitalActive}
          fetchMoreData={fetchMoreData}
          hasMore={hasMore}
        />
      ) : (
        isHospitalActive &&
        !!sortedHospitalList?.length &&
        !!searchInput.length && (
          <RenderNoHospitalsFound currentHospitalList={currentHospitalList} />
        )
      )}
    </React.Fragment>
  );
}

const connectedHospitalContainer = connect()(HospitalContainer);
export { connectedHospitalContainer as HospitalContainer };

const RenderNoHospitalsFound = ({ currentHospitalList }) => {
  return (
    <Typography color="primary" variant="h6" align="center">
      No Hospitals Found
    </Typography>
  );
};
