import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderCentralCommonComponent } from '../../helpers/utilities';
import { useDispatch, useSelector } from 'react-redux';
import usePromiseAllSettled from '../../customHooks/usePromiseAllSettled';
import moment from 'moment';
import { booleanStatesActions } from '../../../_actions';
import './centralAccordion.css'

export const CentralAccordion = ({type,data,allIds=''}) => {
  const [responses, errors, isLoading, fetchData] = usePromiseAllSettled()
  const dispatch = useDispatch()
  const booleanStates = useSelector(state=>state.booleanStates)
  const handleAccordionBodyRender = (type,data)=>{

      return data.map(item=>{
          return (type == item.title && item.details?
                  (<div key = {item.title} className='accordionBodyDataContainer'>
                      {item.details.map(detail => renderCentralCommonComponent(detail))}
                  </div>)
                  :''
                  )
            })
      
  }

const handleSubmit = (event, type)=>{
  event.preventDefault();
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());
    let formDataObject = Object.fromEntries([...formData.entries()].filter(([key, value]) => value !== ''));
    function isValidDate(dateString) {
      return moment(dateString, "MM/DD/YYYY hh:mm A", true).isValid();
    }
  let cashProofData = [];
  let deletedCashProofData = [];
  for (const property in formDataObject) {
      if (isValidDate(formDataObject[property])) {
          const formattedDate = moment(formDataObject[property], "MM/DD/YYYY hh:mm A").format("YYYY-MM-DDTHH:mm");
          formDataObject[property] = formattedDate;
      }
        if(property.includes('fileInfoNameProperty')){
          let key = property.split('fileInfoNameProperty-')[1]
          if(property.includes('databucket')){
            cashProofData.push({
              "url": key,
              "amount": formDataObject[property],
              "atTheTimeOf" : type?.toLowerCase() === 'admission' && booleanStates.isUploadedFile ? 'admission' : 'discharge'
            });
          delete formDataObject[property];
        }
        else{
          deletedCashProofData.push(key)
          delete formDataObject[property];
        }
        formDataObject.extraCashProof = {'cashProofData':cashProofData, 'deletedCashProofData':deletedCashProofData};
      }
  }

    if(booleanStates.isUploadedFile || booleanStates.isUploadedDisFile){
      if(booleanStates.isAmountFillFile && type?.toLowerCase() === 'admission'){
        fetchData(formDataObject, allIds)
        dispatch(booleanStatesActions.updateIsAmountFill(false))
        dispatch(booleanStatesActions.updateIsUploaded(false))
      } 
      else if(booleanStates.isAmountFillDisFile && type?.toLowerCase() === 'discharge'){
        fetchData(formDataObject, allIds)
        dispatch(booleanStatesActions.updateIsAmountDisFill(false))
        dispatch(booleanStatesActions.updateIsUploadedDisFile(false))
      }
      else{
          toast.error('Amount should not empty')
      }
    }
    else{
      fetchData(formDataObject, allIds)
    }
}

const handlePendingFields = () => {
  let totalPendingFields = 0;
  data.forEach(item => {
      let pendingFields = 0;
      if (type === item.title) {
          item && item.details.forEach(element => {
              if ((!element.value || element.value === null) && element.functionType.includes('input')) {
                  pendingFields = element.key==='other_payments' ? pendingFields : pendingFields + 1;
              }
              
          });
          totalPendingFields += pendingFields;
      }
  });
  return totalPendingFields;
};

  return (
    <Accordion className='accordionContainer'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3476CD'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className='accordionHeaderContainer'
        >
          <Typography className='accordionHeaderText'>{type}</Typography>
          {handlePendingFields() ? <Typography className='accordionPendingFiendsText'>{"(Pending Fields: "+handlePendingFields()+")"}</Typography>:''}
        </AccordionSummary>
        <AccordionDetails className='accordionBodyDetails'>
          <form onSubmit={(e) => handleSubmit(e, type)} autoComplete="off">
           {handleAccordionBodyRender(type,data)}
           {
            type !== "Appointment Details" ? <div className='accordionSaveButtonContainer'>
            <button type='submit' className='accordionSaveButton'>Save</button>
            </div>:""
           }
           
          </form>
        </AccordionDetails>
      </Accordion>
  )
}
