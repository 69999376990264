import React, { useState } from 'react'
import "./centralCommonHeader.css"
import { Avatar } from '@mui/material'
import backActionIcon from '../../../assets/backActionIcon.png';
import centralSearchIcon from '../../../assets/centralSearchIcon.png';
import closeIcon from "../../../assets/centralSearchCloseIcon.png"
import patientAvatarIcon from "../../../assets/patientAvatar.png"

export const CentralCommonHeader = ({
  title = '',
  backIcon = false,
  backArrowFunction,
  avatarIcon = false,
  searchIcon = false,
  searchFunction,
  clearFunction,
}) => {
  const [searchActive, setSearchActive] = useState(false)
  const [hasSearchIcon, setHasSearchIcon] = useState(searchIcon)

  const handleClearSearch = () => {
    clearFunction()
    setHasSearchIcon(!hasSearchIcon)
    setSearchActive(false)
  }


  return (
    <>
      <div className='centralCommonHeader_container' style={{justifyContent : avatarIcon ? 'space-around':'space-between'}}>
        {!searchActive ?
          <div className={`centralCommonHeader_header ${avatarIcon ? 'centralCommonHeader_absolute' :''}`}>
            {
              backIcon ?
                <img src={backActionIcon} alt="backIcon" onClick={backArrowFunction} /> : ' '
            }
            {
              !avatarIcon ?
               <h3>
                {title}
              </h3> : ''
            }
          </div>
          :
          <div className="search-input-container" style={{ flex: 2, backgroundColor: "rgba(255,255,255,0.3)", alignItems: 'center', fontSize: '14px', color: '#fff', borderRadius: '50px' }}>
            <input
              name="search"
              id="search"
              placeholder="Search Hospitals"
              style={{ paddingLeft: "20px" }}
              onInput={(event)=>searchFunction(event.target.value)}
            />
            <img src={closeIcon} alt="clearIcon" className="central_clearIcon" onClick={handleClearSearch} />
          </div>
        }
        {hasSearchIcon ?
          <img src={centralSearchIcon} alt="searchIcon" onClick={() => { setSearchActive(!searchActive); setHasSearchIcon(!hasSearchIcon) }} /> : ' '
        }
        {
              avatarIcon ?
                <div className='centralCommonHeader_patientAvatar'>
                  <img src={patientAvatarIcon} alt="avatarIcon"/>
                  <h3>
                    {title}
                  </h3>
                </div> : ''
            }
      </div>
    </>
  )
}
