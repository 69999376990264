export const tableData = {
  hospitalScreen: {
    columns: [
      { key: '', title: "Hospital Name" },
      { key: '', title: "City" },
      { key: '', title: "Surgery Count" },
      { key: '', title: "Provisional Discharge Summary" },
      { key: '', title: "Bill Breakup Summary" },
    ],
    filters: [
      // {key:'',title:'Select Mode of Payment',key:'mopFilter'},
      { title: 'Select City', key: 'cityFilter' },
      { title: 'Select Date Range', key: 'dateRange' },
    ]
  },
  patientScreen: {
    columns: [
      { key: '', title: "Patient Name" },
      { key: '', title: "Doctor Name" },
      { key: '', title: "Ipd Date" },
      { key: 'PDS', title: "Provisional Discharge Summary" },
      { key: 'BB', title: "Bill Breakup Summary" },
    ],
    filters: [
      { title: 'Select Mode of Payment', key: 'mopFilter' },
      { key: 'pdsFilter', title: 'Provisional Discharge Summary' },
      { key: 'bbsFilter', title: 'Bill Breakup Summary' },
      // {key:'',title:'Select City',key:'cityFilter'},
      { title: 'Select Date Range', key: 'dateRange' },
    ]
  },
  hospitalViewScreen: {
    columns: [
      { key: '', title: "Patient Name" },
      { key: '', title: "Doctor Name" },
      { key: '', title: "IPD Date" },
      { key: 'FDS', title: "Final Discharge Summary" },
      { key: 'PB', title: "Provisional Bill" },
      { key: 'FB', title: "Final Bill" },
      { key:'', title:'Accept/Reject'}
    ],
    filters: [
      { title: 'Select Mode of Payment', key: 'mopFilter' },
      { title: 'Select Date Range', key: 'dateRange' },
    ]
  },
  
};
