import { Lock } from "@mui/icons-material";
import { Avatar, Box, Button, CardContent, CardMedia, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { chatBoxActions, groupInfoPopupActions, homeStateActions, hospitalActions, patientActions } from "../../../_actions";
import { chatBoxConstants, hospitalConstants } from "../../../_constants";
import { mainCacheConstants, useIntersectionObserver, useReduxCacheAndDispatch } from "../../../_helpers";
import { getInitialState } from "../../../_helpers/getInitialState";
import { WebSocketContext } from './../../../contexts/WebSocket';
import CardStatusIcon from "./CardStatusIcon";
import GreenCheckIcon from "../../../assets/greenCheck.png";
import WhiteCheckIcon from "../../../assets/whiteCheck.png";
import ArrivedIcon from "../../../assets/arrivedTag.png";


import "./__style.css";
import { Badge } from "reactour";

function Cards(props) {
  const navigate = useNavigate();
  const { sendSocketMessage } = useContext(WebSocketContext);
  const [observerStyles, setObserverStyles] = useState({});
  const cardElementRef = useRef(null);
  const isMountedRef = useRef(null);


  const customIntersectionObserver = useIntersectionObserver();

  const role = useSelector((state) => state.authentication.user.roles.role);

  const username = useSelector((state) => state.authentication.user.name);
  const patientInfo = useSelector((state) => state.patients.patientInfo);
  const currHospitalId = useSelector((state) => state?.hospitals?.currentHospitalData?.hospital_id);

  const currentHospitalSid = useSelector(
    (state) => state.hospitals.currentHospitalData?.sid
  );

  const showGeneralChatMessages = useSelector(
    (state) => state.homeState.showGeneralChatMessages
  );

  const showChatBox = useSelector((state) => state.homeState.showChatBox);

  const surgeryApprovers = useSelector(
    (state) => state.config.versionConfigData.result?.[0]?.surgeryApprovers
  );

  const dispatch = useDispatch();
  const cacheAndDispatch = useReduxCacheAndDispatch();
  const {
    item: {
      _id: appointmentId = "",
      friendly_name = "",
      hospital_id = "",
      sid = "",
      FirstName = "",
      patientId = "",
      leadId = "",
      lastMessageDate = "",
      wasRejected = false,
      eventData = { attributes: {} },
      patientRejection = false,
      unreadMessages = 0,
      okForSurgery=false,
      otDate=null,
      otTime=null,
      arrivalTime=false
    },
    item,
    isHospital,
    patientType = "NA",
    isPatient = false,
    isSmallScreen,
    hospitalViewType
  } = props;
  const fullName = friendly_name || FirstName;

  const isSelected = useMemo(() => {
    if (isHospital) {
      return sid === currentHospitalSid;
    }

    if (isPatient) {
      return showGeneralChatMessages
        ? false
        : appointmentId ? appointmentId === patientInfo["appointmentId"] : leadId === patientInfo["leadId"];
    }

    return false;
  }, [
    sid,
    appointmentId,
    showGeneralChatMessages,
    currentHospitalSid,
    patientInfo,
    isHospital,
    isPatient,
  ]);

  const hasAcceptRejectAuth = useMemo(() => {
    if (isHospital) return false;
    return surgeryApprovers.includes(role);
  }, [surgeryApprovers, isHospital, patientType, role]);

  const hasRejectAuth = useMemo(() => {
    if (hasAcceptRejectAuth) {
      return !!!wasRejected && !!!patientRejection;
    }

    return false;
  }, [hasAcceptRejectAuth, wasRejected, patientRejection]);

  const handleCardClickMain = () => {
    if (props.fixScrollPosition) {
      props.fixScrollPosition(
        props.cardId,
        props.listContainer,
        props.containerId
      );
    }
    dispatch({ type: hospitalConstants.OPEN_DRAWER })
    dispatch(chatBoxActions.privateChatOff())
    const getPatientMessages = ({ params, params: { appointmentId, leadId } }) => {
      if (!patientInfo) return;

      cacheAndDispatch({
        action: chatBoxActions.getPatientMessages,
        params,
        cacheOf: mainCacheConstants.chatBoxMessagesCache,
        key: appointmentId || leadId,
      });
    };

    const getPatientInfo = (params) => {
      if (!patientInfo) return;

      dispatch(patientActions.getPatientInfoAPIData(params));
    };

    const { isHospital } = props;

    //hiding the generalChatmessages
    if (!!showGeneralChatMessages) {
      dispatch(
        homeStateActions.setState({
          showGeneralChatMessages: false,
        })
      );
    }

    if (isHospital) {
      /// to prevent state changes if same hospital is clicked again
      if (isSmallScreen || sid !== currentHospitalSid) {
        dispatch(patientActions.updateSelectedMop({ dateRange: { startDate: null, endDate: new Date('') }, selectedMop: [] }));
        dispatch(homeStateActions.setScreenName({ hospitalList: false, patientList: true, chatBox: false }))
        // Mixpanel.track(constants.BLANK_ERROR, { source: "cardClicked", payload: props });


        // let appointment_id= patientInfo.appointmentId
        // appointment_id && dispatch(patientActions.updatePatientsReadCount({ appointment_id, count: 0 }))

        const initialPatientInfoState = getInitialState("patients")["patientInfo"];
        dispatch(patientActions.setPatientInfo(initialPatientInfoState));

        cacheAndDispatch({
          action: patientActions.getPatientList,
          params: {
            selectedHospitalId: hospital_id,
            sid,
          },
          cacheOf: mainCacheConstants.patientListsCache,
          key: sid,
        });

        cacheAndDispatch({
          action: groupInfoPopupActions.getGroupInfoList,
          params: { sid: sid },
          cacheOf: mainCacheConstants.groupInfoListCache,
          key: sid
        })

        //need to move this
        dispatch(
          hospitalActions.setCurrentHospitalData({
            data: props?.["item"],
          })
        );

        // need to move this
        dispatch(
          hospitalActions.handleLastIndexAPI({
            params: { channelSid: sid },
          })
        );

        //clearing and hiding the chat box --------------
        dispatch(chatBoxActions.clearChatbox());
      }

      if (!!showChatBox) {
        dispatch(
          homeStateActions.setState({
            showChatBox: false,
          })
        );
      }

      // clearing the patientInfo state upon hospital click

      if (!!patientInfo.appointmentId) {
        const initialPatientInfoState =
          getInitialState("patients")["patientInfo"];

        dispatch(patientActions.setPatientInfo(initialPatientInfoState));
      }

      navigate("/patients")
    }

    if (isPatient) {
      const {
        item: { _id: appointmentId = "", patientId = "", leadId = "" },
        item,
      } = props;
      //need to move this
      if (!!!showChatBox) {
        dispatch(
          homeStateActions.setState({
            showChatBox: true,
          })
        );
      }
      /// to prevent state changes if same hospital is clicked again
      if (isSmallScreen || (appointmentId !== patientInfo["allInfo"]["_id"]) || showGeneralChatMessages == true) {
        let messageSocketPayload = {
          action: "readAppointment",
          payload: {
            hospitalId: hospital_id,
            appointmentId: appointmentId
          }
        }
        sendSocketMessage(messageSocketPayload);
        dispatch(patientActions.updatePatientsReadCount({ appointmentId, count: 0 }))

        dispatch(homeStateActions.setScreenName({ hospitalList: false, patientList: false, chatBox: true }))
        //need to move this
        dispatch(
          patientActions.setPatientInfo({
            appointmentId,
            patientId,
            leadId,
            allInfo: item,
          })
        );

        // //this is the API Call for the patient info
        //need to move this
        getPatientInfo({
          appointmentId,
          patientId,
          leadId,
        });

        getPatientMessages({
          params: {
            appointmentId,
            leadId,
            numberOfMessages: chatBoxConstants.MESSAGES_LIMIT,
            hospitalId: currHospitalId
          },
        });
      }
      navigate("/chatbox")
    }
  };

  const handleAcceptRejectClick = (e) => {

    if (!hasAcceptRejectAuth) {
      e.stopPropagation();

      return;
    }

    const { id } = e.target.closest("button");
    const { item } = props;

    dispatch(
      patientActions.setPatientInfo({
        appointmentId,
        patientId,
        leadId,
        allInfo: item,
      })
    );

    dispatch(
      homeStateActions.setState({
        [id]: true,
      })
    );

    e.stopPropagation();
  };

  const getCardStyles = () => {
    const cardActiveStyles = {
      background: `${isHospital || patientType !== "pendingPatient" ? "#3D7CC9" : "#d6e2f2"
        }`,
      color: "#fff",
      ":hover": {
        background: `${isHospital || patientType !== "pendingPatient" ? "#3D7CC9" : "#d6e2f2"
          } !important`,
        color: "#fff !important",
      },
    };

    return cardActiveStyles;
  };

  const RenderRejectedLine = () => {
    if (!!!wasRejected && !!!patientRejection) return <></>;

    let {
      attributes: { authorName = "", authorRole = "" },
      createdAsDate: timeStamp,
    } = eventData;

    if (patientRejection) {
      const { rejectionTime, approvalActionBy } = item;

      authorName = approvalActionBy;
      authorRole = item.authorRole;
      timeStamp = rejectionTime;
    }

    const time = moment(timeStamp).format("hh:mm A DD MMM");

    return (
      <Typography
        sx={{
          textAlign: "left",
          color: "#434343",
          textTransform: "initial",
          width: "100%",
          fontSize: {
            xs: "8px",
            md: "10px",
            lg: "12px",
          },
        }}
      >
        {`Rejected by ${authorName?.length === 0 ? username : authorName}(${authorName?.length === 0 ? role : authorRole}) at ${time}`}
      </Typography>
    );
  };

  const RenderRejectButton = () => {
    return (
      <Button
        variant="contained"
        sx={{
          boxShadow: 1,
          fontSize: "10px",
          color: "#7A7A7A",
          backgroundColor: "#fff",
          textTransform: "capitalize",
          ":hover": {
            backgroundColor: "#fff",
          },
          cursor: hasRejectAuth ? "unset" : "not-allowed",
        }}
        size="small"
        fullWidth
        id="showRejectPopup"
        startIcon={!hasAcceptRejectAuth ? <Lock /> : <></>}
        disableRipple={true}
        disabled={hasAcceptRejectAuth && (wasRejected || patientRejection)}
        onClick={(e) => {
          if (!!!hasRejectAuth) {
            e.stopPropagation();

            return;
          }

          handleAcceptRejectClick(e);
        }}
      >
        Reject
      </Button>
    );
  };

  const getElementId = () =>
    JSON.stringify({ sid, appointmentId, patientId, leadId });

  const visibleCallback = () => {

    if (isMountedRef.current) {

      setObserverStyles(() => ({
        visibility: "visible",
        // transform: "scale(1)",
      }));
    }
  };

  const hiddenCallback = () => {
    if (isMountedRef.current) {

      setObserverStyles(() => ({
        visibility: "none",
      }));
    }
  };


  useEffect(() => {

    isMountedRef.current = true;

    if (isMountedRef.current) {
      customIntersectionObserver({
        selectors: null,
        element: cardElementRef.current,
        visibleCallback,
        hiddenCallback,
      });
      return () => isMountedRef.current = false;
    }
  }, []);

  
  let isChatDisabled = (props?.patientType?.toLowerCase() !== "oldpatient" && props?.item?.appointmentStatus?.toLowerCase() === "cancelled")
  let isRejected = hasAcceptRejectAuth && (wasRejected || patientRejection)
  // const {isSmallScreen}=useWindowSize()
  // console.log("patientType",props?.patientType, patientType)
  return (
    <>
      <Button
        sx={
          (isSelected && !isSmallScreen)
            ? { ...cardButtonBaseStyles, ...getCardStyles(), ...observerStyles }
            : { ...cardButtonBaseStyles, ...observerStyles, ...(isChatDisabled && { backgroundColor: "#d9d9d9" }), ...((isPatient && props?.item?.appointmentStatus === 'Cancelled') && { borderBottom: "5px solid #E00000" }) }
        }
        id={getElementId()}
        ref={cardElementRef}
        className="main-card-btn"
        onClick={(e) => { !isRejected && handleCardClickMain() }}
        disabled={isChatDisabled}
      >
        <Box
          sx={{
            display: "flex",
            gap: ".5em",
            alignItems: "center",
            width: "100%",
          }}
        >
          {fullName && <Avatar
            sx={{
              width: 35,
              height: 35,
              borderRadius:
                isHospital || patientType === "pendingPatient" ? "5px" : "100%",
              fontSize: "1.1rem",
              color: isSelected && (!isSmallScreen) ? "#3D7CC9" : "#fff",
              bgcolor: isSelected && (!isSmallScreen) ? "#fff" : "#3D7CC9",
            }}
          >
            {fullName
              ?.split(" ")
              .map((name) => name[0])
              .slice(0, 2)
              .join("")
              .toUpperCase()}
          </Avatar>}

          <Box sx={{ width: "100%"}}>
            <CardContent
              sx={{
                flex: "1 0 auto",
                width: "100%",
                p: "0px !important",
                display: "flex",
                alignContent: "center",
                flexDirection: "column",
                gap: ".1em",
              }}
            >
              {patientType === 'activePatient' && arrivalTime && props?.item?.appointmentStatus?.toLowerCase() != "cancelled" && <Badge
                overlap="rectangular"
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                variant="dot"
                sx={{ mt: 2, mb: 2, ml: 3, mr: 2, bgcolor: 'error.main' }}
                className="arrivedMediaCard"
              >
                <CardMedia
                  component="img"
                  height="25"
                  image={ArrivedIcon}
                  alt="arrived"
                />
              </Badge>}
              <div className="container-name-status" 
                  style={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'self-start',
                    paddingLeft:'5px',
                    gap:'0.5rem',
                  }}
              >
                <Typography
                  component="div"
                  variant="h6"
                  className="hospital-name"
                  sx={{
                    fontStyle: "Poppins",
                    fontWeight: "normal",
                    fontSize: ".8rem !important",
                    textAlign: "left",
                    color:
                      isSelected && (!isSmallScreen) && (isHospital || patientType !== "pendingPatient")
                        ? "#fff !important"
                        : "#000000",
                    marginTop: patientType == "pendingPatient" ? "10px" : "0px",
                    opacity: "1",
                    textTransform: "capitalize",
                    alignContent: "center",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: otDate!=null && otTime!=null ?  ["100px", "auto"]:["125px", "auto"]
                  }}
                >
                  {fullName}
                </Typography>
                {
                  patientType === 'activePatient' && props?.item?.appointmentStatus?.toLowerCase() != "cancelled" && okForSurgery && 
                  <div className={(isSelected && !isSmallScreen) ? "container-okSurgeryStatus-active common-okSurgeryStatus":"container-okSurgeryStatus common-okSurgeryStatus"}>
                      <img src={(isSelected && !isSmallScreen) ? GreenCheckIcon:WhiteCheckIcon} alt="checkIcon"/>
                      <span>OK for Surgery</span>
                  </div>
                }
              </div>
            
              {isPatient && (
                <Typography component="div" style={card.subHeader}>
                  {/* Is this item still there? */}
                </Typography>
              )}

              {/* {patientType === "pendingPatient" && ( */}
              {(patientType === "pendingPatient" || hospitalViewType) && !isChatDisabled && (
                <Box sx={{ display: "flex", gap: ".5rem" }}>
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: 1,
                      fontSize: "10px",
                      backgroundColor: "#3D7CC9",
                      color: "#fff",
                      textTransform: "capitalize",
                      ":hover": {
                        backgroundColor: "#3D7CC9",
                        color: "#fff",
                      },
                      alignSelf: "center",
                      cursor: hasAcceptRejectAuth ? "unset" : "not-allowed",
                    }}
                    size="small"
                    fullWidth
                    id="showAcceptPopup"
                    onClick={handleAcceptRejectClick}
                    startIcon={!hasAcceptRejectAuth ? <Lock /> : <></>}
                    disableRipple={true}
                  >
                    Accept
                  </Button>
                  {RenderRejectButton()}
                </Box>
              )}
            </CardContent>
          </Box>
          <Box sx={{ width: "100%",display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',gap:'1rem'}}>
          {
                patientType === 'activePatient' && otDate!=null && otTime!=null && props?.item?.appointmentStatus?.toLowerCase() != "cancelled" && 
                <div className="otDateTime-container">
                    <div className="otDateTime-section">
                      <p className="otDateTime-sectionHeader">OT Date:</p>
                      <p className="otDateTime-sectionText">{otDate}</p>
                    </div>
                    <div className="otDateTime-section">
                      <p className="otDateTime-sectionHeader">OT Time Slot</p>
                      <p className="otDateTime-sectionText">{otTime}</p>
                    </div>
                </div>
              }
            <div className="cards-newContainer">
            {isHospital ? (
            <Typography
              component="div"
              className="time-stamp"
              style={{
                fontSize: "9px",
                textAlign: "end",
                textTransform: "capitalize",
                position: "absolute",
                bottom: "0.2rem",
                right: "1rem",
              }}
            >
              {/* {moment(lastMessageDate, "MMM Do YY")} */}
              {moment(lastMessageDate).calendar(null, {
                nextDay: "hh:mm A, DD-MMM",
                nextWeek: "hh:mm A, DD-MMM",
                lastDay: "hh:mm A, DD-MMM",
                lastWeek: "hh:mm A, DD-MMM",
                sameElse: "hh:mm A, DD-MMM",
              })}
            </Typography>
          ):''}
            {!isHospital && appointmentId !== patientInfo.appointmentId && props.item.unreadMessages !== 0 &&
              !hospitalViewType &&
              <Box sx={{ minWidth: '20px' }}>

              <Tooltip placement="bottom-start" componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'white',
                    color: "#009817",
                    boxShadow: "5px 5px 15px #00000029",
                    '& .MuiTooltip-arrow': {
                      color: "white",
                      "&::before": {
                        boxShadow: "5px 5px 15px #00000029"
                      }
                    },
                  },
                },
              }} title={`${unreadMessages} Unread Messages`} arrow>
                <Box sx={{ borderRadius: "50%", px: "3px", fontSize: "12px", color: "white", minWidth: "20px", flexShrink: "0", background: "#009817", fontWeight: 600 }} >
                  {unreadMessages}
                </Box>
              </Tooltip>
            </Box>
            }
          {props?.patientType === "activePatient"  && <CardStatusIcon patientData={props?.item} /> }
          {props?.patientType === "pendingPatient" && isChatDisabled && <CardStatusIcon patientData={props?.item} />}
            </div>
            
          </Box>
        </Box>

        {patientType === "pendingPatient" && <RenderRejectedLine />}
      </Button>
    </>
  );
}

const cardButtonBaseStyles = {
  display: "grid",
  gridTemplateRows: "1fr auto",
  justifyContent: "stretch",
  gap: ".2em",
  padding: "5px 10px",
  cursor: "pointer",
  height: "auto",
  width: "100%",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "10px",
  background: "#fff",
  position: "relative",
};

const card = {
  subHeader: {
    fontSize: "14px",
  },
  image: {
    height: "45px",
    width: "45px",
    paddingLeft: "10px",
  },
};

// export MediaControlCard;

const connectedCards = connect()(Cards);
export { connectedCards as Cards };
