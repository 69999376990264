import { Box } from "@mui/material";
import icWaitingYellow from "../../../assets/icWaitingYellow.png";
import icWaitingYellowChecked from "../../../assets/icWaitingYellowChecked.png";
import IcCancelledRed from "../../../assets/iscancelled.png";

const icons = {
  cancelled: IcCancelledRed,
  waiting: icWaitingYellow,
  waitingChecked: icWaitingYellowChecked
}

const CardStatusIcon = ({ patientData }) => {
  let iconName = ""
  console.log("patientData",patientData)
  if (patientData?.appointmentStatus === "Cancelled") {
    iconName = 'cancelled'
  }

  if (patientData?.appointmentStatus === "Active") {
    iconName = patientData?.timingsUpdated ? "waitingChecked" : 'waiting'
  }

  return (
    <>
    
      {!(iconName==='cancelled') && patientData?.appointmentStatus != "Active" && <Box>
        <img style={{ maxWidth: "30px"}} src={icons[iconName]} alt="icon" />
      </Box>}
      {(iconName==='cancelled') && <Box sx={{position:'absolute',right:'10px',bottom:'-2px',fontSize:"14px",textTransform:"capitalize",color:"#E00000",textDecoration:"underline",}}>
        Cancelled
      </Box>}

    </>
  );
}

export default CardStatusIcon;