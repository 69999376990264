import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let apiUrl = process.env.REACT_APP_BASE_URL;
let commonApiUrl = process.env.REACT_APP_BASE_URL_COMMON;

export const centralCommonDataService = {
  fetchCentralCommonData
};

export function fetchCentralCommonData() {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      // mobileplatform: "web",
      "Content-Type": "application/json",
    },
  };

  return fetch(`${commonApiUrl}/api/getcommondata?Name=deCentralisationScheme`, config)
    .then(handleResponse)
    .then(res => res)
    .catch((error) => {
      console.error(error);
    })
    .then(response =>response);
}
