import { cleanObj } from "../_helpers";
import { getTokenFromLocal, handleResponse } from "../_helpers/handleResponseMiddleware";

let apiUrl = process.env.REACT_APP_BASE_URL;

export const getDocumentsList = (params) => {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
params=cleanObj(params)
  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(params),
  };

  return fetch(`${apiUrl}/api/v2/getPatientMessageDocuments`, config)
    .then(handleResponse)
    .then((res) => {
      return res.result;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
};

export const getDocumentsListOfGeneralChat = (params) => {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(params),
  };

  return fetch(`${apiUrl}/api/getGeneralChatMessageDocuments`, config)
    .then(handleResponse)
    .then((res) => {
      return res.result;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
};

const removeMembers = async (params) =>{
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;
  const config = {
    method:'POST',
    headers:{
      mobileplatform:'web',
      Authorization: `Bearer ${token}`,
      "Content-Type":'application/json',
    },
    body:JSON.stringify(params)
  };
  return fetch (`${apiUrl}/api/removeMultipleUsers`,config)
  .then(handleResponse)
  .then((res)=>{
    return res;
  })
  .catch((error)=>{
    console.error(error);
  })
  .then((response)=>{
    return response
  })
}

export const documentsPopupService = {
  getDocumentsList,
  getDocumentsListOfGeneralChat,
  removeMembers
};
