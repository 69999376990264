import { booleanStatesConstants } from "../../_constants";

export function booleanStates(
    state = {
        isUploadedFile: false,
        isAmountFillFile: false,
        isUploadedDisFile: false,
        isAmountFillDisFile: false,
    },
    action
) {
    switch (action.type) {
        case booleanStatesConstants.UPDATE_IS_UPLOADED:
            return {
                ...state,
                isUploadedFile: action.payload.isUploadedFile,
            };
        case booleanStatesConstants.UPDATE_IS_AMOUNT_FILL:
            return {
                ...state,
                isAmountFillFile: action.payload.isAmountFillFile,
            };
        case booleanStatesConstants.UPDATE_IS_UPLOADED_DIS_FILE:
            return {
                ...state,
                isUploadedDisFile: action.payload.isUploadedDisFile,
            };
        case booleanStatesConstants.UPDATE_IS_AMOUNT_FILL_DIS_FILE:
            return {
                ...state,
                isAmountFillDisFile: action.payload.isAmountFillDisFile,
            };
        default:
            return state;
    }
}
