import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checklistActions } from "../../../../../_actions/checklist.actions";
import crossIcon from "./../../../../../assets/crossIcon.png";
import uploadIcon from "./../../../../../assets/uploadIcon.svg";
import DocumentPopover from "./../../../../common/UploadDocument/DocumentPopover";
import { Button } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { Cards } from "../../../../common/Cards";


function TableRow({ data, screenName, fetchPatient, filterStatus = "" }) {
  
  const childRef = useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [docType, setDocType] = useState("");
  const hospitalFilter = useSelector(state => state.checklistSummary.hospitalFilter)
  const activeTab = useSelector(state => state.checklistSummary.activeTab)
  const handleClick = (event, docType) => {
    setDocType(docType);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover5" : undefined;
  const dispatch = useDispatch();

  const changeScreen = (
    screenName,
    hospitalId = null,
    documentStatus = null
  ) => {
    dispatch(
      checklistActions.changeChecklistScreen(
        screenName,
        hospitalId,
        documentStatus
      )
    );
    dispatch(
      checklistActions.setChecklistFilters({
        filterObj: {
          search: '',
          startDate: hospitalFilter.startDate,
          endDate: hospitalFilter.endDate,
          pds: [],
          bbs: [],
          mop: [],
          filterStatus: documentStatus,
          hospital: hospitalId
        },
        screenName: "patientScreen",
      })
    );
  };

  const uploadDocument = (event, documentKey = null) => {
    childRef.current.handleClick({ event: event.target.parentElement, documentKey: documentKey, item: data, visibleIcons: ['capture', 'image', 'pdf', 'doc', 'csv', 'excel'] });
  };
  const getPopOverContent = () => {
    console.log("type",docType,data)
    return (
      <div className="popoverTable" style={{
        background: "white!important", position: "relative"
      }}>
        <div className="popoverRow">
          <div className="popoverData" style={{ whiteSpace: "nowrap", borderRight: "2px solid #F5F5F5", width: "102px" }}>Uploaded by:</div>
         {screenName === "hospitalViewScreen" ? 
          <div className="popoverData">
              {docType === "fds" ? data.FDSStatusUserName : docType==='pb' ? data.PBStatusUserName : data.FBStatusUserName}
          </div> :
          <div className="popoverData">
            {docType === "pds" ? data.PDSStatusUserName : data.BBStatusUserName}
          </div>}
        </div>
        <div className="popoverRow">
          <div className="popoverData" style={{ whiteSpace: "nowrap", borderRight: "2px solid #F5F5F5", width: "102px" }}>Uploaded on:</div>
         {screenName === "hospitalViewScreen" ?  
          <div className="popoverData">
              {docType === "fds" ? moment(data.FDSStatusUpdatedTime?.split('T')[0]).format('DD-MM-YYYY') : docType==='pb' ? moment(data.PBStatusUpdatedTime?.split('T')[0]).format('DD-MM-YYYY') : moment(data.FBStatusUpdatedTime?.split('T')[0]).format('DD-MM-YYYY')}
          </div> 
          : 
         <div className="popoverData" >
            {docType === "pds"
              ? moment(data.PDSStatusUpdatedTime?.split('T')[0]).format('DD-MM-YYYY')
              : moment(data.BBStatusUpdatedTime?.split('T')[0]).format('DD-MM-YYYY')}
          </div>}
        </div>
        <div className="popoverRow">
          <div className="popoverData" style={{ whiteSpace: "nowrap", borderRight: "2px solid #F5F5F5", width: "102px" }}>Uploaded time:</div>
          {
            screenName === "hospitalViewScreen" ?  
            <div className="popoverData">
                {docType === "fds" ? moment(new Date(data["FDSStatusUpdatedTime"])).format("h:mm a") : docType==='pb' ? moment(new Date(data["PBStatusUpdatedTime"])).format("h:mm a") : moment(new Date(data["FBStatusUpdatedTime"])).format("h:mm a")}
            </div> 
            : 
            <div className="popoverData" >
              {docType === "pds"
                  ? moment(new Date(data["PDSStatusUpdatedTime"])).format("h:mm a")
                  : moment(new Date(data["BBStatusUpdatedTime"])).format("h:mm a")}
            </div>}
        </div>

      </div>
    );
  };
  return (
    <>
      {screenName === "hospitalScreen" && (
        <tr>
          <td>{data.hospitalName}</td>
          <td>{data.city}</td>
          <td
            onClick={() => changeScreen("patientScreen", data.hospitalId, "")}
            style={{
              color: "#3D7CC9",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {data.appointmentCount}
          </td>
          <td>
            <div className="tdExtraPadding" id="uploadChecklistWidth">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Done:
                <span
                  onClick={() =>
                    changeScreen("patientScreen", data.hospitalId, "PDS-Done")
                  }
                  style={{
                    color: "green",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {data.pdsDone}
                </span>
              </div>
              <div style={{ width: "2px", background: "#F5F5F5", height: "100%", position: "absolute", left: "50%" }}></div>

              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Pending:
                <span
                  onClick={() =>
                    changeScreen(
                      "patientScreen",
                      data.hospitalId,
                      "PDS-PENDING"
                    )
                  }
                  style={{
                    color: "red",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {data.pdsPending}
                </span>

              </div>


            </div>
          </td>
          <td>
            <div className="tdExtraPadding" id="uploadChecklistWidth">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Done:
                <span
                  onClick={() =>
                    changeScreen("patientScreen", data.hospitalId, "BB-Done")
                  }
                  style={{
                    color: "green",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {data.bbDone}
                </span>

              </div>
              <div style={{ width: "2px", background: "#F5F5F5", height: "100%", position: "absolute", left: "50%" }}></div>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                Pending:
                <span
                  onClick={() =>
                    changeScreen("patientScreen", data.hospitalId, "BB-Pending")
                  }
                  style={{
                    color: "red",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {data.bbUploadedPending}
                </span>

              </div>

            </div>
          </td>
        </tr>
      )}
      {screenName === "patientScreen" && (
        <tr>
          <td>{data.PatientFirstName}</td>
          <td>{data.DoctorFirstName}</td>
          <td>{moment(data["IPD Date"]).format('DD-MM-YYYY')}</td>
          {(filterStatus === "" || (filterStatus && filterStatus.includes("PDS"))) && (
            <td >
              <div className="tdExtraPadding" id="uploadChecklistWidth">
                <div>Status:</div>

                {data["Pds Status"] === "Pending" ? (<>
                  <div
                    style={{
                      color: "#3D7CC9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={(event) =>
                      uploadDocument(event, "provisionalDischargeSummary")
                    }
                  >
                    {!!data.pdLoading === true ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      <>
                        <img src={uploadIcon} style={{ marginRight: "5px" }} alt="upload Icon" />
                        Upload PDS
                      </>
                    )}
                  </div>
                  <div className="infoIconLine">
                    <InfoIcon


                      style={{ color: "grey", }}
                    ></InfoIcon>
                  </div>
                </>
                ) : (
                  <>
                    <div style={{ color: data["PDS Status Time"] === 'Delayed' ? "red" : 'green', flexWrap: "wrap", textDecoration: "underline" }}>{data["PDS Status Time"]}</div>
                    <div className="infoIconLine">
                      <InfoIcon
                        aria-describedby={id}
                        onClick={(e) => handleClick(e, "pds")}
                        style={{ cursor: "pointer", color: "#3D7CC9", }}
                      ></InfoIcon>
                    </div>
                  </>
                )}
              </div>
            </td>
          )}
          {(filterStatus === "" || (filterStatus && filterStatus.includes("BB"))) && (
            <td>
              <div className="tdExtraPadding" id="uploadChecklistWidth">
                <div>Status:</div>
                <>
                  {data["BB Status"] === "Pending" ? (<>
                    <div
                      style={{
                        color: "#3D7CC9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={(event) => uploadDocument(event, "billBreakup")}
                    >
                      {!!data.bbLoading === true ? (
                        <>
                          <CircularProgress />
                        </>
                      ) : (
                        <>
                          <img alt="" src={uploadIcon} style={{ marginRight: "5px" }} />
                          Upload BBS
                        </>
                      )}
                    </div>
                    <div className="infoIconLine">
                      <InfoIcon
                        style={{ color: "grey", }}
                      ></InfoIcon>
                    </div>
                  </>
                  ) : (
                    <>
                      <div style={{ color: data["BB Status Time"] === 'Delayed' ? "red" : "green", flexWrap: "wrap", textDecoration: "underline" }}>{data["BB Status Time"]}</div>
                      <div className="infoIconLine">
                        <InfoIcon
                          aria-describedby={id}
                          onClick={(e) => handleClick(e, "bbs")}
                          style={{ cursor: "pointer", color: "#3D7CC9", }}

                        ></InfoIcon>
                      </div>
                    </>
                  )}
                </>
              </div>
            </td>
          )}
        </tr>
      )}
      {screenName === "hospitalViewScreen" && (
        <tr>
          <td>{data.patientFirstName}</td>
          <td>{data.doctorFirstName}</td>
          <td>{moment(data["ipdDate"]).format('DD-MM-YYYY')}</td>
         {activeTab !='pending' && <td >
            <div className="tdExtraPadding" id="uploadChecklistWidth">
              <div>Status:</div>

              {!data["fdsUploadHospChat"] ? (<>
                <div
                  style={{
                    color: "#3D7CC9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={(event) =>
                    uploadDocument(event, "finalDischargeSummary")
                  }
                >
                  {!!data.fdsLoading === true ? (
                    <>
                      <CircularProgress />
                    </>
                  ) : (
                    <>
                      <img src={uploadIcon} style={{ marginRight: "5px" }} alt="upload Icon" />
                      Upload FDS
                    </>
                  )}
                </div>
                <div className="infoIconLine">
                  <InfoIcon


                    style={{ color: "grey", }}
                  ></InfoIcon>
                </div>
              </>
              ) : (
                <>
                  <div style={{ color: data["fdsUploadHospChatTime"] === 'Delayed' ? "red" : 'green', flexWrap: "wrap", textDecoration: "underline" }}>{data["fdsUploadHospChatTime"]}</div>
                  <div className="infoIconLine">
                    <InfoIcon
                      aria-describedby={id}
                      onClick={(e) => handleClick(e, "fds")}
                      style={{ cursor: "pointer", color: "#3D7CC9", }}
                    ></InfoIcon>
                  </div>
                </>
              )}
            </div>
          </td>}
          {activeTab !='pending' && <td>
            <div className="tdExtraPadding" id="uploadChecklistWidth">
              <div>Status:</div>
              <>
                {!data["pbUploadHospChat"] ? (<>
                  <div
                    style={{
                      color: "#3D7CC9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={(event) => uploadDocument(event, "provBill")}
                  >
                    {!!data.pbLoading === true ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      <>
                        <img alt="" src={uploadIcon} style={{ marginRight: "5px" }} />
                        Upload PB
                      </>
                    )}
                  </div>
                  <div className="infoIconLine">
                    <InfoIcon
                      style={{ color: "grey", }}
                    ></InfoIcon>
                  </div>
                </>
                ) : (
                  <>
                    <div style={{ color: data["pbUploadHospChatTime"] === 'Delayed' ? "red" : "green", flexWrap: "wrap", textDecoration: "underline" }}>{data["pbUploadHospChatTime"]}</div>
                    <div className="infoIconLine">
                      <InfoIcon
                        aria-describedby={id}
                        onClick={(e) => handleClick(e, "pb")}
                        style={{ cursor: "pointer", color: "#3D7CC9", }}

                      ></InfoIcon>
                    </div>
                  </>
                )}
              </>
            </div>
          </td>}
          { activeTab!='pending' && <td>
            <div className="tdExtraPadding" id="uploadChecklistWidth">
              <div>Status:</div>
              <>
                {!data["fbUploadHospChat"] ? (<>
                  <div
                    style={{
                      color: "#3D7CC9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={(event) => uploadDocument(event, "finalBill")}
                  >
                    {!!data.fbLoading === true ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      <>
                        <img alt="" src={uploadIcon} style={{ marginRight: "5px" }} />
                        Upload FB
                      </>
                    )}
                  </div>
                  <div className="infoIconLine">
                    <InfoIcon
                      style={{ color: "grey", }}
                    ></InfoIcon>
                  </div>
                </>
                ) : (
                  <>
                    <div style={{ color: data["fbUploadHospChatTime"] === 'Delayed' ? "red" : "green", flexWrap: "wrap", textDecoration: "underline" }}>{data["fbUploadHospChatTime"]}</div>
                    <div className="infoIconLine">
                      <InfoIcon
                        aria-describedby={id}
                        onClick={(e) => handleClick(e, "fb")}
                        style={{ cursor: "pointer", color: "#3D7CC9", }}

                      ></InfoIcon>
                    </div>
                  </>
                )}
              </>
            </div>
          </td>}
          {/* {
            activeTab ==='pending' && <td>IPD time</td>
          } */}
          {
            activeTab ==='pending' && <td>
              <Cards item={data} hospitalViewType={true}/>
            </td>
          }
        </tr>
      )}

      <DocumentPopover ref={childRef} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: " 5px 12px 15px #00000029",
            width: filterStatus?.length > 0 ? "25vw" : "18.2vw",
            marginLeft: filterStatus?.length > 0 ? "10px" : '5px',
            marginTop: "20px",
          }
        }}
      // sx={{position:"relative"}}
      >
        <Box sx={{
          position: "relative",
          mt: "10px",
          "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            left: "10px"
          }
        }}>
          <Typography sx={{}}>{getPopOverContent()}</Typography>
          <div onClick={() => handleClose()} style={{ position: "absolute", top: "-10px", right: "10px", cursor: "pointer" }}>
            <img src={crossIcon} style={{ width: "24px", height: "24px" }} alt="cross icon" />
          </div>
        </Box>
      </Popover>
    </>
  );
}

export default TableRow;
