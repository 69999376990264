import { groupInfoPopupConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

const initialState = getInitialState("groupInfoPopup");

export function groupInfoPopup(state = initialState, action) {
  switch (action.type) {
    case groupInfoPopupConstants.GROUP_INFO_LIST_REQUESTING:
      return {
        ...state,
        isFetchingGroupInfoList: true,
      };

    case groupInfoPopupConstants.GROUP_INFO_LIST_FETCHED:
      const { groupInfo } = action;

      return {
        ...state,
        isFetchingGroupInfoList: false,
        isGroupInfoListActive: true,
        groupInfo,
      };

    case groupInfoPopupConstants.GROUP_INFO_LIST_FAILED:
      return {
        ...state,
        groupInfoFetchingError: action.error,
        isFetchingGroupInfoList: false,
        isGroupInfoListActive: false,
      };

    case groupInfoPopupConstants.ADD_GROUP_MEMBER_REQUESTING:
      return {
        ...state,
        isAddGroupMemberRequesting: true,
      };

    case groupInfoPopupConstants.ADD_GROUP_MEMBER_SUCCESS:
      const { addMemberResponse } = action;
      return {
        ...state,
        isAddGroupMemberRequesting: false,
        groupMemberAddRequestSuccess: true,
        addMemberResponse,
      };

    case groupInfoPopupConstants.ADD_GROUP_MEMBER_FAILED:
      return {
        ...state,
        groupMemberAddRequestFailed: action.error,
        isAddGroupMemberRequesting: false,
        groupMemberAddRequestSuccess: false,
      };

    case groupInfoPopupConstants.REMOVE_GROUP_MEMBER_REQUESTING:
      return {
        ...state,
        isRemoveGroupMemberRequesting: true,
      };

    case groupInfoPopupConstants.REMOVE_GROUP_MEMBER_SUCCESS:
      const { removeMemberResponse } = action;
      return {
        ...state,
        isRemoveGroupMemberRequesting: false,
        groupMemberRemoveRequestSuccess: true,
        removeMemberResponse,
      };

    case groupInfoPopupConstants.REMOVE_GROUP_MEMBER_FAILED:
      return {
        ...state,
        groupMemberRemoveRequestFailed: action.error,
        isRemoveGroupMemberRequesting: false,
        groupMemberRemoveRequestSuccess: false,
      };

    case groupInfoPopupConstants.UPDATE_FULL_GROUP_MEMBER_STATE:
      const { newState } = action;

      return {
        ...newState,
      };

    default:
      return state;
  }
}
