import { hospitalConstants } from "../_constants";
import { getInitialState } from "../_helpers/getInitialState";

export function hospitals(state = getInitialState("hospitals"), action) {
  switch (action.type) {
    case hospitalConstants.HOSPITAL_LIST_REQUESTING:
      return {
        ...state,
        isFetchingHospitalList: true,
        isHospitalActive: false,
      };
    case hospitalConstants.HOSPITAL_LIST_FETCHED:
      let hospitalsByIds = {};
      // const hospital = !!action?.hospital?.length
      //   ? action.hospital
      //   : [{ hospital_id: "", sid: "", friendly_name: "" }];
      const { hospital } = action;
      var cityArr = []
      hospital?.forEach((item) => {
        if (item.hospital_city) {
          cityArr.push({ name: item.hospital_city, id: item.hospital_city })
        }
        hospitalsByIds = {
          ...hospitalsByIds,
          [item.hospital_id]: item,
        };
      });
      cityArr = cityArr.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.place === value.place && t.name === value.name
        ))
      )

      return {
        ...state,
        hospitalList: hospital,
        hospitalsByIds,
        isFetchingHospitalList: false,
        isHospitalActive: true,
        totalHospitals: hospital?.length,
        cityArr: cityArr
      };
    case hospitalConstants.HOSPITAL_LIST_FAILED:
      return {
        ...state,
        hospitalFetchingError: action.error,
        isHospitalActive: false,
        isFetchingHospitalList: false,
      };

    case hospitalConstants.SET_HOSPITAL_LIST_SID:
      return {
        ...state,
        currentHospitalSid: action.sid,
      };

    case hospitalConstants.SET_HOSPITAL_LIST_DATA:
      return {
        ...state,
        currentHospitalData: action.data,
      };

    case hospitalConstants.LAST_INDEX_API_REQUESTING:
      return {
        ...state,
        isFetchingLastIndexAPI: true,
        isFetchingLastIndexAPIActive: false,
      };
    case hospitalConstants.LAST_INDEX_API_FETCHED:
      const { lastIndexAPIResponse } = action;
      return {
        ...state,
        lastIndexAPIResponse,
        isFetchingLastIndexAPI: false,
        isFetchingLastIndexAPIActive: true,
      };
    case hospitalConstants.LAST_INDEX_API_FAILED:
      return {
        ...state,
        isFetchingLastIndexAPIError: action.error,
        isFetchingLastIndexAPIActive: false,
        isFetchingLastIndexAPI: false,
      };
    case hospitalConstants.UPDATE_FULL_HOSPITAL_STATE:
      const { newState } = action;

      return {
        ...newState,
    };
    case hospitalConstants.CLOSE_PATIENT:
      return { 
        ...state,
        isDrawer:{
          patient:true, 
          hospital:false
        }
      }
    case hospitalConstants.CLOSE_HOSPITAL:
      return { 
        ...state,
        isDrawer:{
          patient:true, 
          hospital:true
        }
      }
    case hospitalConstants.OPEN_DRAWER:
      return { 
        ...state,
        isDrawer:{
          patient:false, 
          hospital:false
        }
      }

    default:
      return state;
  }
}
