import { CropSquareRounded, SquareRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, DialogContentText, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RatingIcon1, RatingIcon2, RatingIcon3, RatingIcon4, RatingIcon5 } from "../../../../../_components";
import { sendFeedback } from "../../../../../_services";

const ratingName = [
  "",
  "Very Unhappy",
  "Unhappy",
  "Neutral",
  "Happy",
  "Very Happy",
]
const anyOtherIssues = 'Any other issues'
const RatingScreen = ({ onSubmitSuccess }) => {
  const [hospitalId, appId, patientName] = useSelector(state => [state.hospitals.currentHospitalData.hospital_id, state.patients.patientInfo.appointmentId, state.patients.patientInfo.allInfo.FirstName])
  const feedbackQuestions = useSelector(state => state.config.versionConfigData?.result[0]?.patientFeedBackQuestions)
  const feedbackData = useSelector(state => state.config.commonData?.result.data[0].feedbackData)
  const [isLoading, setIsLoading] = useState(false)
  const [anyFieldValue, setAnyFieldValue] = useState("")
  const [checkboxAnswer, setCheckboxData] = useState([])
  const [checkboxOption,setCheckboxOption] = useState([])
  const [isOptional, setOptional] = useState(false)
  const [countFeedbackAnswer, setFeedbackCount] = useState(0)
  const [data, setData] = useState({
    Question_1: feedbackQuestions[0].replace('[Patient Name]', patientName),
    Answer_1: 0,
    Question_2: feedbackQuestions[1].replace('[Patient Name]', patientName),
    Answer_2: "",
    Question_3: feedbackQuestions[2],
    Answer_3: ""
  })

  useEffect(()=>{
    const feedbackAnswerArr = feedbackData[1]?.feedbackOption?.map(op => ({option:op.option,subOption:[]}))
    const feedbackOption = feedbackData[1]?.feedbackOption?.map(op => ({...op,isOpen:false}))
    setCheckboxData([...feedbackAnswerArr])
    setCheckboxOption([...feedbackOption])
  },[feedbackData])

  let shouldFormSubmit = data.Answer_1 >= 4 || countFeedbackAnswer>0 || anyFieldValue.trim()

  const selectInnerCheckBox = (e,option) =>{
    e.stopPropagation()
    let value = e.target.value
    let tempCheckboxAnswer = [...checkboxAnswer]
    
    for(let i=0; i<tempCheckboxAnswer.length; i++){
      if(tempCheckboxAnswer[i].option === option){
        if(e.target.checked){
          tempCheckboxAnswer[i].subOption.push(value)
          setFeedbackCount(prevCount => prevCount+1)
        }
        else{
          for(let j=0; j<tempCheckboxAnswer[i].subOption.length; j++){
            if(value === tempCheckboxAnswer[i].subOption[j]){
              tempCheckboxAnswer[i].subOption.splice(j,1)
              setFeedbackCount(prevCount => prevCount-1)
            }
          }
        }
      }
    }
    setCheckboxData([...tempCheckboxAnswer])
  }

  const handleOtherIssue = (value) =>{
    setAnyFieldValue(value)
    const checkBoxData = [...checkboxAnswer]
    checkBoxData.forEach(item =>{
      if(item.option === 'Any'){
        item.subOption = [value]
      }
    })
    setCheckboxData([...checkBoxData])
  }
  const handleSubmit = () => {
    setIsLoading(true)
    // const payload = { ...data, ...((isOptional && anyFieldValue.trim().length > 0) && { Answer_2: [...checkboxAnswer,{option:'Any', subOption:[anyFieldValue]}] }) }
    const payload = {...data, Answer_2: [...checkboxAnswer]}
    sendFeedback({
      hospitalId: hospitalId,
      appId: appId,
      feedbackData: payload
    }).then(res => {
      onSubmitSuccess && onSubmitSuccess(res)
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const selectedOption = (option) =>{
    let tempCheckboxOption = [...checkboxOption]
    for(let i=0; i<checkboxOption.length; i++){
      if(checkboxOption[i].option === option){
        tempCheckboxOption[i].isOpen = !checkboxOption[i].isOpen
      }
    }

    if(option === 'Any'){
      setOptional(!isOptional)
    }
    setCheckboxOption([...tempCheckboxOption])
  }
  
  const isChecked = (option,sbOption) =>{
    for(let i=0; i<checkboxAnswer.length; i++){
      if(checkboxAnswer[i].option === option){
        if(checkboxAnswer[i].subOption.includes(sbOption)){
          return true
        }
        else{
          return false
        }
      }
    }
  }
  return (
    <Box sx={{ pt: '20px' }}>
      <DialogContentText id="feedback-dialog-description" sx={{ fontSize: '12px', lineHeight: '17px', color: '#7A7A7A', mb: '10px' }}>How was your experience in treating {patientName} (Rating from 1-5):</DialogContentText>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '280px', mx: 'auto', mb: '10px' }}>
        <RatingIcon1 onClick={() => setData({ ...data, Answer_1: 1 })} sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%', boxShadow: `0px 0px 0px ${data.Answer_1 === 1 ? 2.5 : 0}px rgb(0, 38, 62)` }} />
        <RatingIcon2 onClick={() => setData({ ...data, Answer_1: 2 })} sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%', boxShadow: `0px 0px 0px ${data.Answer_1 === 2 ? 2.5 : 0}px rgb(0, 38, 62)` }} />
        <RatingIcon3 onClick={() => setData({ ...data, Answer_1: 3 })} sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%', boxShadow: `0px 0px 0px ${data.Answer_1 === 3 ? 2.5 : 0}px rgb(0, 38, 62)` }} />
        <RatingIcon4 onClick={() => setData({ ...data, Answer_1: 4 })} sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%', boxShadow: `0px 0px 0px ${data.Answer_1 === 4 ? 2.5 : 0}px rgb(0, 38, 62)` }} />
        <RatingIcon5 onClick={() => setData({ ...data, Answer_1: 5 })} sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%', boxShadow: `0px 0px 0px ${data.Answer_1 === 5 ? 2.5 : 0}px rgb(0, 38, 62)` }} />
      </Box>
      <Typography variant="body" component="p" sx={{ fontSize: '15px', color: '#7A7A7A', lineHeight: '20px', textAlign: 'center' }}>
        {data.Answer_1 !== 0 && <>Your rating: <Box component={`span`} sx={{ color: '#00263E' }}>{ratingName[data.Answer_1]}</Box></>}&nbsp;
      </Typography>
      <Box sx={{ display: (data.Answer_1 > 0 && data.Answer_1 <= 3 ? 'block' : 'none') }}>

        <Box sx={{ mt: '30px' }}>
          <Typography sx={{ fontSize: '15px' }}>Please share what went wrong during the treatment of {patientName}?</Typography>
          {feedbackData?.map(feedback =>(
            <>
              {feedback.type === 'checkbox' && 
              <>
                {checkboxOption.map(op =>(
                  <div>
                    <div className="checkboxOption" onClick={()=>selectedOption(op.option)}> <span> {op.isOpen ? '-' : '+' } </span> {op.option === 'Any'? 'Any Other Issues': op.option}</div>
                        {op.isOpen && op?.subOption.length>0 &&
                          op?.subOption.map(sbOption =>(     
                            <FormGroup
                            aria-labelledby="radio-buttons-group-label"
                            name="what-went-wrong-during-the-treatment"
                            sx={{ px: '5px', paddingLeft:'20px' }}
                            onChange={e => { selectInnerCheckBox(e,op.option) }}
                          >
                            <FormControlLabel 
                              value={sbOption}
                              control={<Checkbox sx={{ padding: '6px' }}
                              checked={isChecked(op.option,sbOption)}
                              icon={ <CropSquareRounded sx={{ color: '#FF8300', fontSize: '18px',  }} />}
                              checkedIcon={<SquareRounded sx={{ color: '#FF8300', fontSize: '18px', }} />} />}
                              label={<Typography sx={{ fontSize: '15px', color: '#7A7A7A' }}>{sbOption}</Typography>}
                            />
                            </FormGroup>
                          
                          ))
                        }
                  </div>
                ))}
              </>
            }
            </>
          ))}

          {isOptional &&
            <Box sx={{ px: '10px', mt: 1 }}>
              <Typography sx={{ mt: 2, mb: '10px', fontSize: '15px' }}>Any other issues ( please comment )</Typography>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={2}
                value={anyFieldValue}
                sx={{ width: '100%', ".MuiOutlinedInput-root": { borderRadius: '10px' } }}
                onChange = {(e)=>handleOtherIssue(e.target.value)}
              />
            </Box>
          }
          <Box sx={{ px: '10px' }}>
          <Typography sx={{ mt: 2, mb: '10px', fontSize: '15px' }}>How can we improve? ( please comment )</Typography>
            <TextField
              id="how-can-we-improve"
              multiline
              rows={2}
              sx={{ width: '100%', ".MuiOutlinedInput-root": { borderRadius: '10px' } }}
              onKeyPress={(e) => { setData({ ...data, Answer_3: (e.target.value) }) }}
            />
          </Box>
        </Box>

      </Box>
      <Box sx={{ textAlign: 'center', mt: '30px' }}>
        <LoadingButton loading={isLoading} variant={`contained`} color={`warning`} onClick={()=>handleSubmit()} disabled={!shouldFormSubmit} sx={{ backgroundColor: '#ff8400', width: '100%', maxWidth: '320px', fontSize: '16px', borderRadius: '10px' }}>Submit</LoadingButton>
      </Box>
    </Box>
  );
}

export default RatingScreen;