import { booleanStatesConstants } from "../../_constants";

export function updateIsUploaded(isUploadedFile) {
    return {
        type: booleanStatesConstants.UPDATE_IS_UPLOADED,
        payload: { isUploadedFile },
    };
}

export function updateIsAmountFill(isAmountFillFile) {
    return {
        type: booleanStatesConstants.UPDATE_IS_AMOUNT_FILL,
        payload: { isAmountFillFile },
    };
}
export function updateIsUploadedDisFile(isUploadedDisFile) {
    return {
        type: booleanStatesConstants.UPDATE_IS_UPLOADED_DIS_FILE,
        payload: { isUploadedDisFile },
    };
}

export function updateIsAmountDisFill(isAmountFillDisFile) {
    return {
        type: booleanStatesConstants.UPDATE_IS_AMOUNT_FILL_DIS_FILE,
        payload: { isAmountFillDisFile },
    };
}

export const booleanStatesActions = {
    updateIsUploaded,
    updateIsAmountFill,
    updateIsAmountDisFill,
    updateIsUploadedDisFile
};
