import { } from '../_constants/chatBox.constants';
import { getTokenFromLocal, handleResponse } from "../_helpers/handleResponseMiddleware";
let apiUrl = process.env.REACT_APP_BASE_URL;
let commonApiUrl = process.env.REACT_APP_BASE_URL_COMMON;

export function getChecklistPatient(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/getAppointmentWiseDocList`, config)
    .then(handleResponse)
    .then((res) => {
      return res.result;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}
export function getChecklistHospital(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/getHospitalWiseDocList`, config)
    .then(handleResponse)
    .then((res) => {
      return res.result;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}

export function getPatientsListPerformance(params) {
  let user = getTokenFromLocal();
  let token = user ? user.access_token : null;

  const config = {
    method: "POST",
    headers: {
      mobileplatform: "web",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify({ ...params }),
  };

  return fetch(`${apiUrl}/api/patientsListPerformance`, config)
    .then(handleResponse)
    .then((res) => {
      return res.result;
    })
    .catch((error) => {
      console.error(error);
    })
    .then((response) => {
      // always executed
      return response;
    });
}


export const checklistService = {
  getChecklistPatient,
  getChecklistHospital,
  getPatientsListPerformance
};
