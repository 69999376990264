import { alertActions, configActions } from ".";
import { userConstants } from "../_constants";
import { getTokenFromLocal } from "../_helpers/handleResponseMiddleware";
import { userService } from "../_services";

function getAuth(params, googleLogin) {
  const { mobile } = params;
  return (dispatch) => {
    dispatch(request({ mobile }));
    dispatch(configActions.showSpinningLoader());

    userService.getAuth(params, googleLogin).then(
      (user) => {
        dispatch(success(user));
        if (googleLogin) {
          dispatch(
            userActions.setUserMobileNumber({
              userMobile: user.otpMobile,
            })
          );
          localStorage.setItem("userMobile", user.otpMobile);
        }
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
        dispatch(configActions.hideSpinningLoader());
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function getOtp(params) {
  return (dispatch) => {
    dispatch(request(params.mobile));

    userService.getOtp(params).then(
      (user) => {
        dispatch(success(user));
        // history.push("/home");
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    ).catch(err=>{
      
    });
  };

  function request(user) {
    return { type: userConstants.OTP_REQUEST, user };
  }
  function success(response) {
    return { type: userConstants.OTP_SUCCESS, otpResponse: response };
  }
  function failure(error) {
    return { type: userConstants.OTP_FAILURE, otpResponse: error };
  }
}

function logout(useAPI = true) {
  return (dispatch) => {
    // dispatch(request());
    dispatch(configActions.showSpinningLoader());

    userService.logout(useAPI).then(
      (user) => {
        dispatch(success(user));
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        // dispatch(failure(error));
        dispatch(alertActions.error(error));
        dispatch(configActions.hideSpinningLoader());
      }
    );

    function request(user) {
      return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
      return { type: userConstants.LOGOUT };
    }
    function failure(error) {
      return { type: userConstants.LOGIN_FAILURE, error };
    }
  };
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(user) {
    return { type: userConstants.GETALL_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

function checkAccessToken(navigate, url) {
  return (dispatch) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());

    userService.checkAccessTokenAuth(dispatch, navigate).then(
      (user) => {
        dispatch(success(user));
        // dispatch(configActions.hideSpinningLoader());
        // navigate('/');
      },
      (error) => {
        console.error(error);

        const user = getTokenFromLocal();
        if (!!user?.refresh_token)
          dispatch(userActions.checkRefreshToken(navigate, url));
        else {
          dispatch(failure(error));
          dispatch(configActions.hideSpinningLoader());
          dispatch(logout(false));
        }
      }
    );
  };

  function request() {
    return { type: userConstants.LOGIN_REQUEST };
  }
  function success(user) {
    // window.location.href = "/home";
    // history.push("/home");

    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    if (error.error === "invalid_token") {
      return { type: userConstants.LOGIN_FAILURE, error: {} };
    }
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function checkRefreshToken(navigate, url) {
  return (dispatch) => {
    dispatch(configActions.showSpinningLoader());

    dispatch(request());
    userService.checkRefreshToken().then(
      (user) => {
        dispatch(success(user));
        // dispatch(configActions.hideSpinningLoader());
        // navigate(url);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(logout(false));
        dispatch(configActions.hideSpinningLoader());
        navigate("/login/");
      }
    );
  };

  function request() {
    return { type: userConstants.REFRESH_TOKEN_VERIFY_RQUEST };
  }
  function success(user) {
    // history.push("/home");
    return { type: userConstants.REFRESH_TOKEN_VERIFY_SUCCESS, user };
  }
  function failure(error) {
    // history.push("/login");
    return { type: userConstants.REFRESH_TOKEN_VERIFY_FAILURE, error };
  }
}

const setUserMobileNumber = (params) => {
  const { userMobile } = params;

  return { type: userConstants.SET_USER_MOBILE, userMobile };
};

const getRolesList = (params) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());

    userService.getRolesList(params).then(
      (user) => {
        dispatch(success(user));
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.GET_ROLES_LIST_REQUEST };
  }
  function success(rolesListResponse) {
    return { type: userConstants.GET_ROLES_LIST_SUCCESS, rolesListResponse };
  }
  function failure(error) {
    return { type: userConstants.GET_ROLES_LIST_FAILED, error };
  }
};

const hadleUserRegister = ({ params, navigate }) => {
  return (dispatch) => {
    dispatch(request());
    dispatch(configActions.showSpinningLoader());

    userService.hadleUserRegister(params).then(
      (user) => {
        dispatch(success(user));
        dispatch(configActions.hideSpinningLoader());
      },
      (error) => {
        dispatch(configActions.hideSpinningLoader());
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.HANDLE_USER_REGISTER_REQUEST };
  }
  function success(res) {
    const { status = "", description = "", result } = res;

    // swal({
    //   title: status,
    //   text: description,
    //   icon: status.toLowerCase() === "success" ? "success" : "error",
    // }).catch(() => {
    //   window.location.href = "/login";
    //   window.location.reload();
    // });

    return {
      type: userConstants.HANDLE_USER_REGISTER_SUCCESS,
      registerUserResponse: res,
    };
  }
  function failure(error) {
    return { type: userConstants.HANDLE_USER_REGISTER_FAILED, error };
  }
};

const updateFullState = (newState) => {
  return {
    type: userConstants.UPDATE_FULL_AUTH_STATE,
    newState,
  };
};

const updateNotificationPermissions = (params) => {
  return (dispatch) => {
    userService.updateNotificationPermissions(params).then(res => {
      let token = params.fcmToken
      dispatch({ type: userConstants.UPDATE_NOTIFICATION_PERMISSIONS, token })
    })
  }
}
export const userActions = {
  getAuth,
  logout,
  getAll,
  getOtp,
  checkAccessToken,
  checkRefreshToken,
  setUserMobileNumber,
  getRolesList,
  hadleUserRegister,
  updateFullState,
  updateNotificationPermissions
};
