import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DailogHWA } from '../../../_components';
import UserSanityTable from './userSanityTable';
import { documentsPopupActions} from '../../../_actions';

export default function UserSanity(){
    const dispatch = useDispatch();
    const userSanityPopup = useSelector(state=> state.documentsPopup.userSanityPopup)

    return(
        <DailogHWA
        PaperProps={{ sx: {minWidth: [ '300px','600px', '720px', '980px' ], }}} 
        open={window.location.href.includes('localhost') ? false:userSanityPopup}
        // onClose = {()=>dispatch(documentsPopupActions.hideSanityPopup())}
        title={'User Management'}
        closeProps={{display:'none'}}
        >
          <UserSanityTable/>
        </DailogHWA>
    )
}