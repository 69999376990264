import { Close } from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import { Box, ClickAwayListener, Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { RatingIcon1, RatingIcon2, RatingIcon3, RatingIcon4, RatingIcon5 } from "../../../_components";
import noListImage from "./../../../assets/noList.png";



const PatientReviews = () => {


  const hospitalReview = useSelector(state => state.pcScore.hospitalReview)
  const showLoader = useSelector(state => state.config.showSpinningLoader)


  const [patientReviews, setPatientReviews] = useState(null)
  useEffect(() => {

    if (hospitalReview) {
      let reviews = hospitalReview.map(review => {
        const { FirstName, rating,disease } = review
        return {
          date: review['IPD Date'],
          name: FirstName,
          disease:disease,
          reviewQuestion: [{
            ques: 'Infrastructure',
            ans: 'Very Happy',
            emoji: rating?.Infrastructure
          },
          {
            ques: 'Staff Hospitality',
            ans: 'Happy',
            emoji: rating["Staff Hospitality"]
          }]
        }
      })

      setPatientReviews(reviews)
    }


  }, [hospitalReview])


  return (
    <Box sx={{ px: ['10px', '20px', '40px', '80px'], pb: '30px' }} data-component={'PatientReviews'}>
      {showLoader===false&&<Grid container columnSpacing={3}>
        {patientReviews?.length > 0 && <Grid item xs={12} md={6}>
          <Box sx={{ borderRadius: '5px', background: '#fff', py: ['10px', '15px'], boxShadow: '2px 2px 6px #0000000D', height: '100%' }}>
            {patientReviews?.map((review, idx) => (idx % 2 === 0 ? <ReviewCared key={idx} {...review} /> : null))}
          </Box>
        </Grid>}
        {patientReviews?.length > 1 && <Grid item xs={12} md={6}>
          <Box sx={{ borderRadius: '5px', background: '#fff', py: ['10px', '15px'], boxShadow: '2px 2px 6px #0000000D', height: '100%' }}>
            {patientReviews?.map((review, idx) => (idx % 2 !== 0 ? <ReviewCared key={idx} {...review} /> : null))}
          </Box>
        </Grid>}
        {
          patientReviews!=null&&patientReviews?.length===0&&<div className="noDataFound" style={{justifyContent:'center',width:"100%",position: "absolute",
          top:"40%",
          left:"0px"
         }} >
          <div style={{fontSize:"2rem"}}>Oops! No List Found</div>
          <div style={{height:"265px"}}> <img style={{height:"100%",width:"100%",objectFit:"contain"}} src={noListImage} alt="no list img" /></div>
        </div>
        }
      </Grid>}
    </Box>
  )
}
export default PatientReviews;

const ReviewCared = ({ name, date, disease, reviewQuestion }) => {
  const [patientInfo, setPatientInfo] = useState({ show: false })

  const rating = [
    <RatingIcon1 sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%' }} />,
    <RatingIcon2 sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%' }} />,
    <RatingIcon3 sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%' }} />,
    <RatingIcon4 sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%' }} />,
    <RatingIcon5 sx={{ width: '40px', cursor: 'pointer', borderRadius: '50%' }} />,
  ]

  const ratingName = [
    "Very Unhappy",
    "Unhappy",
    "Neutral",
    "Happy",
    "Very Happy",
  ]


  const getPatientInfo = ({ idx }) => {
    var tempPatientInfo = {}
    if (idx === 0) {
      tempPatientInfo.position = 'left'
      tempPatientInfo['message'] = 'Patient feedback based on parameters like Hospital cleanliness, room & OT Facilities'
    } else if (idx === 1) {
      tempPatientInfo.position = 'right'
      tempPatientInfo['message'] = 'Patient Feedback based on parameters like Hospital Staff compassion & courteousness'
    }
    tempPatientInfo["show"] = true
    setPatientInfo(tempPatientInfo)

  }
  return (
    <Box sx={{ px: ['10px', '20px', '30px'], py: ['10px', '10px', '15px'] }} data-component={'ReviewCared'}>
      <Box sx={{ borderBottom: 'none' }}>
        <Box sx={{ display: 'flex', pt: ['10px', '10px', '20px'], alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', border: '2px solid #F5F5F5', borderBottom: 'none', position: 'relative', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
          <Box sx={{ px: ["10px", '10px', '20px', "30px"], color: '#3D7CC9', flexBasis: ['100%', '100%', '50%'], fontSize: ['16px', '16px', '18px', '20px'], fontWeight: [600] }}>{name}</Box>
          <Box sx={{ px: ["10px", '10px', '20px', "30px"], color: '#818181', flexBasis: ['100%', '100%', '50%'], fontSize: ['16px', '16px', '18px', '20px'] }}>{moment(date).format('DD MMMM YYYY')}, {disease}</Box>
        </Box>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', border: '2px solid #F5F5F5', borderTop: 'none', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px', position: "relative", pt: ['10px', '15px', '25px'] }} container>

          {reviewQuestion.map((question, index) => {
            const { ques, ans, emoji } = question
            return (
              <>
                <Grid key={index} item xs={6}>
                  <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', paddinginline: "30px", paddingTop: "15px", marginBottom: "15px", flexBasis: '50%', borderRight: index === 0 ? "2px solid #F5F5F5" : '0' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginLeft: ['0px', '-10px', '-20px'], pb: '10px' }}><InfoIcon onClick={() => getPatientInfo({ idx: index })} sx={{ fontSize: ['18px', '18px', '22px'], color: "#3D7CC9", marginRight: ['0px', '0px', '20px'] }} /> <Box sx={{ color: '#00263E', fontSize: ['14px', '16px', '18px', '20px'] }}>{ques}</Box></Box>
                    <Box sx={{ pb: '10px' }}>
                      {rating[emoji - 1]}
                    </Box>
                    <Box sx={{ color: '#3D7CC9', fontSize: ['14px', '16px', '18px', '20px'], fontWeight: 700, pb: '10px', mx: ['auto', 'auto', 0] }}>{ratingName[emoji - 1]}</Box>
                  </Box>
                </Grid>
              </>
            )
          })}
          <Box sx={{ width: "100%", position: 'relative' }}>
            {patientInfo?.show &&
              <ClickAwayListener onClickAway={() => setPatientInfo({ show: false })}>
                <Grid sx={{ ...(patientInfo?.position === 'left' ? { left: 0 } : { right: 0 }), width: "100%", maxWidth: '500px', top: "-20px", position: "absolute", backgroundColor: "#3D7CC9", color: "#fff", borderRadius: '5px', boxShadow: '2px 2px 6px #0000000D',zIndex:1 }}>
                  <Grid sx={{ position: "relative", p: ['10px', '15px', '20px', '25px', '30px'] }}>
                    <Box sx={{
                      background: '#fff',
                      width: ['16px', '16px', '20px'],
                      height: ['16px', '16px', '20px'],
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: ['1px', '1px', '2px'],
                      position: 'absolute',
                      zIndex: 1,
                      top: 0,
                      cursor: "pointer",
                      border: "1px solid #f5f5f5",
                      transform: 'translateY(-50%)',
                      ...(patientInfo?.position === 'left' ? { left: '10px' } : { right: '10px' })
                    }}
                      onClick={() => { setPatientInfo({ show: false }) }}
                    >
                      <Close sx={{ width: '100%', color: '#3D7CC9' }} />
                    </Box>
                    <Grid item sx={{ fontWeight: 500, fontSize: ['14px', '16px', '18px', '20px'], lineHeight: ['18px', '20px', '22px', '24px'] }}> {patientInfo?.message}</Grid>
                  </Grid>
                </Grid>
              </ClickAwayListener>}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}